import { Schema } from '@app/src/types/schemas'
import { ConstVars, DefaultImages, Solutions } from '@app/src/wf-constants'

const getProviderSchema = (solution?: string) => {
  const providerSchema: Schema = {
    name: 'supplier',
    defaultImage: DefaultImages.Organization,
    items: [
      {
        accessor: 'id',
        translationId: 'general.id',
        width: 100,
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'id',
      },
      {
        accessor: ConstVars.SupplierAccessors.Name,
        fieldType: 'company',
        translationId: 'schemas.supplier.name',
        width: 300,
        display: {
          table: true,
          details: false,
          export: true,
        },
        exportPath: 'name',
      },
      {
        accessor: ConstVars.SupplierAccessors.ReferralContact,
        translationId: 'schemas.supplier.referralContact',
        fieldType: 'user',
        width: 200,
        display: {
          export: true,
        },
        exportPath: 'referralContact.name',
      },
      {
        accessor: ConstVars.SupplierAccessors.ReferralContact,
        translationId: 'schemas.supplier.referralContact',
        fieldType: 'user',
        width: 200,
        display: {
          export: true,
        },
        exportPath: 'referralContact.email',
      },
      {
        accessor: ConstVars.SupplierAccessors.OwnerUser,
        translationId: 'schemas.supplier.ownerUser',
        fieldType: 'user',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'ownerUser.name',
      },
      {
        accessor: ConstVars.SupplierAccessors.Country,
        translationId: 'schemas.supplier.country',
        fieldType: 'country',
        width: 200,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'country.name',
      },
      {
        accessor: ConstVars.SupplierAccessors.WebsiteAddress,
        translationId: 'schemas.supplier.websiteAddress',
        fieldType: 'link',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'websiteAddress',
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkStatus,
        translationId: 'schemas.supplier.linkStatus',
        width: 150,
        display: {
          table: true,
          details: false,
          export: true,
        },
        exportPath: 'linkStatus',
      },
      {
        accessor: ConstVars.SupplierAccessors.VatNumber,
        translationId: 'schemas.supplier.vatNumber',
        width: 250,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'vatNumber',
      },
      {
        accessor: ConstVars.SupplierAccessors.RegistrationNumber,
        translationId: 'schemas.supplier.registrationNumber',
        width: 300,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'registrationNumber',
      },
      {
        accessor: ConstVars.SupplierAccessors.CreatedAt,
        translationId: 'schemas.supplier.createdAt',
        fieldType: 'date',
        width: 250,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'createdAt',
      },
      {
        accessor: ConstVars.SupplierAccessors.Duns,
        translationId: 'schemas.organization.duns',
        width: 350,
        display: {
          table: true,
          details: true,
          export: true,
        },
        exportPath: 'duns',
      },
      {
        accessor: ConstVars.SupplierAccessors.CustomId,
        translationId: 'schemas.supplier.customId',
        width: 350,
        display: {
          table: false,
          details: true,
          export: true,
        },
        exportPath: 'customId',
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedOrganization,
        translationId: 'schemas.supplier.linkedOrganization',
        fieldType: 'company',
        width: 400,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedRegistrationNumber,
        translationId: 'schemas.supplier.linkedRegistrationNumber',
        width: 250,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedVatNumber,
        translationId: 'schemas.supplier.linkedVatNumber',
        width: 250,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedCountry,
        translationId: 'schemas.supplier.linkedCountry',
        fieldType: 'country',
        width: 250,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedPrimaryAddress,
        translationId: 'schemas.supplier.linkedPrimaryAddress',
        fieldType: 'address',
        width: 350,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.LinkedWebsiteAddress,
        translationId: 'schemas.supplier.linkedWebsiteAddress',
        fieldType: 'link',
        width: 300,
        display: {
          table: false,
          details: true,
          export: false,
        },
      },
      {
        accessor: ConstVars.SupplierAccessors.ProviderApprovalStatus,
        translationId: 'schemas.supplier.providerApprovalStatus',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance,
        },
        exportPath: 'providerApprovalStatusValue',
      },
      {
        accessor: ConstVars.SupplierAccessors.ProviderActivityStatus,
        translationId: 'schemas.provider.activityStatus',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance,
        },
        exportPath: 'providerActivityStatusValue',
      },
      {
        accessor: ConstVars.SupplierAccessors.FinalRiskRating,
        translationId: 'schemas.provider.finalRiskRating',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'finalRiskRatingValue',
      },
      {
        accessor: ConstVars.SupplierAccessors.ProviderPriority,
        translationId: 'schemas.provider.priority',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'providerPriorityValue',
      },
      {
        accessor: ConstVars.SupplierAccessors.SupplierUsage,
        translationId: 'schemas.provider.supplierUsage',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance,
        },
        exportPath: 'supplierUsageValue',
      },
      {
        accessor: ConstVars.SupplierAccessors.Tier,
        translationId: 'schemas.provider.tier',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: solution !== Solutions.Finance,
        },
        exportPath: 'tier',
      },
      {
        accessor: ConstVars.SupplierAccessors.CustomCategory,
        translationId: 'schemas.supplier.customCategories',
        fieldType: '',
        width: 300,
        display: {
          table: false,
          details: false,
          export: true,
        },
        exportPath: 'categoryOptionsNames',
      },
    ],
    actions: ['open', 'edit', 'delete'],
    relatedContentFilters: {},
  }

  return providerSchema
}

export default getProviderSchema
