import { Check } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Stack, Typography, alpha } from '@mui/material'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'
import DrawerViewReferralContact from './DrawerViewReferralContact'

interface DrawerViewInviteSentToReferralContactProps {
  provider: Provider
}

const DrawerViewInviteSentToReferralContact: React.FC<DrawerViewInviteSentToReferralContactProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  return (
    <DrawerView
      showBackButton={false}
      title={formatMessage({ id: 'referral.inviteSent.title' })}
      subTitle={provider.name}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'referral.inviteSent.viewContact' }),
          variant: 'outlined',
          color: 'primary',
          onClick: () => openDrawer(<DrawerViewReferralContact provider={provider} showBackButton={false} />),
        },
      ]}
    >
      <Stack flexGrow={1} alignItems="center" justifyContent="center" spacing={2}>
        <Avatar
          variant="circular"
          size={AvatarSize.XXL}
          sx={({ palette }) => ({
            backgroundColor: alpha(palette.success.main, 0.5),
          })}
        >
          <Check color="primary" fontSize="large" />
        </Avatar>
        <Typography variant="subtitle1" paragraph>
          {formatMessage({ id: 'referral.inviteSent.successMessage' })}
        </Typography>
      </Stack>
      <Alert
        icon={<InfoOutlinedIcon />}
        sx={{
          alignItems: 'flex-start',
          backgroundColor: 'common.white',
        }}
      >
        {formatMessage({ id: 'referral.inviteSent.disclaimer' })}
      </Alert>
    </DrawerView>
  )
}

export default DrawerViewInviteSentToReferralContact
