import DateDisplay from '@app/src/components/DateDisplay'
import RenderFileDownload from '@app/src/components/RenderFileDownload'
import VerificationButton from '@app/src/pages/Questionnaire/Accessor/Actions/VerificationButton'
import { AnswerStatus, ExternalVerificationStatus, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'
import VerificationStatus from '../../VerificationStatus'
import { getResponseValueTypes } from '../../utils'
import SkippedQuestionCard from './SkippedQuestionCard'

const useStyles = makeStyles({
  value: {
    wordBreak: 'break-word',
  },
})

export interface ResponseValueProps {
  item: ResponseItem
  questionType: string
  unitSymbol: string | null
  classes?: {
    responseItem: string
  }
}

const ResponseValue = ({ item, questionType, unitSymbol, ...props }: ResponseValueProps): JSX.Element => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { displayAsFile, displayAsOptions, selectedOptions, hasValue } = getResponseValueTypes(questionType, item)

  const isSomeExternalStatusCorrectionNeeded = item.verifications?.some(
    x => x.externalVerificationStatus === ExternalVerificationStatus.CorrectionNeeded,
  )

  const displayValue = (): React.ReactNode => {
    if (item?.answerStatus !== AnswerStatus.Answered) {
      return <SkippedQuestionCard item={item} />
    }

    if (displayAsFile) {
      return (
        <RenderFileDownload
          fileName={item.uploadReferences?.[0].originalFileName}
          id={item.uploadReferences?.[0].id ?? 0}
        >
          {item.expiresAt && (
            <Box display="flex" alignItems="flex-end" mr={2}>
              <Typography variant="caption">
                {formatMessage({ id: 'questionnaire.expiresAt' }, { date: <DateDisplay value={item.expiresAt} /> })}
              </Typography>
            </Box>
          )}
        </RenderFileDownload>
      )
    }

    if (displayAsOptions) {
      if (selectedOptions?.length) {
        return <Typography>{selectedOptions?.join(', ')}</Typography>
      }
    }

    if (hasValue) {
      return (
        <Typography className={classes.value}>
          {item.answer || ''} {unitSymbol || ''}
        </Typography>
      )
    }

    return <Typography>{formatMessage({ id: 'reporting.noResponse' })}</Typography>
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={props.classes?.responseItem}
        px={isSomeExternalStatusCorrectionNeeded ? 2 : 0}
        py={isSomeExternalStatusCorrectionNeeded ? 1 : 0}
        bgcolor={isSomeExternalStatusCorrectionNeeded ? palette.warning.light : palette.common.white}
        borderRadius={1}
      >
        <Box flexGrow={1}>{displayValue()}</Box>
        <VerificationButton item={item} />
      </Box>

      <VerificationStatus {...item} />
    </>
  )
}

export default ResponseValue
