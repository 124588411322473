import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import ProviderDetails from '@app/src/components/ResourceDetails/ProviderDetails'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import Tabs from '@app/src/components/Tabs'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useRelatedTabs from '@app/src/hooks/relatedTabs'
import OrganizationImage from '@app/src/pages/SolutionSelector/OrganizationImage'
import { getSchemaByResourceType } from '@app/src/schemas'
import { Provider } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { History } from 'history'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, generatePath, useParams } from 'react-router-dom'
import Content, { AllTabs } from './Content'

export interface ResourceExplorerSceneProps {
  history?: History
  match: {
    path: string
    params: {
      resourceType: string
      resourceId: string
      activeTabParam: string
      view?: string
    }
  }
}

const ResourceExplorerScene = (props: ResourceExplorerSceneProps): JSX.Element | null => {
  const { history } = props
  const { resourceType, resourceId, activeTabParam } = useParams<{
    resourceType: string
    resourceId: string
    activeTabParam: string
    view?: string
  }>()
  const { scope } = useAuthentication()

  const { data: provider, isLoading: isLoadingProvider } = useFetchResourceWithPost<Provider>({
    endpoint: endpoints.provider(resourceId),
    key: [FetchKey.Provider, resourceId],
    body: [
      'country',
      'organization.image',
      'organization.locations',
      'organization.country',
      'organization.contacts.user',
      'ownerUser',
      'categoryOptions.category',
      'organization.industryNaceCode',
      'organization.subIndustryNaceCode',
    ],
  })

  const tabs = useRelatedTabs()

  const handleChange = (value: string): void => {
    const selectedTab = tabs.find(t => t.type === value)
    if (history && selectedTab) {
      history.push(
        generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam: selectedTab.type,
          view: selectedTab?.view,
        }),
      )
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!activeTabParam) {
    return (
      <Redirect
        to={generatePath(paths.resourceExplorer, {
          resourceType,
          resourceId,
          activeTabParam: tabs?.[0]?.type,
        })}
      />
    )
  }

  const hasTab = tabs.some(tab => tab.type === activeTabParam)
  if (!hasTab) {
    return null
  }

  const isValidTabParam = (param: string): param is AllTabs => {
    const lowercasedParam = param.toLowerCase()
    return Object.values(AllTabs)
      .map(value => value.toLowerCase())
      .includes(lowercasedParam)
  }

  if (isLoadingProvider) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack direction="row" flexGrow={1}>
      <Helmet>
        <title>{provider ? getOrganizationDisplayName(provider) : 'Resource'}</title>
      </Helmet>

      <Stack minWidth={350} maxWidth={350} mt={4}>
        <OrganizationImage
          imageUrl={provider?.organization?.image?.url}
          organizationName={provider ? getOrganizationDisplayName(provider) : ''}
        />
        <Typography variant="h6" paragraph textAlign="center" mt={2}>
          {provider ? getOrganizationDisplayName(provider) : ''}
        </Typography>
        {provider && <ProviderDetails provider={provider} />}
      </Stack>

      {tabs?.length > 0 && (
        <Stack flexGrow={1} overflow="hidden">
          <Tabs activeTab={activeTabParam} tabs={tabs} handleChange={(_, value) => handleChange(value)} />
          <Content
            parentResourceType={resourceType}
            provider={provider}
            schema={getSchemaByResourceType(activeTabParam, scope.solution)}
            activeTabParam={isValidTabParam(activeTabParam) ? activeTabParam : AllTabs.Overview}
            {...props}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default ResourceExplorerScene
