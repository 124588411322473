import { Config } from '@app/src/config'

interface NotificationSeverityTypes {
  success: 'success'
  error: 'error'
  info: 'info'
  warning: 'warning'
}

export const ConstVars = {
  SupplierAccessors: {
    Name: 'name',
    RegistrationNumber: 'registrationNumber',
    VatNumber: 'vatNumber',
    GlnNumber: 'glnNumber',
    Country: 'country.countryCode',
    CustomId: 'customId',
    WebsiteAddress: 'websiteAddress',
    LinkStatus: 'linkStatus',
    CreatedAt: 'createdAt',
    LinkedOrganization: 'organization',
    LinkedRegistrationNumber: 'organization.registrationNumber',
    LinkedVatNumber: 'organization.vatNumber',
    LinkedGlnNumber: 'organization.glnNumber',
    LinkedCountry: 'organization.country.countryCode',
    LinkedPrimaryAddress: 'organization.locations',
    LinkedWebsiteAddress: 'organization.websiteAddress',
    OwnerUser: 'ownerUser',
    ReferralContact: 'referralContact',
    PublicContact: 'publicContact',
    ProviderApprovalStatus: 'providerApprovalStatus',
    ProviderActivityStatus: 'providerActivityStatus',
    FinalRiskRating: 'finalRiskRating',
    ProviderPriority: 'providerPriority',
    SupplierUsage: 'supplierUsage',
    Tier: 'tier',
    CustomCategory: 'categoryOptions.name',
    Duns: 'duns',
  },
  ContactsAccessors: {
    Contact: '*',
    Organization: 'creatorOrganization',
    Email: 'user.email',
    Position: 'position',
    Phone: 'phoneNumber',
    Linked: 'linked',
    CreatedAt: 'createdAt',
  },
  MappingNodeAccessors: {
    MappingNode: '*',
  },
  UsersAccessors: {
    Name: 'name',
    Email: 'email',
    Position: 'position',
    Phone: 'phoneNumber',
    CreatedAt: 'createdAt',
  },
  ResponseItemsAccessors: {
    Sender: 'response.request.creatorOrganization',
    RequestTemplate: 'response.request.title',
    Responder: 'response.request.responderObject',
    Object: 'requestItem.description',
    Target: 'response.request.targetObject.name',
    TargetType: 'response.request.targetObjectType',
    HasAnswered: 'isAnswered',
    AnswerSet: 'isValueSet',
    Answer: 'answer',
    Unit: 'unit',
    ResponseDate: 'response.submittedAt',
    InternalVerificationStatus: 'internalVerificationStatus',
    InternalVerificationComment: 'internalVerificationComment',
    ExternalVerificationStatus: 'externalVerificationStatus',
    ExternalVerificationComment: 'externalVerificationComment',
    ResponseInternalVerificationStatus: 'response.internalVerificationStatus',
    ResponseInternalVerificationComment: 'response.internalVerificationComment',
    ResponseExternalVerificationStatus: 'response.externalVerificationStatus',
    ResponseExternalVerificationComment: 'response.externalVerificationComment',
    ExpiresAt: 'expiresAt',
    PeriodDisplayName: 'requestItem.periodDisplayName',
    PeriodStartsAt: 'requestItem.periodStartsAt',
    PeriodEndsAt: 'requestItem.periodEndsAt',
    QuestionType: 'requestItem.questionType.name',
    Section: 'requestItem.section.title',
    RequestItemTemplateId: 'requestItem.questionId',
    ActivityStatus: 'response.request.subscriptions[0].target.activityStatus',
    FinalRiskRating: 'response.request.subscriptions[0].target.finalRiskRating',
    Priority: 'response.request.subscriptions[0].target.priority',
    ApprovalStatus: 'response.request.subscriptions[0].target.providerApprovalStatus',
    Tier: 'response.request.subscriptions[0].target.tier',
    SupplierUsage: 'response.request.subscriptions[0].target.supplierUsage',
    CustomCategory: 'response.request.subscriptions[0].target.categoryOptions.name',
    CustomId: 'response.request.subscriptions[0].target.customId',
    Country: 'response.request.subscriptions[0].target.country.name',
    Duns: 'response.request.subscriptions[0].target.duns',
    LinkStatus: 'response.request.subscriptions[0].target.linkStatus',
    Comment: 'comment',
  },
  OrganizationAccessors: {
    Name: 'name',
    RegistrationNumber: 'registrationNumber',
    VatNumber: 'vatNumber',
    GlnNumber: 'glnNumber',
    Country: 'country.countryCode',
    PrimaryAddress: 'location.formattedAddress',
    WebsiteAddress: 'websiteAddress',
  },
  RequestTemplatesAccessors: {
    Title: 'title',
    Creator: 'creatorOrganization',
    CreationDate: 'createdAt',
  },
  AuditLogsAccessors: {
    CreatedAt: 'createdAt',
    ServiceType: 'serviceType',
    SourceEventType: 'sourceEventType',
    SourceObjectType: 'objectType',
    BeforeSnapshot: 'beforeSnapshot',
    AfterSnapshot: 'afterSnapshot',
    CreatorUser: 'creatorUser.name',
    CreatorOrganization: 'creatorOrganization.name',
    LogIdentity: 'logIdentity.userId',
  },
  NotificationAccessors: {
    CreatorOrganization: 'creatorOrganization',
    Service: 'service',
    Event: 'sourceEventType',
    ObjectTitle: 'objectTitle',
    NotificationDate: 'createdAt',
    Seen: 'seen',
    Link: 'link',
    Object: 'details.title',
  },
  ColleaguesAccessors: {
    Name: 'name',
    Email: 'email',
    Position: 'position',
    Phone: 'phoneNumber',
    CreatedAt: 'createdAt',
    Role: 'role',
  },
  InvitationAccessors: {
    Name: 'name',
    Email: 'email',
    Role: 'role',
    Status: 'status',
    CreatedAt: 'createdAt',
  },
  SubscriptionAccessors: {
    CreatorOrganization: 'creatorOrganization',
    RequestTitle: 'request.title',
    CreatedAt: 'createdAt',
  },
  EvaluationAccessors: {
    Target: 'targetObject.name',
    FrameworkSection: 'indicator.section.title',
    FrameworkIndication: 'indicator.description',
    Value: 'value',
    Unit: 'indicator.unit.symbol',
    Period: 'periodStartsAt',
    Evidence: 'evidence',
    Framework: 'indicator.section.framework.title',
    CreatedAt: 'createdAt',
  },
  RiskAccessors: {
    Name: 'parentObject.name',
    Country: 'country.name',
  },
}

export const NotificationSeverity: NotificationSeverityTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
}

export const DEFAULT_TABLE_PAGE_SIZE = 25

export const MIN_FILTER_OPTIONS_TO_SHOW_SEARCH_BAR = 10

export const DEFAULT_LANGUAGE = 'en-US'

export const DEFAULT_CHART_COLORS = ['#3b7eb8', '#1abc9c', '#f39c12', '#34495e', '#bdc3c7', '#9b59b6']

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCtsjwyYnglUqiZe9ws0hxKs36-oJZWwu0'

export const MAX_PAGE_CONTENT_WIDTH = 1195

export const WF_SYSTEM_USER = 1
export const WF_ORGANIZATION_ID = 1

export const DefaultImages = {
  User: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSS7BpholXJ1BnoyDTPEMHBVmLleGWnUYJAvA&usqp=CAU',
  Organization: `${Config.STORAGE_ACCOUNT_URL}/app-imageuploads/default_organization_image.png`,
}

export const Solutions = {
  Investment: 'investing',
  Sourcing: 'sourcing',
  SourcingStart: 'sourcingstart',
  Resources: 'resources',
  Transparency: 'transparency',
  Management: 'management',
  Finance: 'finance',
}

export enum Solution {
  TRANSPARENCY = 'transparency',
  INVESTMENT = 'investing',
  SOURCING = 'sourcing',
  FINANCE = 'finance',
  MANAGEMENT = 'management',
  OTHER = 'other',
}

export enum AccountType {
  TRANSPARENCY = 'transparency',
  INVESTMENT = 'investing',
  SOURCING = 'sourcing',
  SOURCINGSTART = 'sourcingStart',
  FINANCE = 'finance',
  MANAGEMENT = 'management',
}

export const SolutionTitles = {
  Investment: 'Sustainable Investments',
  Sourcing: 'Sustainable Sourcing',
  SourcingStart: 'Sustainable Sourcing Start',
  Transparency: 'Transparency Solution',
  Management: 'Sustainability Management',
  Finance: 'ESG Reporting',
  Default: 'Admin tools',
}

export const SolutionsIds = {
  Investing: 1,
  Sourcing: 2,
  Transparency: 3,
}

export const Roles = {
  WfAdmin: 'worldfavoradmin',
  WfEmployeeAdmin: 'worldfavoremployeeadmin',
  Admin: 'admin',
  Contributor: 'contributor',
}
export const ADMIN_ROLES = [Roles.Admin, Roles.WfAdmin, Roles.WfEmployeeAdmin]
export type Role = (typeof Roles)[keyof typeof Roles]

export const Features = {
  Verification: 'Verification',
}
export type Feature = (typeof Features)[keyof typeof Features]

export const Flows = {
  Interaction: 'interaction',
  Verification: 'verification',
  Overview: 'overview',
  Reporting: 'reporting',
  ManageRequests: 'manageRequests',
}

export const ResourceTypes = {
  Organization: 'organization',
  Investee: 'investee',
  Company: 'company',
  Contact: 'contact',
  User: 'user',
  Supplier: 'supplier',
  Provider: 'provider',
  Request: 'request',
  Inquiry: 'inquiry',
  Response: 'response',
  Overview: 'overview',
  ResponseItem: 'responseItem',
  Answer: 'response/items',
  RequestTemplate: 'requestTemplate',
  AuditLog: 'auditLog',
  Colleague: 'colleague',
  Notification: 'notification',
  Password: 'password',
  Invitation: 'invitation',
  Subscription: 'subscription',
  Indicator: 'indicator',
  Framework: 'framework',
  DataPoint: 'datapoint',
  AccessibleOrganizations: 'accessibleOrganizations',
  Claim: 'claim',
  RiskScreening: 'risk-screening',
  Category: 'category',
  ControlPanel: 'controlPanel',
  CustomReports: 'customReports',
  StandardReports: 'standardReports',
  EDCI: 'edci',
  ReportTable: 'reportTable',
  Product: 'product',
  MappingNode: 'mappingNode',
  ProductMappingRequest: 'productMappingRequest',
  ProductMappingResponse: 'productMappingResponse',
  Assessment: 'assessment',
  Suppliers: 'suppliers',
  Companies: 'companies',
  Investees: 'investees',
  Providers: 'providers',
}

export const PERCENTAGE_UNIT_ID = 113
export const PERCENTAGE_UNIT_SYMBOL = '%'

export const ResourceTypesIds: { [key: string]: number } = {
  Investee: 137,
  Supplier: 113,
}

export enum SolutionTourIds {
  default = 244236,
  transparency = 442851,
  sourcing = 271787,
  sourcingstart = 373705,
  investing = 280401,
  finance = 277449,
}

export const QuestionTypes = {
  Text: 'Text',
  Number: 'Number',
  Options: 'Options',
  File: 'File',
}

export enum WfRequestTags {
  PublicRequest1 = 'WfDefaultRequest1',
  PublicRequest2 = 'WfDefaultRequest2',
  PublicRequest3 = 'WfDefaultRequest3',
}

export enum EsgRequestTags {
  Environmental = 'esg-environmental',
  Social = 'esg-social',
  Governance = 'esg-governance',
  Regulations = 'esg-regulations',
  Initiatives = 'esg-initiatives',
  SupplyChain = 'WfDefaultRequest2',
}

export enum SfdrRequestTags {
  Sfdr = 'sfdr',
  SfdrSusApproach = 'sfdr-sus-approach',
  SfdrPai1 = 'sfdr-pai-1',
  SfdrPai2a = 'sfdr-pai-2a',
  SfdrPai2b = 'sfdr-pai-2b',
  SfdrArticle8and9 = 'sfdr-article-8-9',
}

export type RequestTags = WfRequestTags | EsgRequestTags | SfdrRequestTags

export const ImportTemplates: { [key: string]: string } = {
  datapoint: `${Config.STORAGE_ACCOUNT_URL}/import-templates/EvaluationImport.xlsx`,
  supplier: `${Config.STORAGE_ACCOUNT_URL}/import-templates/SupplierImport.xlsx`,
  investee: `${Config.STORAGE_ACCOUNT_URL}/import-templates/InvesteeImport.xlsx`,
  company: `${Config.STORAGE_ACCOUNT_URL}/import-templates/CompanyImport.xlsx`,
}

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

export const FacetTypes: {
  min: 'min'
  max: 'max'
  avg: 'avg'
  sum: 'sum'
  median: 'median'
  count: 'count'
} = {
  min: 'min',
  max: 'max',
  avg: 'avg',
  sum: 'sum',
  median: 'median',
  count: 'count',
}

export enum ResponseStatus {
  ResponseSubmitted = 'ResponseSubmitted',
  ResponseApproved = 'ResponseApproved',
  ResponseSent = 'Sent',
  ResponseCorrectionNeeded = 'ResponseCorrectionNeeded',
}

export const CreationLimits = {
  Categories: 16,
  CategoryOptions: 50,
}

export const ProviderPriorityValues = {
  NotSelected: 'NotSelected',
  NotAPriority: 'NotAPriority',
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
  VeryHigh: 'VeryHigh',
}

export const ProviderTierValues = {
  NotSelected: 0,
  Tier1: 1,
  Tier2: 2,
  Tier3: 3,
  Tier4: 4,
}

export const ProviderUsageValues = {
  NotSelected: 'NotSelected',
  Direct: 'Direct',
  Indirect: 'Indirect',
}

export const ProviderFinalRiskRatingValues = {
  NotSelected: 'A_NotSelected',
  LowRisk: 'B_Green',
  MediumRisk: 'C_Yellow',
  HighRisk: 'D_Orange',
  ExtremeRisk: 'E_Red',
}

export const ProviderApprovalStatusValues = {
  NotSelected: 'NotSelected',
  NotApproved: 'NotApproved',
  UnderObservation: 'UnderObservation',
  ApprovedAfterConsideration: 'ApprovedAfterConsideration',
  Approved: 'Approved',
}

export const ExternalLinks = {
  TermsAndConditions: 'https://worldfavor.com/terms-conditions',
  PrivacyPolicy: 'https://worldfavor.com/privacy-policy',
}

export const Logos = {
  WorldfavorLogoSquareFilledBlack: '/img/brand/worldfavor_logo_black_2024.svg',
}

export enum OrganizationSettings {
  AutoAcceptValueChainResponses = 'autoAcceptValueChainResponses',
}

export const AmplitudeTrackingEvents = {
  Provider: {
    Questionnaire: {
      Response: 'Provider.Questionnaire.Response',
      SuggestedAnswersModal: 'Provider.Questionnaire.SuggestedAnswersModal',
      SuggestedAnswerSingle: 'Provider.Questionnaire.SuggestedAnswerSingle',
    },
  },
  Onboarding: {
    AddOrganization: {
      OpenAddOrg: 'Onboarding.AddOrganization.OpenedAddOrg',
      SubmittedForm: 'Onboarding.AddOrganization.SubmittedForm',
      OrgAlreadyExists: 'Onboarding.AddOrganization.OrgAlreadyExists',
      InvitedCompany: 'Onboarding.AddOrganization.InvitedCompany',
      OpenedOrgDetails: 'Onboarding.AddOrganization.OpenedOrgDetails',
      OpenedIndustryDetails: 'Onboarding.AddOrganization.OpenedIndustryDetails',
      OpenedPersonalDetails: 'Onboarding.AddOrganization.OpenedPersonalDetails',
      SubmittedAccessRequest: 'Onboarding.AddOrganization.SubmittedAccessRequest',
    },
    SendInvite: {
      OpenedConnectFlow: 'Onboarding.SendInvite.OpenedConnectFlow',
      ReferralContactAdded: 'Onboarding.SendInvite.ReferralContactAdded',
    },
  },
  Organization: {
    UpdatePublicContact: {
      ViewedNudge: 'Organization.UpdatePublicContact.ViewedNudge',
      ContactUpdated: 'Organization.UpdatePublicContact.ContactUpdated',
      ContactDeleted: 'Organization.UpdatePublicContact.ContactDeleted',
    },
    RiskIndex: {
      Toggled: 'Organization.RiskIndex.Toggled',
    },
  },
  Accessor: {
    InviteSupplier: {
      InvitationViewed: 'Accessor.InviteSupplier.InvitationViewed',
      InvitationSent: 'Accessor.InviteSupplier.InvitationSent',
      ContactDeleted: 'Accessor.InviteSupplier.ContactDeleted',
    },
    SuggestedContact: {
      SubmittedRequest: 'Accessor.SuggestedContact.SubmittedRequest',
    },
    ProviderOrganization: {
      AdditionalInformation: 'Accessor.ProviderOrganization.AdditionalInformation',
    },
  },
  Dashboard: {
    Overview: {
      ViewedPage: 'Dashboard.Overview.ViewedPage',
    },
  },
  Analyze: {
    Filter: {
      RiskAssessment: 'Analyze.Filter.RiskAssessment',
    },
    RiskAssessment: {
      Opened: 'Analyze.RiskAssessment.Opened',
      Saved: 'Analyze.RiskAssessment.Saved',
    },
    Assessment: {
      ViewedPage: 'Analyze.Assessment.ViewedPage',
      Toggled: 'Analyze.Assessment.Toggled',
      ExportedExcel: 'Analyze.Assessment.ExportedExcel',
      ExportedPDF: 'Analyze.Assessment.ExportedPDF',
    },
    CustomFlag: {
      Opened: 'Analyze.CustomFlag.Opened',
      Saved: 'Analyze.CustomFlag.Saved',
    },
    DataHub: {
      Insights: {
        ViewedInsights: 'Analyze.DataHub.Insights.ViewedInsights',
        ViewedInsightsModal: 'Analyze.DataHub.Insights.ViewedInsightsModal',
        PdfExport: 'Analyze.DataHub.Insights.PdfExport',
        LeftPage: 'Analyze.DataHub.Insights.LeftPage',
      },
    },
  },
}

export enum Assessments {
  BaselineAssessment = 'baseline assessment',
}
