import LinkButton from '@app/src/components/LinkButton'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { Check, CloseOutlined } from '@mui/icons-material'
import { Alert, Button, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { ProviderTabs } from '../../Providers/ProvidersScene'

type ConnectSuccessScreenProps = {
  invitedProviderIds: number[]
  missingContactProviderIds: number[]
  onClose: () => void
}

const ConnectSuccessScreen: React.FC<ConnectSuccessScreenProps> = ({
  invitedProviderIds = [],
  missingContactProviderIds = [],
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  return (
    <Stack height="100%" alignItems="center">
      <Stack flexGrow={1} alignItems="center" justifyContent="center" width={488}>
        <Avatar variant="circular" size={AvatarSize.XXXL} sx={{ bgcolor: 'success.main' }}>
          <Check color="primary" sx={{ fontSize: 64 }} />
        </Avatar>
        <Typography mt={3}>
          {formatMessage(
            { id: 'form.connectProviders.success.description' },
            { invitedProvidersCount: invitedProviderIds.length },
          )}
        </Typography>

        <Stack spacing={2} mt={4}>
          <Button onClick={onClose} startIcon={<CloseOutlined />}>
            {formatMessage({ id: 'general.close' })}
          </Button>
          <LinkButton
            variant="outlined"
            to={stringifyQueryFilters({
              url: generatePath(paths.providers, {
                view: ProviderTabs.NotConnected,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'id',
                    value: invitedProviderIds,
                    operator: Operators.In,
                  },
                ],
              },
            })}
            onClick={onClose}
          >
            {formatMessage(
              { id: 'form.connectProviders.success.viewInvited' },
              { invitedProvidersCount: invitedProviderIds.length },
            )}
          </LinkButton>
          {Boolean(missingContactProviderIds.length) && (
            <LinkButton
              variant="contained"
              to={stringifyQueryFilters({
                url: generatePath(paths.providers, {
                  view: ProviderTabs.MissingContacts,
                }),
                queryParams: {
                  filters: [
                    {
                      name: 'id',
                      value: missingContactProviderIds,
                      operator: Operators.In,
                    },
                  ],
                },
              })}
              onClick={onClose}
            >
              {formatMessage(
                { id: 'form.connectProviders.success.viewMissing' },
                { missingContactCount: missingContactProviderIds.length },
              )}
            </LinkButton>
          )}
        </Stack>
      </Stack>

      <Stack width={488}>
        <Divider sx={{ my: 4 }} />
        <Alert variant="filled" severity="info" sx={{ backgroundColor: 'common.white', alignItems: 'flex-start' }}>
          {formatMessage({ id: 'form.connectProviders.success.infoAlert' })}
        </Alert>
      </Stack>
    </Stack>
  )
}

export default ConnectSuccessScreen
