import Permissions, { usePermissions } from '@app/src/auth/permissions'
import useConfigurationFilter from '@app/src/hooks/configurationFilter'
import ConfigurationsContent from '@app/src/pages/Configurations/ConfigurationsContent'
import { Box } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import ConfigurationMenu from './ConfigurationMenu'

export enum CONFIGURATION_PAGE_IDS {
  Automations = 'automations',
  AuditLogs = 'audit-logs',
  Colleagues = 'colleagues',
  Categories = 'categories',
  Users = 'users',
  Organizations = 'organizations',
  EDCI = 'edci',
  OrganizationInfo = 'organizationInfo',
  FileRepository = 'file-repository',
  RiskScreening = 'risk-screening',
  ClaimsReview = 'claims-review',
  Assessments = 'assessments',
  SuggestedContacts = 'suggested-contacts',
  AssessmentsAdminConfiguration = 'assessment-admin-config',
}

export interface ConfigurationsParams {
  configurationsPage: CONFIGURATION_PAGE_IDS
  configurationsSubPage: string
}

export type ConfigItems = Array<{
  configId: string
  permissions?: Permissions | Permissions[]
  featureToggleName?: string
}>

export const generateConfigItems = () => [
  {
    configId: CONFIGURATION_PAGE_IDS.Automations,
    permissions: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.Colleagues,
    permissions: [
      Permissions.SOURCING_USER,
      Permissions.FINANCE_USER,
      Permissions.INVESTMENT_USER,
      Permissions.TRANSPARENCY_USER,
    ],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.AuditLogs,
    permissions: [
      Permissions.SOURCING_USER,
      Permissions.FINANCE_USER,
      Permissions.INVESTMENT_USER,
      Permissions.TRANSPARENCY_USER,
    ],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.FileRepository,
    permissions: [Permissions.TRANSPARENCY_USER],
    featureToggleName: 'suggestedAnswers',
  },
  {
    configId: CONFIGURATION_PAGE_IDS.Categories,
    permissions: [Permissions.SOURCING_USER, Permissions.INVESTMENT_USER, Permissions.FINANCE_USER],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.EDCI,
    permissions: Permissions.EDCI_ENABLED,
  },
  {
    configId: CONFIGURATION_PAGE_IDS.Users,
    permissions: [Permissions.WORLDFAVOR_EMPLOYEE_ADMIN],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.Organizations,
    permissions: [Permissions.WORLDFAVOR_EMPLOYEE_ADMIN],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.ClaimsReview,
    permissions: [Permissions.WORLDFAVOR_EMPLOYEE_ADMIN],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.SuggestedContacts,
    permissions: [Permissions.WORLDFAVOR_EMPLOYEE_ADMIN],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.OrganizationInfo,
    permissions: [
      Permissions.SOURCING_USER,
      Permissions.FINANCE_USER,
      Permissions.INVESTMENT_USER,
      Permissions.TRANSPARENCY_USER,
    ],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.RiskScreening,
    permissions: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.Assessments,
    permissions: [Permissions.SOURCING_USER, Permissions.FINANCE_USER, Permissions.INVESTMENT_USER],
  },
  {
    configId: CONFIGURATION_PAGE_IDS.AssessmentsAdminConfiguration,
    permissions: [Permissions.WORLDFAVOR_EMPLOYEE_ADMIN],
  },
]

const ConfigurationsScene: React.FC = () => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const { path } = useRouteMatch()
  const { configurationsPage, configurationsSubPage } = useParams<ConfigurationsParams>()
  const filteredConfigItems = useConfigurationFilter(generateConfigItems())

  if (!hasPermission(Permissions.ACCESS_SOLUTION_CONFIGURATION)) return null

  if (!configurationsPage) {
    return (
      <Redirect
        to={generatePath(path, {
          configurationsPage: filteredConfigItems[0].configId,
        })}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'configurations.configurations' })}</title>
      </Helmet>

      <Box pt={2} flexGrow={1} display="flex">
        <ConfigurationMenu configItems={filteredConfigItems} />

        <Switch>
          <Route
            path={decodeURIComponent(
              generatePath(path, {
                configurationsPage: ':configurationsPage?',
                configurationsSubPage: ':configurationsSubPage?',
                activeTabParam: ':activeTabParam?',
                activeTabSubId: ':activeTabSubId?',
              }),
            )}
          >
            <ConfigurationsContent content={configurationsPage} configurationsSubPage={configurationsSubPage} />
          </Route>
        </Switch>
      </Box>
    </>
  )
}

export default ConfigurationsScene
