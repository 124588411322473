import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import endpoints from '@app/src/api/endpoints'
import TextField from '@app/src/components/Ui/TextField'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import AnswerClassificationPreviewCard from '@app/src/pages/QuestionEditor/AnswerClassification/AnswerClassificationPreviewCard'
import { useCreateResource } from '@app/src/api/updateHooks'
import { NotificationSeverity } from '@app/src/wf-constants'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useQueryClient } from 'react-query'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { generatePath } from 'react-router'
import paths from '@app/src/wf-constants/paths'
import SustainabilityLibraryCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/SustainabilityLibraryCollection'
import ListItemSkeletonWrapper from '@app/src/components/Skeleton/ListItem'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'

interface CreateAssessmentFromQuestionnaireProps {
  questionnaireTemplateId: number
}

interface FormValues {
  name: string
  description: string
  level1Description: string
  level2Description: string
  level3Description: string
  level4Description: string
  level5Description: string
}

const CreateAssessmentFromQuestionnaire: React.FC<CreateAssessmentFromQuestionnaireProps> = ({
  questionnaireTemplateId,
}) => {
  const { data: questionnaireTemplate } = useFetchResourceWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.templateById(questionnaireTemplateId, true),
    body: [
      'creatorOrganization.name',
      'sections.questions.questionType',
      'sections.questions.unit',
      'sections.questions.requestCollection',
      'sections.questions.reportFlagRules',
      'sections.questions.reportFlagRules.creatorOrganization',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskNaceCodes.naceCode',
      'sections.questions.reportFlagRules.suggestedRiskGuidance.suggestedRiskGuidanceLinks',
      'sections.questions.questionAnswerClassificationRules',
    ],
    key: [FetchKey.OrganizationOwnedTemplates, questionnaireTemplateId],
    options: {
      enabled: Boolean(questionnaireTemplateId),
    },
  })
  const { formatMessage } = useIntl()
  const [intervals, setIntervals] = useState<number>(3)
  const { register, handleSubmit } = useForm<FormValues>()
  const queryClient = useQueryClient()
  const { mutate: createAssessmentTemplate } = useCreateResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const questions = questionnaireTemplate?.sections?.flatMap(section => section.questions)
  const history = useHistory()

  const handleIntervalsChange = (
    event: React.ChangeEvent<{
      value: string
    }>,
  ) => {
    setIntervals(parseInt(event.target.value))
  }
  const onSubmit = (values: FormValues) => {
    if (!questionnaireTemplate) return
    const assessmentTemplate = {
      ...values,
      questionnaireTemplateId: questionnaireTemplate.id,
      Levels: intervals,
    }

    const body = {
      assessmentTemplate,
      questionIds: questions?.map(q => q.id) ?? [],
      questionnaireTemplateId: questionnaireTemplate.id,
    }

    createAssessmentTemplate(
      {
        url: endpoints.assessmentTemplate,
        body,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.OrganizationSettings)
          showSnackbar({
            message: formatMessage({ id: 'assessments.successfullyCreated' }),
            severity: NotificationSeverity.success,
          })
          history.push(
            generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
            }),
          )
        },
      },
    )
  }
  return (
    <SustainabilityLibraryCollection
      title={formatMessage({ id: 'assessments.createAssessment' })}
      actionButtons={
        questionnaireTemplate
          ? [
              {
                label: formatMessage({ id: 'general.cancel' }),
                onClick: () =>
                  history.push(
                    generatePath(paths.sustainabilityLibrary, {
                      configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
                    }),
                  ),
              },
              {
                label: formatMessage({ id: 'resourceCollections.general.create' }),
                onClick: handleSubmit(onSubmit),
                variant: 'contained',
              },
            ]
          : undefined
      }
    >
      {!questionnaireTemplate ? (
        <ListItemSkeletonWrapper />
      ) : (
        <Stack alignItems="center">
          <Stack
            component="form"
            sx={{ maxWidth: { md: '70%', sm: '100%' }, mr: { md: 0, sm: 3 } }}
            spacing={4}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <TextField name="name" inputRef={register} label={formatMessage({ id: 'assessments.assessmentName' })} />
              <TextField
                name="description"
                inputRef={register}
                multiline
                minRows={3}
                label={formatMessage({ id: 'assessments.assessmentDescription' })}
              />
            </Stack>
            <Stack sx={{ borderRadius: 2, borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.400', p: 4 }}>
              <FormControl>
                <FormLabel>{formatMessage({ id: 'assessments.intervals' })}</FormLabel>
                <RadioGroup value={intervals} onChange={handleIntervalsChange}>
                  <Stack direction="row">
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label={formatMessage({ id: 'assessments.intervalsCount' }, { count: 3 })}
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label={formatMessage({ id: 'assessments.intervalsCount' }, { count: 5 })}
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>

              <Stack spacing={4}>
                {Array.from({ length: intervals }).map((_, index) => (
                  <Stack key={index}>
                    <Typography variant="subtitle1">
                      {formatMessage({ id: `assessments.levels.${intervals}.level${index + 1}` })}
                    </Typography>
                    <TextField
                      multiline
                      minRows={3}
                      name={`level${index + 1}Description`}
                      inputRef={register}
                      label={formatMessage({ id: 'general.description' })}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Stack
              spacing={4}
              sx={{ borderRadius: 2, borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.400', p: 4 }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {formatMessage({ id: 'templateBuilder.answerClassifications.classification' })}
                </Typography>
                <Typography variant="caption">{formatMessage({ id: 'assessments.classificationInfo' })}</Typography>
              </Box>
              {questions?.map(q => (
                <Paper key={q.id} elevation={0} sx={{ p: 2 }}>
                  <Typography variant="subtitle1">{q.questionText}</Typography>
                  <AnswerClassificationPreviewCard
                    showTitle={false}
                    questionAnswerClassifications={q.questionAnswerClassificationRules}
                  />
                </Paper>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </SustainabilityLibraryCollection>
  )
}

export default CreateAssessmentFromQuestionnaire
