import Permissions, { usePermissions } from '@app/src/auth/permissions'
import useOnScreen from '@app/src/hooks/onScreen'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { Box, Paper, Slide } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import VerificationButton from './VerificationButton'

type VerificationBottomBarProps = {
  headerRef: React.RefObject<HTMLDivElement>
}

const VerificationBottomBar: React.FC<VerificationBottomBarProps> = ({ headerRef }) => {
  const headerOnScreen = useOnScreen(headerRef)
  const { response, request, latestHistory } = useReport()
  const { hasPermission } = usePermissions()
  const { stpResponseResponseItemVerificationFilterLogic } = useFlags()

  const isPrimarySubscriber = response?.request?.isPrimarySubscriber || request?.isPrimarySubscriber
  const isLatestResponse = latestHistory?.responseId === response?.id

  const isReviewEnabled =
    (!stpResponseResponseItemVerificationFilterLogic || isPrimarySubscriber) &&
    hasPermission(Permissions.WRITE_VERIFICATION) &&
    isLatestResponse &&
    response?.draftStatus === ResponseDraftStatus.Submitted

  if (isReviewEnabled)
    return (
      <Slide in={!headerOnScreen} direction="up" mountOnEnter unmountOnExit timeout={150}>
        <Paper
          component={Box}
          elevation={10}
          position="sticky"
          bottom={0}
          px={5}
          py={1}
          pr={10}
          bgcolor="common.white"
          zIndex={1}
          textAlign="right"
        >
          <VerificationButton />
        </Paper>
      </Slide>
    )

  return null
}

export default VerificationBottomBar
