import { Delete } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFileDownload } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'

interface FileRepoRowProps {
  file: string
}

export const FileRepoRow: React.FC<FileRepoRowProps> = ({ file }) => {
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { mutateAsync: deleteFileAsync, isLoading: isDeleting } = useDeleteResource()
  const { downloadFile, isFileDownloading } = useFileDownload()
  const [isWaitingToReload, setIsWaitingToReload] = useState(false)

  const handleFileDownload = async (fileName: string) => {
    await downloadFile(endpoints.suggestionFile(fileName))
  }

  const handleDelete = async () => {
    await deleteFileAsync(
      { url: endpoints.suggestionFile(file) },
      {
        onSuccess: () => {
          setIsWaitingToReload(true)
          queryClient.invalidateQueries(FetchKey.SuggestedResponse)
          setTimeout(() => {
            queryClient.invalidateQueries(FetchKey.FileRepository)
            setIsWaitingToReload(false)
          }, 2000)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <TableRow>
      <ButtonCell label={file} variant="text" loading={isFileDownloading} onClick={() => handleFileDownload(file)} />
      <TableCell width={1}>
        <IconButton onClick={handleDelete} disabled={isDeleting || isWaitingToReload}>
          {isDeleting || isWaitingToReload ? <CircularProgress size={20} /> : <Delete color="primary" />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default FileRepoRow
