import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import { Operators, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SimpleOrganization } from '@app/src/types/organizations'
import { Response } from '@app/src/types/resourceExplorer'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { Avatar, Box, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import ResponseRow from './ResponseRow'
import YourDataFilters from './YourDataFilters'

const useStyles = makeStyles(({ spacing }) => ({
  avatar: {
    marginBottom: spacing(1),
  },
}))

const YourData = () => {
  const { avatar } = useStyles()
  const [tagsFilters, setTagsFilters] = useState<string[]>()
  const { formatMessage } = useIntl()

  const { items, count, isLoading } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload: {
      include: ['request', 'request.template', 'request.template.image', 'request.template.tags'],
      filter: [
        {
          name: 'request.template.isStandardReport',
          filters: [
            {
              value: true,
              operator: Operators.EqualTo,
            },
          ],
        },
        RESPONSE_LATEST_SUBMITTED_FILTER,
        ...(tagsFilters
          ? [
              {
                name: 'request.template.tags.tag',
                filters: [
                  {
                    value: tagsFilters,
                    operator: Operators.In,
                  },
                ],
              },
            ]
          : []),
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 999,
      },
    },
  })

  const { data: organizations, isLoading: isLoadingAccessors } = useFetchResource<SimpleOrganization[]>({
    key: [FetchKey.AccessorOrganization],
    endpoint: endpoints.accessors,
  })

  const getUniqueTags = () => {
    const tags = items.map(item => item.request.template.tags)

    return Array.from(new Set(tags.flat()))
  }

  const uniqueTags = useMemo(() => getUniqueTags(), [])

  return (
    <Box display="flex" flexDirection="column" bgcolor="grey.100" px={4} py={3} minHeight={800}>
      <Box width="70%">
        <Typography variant="body1">{formatMessage({ id: 'transparencyOverview.yourDataDescription' })}</Typography>
      </Box>
      <YourDataFilters setTagsFilters={setTagsFilters} existingTags={uniqueTags} />
      <Box px={2} mt={2} borderRadius="4px" bgcolor="background.default" flexGrow={1}>
        {isLoading || isLoadingAccessors ? (
          <Box py={5} display="flex" justifyContent="center" flexGrow={1}>
            <CircularProgress />
          </Box>
        ) : items.length ? (
          items.map((r, i) => (
            <React.Fragment key={r.id}>
              <ResponseRow
                accessors={organizations}
                responseId={r.id}
                requestId={r.request.id}
                title={`${r.request.template.title} - ${r.request.periodName}`}
                tags={r.request.template.tags}
                isLastTemplate={i !== count - 1}
                isDraft={r.draftStatus === 'Draft'}
              />
            </React.Fragment>
          ))
        ) : (
          <Box py={8} display="flex" justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Avatar className={avatar}>
                <ListAltIcon />
              </Avatar>
              <Typography variant="body1">{formatMessage({ id: 'transparencyOverview.yourDataEmpty' })}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default YourData
