import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddProvider from '@app/src/components/Drawer/Views/DrawerViewAddProvider'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewImport from '@app/src/components/Drawer/Views/DrawerViewImport'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import { ProviderUpdateType } from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import ProviderHeader from '@app/src/pages/ResourceCollection/Collections/Provider/ProviderHeader'
import ProviderRow from '@app/src/pages/ResourceCollection/Collections/Provider/ProviderRow'
import ProvidersFilters from '@app/src/pages/ResourceCollection/Filters/ProvidersFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import getProviderSchema from '@app/src/schemas/provider'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import { ResourceTypes } from '@app/src/wf-constants'
import { LabelOutlined, MailOutlined } from '@mui/icons-material'
import { capitalize } from 'lodash'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import ResourceCollectionScene, { ResourceCollectionSceneProps } from '../../ResourceCollectionScene'
import ConnectProvidersModal from '../Provider/ConnectProviders/ConnectProvidersModal'
import DrawerViewImportInitialStep from '@app/src/components/Drawer/Views/DrawerViewImportInitialStep'
import { useFlags } from 'launchdarkly-react-client-sdk'

type BaseProviderSceneProps = {
  implicitFilters?: FilterGroup[]
  allowedFilters: string[]
  isConnectedProvidersPage?: boolean
} & Omit<ResourceCollectionSceneProps, 'title'>

const BaseProviderScene: FC<BaseProviderSceneProps> = ({
  implicitFilters = [],
  allowedFilters,
  isConnectedProvidersPage,
  ...props
}) => {
  const { hasPermission } = useAccount()
  const { formatMessage } = useIntl()
  const { solution } = useAuthentication().scope
  const { openDrawer } = useDrawer()
  const location = useLocation<{ fromDashboardCategorizeButton: boolean; selectedCompany: ProviderUpdateType }>()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const [isConnectDialogOpen, openConnectDialog, closeConnectDialog] = useDialogState(false)
  const resourceType = useCurrentProviderType()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(
    location?.state?.fromDashboardCategorizeButton,
  )
  const [selectedProviderIds, setSelectedProviderIds] = useState<Array<number>>(() =>
    [location?.state?.selectedCompany.providerId ?? []].flat(),
  )
  const { providerSpend } = useFlags()

  const rawExportPayload = {
    filter: implicitFilters,
    sort: sorting,
    include: [
      'country',
      'ownerUser',
      'creatorUser',
      'creatorOrganization',
      'ownerUser.image',
      'categoryOptions.category',
      'referralContact',
      'referralContact.referralEmailHistory',
      'organization.contacts',
      'organization.contacts.user',
    ],
  }

  const payload = {
    ...rawExportPayload,
    filter: [...rawExportPayload.filter, ...userFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageProviders,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload,
  })

  const {
    items: allProviders,
    isLoading: isLoadingAllProviders,
    refetch: fetchAllProviders,
  } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.AllProviders,
    endpoint: endpoints.providersCollection,
    payload: {
      ...payload,
      pagination: {
        itemsPerPage: count,
        pageNumber: 1,
      },
    },
    options: {
      enabled: false,
    },
  })

  const providers = allProviders.length ? allProviders : pageProviders

  const showCustomCategories = providers.some(provider => Boolean(provider.categoryOptions?.length))
  const unconnectedSelectedProviders = useMemo(
    () =>
      providers
        .filter(provider => selectedProviderIds.includes(provider.id))
        .filter(provider => provider.linkStatus !== ProviderLinkStatus.Connected),
    [selectedProviderIds, providers],
  )

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviderIds(prev => [...prev, provider.id])
    } else {
      setSelectedProviderIds(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }

  const isAllSelected = () => {
    return allProviders.length ? allProviders.every(provider => selectedProviderIds.includes(provider.id)) : false
  }

  const handleSelectAll = async () => {
    if (allProviders.length) {
      setSelectedProviderIds(allProviders.map(provider => provider.id) ?? [])
      return
    }

    const { data } = await fetchAllProviders()
    setSelectedProviderIds(data?.items.map(provider => provider.id) ?? [])
  }

  useEffect(() => {
    setSelectedProviderIds(prev =>
      providers.filter(provider => prev.includes(provider.id)).map(provider => provider.id),
    )
  }, [providers])
  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'resourceTypes.provider' })}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Provider}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={getExportColumns(getProviderSchema(solution))}
                  rawExportPayload={rawExportPayload}
                  selectedIds={selectedProviderIds}
                />,
              ),
            disabled: isLoading || isFetching || isLoadingAllProviders || !count,
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.import' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                providerSpend ? (
                  <DrawerViewImportInitialStep
                    allowedFilters={allowedFilters}
                    providerType={resourceType}
                    solution={solution}
                  />
                ) : (
                  <DrawerViewImport providerType={resourceType} solution={solution} />
                ),
              ),
            disabled: isLoading || isFetching || isLoadingAllProviders || hasPermission(Permissions.TRANSPARENCY_USER),
          },
          {
            label: formatMessage(
              { id: 'resourceCollections.create.addProvider' },
              {
                provider: formatMessage(
                  {
                    id: `general.${capitalize(resourceType)}`,
                  },
                  { count: 1 },
                ).toLowerCase(),
              },
            ),
            variant: 'contained',
            onClick: () => openDrawer(<DrawerViewAddProvider providerType={resourceType} />),
            disabled: isLoading || isFetching || isLoadingAllProviders,
          },
        ]}
        filter={
          <ProvidersFilters allowedFilters={allowedFilters} implicitFilters={implicitFilters} resetPage={resetPage} />
        }
        buttonRow={
          selectedProviderIds.length
            ? [
                ...insertIf<ActionButton>(!isConnectedProvidersPage, {
                  label: formatMessage(
                    { id: 'resourceCollections.general.connect' },
                    { count: unconnectedSelectedProviders.length },
                  ),
                  startIcon: <MailOutlined />,
                  onClick: openConnectDialog,
                  disabled: !unconnectedSelectedProviders.length || isLoading || isFetching || isLoadingAllProviders,
                }),
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.categorize' },
                    { count: selectedProviderIds.length },
                  ),
                  startIcon: <LabelOutlined />,
                  onClick: openUpdateDrawer,
                  disabled: isLoading || isFetching || isLoadingAllProviders,
                },
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAll' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,
                  disabled: isLoading || isFetching || isAllSelected(),
                  loading: isLoadingAllProviders,
                }),
              ]
            : undefined
        }
        {...props}
      >
        <Table<Provider>
          RowComponent={({ row }) => (
            <ProviderRow
              row={row}
              showCustomCategories={showCustomCategories}
              onCheckboxChange={handleCheckboxChange}
              selectedProviderIds={selectedProviderIds}
            />
          )}
          HeaderComponent={() => (
            <ProviderHeader
              showCustomCategories={showCustomCategories}
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedProviderIds={selectedProviderIds}
              setSelectedProviderIds={setSelectedProviderIds}
              providersInPage={pageProviders}
            />
          )}
          data={pageProviders}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>

      <CreationModalProgressContextProvider>
        <ConnectProvidersModal
          onClose={closeConnectDialog}
          open={isConnectDialogOpen}
          providers={unconnectedSelectedProviders}
        />
      </CreationModalProgressContextProvider>

      <UpdateProvidersDrawer
        selectedProviders={providers
          .filter(provider => selectedProviderIds.includes(provider.id))
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedProviderIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </>
  )
}

export default BaseProviderScene
