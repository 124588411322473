import TextField from '@app/src/components/Ui/TextField'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { Box, InputAdornment, Stack } from '@mui/material'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import AiSuggestedAnswerChip from './AiSuggestedAnswerChip'
import SkipQuestionSection from './SkipQuestionSection'
import SuggestPreviousAnswer from './SuggestPreviousAnswer'

type TextFieldWithSuggestionsProps = {
  requestItem: RequestItem
  disabled?: boolean
  hasError?: boolean
  error?: string
  isPreview: boolean
}

const TextFieldWithSuggestions: React.FC<TextFieldWithSuggestionsProps> = ({
  requestItem,
  disabled,
  hasError,
  error,
  isPreview,
}) => {
  const { register, clearErrors, setValue, trigger } = useFormContext()
  const [hasAppliedRecommendation, setHasAppliedRecommendation] = useState(false)
  const { reuseAllQuestionsState } = useReport()
  const { hideInputField, setResponseItemView, previousAnswer, setQuestionSkippedFields } = useResponseItemContext()

  const name = `${requestItem.id}.answer`

  const questionType = requestItem.questionType?.name.toLowerCase()

  const onApply = async (value: string) => {
    if (!previousAnswer) return
    if (previousAnswer.cannotAnswer) setQuestionSkippedFields()
    else if (questionType === 'number') {
      const match = value.match(/\d+(?:[.,]\d+)?/)

      if (match) {
        setValue(name, parseFloat(match[0]?.replace(',', '.')))
      }
    } else {
      setValue(name, value)
    }
    setHasAppliedRecommendation(true)
    setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
    await trigger()
  }

  useEffect(() => {
    switch (reuseAllQuestionsState) {
      case ReuseAllQuestionsStates.ApplyAll:
        onApply(previousAnswer?.answer.toString() ?? '')
        break
      case ReuseAllQuestionsStates.SkipAll:
        setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)
        break
      default:
        setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
    }
  }, [reuseAllQuestionsState])

  return (
    <>
      <Stack display={hideInputField ? 'none' : undefined} spacing={3} px={4} pb={2}>
        <TextField
          required
          onWheelCapture={(e): void => {
            ;(e.target as HTMLInputElement).blur()
          }}
          inputRef={register}
          name={name}
          label={capitalize(questionType)}
          disabled={disabled}
          error={hasError}
          helperText={error}
          type={questionType}
          multiline={questionType === 'text'}
          size="small"
          onChange={(): void => {
            if (hasError) clearErrors(`${requestItem.id}`)
          }}
          inputProps={{
            step: questionType === 'number' ? 'any' : undefined,
          }}
          InputProps={{
            endAdornment: requestItem.unit && <InputAdornment position="end">{requestItem.unit.symbol}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: hasAppliedRecommendation ? true : undefined,
          }}
          sx={{
            width: '100%',
            '& input:disabled': {
              background: 'background.default',
            },
          }}
        />
      </Stack>
      <SkipQuestionSection requestItem={requestItem} />
      <SuggestPreviousAnswer
        requestItem={requestItem}
        onApply={() => onApply(previousAnswer?.answer.toString() ?? '')}
        onSkip={() => setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)}
      />
      <Box mt={1} px={4} width="100%">
        <AiSuggestedAnswerChip
          requestItem={requestItem}
          questionType={questionType}
          onApply={onApply}
          disabled={disabled}
          isPreview={isPreview}
        />
      </Box>
    </>
  )
}

export default TextFieldWithSuggestions
