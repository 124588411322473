import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import AssessmentOverviewGraph from '@app/src/pages/ResourceCollection/Collections/Assessments/AssessmentOverviewGraph'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid, Paper, Stack } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

type AssessmentOverviewSceneProps = Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const AssessmentOverviewScene: React.FC<AssessmentOverviewSceneProps> = ({ ...props }) => {
  const { formatMessage } = useIntl()

  const { data: assessmentSettings = [] } = useFetchResource<OrganizationAssessmentSettings[]>({
    key: FetchKey.OrganizationAssessmentSettings,
    endpoint: endpoints.organizationAssessmentSettings,
  })

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.assessments' })}
      actionButtons={[
        {
          label: formatMessage({ id: 'assessments.setAssessments' }),
          variant: 'outlined',
          to: generatePath(paths.configurations, {
            configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
          }),
        },
      ]}
      {...props}
    >
      <Stack spacing={1} pr={4} mt={1}>
        <Grid container width="100%" py={1} spacing={2}>
          {assessmentSettings
            ?.filter(aS => aS.isActivated)
            .map(aS => (
              <Grid item md={6} key={aS.assessmentTemplate.id}>
                <Paper elevation={0}>
                  <Box p={4} pb={5}>
                    <AssessmentOverviewGraph assessmentTemplate={aS.assessmentTemplate} />
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Stack>
    </ResourceCollectionScene>
  )
}

export default AssessmentOverviewScene
