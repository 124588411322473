import { ActionButton } from '@app/src/components/ActionButtons'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { GenericOrganization, Provider } from '@app/src/types/organizations'
import { ReferralContact } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ChevronRight, MailOutline } from '@mui/icons-material'
import { Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'
import DrawerViewAddOrganizationAsProvider from './DrawerViewAddOrganizationAsProvider'
import DrawerViewCreateReferralContact from './DrawerViewCreateReferralContact'
import DrawerViewSendInviteToReferralContact from './DrawerViewSendInviteToReferralContact'

interface DrawerViewLinkProviderCandidatesProps {
  resource: GenericOrganization
  organizationCandidates?: Provider[]
  referralContact?: ReferralContact
  hideNewCompany?: boolean
}

const DrawerViewLinkProviderCandidates: React.FC<DrawerViewLinkProviderCandidatesProps> = ({
  resource,
  organizationCandidates,
  referralContact,
  hideNewCompany,
}) => {
  const { openDrawer } = useDrawer()
  const resourceType = useCurrentProviderType()
  const { formatMessage } = useIntl()

  return (
    <DrawerView
      title={formatMessage({ id: 'general.connect' })}
      subTitle={resource.name}
      buttons={[
        ...insertIf<ActionButton>(!hideNewCompany, {
          label: formatMessage({ id: 'referral.invitation.newCompany' }),
          startIcon: <MailOutline />,
          variant: 'outlined',
          color: 'primary',
          onClick: () => {
            if (!referralContact) {
              openDrawer(<DrawerViewCreateReferralContact provider={resource} />)
            } else {
              openDrawer(<DrawerViewSendInviteToReferralContact provider={resource} />)
            }
          },
        }),
      ]}
      stackButtons
    >
      <Stack spacing={2}>
        <Typography px={2} variant="subtitle1">
          {formatMessage({ id: 'referral.invitation.orgsFoundTitle' })}
        </Typography>
        <Stack>
          {organizationCandidates
            ?.filter(candidate => !candidate.alreadyLinkedOrganization)
            ?.map((candidate, i) => (
              <React.Fragment key={candidate.id}>
                <ListItem dense disablePadding secondaryAction={<ChevronRight />}>
                  <ListItemButton
                    onClick={() =>
                      openDrawer(
                        <DrawerViewAddOrganizationAsProvider
                          provider={candidate}
                          resource={resource}
                          providerType={resourceType}
                        />,
                        { showAboveDialogs: true },
                      )
                    }
                  >
                    <ListItemAvatar>
                      <CompanyAvatar organizationName={candidate.name} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={candidate.name}
                      secondary={candidate.country?.name ?? formatMessage({ id: 'general.notAdded' })}
                    />
                  </ListItemButton>
                </ListItem>
                {i < organizationCandidates.length - 1 && <Divider light />}
              </React.Fragment>
            ))}
        </Stack>
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewLinkProviderCandidates
