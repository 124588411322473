import { TableRow } from '@mui/material'
import React from 'react'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'

interface AssessmentTemplateRowProps {
  row: AssessmentTemplate
}

export const AssessmentTemplateRow: React.FC<AssessmentTemplateRowProps> = ({ row: assessment }) => {
  return (
    <TableRow>
      <ValueCell value={assessment.name} />
    </TableRow>
  )
}

export default AssessmentTemplateRow
