import WorldfavorLogo from '@app/src/components/Navbar/WorldfavorLogo'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { Solution, useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { AccountContextProps } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import useLogin from '@app/src/hooks/login'
import { pathToRedirect } from '@app/src/pages/SolutionSelector/SolutionSelectorScene'
import { Check, ExpandMore, SyncAltOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

interface Props {
  account: AccountContextProps['account']
}

const OrganizationMenu = ({ account }: Props): JSX.Element | null => {
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { formatMessage } = useIntl()
  const { scope } = useAuthentication()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { palette } = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const history = useHistory()
  const login = useLogin()

  const currentOrganizationSolutions = accessibleOrganizations.find(
    ao => ao?.organization?.id === account?.organization?.id,
  )

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (): void => setOpen(false)

  const handleSolutionClick = async (solution: Solution) => {
    setOpen(false)

    if (solution?.solution?.toLowerCase() === scope.solution?.toLowerCase()) {
      solution && history.push(pathToRedirect(solution.solution))
      return
    }
    if (solution.role && solution.solution && account?.organization?.id) {
      await login(solution.role, solution.solution, account?.organization?.id)
      history.push(pathToRedirect(solution.solution))
      return
    }
  }

  return (
    <>
      <Tooltip arrow title={formatMessage({ id: 'navbar.solutionsAndOrganization' })}>
        <Button
          onClick={handleOpen}
          sx={{ height: '100%', borderRadius: 0, pl: 2, pr: 1.5 }}
          size="large"
          endIcon={<ExpandMore color="action" />}
        >
          <WorldfavorLogo />
        </Button>
      </Tooltip>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClose}
        sx={{ minWidth: 300 }}
      >
        <MenuItem disableRipple sx={{ height: '64px', '&:hover': { backgroundColor: 'inherit' } }}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              size={AvatarSize.Large}
              sx={{ backgroundColor: palette.secondary.dark, color: palette.secondary.contrastText }}
            >
              {account?.organization?.name[0] ?? ''}
            </Avatar>
          </ListItemAvatar>

          <Box display="flex" flexDirection="column">
            <Typography variant="body1" color="text.primary">
              {account?.organization?.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {account?.organization?.vatNumber}
            </Typography>
          </Box>
        </MenuItem>
        {currentOrganizationSolutions?.solution
          .sort((a, b) => a.solution.localeCompare(b.solution))
          .map(solution => (
            <MenuItem key={solution.solution} onClick={() => handleSolutionClick(solution)}>
              <ListItemText>
                <Typography variant="body1" color="text.primary">
                  {formatMessage({ id: `organizationMenu.${solution.solution.toLowerCase()}` })}
                </Typography>
              </ListItemText>
              {solution.solution.toLowerCase() === scope?.solution?.toLowerCase() && (
                <ListItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: '24px' } }}>
                  <Check fontSize="medium" />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
        <MenuItem onClick={handleClose} component={Link} to="/">
          <ListItemIcon sx={{ pr: 4 }}>
            <SyncAltOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body1" color="text.primary">
              {formatMessage({ id: 'accountMenu.solutionSelector' })}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default OrganizationMenu
