import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import ButtonCell from '@app/src/components/Table/Cells/ButtonCell'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { OrganizationRiskSetting } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import { ListItem, ListItemAvatar, ListItemText, TableCell, TableRow } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import RiskIndexIcon from './RiskIndexIcons/RiskIndexIcon'

interface RiskIndexIconProps {
  row: OrganizationRiskSetting
}

interface RiskSettingsSaveModel {
  riskIndexId: number
  isActivated: boolean
}

export const RiskSettingsRow: React.FC<RiskIndexIconProps> = ({ row }: RiskIndexIconProps) => {
  const { formatMessage } = useIntl()
  const [isActivated, setIsActivated] = useState(row.isActivated)
  const { mutateAsync, isLoading } = useUpdateResource<RiskSettingsSaveModel>()
  const { openDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()

  const handleChange = async (row: OrganizationRiskSetting) => {
    setIsActivated(!row.isActivated)
    await mutateAsync(
      {
        url: endpoints.organizationRiskSettings,
        body: {
          riskIndexId: row.riskType.id,
          isActivated: !row.isActivated,
        },
      },
      {
        onSuccess: data => {
          showSnackbar({
            message: formatMessage(
              {
                id: data.isActivated
                  ? 'notifications.successfullyEnabledRiskIndex'
                  : 'notifications.successfullyDisabledRiskIndex',
              },
              { name: formatMessage({ id: `schemas.risk.riskIndexes.${row.riskType.source}.label` }) },
            ),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Organization.RiskIndex.Toggled,
            eventProps: {
              name: row.riskType.name,
              enabled: !row.isActivated,
            },
          })

          queryClient.invalidateQueries(FetchKey.OrganizationRiskSettings)

          queryClient.removeQueries(FetchKey.RiskScreening)
          queryClient.removeQueries([FetchKey.FilterFacet, 'riskStatus'])
        },
        onError: () => {
          setIsActivated(row.isActivated)
          showSnackbar({
            message: formatMessage({
              id: 'notifications.toggleRiskIndexFailed',
            }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }

  return (
    <>
      <TableRow>
        <SwitchCell disabled={isLoading} checked={isActivated} onChange={() => handleChange(row)} />
        <TableCell>
          <ListItem component="div">
            <ListItemAvatar>
              <Avatar
                size={AvatarSize.XXL}
                sx={({ palette }) => ({
                  backgroundColor: 'white',
                  border: `1px solid ${palette.secondary.main}`,
                  mr: 2,
                })}
              >
                <RiskIndexIcon source={row.riskType.source} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: `schemas.risk.riskIndexes.${row.riskType.source}.label` })}
              secondary={formatMessage({ id: `schemas.risk.riskIndexes.${row.riskType.source}.source` })}
            />
          </ListItem>
        </TableCell>
        <CategoryCell value={formatMessage({ id: `schemas.risk.esgArea.${row.riskType.sustainabilityArea}` })} />
        <CategoryCell value={formatMessage({ id: `schemas.risk.indexType.${row.riskType.riskIndexType}` })} />
        <ButtonCell
          label={formatMessage({ id: 'general.showMore' })}
          onClick={() => openDrawer(<RiskIndexInfoDrawerView riskType={row.riskType} />)}
        />
      </TableRow>
    </>
  )
}

export default RiskSettingsRow
