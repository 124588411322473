import { TableRow } from '@mui/material'
import React from 'react'

import RouterLink from '@app/src/components/RouterLink'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { generatePath } from 'react-router-dom'
import { ProductMappingRequest } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'

interface SentMappingRequestRowProps {
  row: ProductMappingRequest
}

const SentMappingRequestRow: React.FC<SentMappingRequestRowProps> = ({ row: mappingRequest }) => {
  return (
    <TableRow
      component={RouterLink}
      to={generatePath(paths.product, { id: mappingRequest.product?.id })}
      underline="none"
    >
      <MappingRequestStatusCell value={mappingRequest?.status} dueDate={mappingRequest?.deadline} />
      <ValueCell value={mappingRequest?.product?.name} />
      <CompanyCell onClick={e => e.preventDefault()} drawer company={mappingRequest?.responderOrganization} />
      <DateCell value={mappingRequest?.deadline} />
      <ValueCell value={mappingRequest.periodName} />
    </TableRow>
  )
}

export default SentMappingRequestRow
