import { Alert, Box, capitalize, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import ActionButtons from '@app/src/components/ActionButtons'
import FileUploadField from '@app/src/components/FileUploadField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ImportTemplates, NotificationSeverity } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewImportProps = {
  solution?: string
  providerType: string
} & Omit<DrawerViewProps, 'title'>

type UploadImportFileResoponse = {
  successCount: number
  errorCount: number
}

const DrawerViewImport: React.FC<DrawerViewImportProps> = ({ solution, providerType, ...props }) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const queryClient = useQueryClient()
  const { mutate: uploadImportFile, isLoading } = useCreateResource<UploadImportFileResoponse, FormData>()

  const [importFile, setImportFile] = useState<string | Blob>('')

  const handleTemplateDownload = (): Window | null => window.open(ImportTemplates[providerType], '_blank')

  const handleImport = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', importFile)

    uploadImportFile(
      { url: endpoints.importData(solution, providerType), body: data },
      {
        onSuccess: ({ successCount = 0, errorCount = 0 }) => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.notification.import' }, { successCount, errorCount }),
            severity: NotificationSeverity.info,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: e => {
          showErrorNotification({ requestError: e as RequestError })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'import.title' })}
      buttons={[
        {
          label: formatMessage({ id: 'import.import' }),
          variant: 'contained',
          onClick: handleImport,
          disabled: !importFile,
          loading: isLoading,
        },
      ]}
      {...props}
      dataTestId="import-drawer"
    >
      <Alert severity="info" sx={{ borderRadius: 0 }}>
        {formatMessage(
          { id: 'import.generalInfo' },
          {
            providerTypeSingular: providerType,
            providerTypePlural: formatMessage(
              { id: `general.${capitalize(providerType)}` },
              { count: 2 },
            ).toLowerCase(),
          },
        )}
      </Alert>

      <Box px={2} mt={2}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.stepOne' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.stepOneDescription' })}
          </Typography>
          <ActionButtons
            buttons={[
              {
                label: formatMessage({ id: 'import.templateDownload' }),
                variant: 'contained',
                color: 'primary',
                onClick: handleTemplateDownload,
                disabled: isLoading,
              },
            ]}
          />
        </Box>
        <Box mt={6}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.stepTwo' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.stepTwoDescription' })}
          </Typography>
          <Box maxWidth={200}>
            <FileUploadField setFile={setImportFile} />
          </Box>
        </Box>
        <Box mt={6}>
          <Typography variant="caption" paragraph>
            {formatMessage({ id: 'import.infoOne' })}
          </Typography>
          <Typography variant="caption">{formatMessage({ id: 'import.infoTwo' })}</Typography>
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewImport
