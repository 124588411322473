import { Box, Checkbox, Divider, FormControlLabel, Link, Typography } from '@mui/material'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import { UpdateSharingOptions, useUpdateSharing } from '@app/src/hooks/updateSharing'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Assertion, Consent } from '@app/src/types/reporting'
import { ExternalLinks } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'

export type ConsentState = {
  internalUsageConsent: boolean
  openNetworkConsent: boolean
  correctInformation: boolean
}

export type DrawerViewSubmitQuestionnaireProps = {
  isAllAccess?: boolean
  sharingProps?: UpdateSharingOptions
  onSubmit: (assertions: Assertion[]) => Promise<void>
} & Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewSubmitQuestionnaire: React.FC<DrawerViewSubmitQuestionnaireProps> = ({
  isAllAccess = false,
  sharingProps,
  onSubmit,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { updateSharing } = useUpdateSharing()
  const [isSubmitting, setSubmitting] = useState(false)
  const [consentState, setConsentState] = useState<ConsentState>({
    internalUsageConsent: false,
    openNetworkConsent: isAllAccess,
    correctInformation: false,
  })

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConsentState({ ...consentState, [event.target.name]: event.target.checked })
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    if (sharingProps) await updateSharing(sharingProps)

    await onSubmit([
      {
        type: Consent.INTERNAL_USAGE_CONSENT,
        value: consentState.internalUsageConsent,
      },
      {
        type: Consent.OPEN_NETWORK_CONSENT,
        value: consentState.openNetworkConsent,
      },
      {
        type: Consent.CORRECT_INFORMATION,
        value: consentState.correctInformation,
      },
    ])

    closeDrawer()
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'reporting.submitValidation' })}
      {...props}
      buttons={[
        {
          variant: 'contained',
          label: formatMessage({ id: 'general.submit' }),
          onClick: handleSubmit,
          disabled: !consentState.internalUsageConsent || !consentState.correctInformation,
          loading: isSubmitting,
        },
      ]}
    >
      <Box px={2}>
        <Typography paragraph>{formatMessage({ id: 'reporting.sharing.submit.description' })}</Typography>
        <Typography mt={3} color="textSecondary" gutterBottom>
          {formatMessage({ id: 'reporting.sharing.submit.confirmText' })}
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={consentState.correctInformation}
              onChange={handleConsentChange}
              name="correctInformation"
            />
          }
          label={
            <Box pl={1}>
              <Typography variant="subtitle1">{formatMessage({ id: 'reporting.correct.title' })}</Typography>
              <Typography variant="body1" color="textSecondary">
                {formatMessage({ id: 'reporting.correct.description' })}
              </Typography>
            </Box>
          }
          sx={{ p: 2, alignItems: 'flex-start' }}
        />
        <Divider light />

        <FormControlLabel
          control={
            <Checkbox
              checked={consentState.internalUsageConsent}
              onChange={handleConsentChange}
              name="internalUsageConsent"
            />
          }
          label={
            <Box pl={1}>
              <Typography variant="subtitle1">{formatMessage({ id: 'reporting.consent.title' })}</Typography>
              <Typography variant="body1" color="textSecondary">
                {formatMessage(
                  { id: 'reporting.consent.description' },
                  {
                    link: (
                      <Link href={ExternalLinks.TermsAndConditions} underline="always" target="_blank" rel="noopener">
                        {formatMessage({ id: 'reporting.consent.termsAndConditions' })}
                      </Link>
                    ),
                  },
                )}
              </Typography>
            </Box>
          }
          sx={{ p: 2, alignItems: 'flex-start' }}
        />
        <Divider light />
      </Box>
    </DrawerView>
  )
}

export default DrawerViewSubmitQuestionnaire
