import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodField'
import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import CreationModalStep from '@app/src/components/CreationModal/CreationModalStep'
import { ReportType, useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import AutomationCategories from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationCategories'
import AutomationDeadlineOffset from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationDeadlineOffset'
import AutomationPeriod from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationPeriod'
import AutomationQuestionnaireType from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationQuestionaireType'
import AutomationQuestionnaires from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationQuestionaires'
import AutomationSummary from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationSummary'
import AutomationType from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreationSteps/AutomationType'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestAutomation } from '@app/src/types/automations'
import { insertIf } from '@app/src/utils/helpersTs'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Dialog, DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

interface CreateAutomationModalProps {
  onClose: () => void
  open: boolean
}

export enum AUTOMATION_QUESTION_KEYS {
  AUTOMATION_TYPE = 'automationType',
  CATEGORY_FILTERS = 'categoryFilters',
  REPORT_TYPE = 'reportType',
  TEMPLATES = 'templates',
  DATE_TYPE = 'dateType',
  DATE_YEAR = 'dateYear',
  DATE_PERIOD = 'datePeriod',
  DEADLINE_OFFSET = 'deadlineOffsetDays',
  REVIEW = 'review',
}

export interface AutomationFormData {
  automationType: RequestAutomationType
  templates: Array<{ id: number; title: string }>
  dateType: Periods
  categoryFilters?: FilterGroup[]
  dateYear: string
  datePeriod: string
  deadlineOffsetDays?: number
}

interface AutomationQuestion {
  title: string
  description?: string
  fieldName: AUTOMATION_QUESTION_KEYS | ''
  children: JSX.Element
  overrideButtonTextId?: string | undefined
  hideNextButton?: boolean
  hasSkipButton?: boolean
  disableInitialBack?: boolean
}

const CreateAutomationModal: React.FC<CreateAutomationModalProps> = ({ onClose, open }) => {
  const { activeStep, setActiveStep, setTotalSteps } = useCreationModalProgress()
  const { formatMessage } = useIntl()
  const { mutateAsync: createAutomation, isLoading: isAutomationLoading } = useUpdateResource<
    RequestAutomation,
    unknown
  >()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()

  const formMethods = useForm<AutomationFormData>({
    shouldUnregister: false,
    defaultValues: {
      [AUTOMATION_QUESTION_KEYS.TEMPLATES]: [],
      [AUTOMATION_QUESTION_KEYS.DATE_TYPE]: Periods.Yearly,
      [AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS]: [],
    },
  })

  const { watch, handleSubmit, reset } = formMethods
  const reportType = watch(AUTOMATION_QUESTION_KEYS.REPORT_TYPE)
  const requestAutomationType = watch(AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE)

  const QUESTIONS: AutomationQuestion[] = [
    {
      title: formatMessage({ id: 'form.createAutomation.automationType.title' }),
      description: formatMessage({ id: 'form.createAutomation.automationType.description' }),
      fieldName: AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE,
      children: <AutomationType />,
      hideNextButton: true,
      disableInitialBack: true,
    },
    ...insertIf(requestAutomationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY, {
      title: formatMessage({ id: 'form.createAutomation.selectCategories.title' }),
      description: formatMessage({ id: 'form.createAutomation.selectCategories.description' }),
      fieldName: AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS,
      children: <AutomationCategories />,
    }),
    {
      title: formatMessage({ id: 'form.createRequest.mainMenu.title' }),
      fieldName: AUTOMATION_QUESTION_KEYS.REPORT_TYPE,
      children: <AutomationQuestionnaireType />,
      hideNextButton: true,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.selectQuestionnaire.title' }),
      description: formatMessage({
        id:
          requestAutomationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
            ? 'form.createAutomation.selectQuestionnaire.descriptionForEveryNewProvider'
            : 'form.createAutomation.selectQuestionnaire.descriptionForProvidersInCategory',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.TEMPLATES,
      children: <AutomationQuestionnaires />,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.selectPeriod.title' }),
      description: formatMessage({
        id:
          reportType === ReportType.COMPANY
            ? 'form.createAutomation.selectPeriod.descriptionCompanySpecific'
            : 'form.createAutomation.selectPeriod.descriptionWfStandard',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.DATE_YEAR,
      children: <AutomationPeriod />,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.deadlineOffset.title' }),
      description: formatMessage({
        id: 'form.createAutomation.deadlineOffset.description',
      }),
      fieldName: AUTOMATION_QUESTION_KEYS.DEADLINE_OFFSET,
      children: <AutomationDeadlineOffset />,
      hasSkipButton: true,
    },
    {
      title: formatMessage({ id: 'form.createAutomation.summary.title' }),
      overrideButtonTextId: 'form.createAutomation.summary.finalizeAutomation',
      description: formatMessage({
        id:
          requestAutomationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
            ? 'form.createAutomation.summary.descriptionForEveryNewProvider'
            : 'form.createAutomation.summary.descriptionForProvidersInCategory',
      }),
      fieldName: '',
      children: <AutomationSummary />,
    },
  ]

  useEffect(() => {
    if (!open) {
      setActiveStep(1)
      reset()
    }
  }, [open])

  const onSubmit = async (values: AutomationFormData) => {
    const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
      dateType: values.dateType,
      datePeriod: values.datePeriod,
      dateYear: values.dateYear,
    })

    const body = {
      templateIds: values.templates.map(template => template.id),
      periodStartsAt,
      periodEndsAt,
      deadlineOffsetDays: values.deadlineOffsetDays,
      automationType: values.automationType,
      categoryFilters: values.categoryFilters,
    }

    await createAutomation(
      { body: body, url: endpoints.saveAutomation },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.automationCreated' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.Automation)
          onClose()
        },
      },
    )
  }

  const activeQuestion = useMemo(() => QUESTIONS[activeStep - 1], [activeStep])

  useEffect(() => {
    setTotalSteps(QUESTIONS.length)
  }, [QUESTIONS.length])

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <DialogContent>
        <CreationModalContainer
          title={formatMessage({ id: 'configurations.automations' })}
          onClose={() => {
            onClose()
            setActiveStep(1)
          }}
        >
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {activeQuestion && (
                <CreationModalStep
                  title={activeQuestion.title}
                  description={activeQuestion.description}
                  fieldnames={[activeQuestion.fieldName].filter(Boolean)}
                  onBack={() => undefined}
                  hideNextButton={activeQuestion.hideNextButton}
                  disableInitialBack={activeQuestion.disableInitialBack}
                  hasSkipButton={activeQuestion.hasSkipButton}
                  isLoading={isAutomationLoading}
                  overrideButtonTextId={activeQuestion.overrideButtonTextId}
                >
                  {activeQuestion.children}
                </CreationModalStep>
              )}
            </form>
          </FormProvider>
        </CreationModalContainer>
      </DialogContent>
    </Dialog>
  )
}

export default CreateAutomationModal
