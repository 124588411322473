import {
  Button,
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React, { FC, useEffect } from 'react'

import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, LibraryAddCheckOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'

interface ReuseAllPreviousAnswersDialogProps {
  open: boolean
  onClose: () => void
}

const ReuseAllPreviousAnswersDialog: FC<ReuseAllPreviousAnswersDialogProps> = ({ open, onClose }) => {
  const { previouslyRespondedQuestions, setReuseAllQuestionsState } = useReport()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()

  const handleReuseQuestionsSelect = (state: ReuseAllQuestionsStates) => {
    setReuseAllQuestionsState(state)
    trackEvent({
      name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswersModal,
      eventProps: {
        action: state,
      },
    })
    onClose()
  }

  useEffect(() => {
    if (!open) return
    trackEvent({
      name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswersModal,
      eventProps: {
        action: 'ModalViewed',
      },
    })
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack p={4} spacing={2}>
        <Typography variant="h3">
          {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.title' })}
        </Typography>
        <Typography color="textSecondary">
          {formatMessage(
            { id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.description' },
            { previouslyRespondedQuestionsCount: previouslyRespondedQuestions?.length },
          )}
        </Typography>
        <List>
          <ListItem
            disablePadding
            secondaryAction={
              <Button onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.ApplyAll)} variant="contained">
                {formatMessage({ id: 'general.select' })}
              </Button>
            }
          >
            <ListItemButton onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.ApplyAll)}>
              <ListItemIcon>
                <LibraryAddCheckOutlined fontSize="large" sx={{ mr: 2, color: 'communication.dark' }} />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.applyAllPrimary' })}
                secondary={formatMessage({
                  id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.applyAllSecondary',
                })}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            disablePadding
            secondaryAction={
              <Button variant="contained" onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.Review)}>
                {formatMessage({ id: 'general.select' })}
              </Button>
            }
          >
            <ListItemButton onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.Review)}>
              <ListItemIcon>
                <CheckBoxOutlined fontSize="large" sx={{ mr: 2, color: 'communication.dark' }} />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.reviewPrimary' })}
                secondary={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.reviewSecondary' })}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            disablePadding
            secondaryAction={
              <Button onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.SkipAll)} variant="contained">
                {formatMessage({ id: 'general.select' })}
              </Button>
            }
          >
            <ListItemButton onClick={() => handleReuseQuestionsSelect(ReuseAllQuestionsStates.SkipAll)}>
              <ListItemIcon>
                <CheckBoxOutlineBlankOutlined fontSize="large" sx={{ mr: 2, color: 'communication.dark' }} />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.skipPrimary' })}
                secondary={formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.reuseAllDialog.skipSecondary' })}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </Dialog>
  )
}

export default ReuseAllPreviousAnswersDialog
