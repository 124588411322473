import React from 'react'
import { useIntl } from 'react-intl'

import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import QuestionStep from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import QuestionSteps from '@app/src/components/CreateNewRequestModal/Generic/QuestionSteps'
import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodField'
import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import InstructionsStep, {
  InstructionsFormData,
} from '@app/src/pages/Product/CreateProductRequestModalSteps/InstructionsStep'
import ReviewStep from '@app/src/pages/Product/CreateProductRequestModalSteps/ReviewStep'
import SelectPeriodStep, {
  SelectPeriodStepFormData,
} from '@app/src/pages/Product/CreateProductRequestModalSteps/SelectPeriodStep'
import SelectProviderStep, {
  SelectProviderStepFormData,
} from '@app/src/pages/Product/CreateProductRequestModalSteps/SelectProviderStep'
import { Provider } from '@app/src/types/organizations'
import { Product } from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Dialog, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FieldValues } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import SelectDeadlineStep, { SelectDeadlineStepFormData } from './CreateProductRequestModalSteps/SelectDeadlineStep'

type CreateProductRequestModalProps = {
  onClose: () => void
  open: boolean
  product: Product
}

const useStyles = makeStyles(({ spacing }) => ({
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(10),
    '&:first-child': {
      paddingTop: spacing(9),
    },
  },
  progressBar: {
    marginTop: spacing(2),
  },
}))

export interface ProductRequestFormData {
  period: string
  provider: Provider
  deadline: string
  targetProductId: number
}

const CreateProductRequestModal: React.FC<CreateProductRequestModalProps> = ({ onClose, open, product }) => {
  const classes = useStyles()
  const { setActiveStep } = useCreationModalProgress()
  const { formatMessage } = useIntl()
  const { mutate: createRequest } = useCreateResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const handleFormSubmit = async (values: FieldValues) => {
    const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
      dateType: Periods.Yearly,
      dateYear: values.period,
    })

    const body = {
      targetProductId: product.id,
      responderOrganizationId: values.provider.organizationId,
      periodStartsAt,
      periodEndsAt,
      deadline: formatDate(values.deadline),
      instructions: values.instructions,
    }

    createRequest(
      { url: endpoints.saveProductMappingRequest, body },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'form.createProduct.success' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          setActiveStep(1)
          onClose()
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CreationModalContainer
          title={product.name}
          onClose={() => {
            onClose()
            setActiveStep(1)
          }}
        >
          <QuestionSteps>
            <QuestionStep<SelectProviderStepFormData>
              disableInitialBack
              title={formatMessage({ id: 'form.createProduct.selectProvider.title' })}
              description={formatMessage({ id: 'form.createProduct.selectProvider.description' })}
              render={props => <SelectProviderStep {...props} />}
            />

            <QuestionStep<SelectPeriodStepFormData>
              title={formatMessage({ id: 'form.createProduct.selectPeriod.title' })}
              description={formatMessage({ id: 'form.createProduct.selectPeriod.description' })}
              allowSkip
              render={props => <SelectPeriodStep {...props} />}
            />

            <QuestionStep<SelectDeadlineStepFormData>
              disableInitialBack
              allowSkip
              title={formatMessage({ id: 'form.createProduct.selectDeadline.title' })}
              description={formatMessage({ id: 'form.createProduct.selectDeadline.description' })}
              render={props => <SelectDeadlineStep {...props} />}
            />

            <QuestionStep<InstructionsFormData>
              title={formatMessage({ id: 'form.createProduct.instructions.title' })}
              description={formatMessage({ id: 'form.createProduct.instructions.description' })}
              allowSkip
              render={props => <InstructionsStep {...props} />}
            />

            <QuestionStep<FieldValues>
              title={formatMessage({ id: 'form.createProduct.review.title' })}
              description={formatMessage({ id: 'form.createProduct.review.description' })}
              onSubmit={handleFormSubmit}
              render={props => <ReviewStep {...props} product={product} />}
            />
          </QuestionSteps>
        </CreationModalContainer>
      </DialogContent>
    </Dialog>
  )
}

export default CreateProductRequestModal
