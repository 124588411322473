import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import Filters, { FiltersContextProps } from '@app/src/pages/ResourceCollection/Filters/Filters'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResetPage, SortOrder } from '@app/src/types/filter'
import { Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, createFilterOptions, Grid, Tooltip } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem } from '..'
import AllFiltersButton from './AllFiltersButton'
import OptionWithLabel from './OptionWithLabel'
import RangeFilter from './RangeFilter'
import StatusOption from './StatusOption'

interface ResponseFiltersProps {
  implicitFilters?: FilterGroup[]
  allowedFilters: FiltersContextProps['allowedFilters']
  resetPage?: ResetPage
}

const ResponsesFilters = ({ allowedFilters, resetPage, implicitFilters }: ResponseFiltersProps): JSX.Element => {
  const { renderWithPermission } = usePermissions()
  const autocompleteClasses = useAutocompleteStyles()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)
  const { solution } = useAuthentication().scope
  const { openDrawer } = useDrawer()

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 0
          return (
            <>
              <Grid item md={5} sm={10}>
                <Filter name="request.template.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.ResponseFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: 'request.template.title' }],
                        sort: { target: 'label', order: SortOrder.ASCENDING },
                      }}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="request.inquiries.status" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      size="small"
                      multiple
                      facetsParam={{
                        key: [FetchKey.ResponseFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: filterName, isEnum: true }],
                      }}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <StatusOption
                          key={option.label}
                          props={props}
                          option={option}
                          selected={selected}
                          verification
                        />
                      )}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                      value={[value].flat()}
                      skipNotSet={false}
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Tooltip
                                key={option?.value?.toString() || index}
                                title={formatMessage({
                                  id: `reporting.reviewStatuses.${option.label}`,
                                })}
                                classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                arrow
                              >
                                <Chip
                                  label={formatMessage({
                                    id: `reporting.statuses.${option.label}`,
                                  })}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              </Tooltip>
                            ),
                        )
                      }
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={2}>
                <Filter name="request.periodName" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      implicitFilters={implicitFilters}
                      multiple
                      facetsParam={{
                        key: [FetchKey.ResponseFacets, filterName],
                        endpoint: endpoints.responsesWithFacets,
                        facetsParam: [{ name: filterName, isEnum: true }],
                        sort: { target: 'label', order: SortOrder.DESCENDING },
                      }}
                      filterName={filterName}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item md={3}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.responses' })}>
                              <Filter name="request.template.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ResponseFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [{ name: 'request.template.title' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>

                              <Filter name="request.inquiries.status" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ResponseFacets, filterName],
                                      endpoint: endpoints.responsesWithFacets,
                                      facetsParam: [{ name: filterName, isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <StatusOption
                                        key={option.label}
                                        props={props}
                                        option={option}
                                        selected={selected}
                                        verification
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Tooltip
                                              key={option?.value?.toString() || index}
                                              title={formatMessage({
                                                id: `reporting.reviewStatuses.${option.label}`,
                                              })}
                                              classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                              arrow
                                            >
                                              <Chip
                                                label={formatMessage({
                                                  id: `reporting.statuses.${option.label}`,
                                                })}
                                                size="small"
                                                {...getTagProps({ index })}
                                              />
                                            </Tooltip>
                                          ),
                                      )
                                    }
                                    skipNotSet={false}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.companyDetails' })}>
                              <Box mb={2}>
                                <Filter name="request.subscriptions.target.countryId" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [{ name: 'request.subscriptions.target.country.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.filter.country.countryCode' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter name="request.subscriptions.target.organization.id" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [{ name: 'request.subscriptions.target.organization.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.requestDetails' })}>
                              <Box mb={2}>
                                <Filter name="request.periodName" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [{ name: filterName, isEnum: true }],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.responseDetails' })}>
                              {verificationEnabled && (
                                <>
                                  <Box mb={2}>
                                    <Filter name="verifications.internalVerificationStatus" operator={Operators.In}>
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseFacets, filterName],
                                            endpoint: endpoints.responsesWithFacets,
                                            facetsParam: [{ name: filterName }],
                                          }}
                                          renderOption={(props, option, { selected }): JSX.Element => (
                                            <StatusOption
                                              key={option.label}
                                              props={props}
                                              option={option}
                                              selected={selected}
                                              verification
                                            />
                                          )}
                                          filterName={filterName}
                                          onChange={value => onChange(value)}
                                          fieldLabel={formatMessage({
                                            id: 'filters.responseItem.internalVerificationStatus',
                                          })}
                                          value={[value].flat()}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Tooltip
                                                    key={option?.value?.toString() || index}
                                                    title={formatMessage({
                                                      id: `reporting.internalVerificationStatuses.${option.label}`,
                                                    })}
                                                    classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                                    arrow
                                                  >
                                                    <Chip
                                                      label={formatMessage({
                                                        id: `reporting.internalVerificationStatuses.${option.label}`,
                                                      })}
                                                      size="small"
                                                      {...getTagProps({ index })}
                                                    />
                                                  </Tooltip>
                                                ),
                                            )
                                          }
                                          skipNotSet={false}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              <RangeFilter
                                uniqueIdFrom="responseDateFrom"
                                uniqueIdTo="responseDateTo"
                                name="submittedAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.response.responseDateFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.response.responseDateTo' })}
                              />
                            </FilterSection>
                            <FilterSection
                              defaultCollapsed
                              label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                            >
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="request.subscriptions.target.ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [{ name: 'request.subscriptions.target.ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>

                              <Box mb={2}>
                                <Filter operator={Operators.In} name="request.subscriptions.target.finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [
                                          { name: 'request.subscriptions.target.finalRiskRating', isEnum: true },
                                        ],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <li {...props}>
                                          <OptionIcon selected={selected} multiple />
                                          <FinalRiskRatingIcon value={option?.label} />
                                          {option?.label
                                            ? formatMessage({
                                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                              })
                                            : ''}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="request.subscriptions.target.priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseFacets, filterName],
                                        endpoint: endpoints.responsesWithFacets,
                                        facetsParam: [{ name: 'request.subscriptions.target.priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {solution !== Solutions.Finance && (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="request.subscriptions.target.activityStatus">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseFacets, filterName],
                                            endpoint: endpoints.responsesWithFacets,
                                            facetsParam: [
                                              { name: 'request.subscriptions.target.activityStatus', isEnum: true },
                                            ],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.activityStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.activityStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter
                                      operator={Operators.In}
                                      name="request.subscriptions.target.providerApprovalStatus"
                                    >
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseFacets, filterName],
                                            endpoint: endpoints.responsesWithFacets,
                                            facetsParam: [
                                              {
                                                name: 'request.subscriptions.target.providerApprovalStatus',
                                                isEnum: true,
                                              },
                                            ],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="request.subscriptions.target.supplierUsage">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.ResponseFacets, filterName],
                                              endpoint: endpoints.responsesWithFacets,
                                              facetsParam: [
                                                { name: 'request.subscriptions.target.supplierUsage', isEnum: true },
                                              ],
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage({
                                                  id: option?.label
                                                    ? `schemas.provider.supplierUsageValues.${option.label}`
                                                    : 'general.notAvailable',
                                                })}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage({
                                                        id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                      })}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="request.subscriptions.target.tier">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.ResponseFacets, filterName],
                                              endpoint: endpoints.responsesWithFacets,
                                              facetsParam: [
                                                { name: 'request.subscriptions.target.tier', isEnum: true },
                                              ],
                                            }}
                                            filterOptions={(options, state) => [...tierFilter(options, state)]}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option?.label ?? '' },
                                                )}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage(
                                                        {
                                                          id: `schemas.provider.tierValues`,
                                                        },
                                                        { tier: option.label },
                                                      )}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                            </FilterSection>
                            <CustomCategoriesFilters baseFilterName="request.subscriptions.target" />
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default ResponsesFilters
