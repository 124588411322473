import { EmailOutlined } from '@mui/icons-material'
import { Box, Button, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { useReferralContact } from '@app/src/hooks/referralContact'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import CustomersLogos from './CustomersLogos'
import RatingsImage from './RatingsImage'
import UsersImage from './UsersImage'
import WfLogoAndLettering from './WfLogoAndLettering'

export interface EmailPreviewProps {
  provider: Provider
}

const EmailPreview: React.FC<EmailPreviewProps> = ({ provider }) => {
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const { formatMessage } = useIntl()
  const { referralContact } = useReferralContact(provider.id)

  const currentOrg = accessibleOrganizations.find(org => org?.organization?.id === account?.organization?.id)

  const currentOrgName = currentOrg?.organization?.name
  const currentUserName = account?.user.name

  if (!provider || !referralContact) return null

  return (
    <Stack flexGrow={1} py={4} maxWidth={480} alignSelf="center">
      <ListItem sx={{ pb: 2 }}>
        <ListItemAvatar>
          <Avatar variant="circular"> </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={formatMessage({ id: 'general.worldfavor' })}
          secondary={formatMessage(
            { id: 'referral.invitation.emailPreview.mailSubject' },
            { currentUserName, currentOrgName },
          )}
        />
      </ListItem>
      <Stack p={4} bgcolor="common.white" spacing={2}>
        <WfLogoAndLettering />
        <Typography variant="h3">
          {formatMessage({ id: 'referral.invitation.emailPreview.title' }, { currentOrgName })}
        </Typography>
        <Typography variant="subtitle1">
          {formatMessage(
            { id: 'referral.invitation.emailPreview.greeting' },
            { contactName: referralContact.firstName },
          )}
        </Typography>
        <Typography>
          {formatMessage({ id: 'referral.invitation.emailPreview.body' }, { currentUserName, currentOrgName })}
        </Typography>
        <Stack
          border={({ palette }) => `1px solid ${palette.grey[300]}`}
          borderRadius={4}
          p={4}
          spacing={2}
          alignItems="center"
          textAlign="center"
        >
          <Avatar size={AvatarSize.XXL} sx={{ bgcolor: 'secondary.main' }}>
            <EmailOutlined />
          </Avatar>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'referral.invitation.emailPreview.ctaTitle' }, { currentOrgName })}
          </Typography>
          <Typography>{formatMessage({ id: 'referral.invitation.emailPreview.ctaBody' })}</Typography>
          <Button variant="contained">{formatMessage({ id: 'general.accept' })}</Button>
        </Stack>
        <Stack spacing={10} alignItems="center" pt={8}>
          <Stack spacing={2} alignItems="center" textAlign="center">
            <CustomersLogos />
            <Typography>{formatMessage({ id: 'referral.invitation.emailPreview.customersInfo' })}</Typography>
            <Button variant="outlined">{formatMessage({ id: 'general.learnMore' })}</Button>
          </Stack>
          <Stack spacing={2} alignItems="center" textAlign="center">
            <RatingsImage />
            <Typography>{formatMessage({ id: 'referral.invitation.emailPreview.reviewInfo' })}</Typography>
            <Button variant="outlined">{formatMessage({ id: 'general.readReviews' })}</Button>
          </Stack>
          <Stack spacing={2} alignItems="center" textAlign="center">
            <UsersImage />
            <Typography>{formatMessage({ id: 'referral.invitation.emailPreview.usersInfo' })}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box bgcolor="grey.200" width="100%" textAlign="center" py={4}>
        <Typography>{formatMessage({ id: 'referral.invitation.emailPreview.footer' })}</Typography>
      </Box>
    </Stack>
  )
}

export default EmailPreview
