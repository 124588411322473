import TextField from '@app/src/components/Ui/TextField'
import { FC, default as React } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface SkipQuestionCommentFieldProps {
  requestItemId: number
  answerStatusComment: string
  hasError: boolean
  label: string
}

const SkipQuestionCommentField: FC<SkipQuestionCommentFieldProps> = ({
  requestItemId,
  answerStatusComment,
  hasError,
  label,
}) => {
  const { errors, clearErrors, register } = useFormContext()
  const { formatMessage } = useIntl()

  return (
    <TextField
      onWheelCapture={(e): void => {
        ;(e.target as HTMLInputElement).blur()
      }}
      placeholder={formatMessage({ id: 'general.typeHere' })}
      inputRef={register}
      name={answerStatusComment}
      error={hasError}
      helperText={errors[requestItemId]?.message}
      multiline
      size="small"
      label={label}
      onChange={(): void => {
        if (hasError) clearErrors(`${requestItemId}`)
      }}
    />
  )
}

export default SkipQuestionCommentField
