import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import HoverDialog from '@app/src/components/HoverDialog'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import ChartTypeSelector, {
  ChartType,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import useAssessmentOverviewGraph, {
  AssessmentOverViewGraphDatapoint,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/useAssessmentOverviewGraph'
import { BaseAssessmentViews } from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/BaseAssessmentScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { Assessments } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import ReactEcharts from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, Link, Link as RouterLink } from 'react-router-dom'

interface Props {
  subtitle?: string
  assessmentTemplate: AssessmentTemplate
}

const AssessmentOverviewGraphFooter: React.FC<Pick<Props, 'assessmentTemplate'>> = ({ assessmentTemplate }) => {
  const { formatMessage } = useIntl()
  if (assessmentTemplate.name.toLowerCase() === Assessments.BaselineAssessment)
    return (
      <Stack alignItems="center">
        <Button
          variant="contained"
          component={RouterLink}
          to={generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Details })}
          endIcon={<OpenInNew />}
          target="_blank"
        >
          {formatMessage({ id: 'baseAssessment.exploreResults' })}
        </Button>
      </Stack>
    )
  return null
}

const AssessmentOverviewGraph: React.FC<Props> = ({ assessmentTemplate }) => {
  const { facets: f } = useFetchFacets({
    key: FetchKey.AssessmentFacets,
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    filter: [
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
    ],
  })
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { formatMessage } = useIntl()
  const [highlightedLevel, setHighlightedLevel] = React.useState<AssessmentOverViewGraphDatapoint>()
  const [xOffset, setXOffset] = React.useState<number>(0)
  const [yOffset, setYOffset] = React.useState<number>(0)
  const [hoverDialogEnabled, setHoverDialogEnabled] = React.useState<boolean>(false)
  const has5Levels = assessmentTemplate.levels !== 3 // null or 5 shows 5 levels to be backward compatible
  const events = useMemo(
    () => ({
      mouseover: ({
        data,
        event,
      }: {
        data: AssessmentOverViewGraphDatapoint
        event: { event: { zrX: number; zrY: number } }
      }) => {
        setXOffset(event.event.zrX)
        setYOffset(-510 + event.event.zrY)
        setHighlightedLevel(data)
        setHoverDialogEnabled(true)
      },
      mouseout: (param: { event: { event: MouseEvent } }) => {
        // if we're hovering the tooltip (relatedTarget) we don't close the hover dialog
        if (!param.event.event.relatedTarget) {
          setHoverDialogEnabled(false)
        }
      },
    }),
    [],
  )

  const [facets = []] = f
  const levels = useMemo<Record<number, FacetItem | undefined>>(
    () => ({
      1: facets.find(l => l.value === 1),
      2: facets.find(l => l.value === 2),
      3: facets.find(l => l.value === 3),
      4: facets.find(l => l.value === 4),
      5: facets.find(l => l.value === 5),
    }),
    [facets],
  )
  const totalSuppliersThatResponded = useMemo(
    () => Object.values(levels)?.reduce((acc, level) => acc + (level?.count ?? 0), 0) ?? 0,
    [levels],
  )
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Horizontal)
  const option = useAssessmentOverviewGraph(levels, selectedChartType, has5Levels)
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="overline" color="textSecondary">
            {formatMessage({ id: 'assessments.assessmentOverview' }, { name: assessmentTemplate.name })}
          </Typography>
        </Stack>
        <ChartTypeSelector
          selectedChartType={selectedChartType}
          setSelectedChartType={setSelectedChartType}
          allowedChartTypes={[ChartType.Pie, ChartType.Horizontal, ChartType.Vertical]}
        />
      </Stack>
      <HoverDialog
        placement="bottom-start"
        positionOffset={[xOffset, yOffset]}
        width={300}
        content={
          highlightedLevel && hoverDialogEnabled ? (
            <Stack spacing={1} alignItems="start">
              <Typography color="primary" variant="subtitle2">
                {highlightedLevel.name}
              </Typography>
              <Stack direction="row" spacing={4} alignItems="center" justifyContent="space-between">
                <Typography variant="body2">
                  {formatMessage(
                    { id: 'baseAssessment.percentOfAllSuppliers' },
                    { value: Math.round((highlightedLevel.value / totalSuppliersThatResponded) * 100) },
                  )}
                </Typography>
                <Button
                  component={Link}
                  to={stringifyQueryFilters({
                    url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Details }),
                    queryParams: {
                      filters: [
                        {
                          name: 'totalLevel',
                          value: [highlightedLevel.level],
                          operator: Operators.In,
                        },
                      ],
                    },
                    includeCurrentFilters: true,
                  })}
                >
                  {formatMessage({ id: 'baseAssessment.seeDetails' })}
                </Button>
              </Stack>
            </Stack>
          ) : undefined
        }
      >
        <Box width="100%" py={1}>
          <ReactEcharts option={option} style={{ height: 500 }} onEvents={events} />
        </Box>
      </HoverDialog>

      <AssessmentOverviewGraphFooter assessmentTemplate={assessmentTemplate} />
    </Stack>
  )
}

export default AssessmentOverviewGraph
