import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Tabs from '@app/src/components/Tabs'
import SubscribersPanel from '@app/src/pages/Questionnaire/Provider/Actions/SubscribersPanel'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Inquiry } from '@app/src/types/resourceExplorer'
import { Box, Skeleton } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import HistoryLog from '../../HistoryLog'
import GiveAccessNudgeDialog from './GiveAccessNudgeDialog'
import VerificationMessages from './VerificationMessages'

enum RightPanelTab {
  SUBSCRIBERS = 'subscribers',
  HISTORY_LOG = 'history-log',
  MESSAGES = 'messages',
}

const ReportActions: React.FC = () => {
  const { formatMessage } = useIntl()
  const { request, response, isRequestLoading, isResponseLoading } = useReport()

  const [activeTab, setActiveTab] = React.useState<RightPanelTab>(RightPanelTab.SUBSCRIBERS)

  const { items: inquiries, isFetching: isFetchingInquiries } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.InquiryOpenRequests,
    endpoint: endpoints.inquiriesCollection,
    payload: {
      filter: [
        {
          name: 'questionnaireTemplateId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: request?.questionnaireTemplateId,
            },
          ],
        },
        {
          name: 'periodStartsAt',
          filters: [
            {
              operator: Operators.EqualTo,
              value: request?.periodStartsAt,
            },
          ],
        },
        {
          name: 'periodEndsAt',
          filters: [
            {
              operator: Operators.EqualTo,
              value: request?.periodEndsAt,
            },
          ],
        },
      ],
      include: ['creatorOrganization.image'],
    },
    options: { enabled: Boolean(request) },
  })

  const tabs = [
    {
      type: RightPanelTab.SUBSCRIBERS,
      label: formatMessage({ id: 'reporting.sharing.subscribers' }),
      url: '',
    },
    {
      type: RightPanelTab.HISTORY_LOG,
      label: formatMessage({ id: 'reporting.historyLog' }),
      url: '',
    },
    {
      type: RightPanelTab.MESSAGES,
      label: formatMessage({ id: 'reporting.messages' }),
      url: '',
      badgeText: response?.verifications?.length ?? 0,
      badgeSx: { '& .MuiBadge-badge': { xs: { right: 0 }, xl: { right: -5 } } },
    },
  ]

  if (isRequestLoading || isRequestLoading || isResponseLoading) {
    return (
      <Box position="sticky" top={80} display="flex">
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} sx={{ mr: 1 }} />
        <Skeleton width="33%" height={48} />
      </Box>
    )
  }

  return (
    <>
      <Box position="sticky" top={80}>
        <Tabs
          variant="fullWidth"
          tabs={tabs}
          activeTab={activeTab}
          handleChange={(_, value) => setActiveTab(value as RightPanelTab)}
        />
        {activeTab === RightPanelTab.SUBSCRIBERS && (
          <SubscribersPanel inquiries={inquiries} isFetchingInquiries={isFetchingInquiries} />
        )}
        {activeTab === RightPanelTab.HISTORY_LOG && <HistoryLog />}
        {activeTab === RightPanelTab.MESSAGES && <VerificationMessages />}
      </Box>

      <GiveAccessNudgeDialog inquiries={inquiries} isFetchingInquiries={isFetchingInquiries} />
    </>
  )
}

export default ReportActions
