import SelectFilterStep, {
  CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS,
} from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import { PROVIDER_SELECTOR_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/ProviderSelectorFilterDrawer'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

const AutomationCategories: React.FC = () => {
  const { setValue } = useFormContext()
  const filters = useGetApiQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)

  useDeepCompareEffect(() => setValue(AUTOMATION_QUESTION_KEYS.CATEGORY_FILTERS, filters), [filters])

  return (
    <Filters allowedFilters={PROVIDER_SELECTOR_ALLOWED_FILTERS}>
      {() => <SelectFilterStep showFiltersButtonTextId="form.createAutomation.selectCategories.showCategories" />}
    </Filters>
  )
}
export default AutomationCategories
