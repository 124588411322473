import { OptionIcon } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import { PreviewMode } from '@app/src/pages/Questionnaire/Provider/Report/EditResponse'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import useFetchAiSuggestions from '@app/src/pages/Questionnaire/hooks/useFetchAiSuggestions'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import AiSuggestedAnswerChip from './AiSuggestedAnswerChip'
import SkipQuestionSection from './SkipQuestionSection'
import SuggestPreviousAnswer from './SuggestPreviousAnswer'

type OptionSelectWithSuggestionProps = {
  requestItem: RequestItem
  periodName: string | undefined
  disabled?: boolean
  hasError?: boolean
  error?: string
  previewMode?: PreviewMode
}

const OptionSelectWithSuggestion: React.FC<OptionSelectWithSuggestionProps> = ({
  requestItem,
  periodName,
  disabled,
  hasError,
  error,
  previewMode,
}) => {
  const { control, clearErrors, setValue, trigger } = useFormContext()
  const { fetchedData, isLoading } = useFetchAiSuggestions(requestItem, 'options', Boolean(previewMode), periodName)
  const { formatMessage } = useIntl()
  const { hideInputField, setResponseItemView, previousAnswer, setQuestionSkippedFields } = useResponseItemContext()
  const { reuseAllQuestionsState } = useReport()

  const [options, setOptions] = useState<Option[]>(() =>
    requestItem.requestCollection?.items?.map(option => ({
      value: option.value,
      label: option.displayText,
    })),
  )

  const transformedOptions =
    previewMode === PreviewMode.SelectionDisabled ? options.map(op => ({ ...op, disabled: true })) : options

  const name = `${requestItem.id}.selectedOptions`

  const onApply = () =>
    setValue(
      name,
      transformedOptions.filter(option => option.additionalText).map(option => option.value),
    )

  const suggestedOptions = useMemo(() => {
    return new Set(fetchedData?.selectedOptions?.map(op => op.toLowerCase()) ?? [])
  }, [fetchedData?.selectedOptions])

  useEffect(() => {
    if (isLoading) return

    const optionsWithSuggestions = options
      ?.map(option => {
        if (suggestedOptions.has(option.value.toString().toLowerCase())) {
          return {
            ...option,
            additionalText: formatMessage({ id: 'suggestedAnswers.suggestedByAi' }),
          }
        }
        return option
      })
      ?.sort((a, b) => {
        if (a.additionalText && !b.additionalText) return -1
        if (!a.additionalText && b.additionalText) return 1
        return 0
      })

    setOptions(optionsWithSuggestions)
  }, [requestItem, fetchedData, isLoading])

  const applyPreviousAnswer = async () => {
    if (!previousAnswer) return
    if (previousAnswer.cannotAnswer) {
      setQuestionSkippedFields()
    } else {
      setValue(name, previousAnswer?.selectedOptions)
    }

    setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
    await trigger()
  }

  useEffect(() => {
    switch (reuseAllQuestionsState) {
      case ReuseAllQuestionsStates.ApplyAll:
        applyPreviousAnswer()
        break
      case ReuseAllQuestionsStates.SkipAll:
        setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)
        break
      default:
        setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
    }
  }, [reuseAllQuestionsState])

  return (
    <>
      <Stack spacing={3} display={hideInputField ? 'none' : undefined} px={4} pb={2}>
        <Select<string | number>
          multiple={requestItem.allowMultiChoice}
          required
          enableAutoSelect
          disabled={disabled}
          name={name}
          fieldLabel="Options"
          control={control}
          error={error}
          size="small"
          renderOption={(props, option, { selected }): JSX.Element => (
            <li {...props}>
              <OptionIcon selected={selected} multiple={requestItem.allowMultiChoice} />
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography variant="caption" color="communication.dark">
                  {option.additionalText}
                </Typography>
              </Stack>
            </li>
          )}
          findSelectedValue={(value, option): boolean => {
            const flatValue = [value].flat()

            return requestItem.allowMultiChoice ? flatValue.includes(option.value) : option.value === flatValue[0]
          }}
          options={transformedOptions}
          onClick={(): void => {
            if (hasError) clearErrors(`${requestItem.id}`)
          }}
        />
      </Stack>
      <SkipQuestionSection requestItem={requestItem} />
      <SuggestPreviousAnswer
        requestItem={requestItem}
        onApply={applyPreviousAnswer}
        onSkip={() => setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)}
      />
      <Box mt={1} px={4} width="100%">
        <AiSuggestedAnswerChip
          requestItem={requestItem}
          questionType="options"
          disabled={disabled}
          onApply={onApply}
          isPreview={Boolean(previewMode)}
        />
      </Box>
    </>
  )
}

export default OptionSelectWithSuggestion
