import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import useActorTypes from '@app/src/hooks/useActorTypes'
import CustomCategoriesFilters from '@app/src/pages/ResourceCollection/Filters/CustomCategoriesFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import FilterSelect from '@app/src/pages/ResourceCollection/Filters/FilterSelect'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderStandardCategoryNames, StandardCategoryOptions } from '@app/src/types/categories'
import { Box, Chip } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'

const ProviderCategoryFilterDrawer: React.FC = () => {
  const { formatMessage } = useIntl()
  const { renderWithPermission } = usePermissions()
  const { productMapping } = useFlags()

  const options = (categoryName: string): Array<string | number> => {
    switch (categoryName) {
      case ProviderStandardCategoryNames.ActivityStatus:
        return StandardCategoryOptions.providerActivityStatus
      case ProviderStandardCategoryNames.FinalRiskRating:
        return StandardCategoryOptions.finalRiskRating
      case ProviderStandardCategoryNames.Priority:
        return StandardCategoryOptions.providerPriority
      case ProviderStandardCategoryNames.ApprovalStatus:
        return StandardCategoryOptions.providerApprovalStatus
      case ProviderStandardCategoryNames.Tier:
        return StandardCategoryOptions.tier
      case ProviderStandardCategoryNames.SupplierUsage:
        return StandardCategoryOptions.supplierUsage
      default:
        return []
    }
  }

  const { items: actorTypes } = useActorTypes()

  return (
    <>
      <FilterSection label={formatMessage({ id: 'schemas.filter.companies' })}>
        <Filter operator={Operators.In} name="country.id">
          {({ value, onChange, filterName }) => (
            <FilterFacetSelect
              size="small"
              multiple
              facetsParam={{
                key: [FetchKey.Provider, filterName],
                endpoint: endpoints.providersWithFacets,
                facetsParam: [{ name: 'country.name' }],
              }}
              filterName={filterName}
              onChange={value => onChange(value)}
              fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
              value={[value].flat()}
            />
          )}
        </Filter>
      </FilterSection>
      <FilterSection defaultCollapsed label={formatMessage({ id: 'schemas.filter.additionalInformation' })}>
        <Box mb={2}>
          <Filter operator={Operators.In} name={ProviderStandardCategoryNames.ActivityStatus}>
            {({ value, onChange, filterName }) => (
              <FilterSelect
                size="small"
                multiple
                renderOption={(props, option, { selected }): JSX.Element => (
                  <li {...props}>
                    <OptionIcon selected={selected} multiple />
                    {option?.label !== undefined
                      ? formatMessage({ id: `schemas.provider.activityStatusValues.${option.label}` })
                      : ''}
                  </li>
                )}
                options={
                  options(filterName)?.map(categoryOption => ({
                    label: String(categoryOption),
                    value: categoryOption,
                  })) ?? []
                }
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.value.toString() || index}
                          label={formatMessage({
                            id: `schemas.provider.activityStatusValues.${option.label}`,
                          })}
                          size="small"
                        />
                      ),
                  )
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </Box>
        <Box mb={2}>
          <Filter operator={Operators.In} name={ProviderStandardCategoryNames.FinalRiskRating}>
            {({ value, onChange, filterName }) => (
              <FilterSelect
                size="small"
                multiple
                renderOption={(props, option, { selected }): JSX.Element => (
                  <li {...props}>
                    <OptionIcon selected={selected} multiple />
                    <FinalRiskRatingIcon value={option?.label} />
                    {option?.label !== undefined
                      ? formatMessage({
                          id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                        })
                      : ''}
                  </li>
                )}
                options={
                  options(filterName)?.map(categoryOption => ({
                    label: String(categoryOption),
                    value: categoryOption,
                  })) ?? []
                }
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.value.toString() || index}
                          icon={<FinalRiskRatingIcon value={option.label} />}
                          label={formatMessage({
                            id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                          })}
                          size="small"
                        />
                      ),
                  )
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </Box>
        <Box mb={2}>
          <Filter operator={Operators.In} name={ProviderStandardCategoryNames.Priority}>
            {({ value, onChange, filterName }) => (
              <FilterSelect
                size="small"
                multiple
                renderOption={(props, option, { selected }): JSX.Element => (
                  <li {...props}>
                    <OptionIcon selected={selected} multiple />
                    {option?.label !== undefined
                      ? formatMessage({ id: `schemas.provider.priorityValues.${option.label}` })
                      : ''}
                  </li>
                )}
                options={
                  options(filterName)?.map(categoryOption => ({
                    label: String(categoryOption),
                    value: categoryOption,
                  })) ?? []
                }
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.value.toString() || index}
                          label={formatMessage({
                            id: `schemas.provider.priorityValues.${option.label}`,
                          })}
                          size="small"
                        />
                      ),
                  )
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </Box>
        <Box mb={2}>
          <Filter operator={Operators.In} name={ProviderStandardCategoryNames.ApprovalStatus}>
            {({ value, onChange, filterName }) => (
              <FilterSelect
                size="small"
                multiple
                renderOption={(props, option, { selected }): JSX.Element => (
                  <li {...props}>
                    <OptionIcon selected={selected} multiple />
                    {option?.label !== undefined
                      ? formatMessage({
                          id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                        })
                      : ''}
                  </li>
                )}
                options={
                  options(filterName)?.map(categoryOption => ({
                    label: String(categoryOption),
                    value: categoryOption,
                  })) ?? []
                }
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.value.toString() || index}
                          label={formatMessage({
                            id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                          })}
                          size="small"
                        />
                      ),
                  )
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </Box>
        {renderWithPermission({
          [Permissions.SOURCING_USER]: (
            <>
              <Box mb={2}>
                <Filter operator={Operators.In} name={ProviderStandardCategoryNames.SupplierUsage}>
                  {({ value, onChange, filterName }) => (
                    <FilterSelect
                      size="small"
                      multiple
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <li {...props}>
                          <OptionIcon selected={selected} multiple />
                          {option?.label !== undefined
                            ? formatMessage({ id: `schemas.provider.supplierUsageValues.${option.label}` })
                            : ''}
                        </li>
                      )}
                      options={
                        options(filterName)?.map(categoryOption => ({
                          label: String(categoryOption),
                          value: categoryOption,
                        })) ?? []
                      }
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Chip
                                {...getTagProps({ index })}
                                key={option.value.toString() || index}
                                label={formatMessage({
                                  id: `schemas.provider.supplierUsageValues.${option.label}`,
                                })}
                                size="small"
                              />
                            ),
                        )
                      }
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>
              <Box mb={2}>
                <Filter operator={Operators.In} name={ProviderStandardCategoryNames.Tier}>
                  {({ value, onChange, filterName }) => (
                    <FilterSelect
                      size="small"
                      multiple
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <li {...props}>
                          <OptionIcon selected={selected} multiple />
                          {option?.value !== undefined
                            ? formatMessage(
                                {
                                  id: `schemas.provider.tierValues`,
                                },
                                { tier: option.label },
                              )
                            : ''}
                        </li>
                      )}
                      options={
                        options(filterName)?.map(categoryOption => ({
                          label: String(categoryOption),
                          value: categoryOption,
                        })) ?? []
                      }
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Chip
                                {...getTagProps({ index })}
                                key={option.value.toString() || index}
                                label={formatMessage(
                                  {
                                    id: `schemas.provider.tierValues`,
                                  },
                                  { tier: option.label },
                                )}
                                size="small"
                              />
                            ),
                        )
                      }
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Box>
            </>
          ),
        })}
      </FilterSection>
      <CustomCategoriesFilters />

      {productMapping && (
        <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
          <Filter operator={Operators.In} name="mappingNodes.actorTypeModel.id">
            {({ value, onChange }) => (
              <FilterSelect
                size="small"
                multiple
                groupBy={option => option.additionalText ?? ''}
                renderOption={(props, option, { selected }) => (
                  <OptionWithLabel
                    key={Number(option.value)}
                    props={props}
                    label={option?.label}
                    multiple
                    selected={selected}
                  />
                )}
                options={actorTypes.map(actorType => ({
                  label: formatMessage({ id: `schemas.mappingNode.actorTypeValues.${actorType.name}` }),
                  value: actorType.id,
                  additionalText: actorType.actorTypeGroup
                    ? formatMessage({ id: `schemas.mappingNode.actorTypeGroups.${actorType.actorTypeGroup}` })
                    : '',
                }))}
                renderTags={(value, getTagProps) =>
                  value?.map(
                    (option, index) =>
                      option && (
                        <Chip
                          {...getTagProps({ index })}
                          key={option?.value?.toString() || index}
                          label={option?.label}
                          size="small"
                        />
                      ),
                  )
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                value={[value].flat()}
              />
            )}
          </Filter>
          <Filter operator={Operators.In} name="mappingNodes.tier">
            {({ value, onChange }) => (
              <FilterSelect
                size="small"
                multiple
                options={
                  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(tier => ({
                    label: tier.toString(),
                    value: tier.toString(),
                  })) ?? []
                }
                onChange={value => onChange(value)}
                fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                value={[value].flat()}
              />
            )}
          </Filter>
        </FilterSection>
      )}
    </>
  )
}
export default ProviderCategoryFilterDrawer
