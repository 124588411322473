import {
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { InfoOutlined } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Select from '../../../components/Form/Select/ControlledSelect'
import TextField from '../../../components/Ui/TextField'
import AdvancedFeatures from '../AdvancedFeatures'

export enum NumberFields {
  NumberGreaterThan = AssessmentRuleType.NumberGreaterThan,
  NumberLowerThan = AssessmentRuleType.NumberLowerThan,
  NumberEqualTo = AssessmentRuleType.NumberEqualTo,
}

type QuestionAnswerClassificationRuleSaveModel = Omit<QuestionAnswerClassificationRule, 'rank'> & {
  rank: QuestionAnswerRank | 'NotSet'
  disabled?: boolean
}

const NumberClassification: React.FC = () => {
  const { formatMessage } = useIntl()
  const { control, register, setValue, watch } = useFormContext()

  const startOfName = 'questionNumberClassificationRules'

  const watchQuestionNumberClassificationRules: Array<QuestionAnswerClassificationRuleSaveModel | undefined> =
    watch(startOfName)

  const presentValues = useMemo(
    () =>
      Object.values(watchQuestionNumberClassificationRules ?? {})
        .filter(item => item?.rank !== 'NotSet')
        .map(item => item?.rank)
        .filter(Boolean),
    [watchQuestionNumberClassificationRules],
  )

  return (
    <Grid item xs={12}>
      <AdvancedFeatures hideWarningBanner>
        <Grid container bgcolor="common.white" spacing={2}>
          <Grid item xs={12} alignItems="center">
            <Box display="flex" alignItems="flex-start">
              <InfoOutlined fontSize="small" color="action" />
              <Typography variant="caption" color="textSecondary" ml={1}>
                {formatMessage({ id: 'templateBuilder.answerClassifications.numberAdvancedInfo' })}
              </Typography>
            </Box>
          </Grid>

          {Object.values(NumberFields).map(item => (
            <React.Fragment key={item}>
              <Grid item xs={12}>
                <TextField
                  name={`${startOfName}.${item}.value`}
                  inputRef={register}
                  hoveringLabel
                  placeholder={formatMessage({ id: 'templateBuilder.answerClassifications.addNumber' })}
                  label={formatMessage({
                    id: `templateBuilder.answerClassifications.numberClassification.${item}`,
                  })}
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name={`${startOfName}.${item}.rank`}
                  control={control}
                  fieldLabel={formatMessage({ id: 'templateBuilder.answerClassifications.classification' })}
                  options={Object.values(QuestionAnswerRank)
                    .filter(item => item !== QuestionAnswerRank.NotSet)
                    .map(item => ({
                      label: formatMessage({
                        id: `templateBuilder.answerClassifications.classificationsRanks.${item}`,
                      }),
                      value: item,
                      disabled: presentValues.includes(item),
                    }))}
                  size="small"
                  onInputChange={(_e, value) => {
                    if (!value) setValue(`${startOfName}.${item}.rank`, undefined)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider flexItem sx={{ my: 2 }} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </AdvancedFeatures>
    </Grid>
  )
}

export default NumberClassification
