import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type DataHubInsightsDetailsHeaderProps = SortableHeaderProps & {
  requestItems?: string[]
  hasFlags: boolean
}

const DataHubInsightsDetailsHeader: React.FC<DataHubInsightsDetailsHeaderProps> = ({
  requestItems,
  hasFlags,
  toggleSorting,
  activeSorting,
}) => {
  const { formatMessage } = useIntl()

  const itemCells = requestItems?.map((item, index) => (
    <TableHeaderCell maxRows={2} label={item} key={`${item}-${index}`} minWidth={TableCellWidth.LARGE} />
  ))

  if (!itemCells?.length) return null

  return (
    <TableRow>
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'resourceCollections.general.company' })}
        sorting={{ name: 'request.responderOrganization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        maxRows={2}
        label={formatMessage({ id: 'statistics.totalRisk' })}
        minWidth={TableCellWidth.SMALL}
        sorting={{ name: 'responseAnalytics.flagCount', toggleSorting, activeSorting }}
      />
      {...itemCells}
    </TableRow>
  )
}

export default DataHubInsightsDetailsHeader
