import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const AssessmentTemplateHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell
        minWidth={TableCellWidth.LARGE}
        label={formatMessage({ id: 'schemas.assessmentTemplate.name' })}
      />
    </TableRow>
  )
}

export default AssessmentTemplateHeader
