import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem, RequestStatus } from '@app/src/types/resourceExplorer'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ResponseItemContext from './ResponseItemContext'

export enum ResponseItemView {
  ShowSuggestedAnswer = 'ShowSuggestedAnswer',
  SuggestedAnswerSkipped = 'SuggestedAnswerSkipped',
  SuggestedAnswerAccepted = 'SuggestedAnswerAccepted',
  SkipQuestion = 'SkipQuestion',
}

interface Props {
  children: JSX.Element
  hasValue: boolean
  requestItem: RequestItem
  cannotAnswer: boolean
}

const ResponseItemContextProvider = ({ children, hasValue, requestItem, cannotAnswer }: Props): JSX.Element => {
  const [responseItemView, setResponseItemView] = useState<ResponseItemView>(ResponseItemView.ShowSuggestedAnswer)
  const { previouslyRespondedQuestions, request } = useReport()
  const { setValue } = useFormContext()

  useEffect(() => {
    if (hasValue && responseItemView !== ResponseItemView.SuggestedAnswerAccepted) {
      setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)
    }
  }, [hasValue, responseItemView])

  const previousAnswer = previouslyRespondedQuestions?.find(
    item => item.requestItem.questionId === requestItem.questionId,
  )

  const hideInputField =
    cannotAnswer ||
    (responseItemView === ResponseItemView.ShowSuggestedAnswer &&
      Boolean(previousAnswer) &&
      request?.requestStatus === RequestStatus.Sent)

  const setQuestionSkippedFields = () => {
    if (!previousAnswer) return
    setValue(`${requestItem.id}.cannotAnswer`, true)
    setValue(`${requestItem.id}.planOnHaving`, Boolean(previousAnswer.estimatedAnswerAt))
    setValue(`${requestItem.id}.estimatedAnswerAt`, previousAnswer.estimatedAnswerAt)
    setValue(`${requestItem.id}.answerStatus`, previousAnswer.answerStatus)
    setValue(`${requestItem.id}.comment`, previousAnswer.comment)
  }

  return (
    <ResponseItemContext.Provider
      value={{
        responseItemView: responseItemView,
        setResponseItemView: setResponseItemView,
        previousAnswer,
        hideInputField,
        setQuestionSkippedFields,
      }}
    >
      {children}
    </ResponseItemContext.Provider>
  )
}

export default ResponseItemContextProvider
