import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(({ spacing }) => ({
  progressBar: {
    marginTop: spacing(2),
  },
}))

interface RequestModalContainerProps {
  title?: string
  onClose: () => void
  showProgress?: boolean
  overflow?: 'hidden' | 'visible'
  disablePadding?: boolean
}

const CreationModalContainer: React.FC<PropsWithChildren<RequestModalContainerProps>> = ({
  title,
  onClose,
  showProgress = true,
  children,
  overflow = 'hidden',
  disablePadding,
}) => {
  const { formatMessage } = useIntl()
  const { progress, activeStep, totalSteps } = useCreationModalProgress()
  const { progressBar } = useStyles()

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" alignItems="center">
          <Typography variant="overline">{title}</Typography>

          {showProgress && (
            <>
              <Box mx={2}>•</Box>
              <Typography variant="subtitle1" color="textSecondary">
                {formatMessage({ id: 'form.createRequest.steps' }, { activeStep, totalSteps })}
              </Typography>
            </>
          )}
        </Box>

        <Button variant="text" onClick={onClose}>
          {formatMessage({ id: 'general.cancel' })}
        </Button>
      </Box>

      {showProgress && <LinearProgress className={progressBar} variant="determinate" value={progress} />}

      <Box display="flex" justifyContent="center" flexGrow={1} py={disablePadding ? 0 : 5} overflow={overflow}>
        {children}
      </Box>
    </Box>
  )
}

export default CreationModalContainer
