import QuestionConfig from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionConfig'
import QuestionsConfig from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/QuestionsConfig'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import React from 'react'
import CreateAutomation from './SustainabilityLibraryPages/Questionnaires/Flags/CreateAutomation'
import QuestionnaireConfig from './SustainabilityLibraryPages/Questionnaires/QuestionnaireConfig'
import QuestionnairesConfig from './SustainabilityLibraryPages/Questionnaires/QuestionnairesConfig'
import Assessments from '@app/src/components/Configurations/SustainabilityLibraryPages/Assessments/Assessments'
import CreateAssessmentFromQuestionnaire from '@app/src/components/Configurations/SustainabilityLibraryPages/Assessments/CreateAssessmentFromQuestionnaire'

interface Props {
  content: SUSTAINABILITY_LIBRARY_PAGE_IDS
  configurationsSubPage?: string
  activeTabSubId?: string
  activeTabParam?: string
}

const SustainabilityLibraryContent: React.FC<Props> = ({ content, configurationsSubPage, activeTabSubId }) => {
  switch (content) {
    case SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires:
      if (activeTabSubId) return <CreateAutomation requestItemTemplateId={activeTabSubId} />

      return Number(configurationsSubPage) ? (
        <QuestionnaireConfig id={configurationsSubPage ?? ''} />
      ) : (
        <QuestionnairesConfig />
      )

    case SUSTAINABILITY_LIBRARY_PAGE_IDS.Questions:
      return Number(configurationsSubPage) ? <QuestionConfig id={configurationsSubPage ?? ''} /> : <QuestionsConfig />
    case SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments:
      return Number(configurationsSubPage) ? (
        <CreateAssessmentFromQuestionnaire questionnaireTemplateId={Number(configurationsSubPage)} />
      ) : (
        <Assessments />
      )
  }
}

export default SustainabilityLibraryContent
