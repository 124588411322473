import useQueryFilters, { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { QueryParamType } from '@app/src/hooks/queryState'
import { FilterGroupQueryString, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ResetPage } from '@app/src/types/filter'
import React, { createContext, useContext, useMemo, useState } from 'react'

export interface FilterInterface {
  ['name']: string
  ['operator']: Operators
  [key: string]: string | Array<string>
}

export interface FiltersContextProps {
  filters: Record<string, FilterInterface | FilterInterface[]>
  setQueryFilter: (
    name: string,
    value: FilterInterface['value'],
    operator: Operators,
    uniqueId?: string,
    replace?: boolean,
    intersect?: boolean,
  ) => void
  setQueryFilters: (value: QueryParamType, replace?: boolean | undefined) => void
  setQueryFilterOperator: (name: string, operator: Operators, uniqueId?: string) => void
  allowedFilters: Array<string>
  clearQueryFilter: () => void
  apiFilters: FilterGroupQueryString[]
  visibleFilters: Array<string>
  addQuickFilter: (name: string) => void
  inDrawerFiltersCount: number
}

export interface FiltersChildrenRenderProps {
  filters: FiltersContextProps['filters']
  setQueryFilter: FiltersContextProps['setQueryFilter']
  clearQueryFilter: FiltersContextProps['clearQueryFilter']
  inDrawerFiltersCount: number
  isFiltered: boolean
}

export interface FiltersProps {
  allowedFilters: FiltersContextProps['allowedFilters']
  resetPage?: ResetPage
  children: ({ filters, setQueryFilter, clearQueryFilter, isFiltered }: FiltersChildrenRenderProps) => JSX.Element
}

const FiltersContext = createContext<FiltersContextProps>({
  filters: {},
  setQueryFilter: () => undefined,
  setQueryFilters: () => undefined,
  setQueryFilterOperator: () => undefined,
  clearQueryFilter: () => undefined,
  allowedFilters: [],
  apiFilters: [],
  visibleFilters: [],
  addQuickFilter: () => undefined,
  inDrawerFiltersCount: 0,
})

export const useFiltersContext = (): FiltersContextProps => {
  return useContext(FiltersContext)
}

const Filters: React.FC<FiltersProps> = ({ allowedFilters, resetPage, children }) => {
  const { filters, setQueryFilter, clearQueryFilter, setQueryFilterOperator, setQueryFilters } = useQueryFilters(
    allowedFilters,
    resetPage,
  )
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const [quickFilters, setQuickFilters] = useState<Array<string>>([])

  const addQuickFilter = (name: string) => {
    setQuickFilters(prev => Array.from(new Set([...prev, name])))
  }
  const inDrawerFiltersCount = useMemo(
    () =>
      Object.values(filters)
        .flat()
        .filter(apiFilter => !quickFilters.includes(apiFilter.name)).length,
    [quickFilters, filters],
  )

  const isFiltered = Object.keys(filters).length > 0

  return (
    <FiltersContext.Provider
      value={{
        filters,
        allowedFilters,
        setQueryFilter,
        setQueryFilters,
        setQueryFilterOperator,
        clearQueryFilter,
        apiFilters,
        visibleFilters: quickFilters,
        addQuickFilter,
        inDrawerFiltersCount,
      }}
    >
      <>
        {children({
          filters,
          setQueryFilter,
          clearQueryFilter,
          inDrawerFiltersCount,
          isFiltered,
        })}
      </>
    </FiltersContext.Provider>
  )
}

export default Filters
