import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewProviderRiskCard from '@app/src/components/Drawer/Views/DrawerViewProviderRiskCard'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import RiskChipCell from '@app/src/components/Table/Cells/RiskChipCell'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import QuestionnaireChart from '@app/src/pages/ResourceCollection/Collections/QuestionnaireChart'
import { FinalRiskRating } from '@app/src/types/categories'
import { Provider } from '@app/src/types/organizations'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { Add, Create } from '@mui/icons-material'
import { Box, Button, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Operators } from '../Filters/useFilters'

interface ProvidersOverviewProps {
  providerId: number
}

export enum DrawerViews {
  RiskCard = 'RiskCard',
  FinalRiskRating = 'FinalRiskRating',
}

const ProvidersOverview: React.FC<ProvidersOverviewProps> = ({ providerId }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const {
    items: [provider],
    isLoading,
  } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: providerId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: ['organization.*', 'risk', 'locations', 'riskPrioritization'],
    },
  })

  const providerRisksOrderedPeriods = useMemo(
    () => provider?.riskPrioritization?.map(risk => risk.periodName).sort(comparePeriods),
    [provider],
  )
  const latestProviderRiskPeriod = providerRisksOrderedPeriods?.[providerRisksOrderedPeriods?.length - 1]
  const latestProviderRisk = provider?.riskPrioritization?.find(risk => risk.periodName === latestProviderRiskPeriod)

  return (
    <Box pt={2} pr={5}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <StatisticsCard
            title={formatMessage({ id: 'schemas.providerRisk.riskStatusWithYear' }, { year: latestProviderRiskPeriod })}
            action={
              latestProviderRisk && (
                <Button
                  onClick={() =>
                    openDrawer(
                      <DrawerViewProviderRiskCard
                        riskPrioritizationSummary={{
                          riskPrioritization: latestProviderRisk,
                          hqCountry: provider?.country,
                        }}
                      />,
                    )
                  }
                >
                  {formatMessage({ id: 'general.seeMore' })}
                </Button>
              )
            }
            justifyContent="left"
          >
            {isLoading ? (
              <Skeleton width={150} height={35} variant="rounded" />
            ) : latestProviderRisk?.riskStatus ? (
              <RiskChipCell disableCell risk={latestProviderRisk.riskStatus} size="medium" />
            ) : (
              <Stack height="100%" justifyContent="center">
                <Typography>{formatMessage({ id: 'general.notSet' })}</Typography>
              </Stack>
            )}
          </StatisticsCard>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StatisticsCard title={formatMessage({ id: 'schemas.provider.finalRiskRating' })} justifyContent="left">
            <Box display="flex" alignItems="center" py={2}>
              {isLoading ? (
                <Skeleton width={150} height={35} variant="rounded" />
              ) : (
                <>
                  {provider?.finalRiskRating !== FinalRiskRating.NotSelected && (
                    <FinalRiskRatingCell value={provider?.finalRiskRating} disableCell />
                  )}

                  <Button
                    startIcon={provider?.finalRiskRating === FinalRiskRating.NotSelected ? <Add /> : <Create />}
                    size="small"
                    onClick={() =>
                      openDrawer(
                        <DrawerViewRiskAssessment
                          providerIds={[provider?.id ?? 0]}
                          subTitle={provider?.name}
                          finalRiskRating={provider?.finalRiskRating ?? FinalRiskRating.NotSelected}
                        />,
                      )
                    }
                    variant="text"
                    sx={{ ml: 1 }}
                  >
                    {formatMessage({
                      id:
                        provider?.finalRiskRating === FinalRiskRating.NotSelected
                          ? 'schemas.provider.addFinalRiskRating'
                          : 'general.edit',
                    })}
                  </Button>
                </>
              )}
            </Box>
          </StatisticsCard>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: '100%', p: 3 }}>
            {provider && <QuestionnaireChart provider={provider} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProvidersOverview
