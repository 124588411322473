import useOnScreen from '@app/src/hooks/onScreen'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { HistoryStatus } from '@app/src/types/resourceExplorer'
import { Box, Paper, Slide } from '@mui/material'
import React from 'react'
import ReportButton from './ReportButton'

type ButtonBottomBarProps = {
  headerRef: React.RefObject<HTMLDivElement>
}

const ButtonBottomBar: React.FC<ButtonBottomBarProps> = ({ headerRef }) => {
  const { reportStatus } = useReport()
  const headerOnScreen = useOnScreen(headerRef)

  if (reportStatus === HistoryStatus.Draft)
    return (
      <Slide in={!headerOnScreen} direction="up" mountOnEnter unmountOnExit timeout={150}>
        <Paper
          component={Box}
          elevation={10}
          position="sticky"
          bottom={0}
          px={5}
          py={1}
          pr={10}
          bgcolor="common.white"
          zIndex={1}
        >
          <ReportButton />
        </Paper>
      </Slide>
    )

  return null
}

export default ButtonBottomBar
