import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import DrawerViewSkeleton from '@app/src/components/Drawer/DrawerViewSkeleton'
import LoadingButton from '@app/src/components/LoadingButton'
import InviteSentContent from '@app/src/components/ReferralContact/InviteSentContent'
import { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useReferralContact } from '@app/src/hooks/referralContact'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { Provider } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import {
  ContentCopyOutlined,
  DeleteOutline,
  EmailOutlined,
  InfoOutlined,
  MailOutline,
  RefreshOutlined,
} from '@mui/icons-material'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import DrawerViewLinkProvider from './DrawerViewLinkProvider'
import DrawerViewSendInviteToReferralContact from './DrawerViewSendInviteToReferralContact'

type DrawerViewReferralContactProps = {
  provider: Provider
} & Omit<DrawerViewProps, 'title'>

const DrawerViewReferralContact: React.FC<DrawerViewReferralContactProps> = ({ provider, ...props }) => {
  const { mutateAsync: deleteResource, isLoading: isDeleting } = useDeleteResource()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialogState()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)

  const hasSentInvite = Boolean(referralContact?.referralEmailHistory?.length)

  const handleDeleteReferralContact = async () => {
    deleteResource(
      { url: endpoints.deleteReferralContact(referralContact?.id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'referral.delete.successMessage' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.ReferralContactCollection)
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.ProviderReferralContactFacets)

          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.InviteSupplier.ContactDeleted,
          })

          openDrawer(<DrawerViewLinkProvider provider={provider} />)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const copyRefLinkToClipBoard = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}?referral=${referralContact.referralCode}`)
    showSnackbar({ message: formatMessage({ id: 'referral.sendInvite.copySuccess' }), severity: 'success' })
  }

  if (isReferralContactsLoading) {
    return <DrawerViewSkeleton buttons={2} />
  }

  return (
    <DrawerView
      title={referralContact.name}
      subTitle={provider.name}
      stackButtons
      buttons={[
        {
          label: formatMessage({ id: 'referral.sendInvite.delete' }),
          startIcon: <DeleteOutline />,
          onClick: openConfirmationDialog,
          loading: isDeleting,
        },
        ...insertIf<ActionButton>(hasSentInvite, {
          label: formatMessage({ id: 'referral.sendInvite.copyInvite' }),
          startIcon: <ContentCopyOutlined color="action" />,
          variant: 'outlined',
          onClick: copyRefLinkToClipBoard,
        }),
        {
          label: hasSentInvite
            ? formatMessage({ id: 'referral.sendInvite.resendInviteToConnect' })
            : formatMessage({ id: 'referral.sendInvite.inviteToConnect' }),
          startIcon: hasSentInvite ? <RefreshOutlined /> : <MailOutline />,
          variant: 'contained',
          onClick: () => openDrawer(<DrawerViewSendInviteToReferralContact provider={provider} />),
          disabled: isDeleting,
        },
      ]}
      {...props}
    >
      <Stack alignItems="center" spacing={2} py={2}>
        <PersonAvatar name={referralContact.name} size={AvatarSize.XXL} disabled />
        <Typography variant="h6">{referralContact.name}</Typography>
        {referralContact?.referralEmailHistory?.length ? (
          <Chip label={formatMessage({ id: 'referral.inviteSent.title' })} variant="filled" />
        ) : (
          <Chip
            label={formatMessage({ id: 'referral.invitation.notConnected' })}
            variant="outlined"
            icon={<InfoOutlined fontSize="small" />}
          />
        )}
      </Stack>
      <ListItem>
        <ListItemIcon>
          <EmailOutlined />
        </ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'referral.inviteSent.email' })} secondary={referralContact?.email} />
      </ListItem>

      {hasSentInvite && <InviteSentContent provider={provider} />}

      <Dialog open={isConfirmationDialogOpen} maxWidth="sm">
        <DialogTitle>{formatMessage({ id: 'referral.delete.title' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'referral.delete.description' }, { br: <br /> })}</DialogContent>

        <DialogActions>
          <Button disabled={isDeleting} onClick={closeConfirmationDialog}>
            {formatMessage({ id: 'general.cancel' })}
          </Button>
          <LoadingButton color="error" loading={isDeleting} onClick={handleDeleteReferralContact}>
            {formatMessage({ id: 'referral.delete.deleteContact' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </DrawerView>
  )
}

export default DrawerViewReferralContact
