import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Table from '@app/src/components/Table'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import FileRepoHeader from '@app/src/pages/Configurations/ConfigurationsPages/FileRepository/FileRepoHeader'
import FileRepoRow from '@app/src/pages/Configurations/ConfigurationsPages/FileRepository/FileRepoRow'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

const FileRepository: React.FC = () => {
  const [isWaitingToReload, setIsWaitingToReload] = React.useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { mutateAsync: uploadFileAsync, isLoading: isUploading } = useCreateResource<unknown, FormData>()

  const {
    data: files,
    isLoading,
    isError,
  } = useFetchResource<string[]>({
    endpoint: endpoints.suggestionFiles,
    key: FetchKey.FileRepository,
  })

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    await uploadFileAsync(
      { url: endpoints.suggestionFiles, body: formData },
      {
        onSuccess: () => {
          setIsWaitingToReload(true)
          setTimeout(() => {
            queryClient.invalidateQueries(FetchKey.FileRepository)
            queryClient.invalidateQueries(FetchKey.SuggestedResponse)
            setIsWaitingToReload(false)
          }, 2000)
        },
        onError: error =>
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          }),
      },
    )
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      await uploadFile(selectedFile)
    }
  }

  return (
    <ConfigurationCollection
      actionButtons={[
        {
          label: formatMessage({ id: 'schemas.repositoryFile.upload' }),
          onClick: handleFileClick,
          variant: 'contained',
          loading: isUploading,
        },
      ]}
      enablePadding={{ right: true }}
    >
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <Table<string>
        HeaderComponent={() => <FileRepoHeader />}
        RowComponent={({ row }) => <FileRepoRow file={row} />}
        data={files ?? []}
        isLoading={isLoading || isUploading || isWaitingToReload}
        isError={isError}
        count={10000}
        page={1}
        pageSize={10000}
        noPagination
      />
    </ConfigurationCollection>
  )
}

export default FileRepository
