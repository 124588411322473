import { Divider, Skeleton, Stack, Typography } from '@mui/material'
import { useReferralContact } from '@app/src/hooks/referralContact'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'
import SendInviteTimeline from './SendInviteTimeline'

interface SendInviteContentProps {
  provider: Provider
}

const SendInviteContent: React.FC<SendInviteContentProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)

  if (isReferralContactsLoading) {
    return <Skeleton variant="rectangular" height={80} />
  }

  return (
    <Stack p={2} flexGrow={1} height="100%">
      <Stack flexGrow={1}>
        <Typography color="textSecondary">
          {formatMessage(
            { id: 'referral.invitation.header' },
            {
              referralName: referralContact?.name,
              providerName: provider.name,
              referralEmail: referralContact?.email,
            },
          )}
        </Typography>
        <Divider sx={{ my: 4 }} />

        <Typography variant="subtitle1" color="textPrimary">
          {formatMessage({ id: 'referral.invitationSchedule.header' })}
        </Typography>
        <SendInviteTimeline />
      </Stack>
    </Stack>
  )
}

export default SendInviteContent
