import React from 'react'
import EmptyState from '@app/src/components/EmptyState'
import FormatListNumbered from '@mui/icons-material/FormatListNumbered'
import { Button, Stack, Typography } from '@mui/material'
import SustainabilityLibraryCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/SustainabilityLibraryCollection'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import endpoints from '@app/src/api/endpoints'
import { AssessmentTemplate, Request } from '@app/src/types/resourceExplorer'
import Table from '@app/src/components/Table'
import AssessmentTemplateRow from '@app/src/components/Configurations/SustainabilityLibraryPages/Assessments/AssessmentTemplateRow'
import AssessmentTemplateHeader from '@app/src/components/Configurations/SustainabilityLibraryPages/Assessments/AssessmentTemplateHeader'
import usePagination from '@app/src/hooks/pagination'
import { useConfirm } from 'material-ui-confirm'
import { SortOrder } from '@app/src/types/filter'
import { useForm } from 'react-hook-form'
import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import { WF_REPORT_QUESTION_KEYS } from '@app/src/components/CreateNewRequestModal/WfReport'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import paths from '@app/src/wf-constants/paths'
import { useHistory } from 'react-router-dom'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'

interface FormData {
  templateIds: { id: number; title: string }
}

const Assessments: React.FC = () => {
  const { items, count, isError, isLoading } = useFetchCollectionWithPost<AssessmentTemplate>({
    endpoint: endpoints.assessmentTemplateCollection,
    key: FetchKey.AssessmentTemplateCollection,
    payload: {
      filter: [],
      include: [],
    },
  })

  const [page, pageSize, setPage, setPageSize] = usePagination()
  const confirm = useConfirm()
  const { control, handleSubmit } = useForm<FormData>()
  const { formatMessage } = useIntl()
  const history = useHistory()

  return (
    <SustainabilityLibraryCollection
      actionButtons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.create' }),
          onClick: () =>
            confirm({
              title: formatMessage({ id: 'assessments.createAssessmentModalTitle' }),
              content: (
                <Stack spacing={2}>
                  <Select<{ title: string; id: number }, Request>
                    name={WF_REPORT_QUESTION_KEYS.TEMPLATE_IDS}
                    fieldLabel={formatMessage({ id: 'assessments.selectQuestionnaire' })}
                    control={control}
                    required
                    enableAutoSelect
                    forceFetch
                    navigation={{
                      url: endpoints.worldfavorTemplates,
                      type: 'post',
                      postObject: {
                        include: [],
                        filter: [],
                        sort: { target: 'title', order: SortOrder.ASCENDING },
                      },
                    }}
                    findSelectedValue={(value, option): boolean => value?.id === option?.value?.id}
                    objectToOption={({ title, id }: Request): Option<{ title: string; id: number }> => ({
                      label: title,
                      value: { title, id },
                    })}
                  />
                  <Typography variant="caption">
                    {formatMessage({ id: 'assessments.createAssessmentModalDescription' })}
                  </Typography>
                </Stack>
              ),
            }).then(() => {
              handleSubmit(values => {
                history.push(
                  generatePath(paths.sustainabilityLibrary, {
                    configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Assessments,
                    configurationsSubPage: values.templateIds?.id ?? 0,
                  }),
                )
              })()
            }),
          variant: 'contained',
        },
      ]}
    >
      <Table<AssessmentTemplate>
        data={items}
        HeaderComponent={AssessmentTemplateHeader}
        RowComponent={AssessmentTemplateRow}
        count={count}
        isError={isError}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        emptyState={
          <EmptyState
            iconComponent={FormatListNumbered}
            title={formatMessage({ id: 'assessments.emptyStateTitle' })}
            description={formatMessage({ id: 'assessments.emptyStateDescription' })}
            children={<Button variant="outlined">{formatMessage({ id: 'general.create' })}</Button>}
          />
        }
      />
    </SustainabilityLibraryCollection>
  )
}

export default Assessments
