import { CheckCircle } from '@mui/icons-material'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab'
import { Box, Skeleton, Typography } from '@mui/material'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { useReferralContact } from '@app/src/hooks/referralContact'
import React from 'react'
import { useIntl } from 'react-intl'
import { Provider } from '@app/src/types/organizations'

interface InviteSentContentProps {
  provider: Provider
}

const InviteSentContent: React.FC<InviteSentContentProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)

  const addDays = (date: string, days: number): string => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result.toISOString()
  }

  const getEvents = () => {
    const baseDate = referralContact?.referralEmailHistory?.[0]?.createdAt || new Date().toISOString()
    const firstReminderDate = addDays(baseDate, 7)
    const secondReminderDate = addDays(baseDate, 14)
    const isPassedDate = (date: string) => new Date(date) <= new Date()

    return [
      {
        id: 1,
        message: 'referral.invitationSchedule.emailSent',
        variables: { date: formatValueToDate({ value: baseDate, withTime: true }) },
        completed: isPassedDate(baseDate),
      },
      {
        id: 2,
        message: `referral.invitationSchedule.${
          isPassedDate(firstReminderDate) ? 'reminderEmailSent' : 'reminderEmailScheduled'
        }`,
        variables: { date: formatValueToDate({ value: firstReminderDate }) },
        completed: isPassedDate(firstReminderDate),
      },
      {
        id: 3,
        message: `referral.invitationSchedule.${
          isPassedDate(secondReminderDate) ? 'reminderEmailSent' : 'reminderEmailScheduled'
        }`,
        variables: { date: formatValueToDate({ value: secondReminderDate }) },
        completed: isPassedDate(secondReminderDate),
      },
    ]
  }

  if (isReferralContactsLoading) {
    return <Skeleton variant="rectangular" height={80} />
  }

  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Timeline
        sx={{
          padding: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {getEvents().map((event, i) => (
          <TimelineItem key={event.id}>
            <TimelineSeparator>
              {event.completed ? (
                <CheckCircle fontSize="inherit" sx={{ my: 1.5 }} />
              ) : (
                <TimelineDot style={{ alignSelf: 'flex-start', fontSize: 10 }} variant="outlined" />
              )}
              {i !== getEvents().length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography>{formatMessage({ id: event.message }, event.variables)}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  )
}

export default InviteSentContent
