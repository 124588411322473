import { useAccessibleOrganizations } from '@app/src/context/AccessibleOrganizationsContext'
import { useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useUnsetReferral } from '@app/src/hooks/referral'
import { ClaimLocation, CompanyRanges, ExistingOrganizationType, Provider } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ExistingOrganization from './ExistingOrganization'
import SimilarOrganizations from './ExistingOrganization/SimilarOrganizations'
import OrgDetailsStep from './OrgDetailsStep'
import OrgIndustryStep from './OrgIndustryStep'
import OrgInfoStep from './OrgInfoStep'
import SuccessStep from './SuccessStep'
import UserDetailsStep from './UserDetailsStep'

interface ClaimDialogProps {
  closeDialog: () => void
  isDialogOpen: boolean
  openReferralDialog: () => void
}

export enum ClaimFormFields {
  Name = 'name',
  WebsiteAddress = 'websiteAddress',
  Location = 'location',
  Address = 'location.address',
  CompanySizeRange = 'companySizeRange',
  RevenueRange = 'revenueRange',
  VatNumber = 'vatNumber',
  Industry = 'industry',
  JobTitle = 'jobTitle',
  PhoneNumber = 'phoneNumber',
  Logo = 'logo',
  InternalUsageConsent = 'internalUsageConsent',
}

export type ClaimFormData = {
  name: string
  websiteAddress: string
  industry: {
    industryId: number
    subIndustryId: number
    industryName: string
  }
  address?: string
  location: Partial<ClaimLocation>
  vatNumber: string
  jobTitle?: string
  phoneNumber?: string
  logo: string
  companySizeRange?: CompanyRanges
  revenueRange?: CompanyRanges
}

export type ExistingOrganizationsData = {
  existingOrganizationData?: ExistingOrganizationType
  similarOrganizationsData?: Provider[]
}

const ClaimDialog: React.FC<ClaimDialogProps> = ({ isDialogOpen, closeDialog, openReferralDialog }) => {
  const formMethods = useForm<ClaimFormData>({
    shouldUnregister: false,
  })
  const { trackEvent } = useAmplitude()
  const [step, setStep] = useState(1)
  const { account } = useAccount()
  const { accessibleOrganizations } = useAccessibleOrganizations()
  const unsetReferral = useUnsetReferral()
  const [existingOrganization, setExistingOrganization] = useState<ExistingOrganizationType>()
  const [similarOrganizations, setSimilarOrganizations] = useState<Provider[]>()

  const referralCode = account?.referralCode

  const handleClose = (): void => {
    if (referralCode) {
      unsetReferral()
    }
    closeDialog()
  }

  const handleNextStep = (): void => {
    setStep(s => s + 1)
  }

  const handlePreviousStep = (): void => {
    setStep(s => s - 1)
  }

  const handleExistingOrganizations = ({
    existingOrganizationData,
    similarOrganizationsData,
  }: ExistingOrganizationsData) => {
    if (existingOrganizationData) {
      trackEvent({
        name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OrgAlreadyExists,
        eventProps: {
          is_first_org: !accessibleOrganizations.length,
        },
      })
      setExistingOrganization(existingOrganizationData)
      return
    }

    if (similarOrganizationsData?.length) {
      setSimilarOrganizations(similarOrganizationsData)
      return
    }

    handleNextStep()
  }

  const closeOrReturnToReferral = (): void => {
    if (referralCode) {
      openReferralDialog()
    }
    closeDialog()
  }

  useEffect(() => {
    setStep(1)
    setExistingOrganization(undefined)
    setSimilarOrganizations(undefined)
    formMethods.reset({})
  }, [isDialogOpen])

  useEffect(() => {
    if (isDialogOpen) {
      trackEvent({
        name: AmplitudeTrackingEvents.Onboarding.AddOrganization.OpenAddOrg,
        eventProps: {
          is_first_org: !accessibleOrganizations.length,
        },
      })
    }
  }, [isDialogOpen])

  if (existingOrganization) {
    return (
      <Dialog open={isDialogOpen} onClose={handleClose} fullScreen>
        <FormProvider {...formMethods}>
          <ExistingOrganization
            existingOrganization={existingOrganization}
            onPreviousStep={() => setExistingOrganization(undefined)}
            onClose={handleClose}
          />
        </FormProvider>
      </Dialog>
    )
  }

  if (similarOrganizations?.length) {
    return (
      <Dialog open={isDialogOpen} onClose={handleClose} fullScreen>
        <FormProvider {...formMethods}>
          <SimilarOrganizations
            similarOrganizations={similarOrganizations}
            onPreviousStep={() => setSimilarOrganizations(undefined)}
            onNextStep={() => {
              setSimilarOrganizations(undefined)
              handleNextStep()
            }}
            onClose={handleClose}
          />
        </FormProvider>
      </Dialog>
    )
  }

  return (
    <Dialog open={isDialogOpen} onClose={handleClose} fullScreen>
      <FormProvider {...formMethods}>
        {step === 1 && (
          <OrgInfoStep onPreviousStep={closeOrReturnToReferral} onExistingOrganizations={handleExistingOrganizations} />
        )}
        {step === 2 && <OrgDetailsStep onPreviousStep={handlePreviousStep} onNextStep={handleNextStep} />}
        {step === 3 && <OrgIndustryStep onPreviousStep={handlePreviousStep} onNextStep={handleNextStep} />}
        {step === 4 && <UserDetailsStep onPreviousStep={handlePreviousStep} onNextStep={handleNextStep} />}
        {step === 5 && <SuccessStep closeDialog={closeDialog} />}
      </FormProvider>
    </Dialog>
  )
}

export default ClaimDialog
