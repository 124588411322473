import { ChevronLeftOutlined, CloseOutlined } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import React, { PropsWithChildren, ReactNode } from 'react'
import TextTruncate from 'react-text-truncate'
import { useDrawer } from './DrawerContext'

export type DrawerViewProps = {
  title: string
  subTitle?: string
  showBackButton?: boolean
  buttons?: ActionButtonsProps['buttons']
  stackButtons?: boolean
  fullscreenContent?: ReactNode
  onFormSubmit?: () => Promise<void>
  dataTestId?: string
}

const FormWrapper: React.FC<PropsWithChildren<Pick<DrawerViewProps, 'onFormSubmit'>>> = ({
  onFormSubmit,
  children,
}) => {
  if (onFormSubmit) {
    return (
      <Box component="form" onSubmit={onFormSubmit} display="flex" flexDirection="column" flexGrow={1} height="100%">
        {children}
      </Box>
    )
  }

  return <>{children}</>
}

const DrawerView: React.FC<PropsWithChildren<DrawerViewProps>> = ({
  showBackButton = true,
  children,
  title,
  subTitle,
  buttons,
  stackButtons,
  fullscreenContent,
  dataTestId,
  onFormSubmit,
}) => {
  const { hasPreviousView, goBack, closeDrawer, options } = useDrawer()

  const shouldShowBack = hasPreviousView && showBackButton

  return (
    <FormWrapper onFormSubmit={onFormSubmit}>
      <Box display="flex" overflow="hidden" flexGrow={1} data-testid={dataTestId}>
        {options.fullScreen && (
          <Stack
            flexGrow={1}
            borderRight={({ palette }) => `1px solid ${palette.grey[100]}`}
            overflow="auto"
            bgcolor="grey.100"
          >
            {fullscreenContent}
          </Stack>
        )}

        <Stack width="100%" maxWidth={390} data-testid="drawer-view">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={2}
            pl={shouldShowBack ? 1 : 3}
            pr={1}
          >
            {shouldShowBack && (
              <IconButton onClick={goBack} sx={{ mr: 1 }} size="large">
                <ChevronLeftOutlined />
              </IconButton>
            )}

            <Stack flexGrow={1} mr={2}>
              <Typography variant="h6">
                <TextTruncate line={1} text={title} />
              </Typography>
              <Typography variant="caption">
                <TextTruncate line={1} text={subTitle} />
              </Typography>
            </Stack>

            <IconButton onClick={closeDrawer} size="large">
              <CloseOutlined />
            </IconButton>
          </Box>

          <Stack flexGrow={1} overflow="hidden">
            <Stack display="flex" flexGrow={1} overflow="auto" pb={3}>
              {children}
            </Stack>

            {buttons && (
              <Box p={2} gap={stackButtons ? 2 : 1} display="flex" flexWrap={stackButtons ? 'wrap' : 'initial'}>
                <ActionButtons fullWidthButtons buttons={buttons} />
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </FormWrapper>
  )
}

export default DrawerView
