import Permissions, { usePermissions } from '@app/src/auth/permissions'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ProviderConnectedCell from '@app/src/components/Table/Cells/ProviderConnectedCell'
import ReferralContactCell from '@app/src/components/Table/Cells/ReferralContactCell'
import TagsCell from '@app/src/components/Table/Cells/TagsCell'
import UserChipCell from '@app/src/components/Table/Cells/UserChipCell'
import UserGroupCell from '@app/src/components/Table/Cells/UserGroupCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ProviderRowProps {
  row: Provider
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviderIds: Array<number>
  showCustomCategories?: boolean
}

export const ProviderRow: React.FC<ProviderRowProps> = ({
  row: provider,
  onCheckboxChange,
  selectedProviderIds,
  showCustomCategories = false,
}) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  const isChecked = Boolean(selectedProviderIds?.find(providerId => providerId === provider.id))

  return (
    <TableRow selected={isChecked} data-testid="provider-row">
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, provider)} />
      <CompanyCell company={provider} drawer maxRows={1} />
      <ProviderConnectedCell provider={provider} data-testid="provider-status" />
      <CountryCell countryCode={provider.organization?.country?.countryCode ?? provider.country?.countryCode} />
      {provider.linkStatus === ProviderLinkStatus.Connected ? (
        <UserGroupCell
          users={provider.organization.contacts?.map(contact => contact.user)}
          subTitle={provider.name}
          drawer
        />
      ) : (
        <ReferralContactCell provider={provider} drawer />
      )}
      <UserChipCell user={provider.ownerUser} drawer />
      <LinkCell
        href={provider.organization?.websiteAddress ?? provider.websiteAddress}
        data-testid="provider-website"
      />
      {showCustomCategories && (
        <TagsCell amountOfTagsShow={2} tags={provider.categoryOptions?.map(catOpt => catOpt.name) ?? []} />
      )}
      <ValueCell value={provider.vatNumber} />

      {!hasPermission(Permissions.FINANCE_USER) && (
        <CategoryCell
          value={formatMessage({ id: `schemas.provider.activityStatusValues.${provider.activityStatus}` })}
          defaultValue={formatMessage({ id: `schemas.provider.activityStatusValues.NotSelected` })}
        />
      )}

      <FinalRiskRatingCell value={provider.finalRiskRating} />
      <CategoryCell
        value={formatMessage({ id: `schemas.provider.priorityValues.${provider.priority}` })}
        defaultValue={formatMessage({ id: `schemas.provider.priorityValues.NotSelected` })}
      />

      {!hasPermission(Permissions.FINANCE_USER) && (
        <CategoryCell
          value={formatMessage({
            id: `schemas.provider.providerApprovalStatusValues.${provider.providerApprovalStatus}`,
          })}
          defaultValue={formatMessage({ id: `schemas.provider.providerApprovalStatusValues.NotSelected` })}
        />
      )}

      {hasPermission(Permissions.SOURCING_USER) && (
        <>
          <CategoryCell
            value={formatMessage(
              {
                id: `schemas.provider.tierValues`,
              },
              { tier: provider.tier ?? 0 },
            )}
            defaultValue={formatMessage(
              {
                id: `schemas.provider.tierValues`,
              },
              { tier: 0 },
            )}
          />
          <CategoryCell
            value={formatMessage({
              id: `schemas.provider.supplierUsageValues.${provider.supplierUsage ?? 'NotSelected'}`,
            })}
            defaultValue={formatMessage({ id: `schemas.provider.supplierUsageValues.NotSelected` })}
          />
        </>
      )}
      <ValueCell value={provider.registrationNumber} />
      <ValueCell value={provider.customId} />
      <DateCell value={provider.createdAt} />
    </TableRow>
  )
}

export default ProviderRow
