import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource, useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import Dialog from '@app/src/components/Dialog'
import DrawerTemplate, { DrawerProps } from '@app/src/components/Drawer_DEPRECATED/DrawerTemplate'
import EmptyState from '@app/src/components/EmptyState'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import RequestAutomationWarningAlert from '@app/src/pages/Configurations/ConfigurationsPages/Automation/Warning/RequestAutomationWarningAlert'
import CategoryForm, { RESET_FORM } from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryForm'
import { CategoryHeader } from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryHeader'
import CategoryOptionListItem from '@app/src/pages/Configurations/ConfigurationsPages/Category/CategoryOptionListItem'
import {
  Category,
  CategoryOption,
  FinalRiskRating,
  ProviderActivityStatus,
  ProviderPriority,
  ProviderStandardCategoryNames,
  StandardCategory,
  SupplierUsage,
} from '@app/src/types/categories'
import { ProviderApprovalStatus } from '@app/src/types/organizations'
import { capitalize } from '@app/src/utils/helpers'
import { br } from '@app/src/utils/translationMarkup'
import { NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import CategoryIcon from '@mui/icons-material/Category'
import { Box, darken, List } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import StandardCategoryDrawerContent from './StandardCategoryDrawerContent'

const DEFAULT_CATEGORY_STATE: Category = { name: '' }

interface CategoryDrawerContentProps {
  category?: Category
  categories?: Category[]
  isDrawerOpen?: boolean
  closeDrawer?: () => void
  view: CategoryDrawerViews
  providersUpdate?: ProviderUpdateType[]
  setSelectedProviders?: React.Dispatch<React.SetStateAction<Array<number>>>
}

export type ProviderUpdateType = {
  providerId: number
  name?: string
  providerApprovalStatus?: ProviderApprovalStatus
  providerApprovalComment?: string
  categoryOptionIds?: number[]
  activityStatus?: ProviderActivityStatus
  finalRiskRating?: FinalRiskRating
  priority?: ProviderPriority
  tier?: number
  ownerUserId?: number
  supplierUsage?: SupplierUsage
}

export enum CategoryDrawerViews {
  CreateCategory,
  EditCategory,
  EditCategoryOptions,
  AssignCategoryOptions,
  SetStandardCategory,
}

const useStyles = makeStyles(({ palette }) => ({
  categoryHeaderRoot: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  dangerZoneButton: {
    color: palette.error.dark,
    borderColor: palette.error.dark,
    '&:hover': {
      color: darken(palette.error.dark, 0.1),
      borderColor: darken(palette.error.dark, 0.1),
    },
  },
}))

export type SetProvidersUpdate = (categoryName: ProviderStandardCategoryNames, value: string | number) => void

const CategoryDrawerContent = ({
  category: categoryProp,
  closeDrawer,
  view,
  categories,
  providersUpdate,
  setSelectedProviders,
}: CategoryDrawerContentProps): JSX.Element => {
  const classes = useStyles()
  const { mutate: createResource } = useCreateResource()
  const { mutate: updateResource, isLoading: isUpdateLoading } = useUpdateResource()
  const { mutate: deleteResource } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const drawerContainerRef = useRef<HTMLDivElement | null>(null)
  const [currentlyEditing, setCurrentEditing] = useState<number | null>(null)
  const [category, setCategory] = useState<Category | StandardCategory>(categoryProp ?? DEFAULT_CATEGORY_STATE)
  const [categoryHasJustBeenCreated, setCategoryHasJustBeenCreated] = useState(false)
  const [drawerView, setDrawerView] = useState<CategoryDrawerViews>(view)
  const [isCancelChangesDialogOpen, openCancelChangesDialog, closeCancelChangesDialog] = useDialogState(false)
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState(false)
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false)
  const [drawerProvidersUpdate, _setDrawerProvidersUpdate] = useState<Array<ProviderUpdateType>>(providersUpdate ?? [])

  const { data: categoryOptions } = useFetchResource<CategoryOption[]>({
    key: [FetchKey.CategoryOption, category?.id],
    endpoint: endpoints.categoryOptionsByCategory(category?.id),
    options: {
      enabled: typeof category.id !== 'undefined',
    },
  })

  const sortedCategoryOptions = useMemo(
    () => categoryOptions?.sort((a, b) => a.name.localeCompare(b.name)),
    [categoryOptions],
  )

  useEffect(() => {
    // Needed in order to land in the right case in the drawerButtons function
    if (providersUpdate && drawerView === CategoryDrawerViews.EditCategoryOptions)
      setDrawerView(CategoryDrawerViews.AssignCategoryOptions)
  }, [drawerView, providersUpdate])

  const setCategoryName = (categoryName: string): void => {
    setCategory(oldState => ({ ...oldState, name: categoryName }))
  }

  const setDrawerProvidersUpdate: SetProvidersUpdate = (categoryName, value) => {
    _setDrawerProvidersUpdate(prev =>
      prev.map(p => ({
        ...p,
        [categoryName]: value,
      })),
    )
  }

  const createCategory = ({ name }: Pick<Category, 'name'>) => {
    createResource(
      { url: endpoints.category, body: { name: name.trim() } },
      {
        onSuccess: data => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulCategoryCreated' }, { categoryName: name }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.Category)
          setCategoryHasJustBeenCreated(true)
          setCategory({
            name,
            id: (data as Category)?.id,
          })
          setDrawerView(CategoryDrawerViews.EditCategoryOptions)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const createCategoryOption = ({ name }: Pick<Category, 'name'>) => {
    createResource(
      { url: endpoints.categoryOption, body: { name: name.trim(), categoryId: category?.id } },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              { id: 'notifications.successfulCategoryOptionCreated' },
              { categoryOptionName: name },
            ),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries([FetchKey.CategoryOption, category?.id])
          setCategoryHasJustBeenCreated(false)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const updateCategory = ({ name }: Pick<Category, 'name'>) => {
    updateResource(
      { url: endpoints.category, body: { name: name.trim(), id: category.id } },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulCategoryUpdated' }, { categoryName: name }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.Category)
          setCategory({ name, id: category.id })
          setDrawerView(CategoryDrawerViews.EditCategoryOptions)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const deleteCategory = () => {
    deleteResource(
      { url: endpoints.categoryById(category?.id), body: {} },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulCategoryDeleted' }, { categoryName: category?.name }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.Category)
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          if (closeDrawer) closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const updateProviders = (): void => {
    const body = drawerProvidersUpdate

    updateResource(
      { url: endpoints.updateProviders, body },
      {
        onError: requestError => {
          showErrorNotification({ requestError })
        },
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulCategoryOptionsApplied' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          queryClient.invalidateQueries(FetchKey.RiskScreening)
          setSelectedProviders?.(drawerProvidersUpdate.map(item => item.providerId))
          closeDrawer?.()
        },
      },
    )
  }

  const drawerHeader = (): JSX.Element | undefined => {
    switch (drawerView) {
      case CategoryDrawerViews.EditCategoryOptions:
      case CategoryDrawerViews.AssignCategoryOptions:
        return (
          <>
            <CategoryHeader
              name={category?.name ?? ''}
              onClick={() => {
                setCategoryName(category?.name ?? '')
                setDrawerView(CategoryDrawerViews.EditCategory)
              }}
              onCancel={
                drawerView === CategoryDrawerViews.AssignCategoryOptions
                  ? () => {
                      if (closeDrawer && !isUnsavedChanges) closeDrawer()
                      else openCancelChangesDialog()
                    }
                  : undefined
              }
              variant="h4"
              classes={{ root: classes.categoryHeaderRoot }}
            />
            <RequestAutomationWarningAlert
              automationType={RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY}
              currentCategory={category}
              isCustomCategoryOption
            />
            <Box>
              <CategoryForm
                disabled={Boolean(currentlyEditing)}
                showButtonsOnChange
                onCancel={RESET_FORM}
                onSave={createCategoryOption}
                placeholder={formatMessage({ id: 'category.addValue' })}
                categoryOptions={sortedCategoryOptions}
              />
            </Box>
          </>
        )
      case CategoryDrawerViews.SetStandardCategory:
        return (
          <>
            <CategoryHeader
              isStandardCategory
              name={formatMessage({ id: `schemas.provider.${category?.name}` })}
              onCancel={() => {
                if (closeDrawer && !isUnsavedChanges) closeDrawer()
                else openCancelChangesDialog()
              }}
              variant="h4"
              classes={{ root: classes.categoryHeaderRoot }}
            />
            <RequestAutomationWarningAlert
              automationType={RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY}
              currentCategory={category}
            />
          </>
        )
      case CategoryDrawerViews.EditCategory:
      case CategoryDrawerViews.CreateCategory:
      default:
        return
    }
  }

  const drawerButtons = (): DrawerProps['buttons'] => {
    switch (drawerView) {
      case CategoryDrawerViews.EditCategory:
        return [
          {
            label: formatMessage({ id: 'category.delete.action' }),
            onClick: openDeleteDialog,
            variant: 'outlined',
            classes: { root: classes.dangerZoneButton },
            fullWidth: true,
          },
        ]
      case CategoryDrawerViews.EditCategoryOptions:
        return [
          {
            label: formatMessage({ id: 'general.close' }),
            onClick: () => {
              if (closeDrawer) closeDrawer()
              setCurrentEditing(null)
              setDrawerView(CategoryDrawerViews.CreateCategory)
            },
            variant: 'outlined',
            fullWidth: true,
          },
        ]
      case CategoryDrawerViews.CreateCategory:
        return [
          {
            label: formatMessage({ id: 'general.close' }),
            onClick: closeDrawer,
            variant: 'outlined',
            fullWidth: true,
          },
        ]
      case CategoryDrawerViews.SetStandardCategory:
      case CategoryDrawerViews.AssignCategoryOptions:
        //Workaround to be able to render another component in this generic things.
        //TODO: This drawer setup needs some cleanup and simplifying
        if (category.name === ProviderStandardCategoryNames.FinalRiskRating) {
          return []
        }

        return [
          {
            label: formatMessage({ id: 'general.cancel' }),
            onClick: () => {
              if (closeDrawer && !isUnsavedChanges) closeDrawer()
              else openCancelChangesDialog()
            },
            variant: 'text',
            fullWidth: true,
            disabled: isUpdateLoading,
          },
          {
            label: formatMessage(
              { id: 'category.categoryOptions.applyToAmountProviders' },
              {
                provider: formatMessage(
                  { id: `general.${capitalize(ResourceTypes.Company)}` },
                  { count: providersUpdate?.length },
                ).toLowerCase(),
                count: providersUpdate?.length,
              },
            ),
            onClick: updateProviders,
            variant: 'contained',
            fullWidth: true,
            loading: isUpdateLoading,
            disabled: !isUnsavedChanges,
          },
        ]
      default:
        return []
    }
  }

  const drawerTitle = (): string | undefined => {
    switch (drawerView) {
      case CategoryDrawerViews.EditCategory:
        return formatMessage({ id: 'category.edit' })
      case CategoryDrawerViews.CreateCategory:
        return formatMessage({ id: 'category.create' })
      case CategoryDrawerViews.EditCategoryOptions:
      default:
        return
    }
  }

  const renderDrawerContent = (): JSX.Element => {
    switch (drawerView) {
      case CategoryDrawerViews.SetStandardCategory:
        return (
          <StandardCategoryDrawerContent
            setIsUnsavedChanges={setIsUnsavedChanges}
            setProvidersUpdate={setDrawerProvidersUpdate}
            providersUpdate={drawerProvidersUpdate}
            category={category as StandardCategory}
            closeDrawer={closeDrawer}
          />
        )

      case CategoryDrawerViews.EditCategory:
        return (
          <CategoryForm
            onCancel={() => setDrawerView(CategoryDrawerViews.EditCategoryOptions)}
            id={category?.id}
            onSave={updateCategory}
            defaultValue={category.name}
            placeholder={formatMessage({ id: 'category.name' })}
            categories={categories}
          />
        )

      case CategoryDrawerViews.EditCategoryOptions:
      case CategoryDrawerViews.AssignCategoryOptions:
        return sortedCategoryOptions?.length ? (
          <List>
            {sortedCategoryOptions?.map(categoryOption => (
              <CategoryOptionListItem
                setIsUnsavedChanges={setIsUnsavedChanges}
                setProvidersUpdate={_setDrawerProvidersUpdate}
                providersUpdate={drawerProvidersUpdate}
                containerRef={drawerContainerRef}
                key={categoryOption.id}
                editMode={categoryOption.id === currentlyEditing}
                setEditMode={setCurrentEditing}
                categoryOption={categoryOption}
                categoryOptions={sortedCategoryOptions}
              />
            ))}
          </List>
        ) : (
          <EmptyState
            iconComponent={CategoryIcon}
            title={formatMessage({
              id: `category.categoryOptions.emptyState.title.${categoryHasJustBeenCreated ? 'new' : 'existing'}`,
            })}
            description={formatMessage({ id: 'category.categoryOptions.emptyState.description' })}
          />
        )

      case CategoryDrawerViews.CreateCategory:
        return (
          <CategoryForm
            showButtonsOnChange
            onCancel={closeDrawer}
            onSave={createCategory}
            placeholder={formatMessage({ id: 'category.name' })}
            categories={categories}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <>
      <DrawerTemplate
        customHeader={drawerHeader()}
        title={drawerTitle()}
        buttons={drawerButtons()}
        closeDrawer={closeDrawer}
        containerRef={drawerContainerRef}
      >
        {renderDrawerContent()}
      </DrawerTemplate>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        title={formatMessage({ id: 'general.areYouSure' })}
        content={formatMessage({ id: 'category.delete.description' }, { br })}
        buttons={[
          {
            label: formatMessage({ id: 'general.confirm' }),
            variant: 'text',
            onClick: () => {
              deleteCategory()
              closeDeleteDialog()
            },
            classes: { root: classes.dangerZoneButton },
          },
          {
            label: formatMessage({ id: 'general.cancel' }),
            variant: 'contained',
            onClick: closeDeleteDialog,
          },
        ]}
      />
      <Dialog
        open={isCancelChangesDialogOpen}
        onClose={closeCancelChangesDialog}
        title={formatMessage({ id: 'general.youHaveUnsavedChanges' })}
        content={formatMessage({ id: 'resourceCollections.update.exitWithoutApplyingChanges' }, { br })}
        buttons={[
          {
            label: formatMessage({ id: 'resourceCollections.update.discardChanges' }),
            variant: 'text',
            classes: { root: classes.dangerZoneButton },
            onClick: () => {
              if (closeDrawer) closeDrawer()
              closeCancelChangesDialog()
            },
          },
          {
            label: formatMessage({ id: 'resourceCollections.update.goBack' }),
            variant: 'contained',
            onClick: closeCancelChangesDialog,
          },
        ]}
      />
    </>
  )
}

export default CategoryDrawerContent
