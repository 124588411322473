import React from 'react'
import { DebouncedState, useDebouncedCallback } from 'use-debounce'

export enum Operators {
  EqualTo = 'eq',
  NotEqualTo = 'neq',
  GreaterThan = 'gt',
  LowerThan = 'lt',
  GreaterThanOrEqual = 'gte',
  LowerThanOrEqual = 'lte',
  Contains = 'contains',
  NotContains = 'notcontains',
  AllowNulls = 'allownulls',
  NotAllowNulls = 'notallownulls',
  In = 'in',
  NotIn = 'notin',
  IsNull = 'isnull',
  IsNotNull = 'isnotnull',
}

export type FilterValue = string | number | string[] | number[] | boolean

export type Filter = {
  value?: FilterValue
  operator: Operators
}

export type GetFiltersQuery = (options?: { exclude: string }) => FilterGroup[]

export interface FilterGroup {
  name: string
  key?: string
  filters: Filter[]
  intersect?: boolean //Controls filter combination. If false: response.items.some(it => it.filter1 && it.filter2) if true: response.items.some(it => it.filter1) && response.items.some(it => it.filter2)
}

export interface FilterGroupQueryString extends FilterGroup {
  filters: FilterQueryString[]
}

export interface FilterQueryString extends Filter {
  uniqueId?: string
}

export interface FilterFormValue {
  [name: string]: Filter
}

export interface FilterDefinition {
  name: string //todo delete
  value: string //todo delete
  operator: string //todo delete
  default: string //todo delete
  valueFieldName: string
  operatorFieldName: string
  fieldName: string
  filterName: string
}

export const getFilterDefinition = (name: string): FilterDefinition => {
  const safeName = name.replace(/\./g, '__')
  return {
    name: safeName,
    default: name,
    value: `${safeName}.value`, //Todo delete
    operator: `${safeName}.operator`, //todo delete
    //new stuff
    fieldName: name,
    filterName: name,
    valueFieldName: `${safeName}.value`,
    operatorFieldName: `${safeName}.operator`,
  }
}

export const RESPONSE_LATEST_SUBMITTED_FILTER: FilterGroup = {
  name: 'isLatestSubmitted',
  filters: [{ operator: Operators.EqualTo, value: true }],
}

export const RESPONSE_ITEM_LATEST_SUBMITTED_FILTER: FilterGroup = {
  name: 'response.isLatestSubmitted',
  filters: [{ operator: Operators.EqualTo, value: true }],
}

export const RESQUEST_ITEM_IS_DELETED_FILTER: FilterGroup = {
  name: 'requestItem.template.deletedAt',
  filters: [{ operator: Operators.IsNull }],
}

//todo remove this it was just so eslint doesn't scream at me
export const safeFilterName = getFilterDefinition

function isMultiple(value: FilterValue): value is string[] | number[] {
  return (value as string[] | number[])?.length !== undefined
}

function isFilterValue(value?: FilterValue): value is FilterValue {
  return typeof value !== 'undefined' || value !== null
}

function isFilterGroup(value: FilterGroup[]): value is FilterGroup[] {
  if (value instanceof Array) {
    for (let index = 0; index < value.length; index++) {
      const element = value[index]
      if (typeof element.name !== 'string' && !(element.filters instanceof Array)) {
        return false
      }
    }
    return true
  }
  return false
}

function parseJsonFilter(filterString?: string): FilterGroup[] | undefined {
  if (!filterString) return
  const parsed = JSON.parse(filterString)
  if (isFilterGroup(parsed)) {
    return parsed
  }
}

export const useFilters = (
  resetPage?: () => void,
  defaultValue?: string,
): {
  filters: FilterGroup[]
  setFilters: (filters: { [key: string]: Filter }) => void
  getFiltersQuery: GetFiltersQuery
  getFilters: () => { [key: string]: Filter }
  debouncedSetFilters: DebouncedState<(filters: unknown) => void>
} => {
  const [filters, _setFilters] = React.useState<FilterGroup[]>((): FilterGroup[] => {
    const filter = parseJsonFilter(defaultValue)
    return filter || []
  })

  const setFilters = (filters: { [key: string]: Filter }): void => {
    if (!filters) {
      _setFilters([])
    }

    if (resetPage) resetPage()

    _setFilters(
      Object.keys(filters)
        .filter(key => {
          //Keeping filters with value
          const value = filters[key].value
          if (!isFilterValue(value)) return false
          if (isMultiple(value)) {
            return value?.length > 0
          }
          return filters[key].value !== ''
        })
        ?.map(key => ({
          name: key.replace(/__/g, '.'),
          filters: [
            {
              value: filters[key].value,
              operator: filters[key].operator,
            },
          ],
        })),
    )
  }

  const getFiltersQuery: GetFiltersQuery = options => {
    filters.map(filterGroup => ({
      ...filterGroup,
      name: filterGroup.name.replace(/__/g, '.'),
    }))
    if (options?.exclude) {
      return filters.filter(filter => filter.name !== options.exclude)
    }
    return filters
  }

  const getFilters = () => {
    return filters.reduce<{ [key: string]: Filter }>((formFilterList, currentFilter) => {
      currentFilter.filters.forEach((item, i) => {
        const filterName =
          currentFilter.filters.length === 1
            ? safeFilterName(currentFilter.name).name
            : `${safeFilterName(currentFilter.name).name}-${i}`
        formFilterList[filterName] = item
      })
      return formFilterList
    }, {})
  }

  const debouncedSetFilters = useDebouncedCallback(values => {
    setFilters(values)
  }, 500)

  return { filters, setFilters, debouncedSetFilters, getFiltersQuery, getFilters }
}

export const updateFilterName = (filters: FilterGroup[], targetFilterName: string, newFilterName: string) =>
  filters.map(filter => (filter.name === targetFilterName ? { ...filter, name: newFilterName } : filter))
