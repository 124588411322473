import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Request } from '@app/src/types/resourceExplorer'
import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import EsgLibraryFilters from './EsgLibraryFilters'
import TemplateRow from './TemplateRow'

export enum VisibleTemplates {
  AllTemplates = 'all-templates',
  RecommendedTemplates = 'recommended-templates',
}

export type TemplateFilter = {
  fetchKey: string
  endpoint: string
  filter: FilterGroup[]
}

export enum ChipsValue {
  ALL = 'all-templates',
  RECOMMENDED = 'recommended-templates',
  ENVIRONMENT = 'esg-environmental',
  SOCIAL = 'esg-social',
  GOVERNANCE = 'esg-governance',
  REGULATIONS = 'esg-regulations',
  INITIATIVES = 'esg-initiatives',
  SUPPLY_CHAIN = 'WfDefaultRequest2',
}

const EsgLibrary = () => {
  const [visibleTemplates, setVisibleTemplates] = useState<VisibleTemplates>(VisibleTemplates.AllTemplates)
  const [tagsFilters, setTagsFilters] = useState<string[] | undefined>()
  const { formatMessage } = useIntl()

  const { items: templates, isLoading } = useFetchCollectionWithPost<Request>({
    key: [FetchKey.RequestTemplate],
    endpoint: endpoints.worldfavorTemplatesWithRecommendation,
    payload: {
      filter: tagsFilters
        ? [
            {
              name: 'tags.tag',
              filters: [
                {
                  value: tagsFilters,
                  operator: Operators.In,
                },
              ],
            },
          ]
        : [],
      include: ['tags'],
    },
  })

  const { items: recommendedTemplates, isLoading: isRecommendedLoading } = useFetchCollectionWithPost<Request>({
    key: [FetchKey.RequestTemplate, 'recommended'],
    endpoint: endpoints.recommendedWorldfavorTemplates,
    payload: {
      filter: [],
      include: ['tags'],
    },
  })

  return (
    <Box display="flex" flexDirection="column" bgcolor="grey.100" px={4} py={3} minHeight={800}>
      <Box width="70%" pb={1}>
        <Typography variant="body1">{formatMessage({ id: 'transparencyOverview.esgLibraryDescription' })}</Typography>
      </Box>
      <EsgLibraryFilters setTagsFilters={setTagsFilters} setVisibleTemplates={setVisibleTemplates} />
      <Box px={2} mt={2} borderRadius="4px" bgcolor="background.default" flexGrow={1}>
        {(isLoading || isRecommendedLoading) && (
          <Box py={5} display="flex" justifyContent="center" flexGrow={1}>
            <CircularProgress />
          </Box>
        )}
        {(visibleTemplates === VisibleTemplates.AllTemplates ? templates : recommendedTemplates)?.map((t, i) => (
          <React.Fragment key={t.id}>
            <TemplateRow
              templateId={t.id}
              title={t.title}
              tags={t.tags}
              reportTemplateState={t.reportTemplateState}
              isLastTemplate={i !== visibleTemplates.length - 1}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  )
}

export default EsgLibrary
