import { Button, ButtonGroup, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { getHalfYears, getMonths, getQuarters, getYears } from '@app/src/utils'
import { COMPANY_REPORT_QUESTION_KEYS } from './CompanyReport'

const useStyles = makeStyles(({ palette }) => ({
  buttonGroup: {
    '& .MuiButton-outlined': {
      border: `1px solid ${palette.grey[500]}`,
    },
  },
  activeButton: {
    backgroundColor: palette.grey[100],
  },
}))

export enum Periods {
  Yearly = 'Yearly',
  HalfYearly = 'HalfYearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
}

type PeriodFieldProps = {
  fieldNames?: Array<COMPANY_REPORT_QUESTION_KEYS>
}

const PeriodField: React.FC<PeriodFieldProps> = ({ fieldNames }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { watch, setValue, control } = useFormContext()

  const watchDateType: string = watch(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE)
  const watchDateYear: string = watch(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR)

  const DATE_YEAR_OPTIONS = useMemo(() => getYears(10, 0).map(y => ({ value: y, label: String(y) })), [])
  const DATE_PERIOD_OPTIONS = useMemo((): Option<number>[] => {
    switch (watchDateType) {
      case Periods.HalfYearly:
        return getHalfYears(watchDateYear)
      case Periods.Quarterly:
        return getQuarters(watchDateYear)
      default:
        return getMonths()
    }
  }, [watchDateType, watchDateYear])

  const PERIOD_FIELD_LABEL = useMemo((): string => {
    switch (watchDateType) {
      case Periods.HalfYearly:
        return formatMessage({ id: 'form.createRequest.selectPeriodIntervalLabel' })
      case Periods.Quarterly:
        return formatMessage({ id: 'form.createRequest.selectPeriodQuarterLabel' })
      default:
        return formatMessage({ id: 'form.createRequest.selectPeriodMonthLabel' })
    }
  }, [watchDateType, watchDateYear])

  useEffect(() => {
    if (watchDateType !== Periods.Yearly) {
      fieldNames?.push(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD)
    } else {
      const fieldIndex = fieldNames?.indexOf(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD) ?? -1

      if (fieldIndex > -1) {
        fieldNames?.splice(fieldIndex, 1)
      }
    }
  }, [watchDateType, fieldNames])

  const resetPeriodValues = () => {
    setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR, undefined)
    setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup className={classes.buttonGroup} fullWidth variant="outlined">
          <Button
            className={classNames({ [classes.activeButton]: watchDateType === Periods.Yearly })}
            onClick={() => {
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE, Periods.Yearly)
              resetPeriodValues()
            }}
          >
            {formatMessage({ id: 'form.createRequest.periodTypes.yearly' })}
          </Button>
          <Button
            className={classNames({ [classes.activeButton]: watchDateType === Periods.HalfYearly })}
            onClick={() => {
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE, Periods.HalfYearly)
              resetPeriodValues()
            }}
          >
            {formatMessage({ id: 'form.createRequest.periodTypes.halfYearly' })}
          </Button>
          <Button
            className={classNames({ [classes.activeButton]: watchDateType === Periods.Quarterly })}
            onClick={() => {
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE, Periods.Quarterly)
              resetPeriodValues()
            }}
          >
            {formatMessage({ id: 'form.createRequest.periodTypes.quarterly' })}
          </Button>
          <Button
            className={classNames({ [classes.activeButton]: watchDateType === Periods.Monthly })}
            onClick={() => {
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE, Periods.Monthly)
              resetPeriodValues()
            }}
          >
            {formatMessage({ id: 'form.createRequest.periodTypes.monthly' })}
          </Button>
        </ButtonGroup>
      </Grid>

      {Boolean(watchDateType) && (
        <Grid item xs={watchDateType === Periods.Yearly ? 12 : 6}>
          <Select
            options={DATE_YEAR_OPTIONS}
            fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
            onInputChange={(e, value) => {
              if (!value) setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR, undefined)
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
            }}
            defaultValue={-1}
            required
            enableAutoSelect
            name={COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR}
            control={control}
          />
        </Grid>
      )}

      {Boolean(watchDateType && watchDateType !== Periods.Yearly) && (
        <Grid item xs={6}>
          <Select
            disabled={!watchDateYear}
            options={DATE_PERIOD_OPTIONS}
            fieldLabel={PERIOD_FIELD_LABEL}
            onInputChange={(e, value) => {
              if (!value) setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
            }}
            defaultValue={-1}
            required
            enableAutoSelect
            name={COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD}
            control={control}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default PeriodField
