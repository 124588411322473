import { LibraryBooksOutlined } from '@mui/icons-material'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { ActionButtonsProps } from '@app/src/components/ActionButtons'
import { TabsProps } from '@app/src/components/Tabs'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { Route, Switch, generatePath, useParams } from 'react-router-dom'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import AssessmentDetailsScene from './AssessmentDetailsScene'
import AssessmentOverviewScene from './AssessmentOverviewScene'
import BaseAssessmentInfoDialog from './BaseAssessmentInfoDialog'

export enum BaseAssessmentViews {
  Overview = 'overview',
  Details = 'details',
}

interface BaseAssessmentSceneParams {
  view: BaseAssessmentViews
}

const allowedFilters = [
  'provider.id',
  'provider.name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'provider.categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'provider.organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'periodName',
  'totalLevel',
]

const BaseAssessmentScene: React.FC = () => {
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { view } = useParams<BaseAssessmentSceneParams>()
  const [open, openDialog, closeDialog] = useDialogState()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)

  const actionTabs: TabsProps['tabs'] = [
    {
      type: BaseAssessmentViews.Overview,
      url: stringifyQueryFilters({
        url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview }),
        queryParams: {},
        includeCurrentFilters: true,
      }),
      label: formatMessage({ id: 'baseAssessment.tabs.overview' }),
    },
    {
      type: BaseAssessmentViews.Details,
      url: stringifyQueryFilters({
        url: generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Details }),
        queryParams: {},
        includeCurrentFilters: true,
      }),
      label: formatMessage({ id: 'baseAssessment.tabs.details' }),
    },
  ]

  const actionButtons: ActionButtonsProps['buttons'] = [
    {
      label: formatMessage({ id: 'baseAssessment.infoDialog.infoButton' }),
      variant: 'text',
      startIcon: <LibraryBooksOutlined />,
      onClick: openDialog,
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: view,
      },
    })
  }, [view])

  if (!canAccessAssessmentTemplate) return null

  if (!view) {
    return (
      <Redirect
        to={generatePath(paths.baseAssessment, {
          view: BaseAssessmentViews.Overview,
        })}
      />
    )
  }

  return (
    <>
      <Switch>
        <Route path={generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview })} exact>
          <AssessmentOverviewScene
            allowedFilters={allowedFilters}
            userFilters={userFilters}
            actionButtons={actionButtons}
            tabs={{ actionTabs, activeTabParam: view }}
          />
        </Route>
        <Route path={generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Details })} exact>
          <AssessmentDetailsScene
            allowedFilters={allowedFilters}
            userFilters={userFilters}
            actionButtons={actionButtons}
            tabs={{ actionTabs, activeTabParam: view }}
          />
        </Route>
      </Switch>
      <BaseAssessmentInfoDialog open={open} closeDialog={closeDialog} />
    </>
  )
}

export default BaseAssessmentScene
