import { Box, Stack, Typography } from '@mui/material'
import ActionButtons, { ActionButtonsProps } from '@app/src/components/ActionButtons'
import Tabs, { TabsProps } from '@app/src/components/Tabs'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router'

export type ResourceCollectionSceneProps = {
  title: ReactNode
  subtitle?: ReactNode
  tabs?: {
    actionTabs: TabsProps['tabs']
    activeTabParam: string
    onTabChange?: (value: string) => void
  }
  filter?: ReactNode
  actionButtons?: ActionButtonsProps['buttons']
  buttonRow?: ActionButtonsProps['buttons']
  embedded?: boolean
  enableScroll?: boolean
}

const ResourceCollectionScene: React.FC<PropsWithChildren<ResourceCollectionSceneProps>> = ({
  title,
  subtitle,
  tabs,
  actionButtons = [],
  filter,
  buttonRow,
  enableScroll,
  embedded,
  children,
}) => {
  const history = useHistory()

  const handleTabChange = (value: string): void => {
    const selectedTab = tabs?.actionTabs.find(t => t.type === value)

    if (selectedTab) {
      history.push(selectedTab.url)
    }
  }

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      data-testid="resource-collection"
    >
      {(typeof title === 'string' || title instanceof String) && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}

      <Box px={embedded ? 0 : 4} pt={embedded ? 0 : 3}>
        {!embedded && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h1" data-testid="resource-collection-title">
              {title}
            </Typography>
            <Stack direction="row" spacing={1}>
              {actionButtons && <ActionButtons buttons={actionButtons} />}
            </Stack>
          </Box>
        )}
        {subtitle && <Box mt={embedded ? 0 : 2}>{subtitle}</Box>}

        {tabs?.actionTabs && (
          <Tabs
            activeTab={tabs?.activeTabParam}
            tabs={tabs?.actionTabs}
            handleChange={(_, value) => (tabs.onTabChange ? tabs.onTabChange(value) : handleTabChange(value))}
            sx={{ pt: 2 }}
          />
        )}
      </Box>

      <Box mt={!tabs && !embedded ? 3 : 0} flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        {(filter || buttonRow) && (
          <Stack py={3} px={4} gap={2} bgcolor="grey.50">
            {filter && <Box>{filter}</Box>}
            {buttonRow && (
              <Stack direction="row" gap={1}>
                <ActionButtons sizes="small" variants="outlined" buttons={buttonRow} />
              </Stack>
            )}
          </Stack>
        )}

        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height={enableScroll ? 'auto' : 10}
          pl={4}
          bgcolor="grey.100"
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default ResourceCollectionScene
