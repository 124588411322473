import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import StandardReportHeader from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/StandardReportHeader'
import StandardReportsRow from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questionnaires/StandardReportsRow'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import React from 'react'

const StandardQuestionnaireCollection: React.FC = () => {
  const [page, pageSize, setPage, setPageSize] = usePagination({
    page: 1,
    pageSize: 10,
  })

  const {
    items: reports,
    count,
    isLoading,
    isError,
  } = useFetchCollectionWithPost<QuestionnaireTemplate>({
    endpoint: endpoints.worldfavorTemplates,
    key: FetchKey.RequestTemplate,
    payload: {
      filter: [],
      include: ['sections.questions.reportFlagRules', 'assessmentQuestionnaireLink.assessmentTemplate'],
      sort: { target: 'title', order: SortOrder.ASCENDING },
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  return (
    <Table<QuestionnaireTemplate>
      RowComponent={StandardReportsRow}
      HeaderComponent={StandardReportHeader}
      data={reports}
      page={page}
      isLoading={isLoading}
      count={count}
      isError={isError}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  )
}

export default StandardQuestionnaireCollection
