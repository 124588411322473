import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'

const FileRepoHeader: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <TableRow>
      <TableHeaderCell label={formatMessage({ id: 'schemas.repositoryFile.name' })} />
      <TableHeaderCell minWidth={TableCellWidth.EMPTY} label={formatMessage({ id: 'general.actions' })} />
    </TableRow>
  )
}

export default FileRepoHeader
