import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { ExistingOrganizationType } from '@app/src/types/organizations'
import { ArrowBack } from '@mui/icons-material'
import { Button, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import TextTruncate from 'react-text-truncate'
import OrganizationRequestAccess from './OrganizationRequestAccess'

type ExistingOrganizationProps = {
  existingOrganization: ExistingOrganizationType
  onPreviousStep: () => void
  onClose: () => void
}

const ExistingOrganization: React.FC<ExistingOrganizationProps> = ({
  existingOrganization,
  onPreviousStep,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const [showRequestAccess, setShowRequestAccess] = useState(false)

  if (showRequestAccess) {
    return (
      <OrganizationRequestAccess
        organization={existingOrganization}
        onBack={() => setShowRequestAccess(false)}
        onClose={onClose}
      />
    )
  }

  return (
    <OnboardingForm
      header={formatMessage({ id: 'claim.createClaim.existingOrganizationStep.header' })}
      subheader={formatMessage({ id: 'claim.createClaim.existingOrganizationStep.subHeader' })}
    >
      <ListItem sx={({ palette }) => ({ border: `1px solid ${palette.divider}`, borderRadius: 1 })}>
        <ListItemAvatar>
          <CompanyAvatar organizationName={existingOrganization.name} />
        </ListItemAvatar>
        <ListItemText
          primary={<TextTruncate line={1} text={existingOrganization.name} />}
          secondary={existingOrganization.country?.name}
        />
        <Button onClick={() => setShowRequestAccess(true)} sx={{ ml: 1 }}>
          {formatMessage({ id: 'general.view' })}
        </Button>
      </ListItem>

      <Stack pt={2} direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
      </Stack>
    </OnboardingForm>
  )
}

export default ExistingOrganization
