import EditResponse, { PreviewMode } from '@app/src/pages/Questionnaire/Provider/Report/EditResponse'
import { Question } from '@app/src/types/resourceExplorer'
import { mapToRequestItem } from '@app/src/utils/previewMapper'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface QuestionInputPreviewProps {
  question: Question | undefined
}

const QuestionInputPreview: React.FC<QuestionInputPreviewProps> = ({ question }) => {
  const formMethods = useForm()

  if (!question) return null
  const previewRequestItem = mapToRequestItem(question, true)

  return (
    <form>
      <FormProvider {...formMethods}>
        <EditResponse
          questionType={question?.questionType.name.toLowerCase() ?? ''}
          requestItem={previewRequestItem}
          periodName={new Date().getFullYear().toString()}
          previewMode={PreviewMode.SelectionDisabled}
        />
      </FormProvider>
    </form>
  )
}

export default QuestionInputPreview
