import SelectableTemplateWithPreview from '@app/src/components/CreateNewRequestModal/Common/SelectableTemplateWithPreview'
import EmptyState from '@app/src/components/EmptyState'
import GuidanceBanner from '@app/src/components/GuidanceBanner'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import useTemplatesThatCanBeSent from '@app/src/hooks/useTemplatesThatCanBeSent'
import {
  AUTOMATION_QUESTION_KEYS,
  AutomationFormData,
} from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestTags, WfRequestTags } from '@app/src/wf-constants'
import { TaskOutlined } from '@mui/icons-material'
import { Box, Chip, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const recommendedTags: string[] = [WfRequestTags.PublicRequest1, WfRequestTags.PublicRequest2]

const AutomationQuestionnaires: React.FC = () => {
  const { formatMessage } = useIntl()
  const { getValues, watch } = useFormContext<AutomationFormData>()
  const reportType = watch('reportType')
  const { templates, isLoading } = useTemplatesThatCanBeSent(reportType as ReportType)

  const isRecommended = (tags?: RequestTags[]) => tags?.some(t => recommendedTags.includes(t)) ?? false

  const sortedTemplates = templates
    .map(template => ({ ...template, isRecommended: isRecommended(template.tags) }))
    .sort(a => (a.isRecommended ? -1 : 1))

  return (
    <Box display="flex" flexDirection="column">
      {isLoading ? (
        <Box height={150} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography color="textSecondary" variant="caption">
            {formatMessage({ id: 'form.createRequest.selected' }, { number: getValues()?.templates?.length ?? 0 })}
          </Typography>

          <Box height={300} sx={{ overflowY: 'auto' }}>
            {!templates.length && (
              <EmptyState
                title={formatMessage({ id: 'form.createAutomation.selectQuestionnaire.templateEmptyState.title' })}
                description={formatMessage({
                  id: 'form.createAutomation.selectQuestionnaire.templateEmptyState.description',
                })}
                iconComponent={TaskOutlined}
                sx={{ mt: 2 }}
              />
            )}
            {sortedTemplates.map(template => (
              <SelectableTemplateWithPreview
                template={template}
                key={template.id}
                formKey={AUTOMATION_QUESTION_KEYS.TEMPLATES}
                chip={
                  template.isRecommended ? (
                    <Chip
                      label={formatMessage({ id: 'transparencyOverview.templateRecommended' })}
                      sx={({ palette }) => ({ backgroundColor: palette.communication.main })}
                    />
                  ) : undefined
                }
              />
            ))}
          </Box>
        </>
      )}
      {reportType === ReportType.WF && (
        <Box mt={2}>
          <GuidanceBanner
            title={formatMessage({ id: 'form.createAutomation.selectQuestionnaire.guidance.title' })}
            description={formatMessage({ id: 'form.createAutomation.selectQuestionnaire.guidance.description' })}
          />
        </Box>
      )}
    </Box>
  )
}
export default AutomationQuestionnaires
