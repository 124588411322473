import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Provider } from '@app/src/types/organizations'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem } from '../..'

interface AssessmentHeaderProps extends SortableHeaderProps {
  selectedProviders: Array<number>
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
  providersInPage: Provider[]
  assessmentTemplates: FacetItem[]
}

const AssessmentHeader: React.FC<AssessmentHeaderProps> = ({
  toggleSorting,
  activeSorting,
  selectedProviders,
  setSelectedProviders,
  providersInPage,
  assessmentTemplates,
}) => {
  const { formatMessage } = useIntl()
  const assessments = assessmentTemplates.map(item => item.label)

  const isChecked = providersInPage?.every(provider => selectedProviders?.includes(provider.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedProviders(prev => Array.from(new Set([...prev, ...providersInPage.map(provider => provider.id)])))
    } else {
      setSelectedProviders([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.companyName' })}
        sorting={{ name: 'name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />

      {assessments.map(label => (
        <TableHeaderCell key={label} label={label} minWidth={TableCellWidth.MEDIUM} />
      ))}
    </TableRow>
  )
}

export default AssessmentHeader
