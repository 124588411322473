import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport, { getExportColumns } from '@app/src/components/Drawer/Views/DrawerViewExport'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import ProductHeader from '@app/src/pages/ResourceCollection/Collections/Product/ProductHeader'
import ProductRow from '@app/src/pages/ResourceCollection/Collections/Product/ProductRow'
import ProductFilters from '@app/src/pages/ResourceCollection/Filters/ProductFilters'
import ProductSchema from '@app/src/schemas/product'
import { Product } from '@app/src/types/product'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import ResourceCollectionScene from '../ResourceCollectionScene'

const allowedFilters = [
  'id',
  'name',
  'provider.organization.id',
  'gtin',
  'productStatus',
  'mappingStatus',
  'productNumber',
  'productCustomId',
  'country.id',
  'createdAt',
  'updatedAt',
  'purchaseOrder',
]

const ProductsCollection: React.FC = () => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { hasPermission } = usePermissions()
  const filters = useGetApiQueryFilters(allowedFilters)

  const rawExportPayload = {
    filter: [],
    sort: sorting,
    include: ['provider', 'country', 'provider.organization'],
  }

  const payload = {
    filter: filters,
    sort: sorting,
    include: ['provider', 'country', 'provider.organization'],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: products,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Product>({
    key: FetchKey.ProductCollection,
    endpoint: endpoints.productsCollection,
    payload,
  })

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.allProducts' })}
      filter={<ProductFilters allowedFilters={allowedFilters} />}
      actionButtons={
        !hasPermission(Permissions.TRANSPARENCY_USER)
          ? [
              {
                label: formatMessage({ id: 'resourceCollections.general.export' }),
                variant: 'outlined',
                onClick: () =>
                  openDrawer(
                    <DrawerViewExport
                      resourceType={ResourceTypes.Product}
                      count={count}
                      userFilter={filters}
                      exportColumns={getExportColumns(ProductSchema)}
                      rawExportPayload={rawExportPayload}
                    />,
                  ),
                disabled: isLoading || count === 0,
              },
              {
                variant: 'contained',
                to: generatePath(paths.createProduct),
                label: formatMessage({ id: 'schemas.product.create.new' }),
              },
            ]
          : []
      }
    >
      <Table<Product>
        HeaderComponent={() => <ProductHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
        RowComponent={ProductRow}
        data={products}
        count={count}
        page={page}
        isError={isError}
        isLoading={isLoading || isFetching}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        stickyColumnIndex={1}
      />
    </ResourceCollectionScene>
  )
}

export default ProductsCollection
