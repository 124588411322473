// TODO: type this file
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useState } from 'react'
import { Solutions, SolutionTitles } from '@app/src/wf-constants'
import { getDateWithoutTimeFromString } from '@app/src/utils/helpersTs'

export const getGoogleMapsLink = (lat, lng) => `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`

export const isIE = () => {
  const { userAgent } = navigator
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1
}

export const getYears = (past = 30, future = 2, order = 'DESC') => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = currentYear - past; i <= currentYear + future; i++) {
    if (order === 'DESC') years.unshift(i)
    else years.push(i)
  }
  return years
}

export const getQuarters = year => {
  return [
    {
      value: 0,
      label: `Q1 ${year}`,
    },
    {
      value: 1,
      label: `Q2 ${year}`,
    },
    {
      value: 2,
      label: `Q3 ${year}`,
    },
    {
      value: 3,
      label: `Q4 ${year}`,
    },
  ]
}

export const getHalfYears = year => {
  return [
    {
      value: 0,
      label: `H1 ${year}`,
    },
    {
      value: 1,
      label: `H2 ${year}`,
    },
  ]
}

export const getMonths = () => {
  return Array.from({ length: 12 }, (e, i) => {
    return {
      value: i + 1,
      label: new Date(null, i + 1, null).toLocaleDateString('en', { month: 'long' }),
    }
  })
}

export const getMonthsLastDay = (year, month) => {
  const d = new Date(year, month, 0)
  return d.getDate()
}

export const getPeriodDisplayName = (startDate, endDate) => {
  if (!startDate || !endDate) return ''

  const date1 = getDateWithoutTimeFromString(startDate)
  const date2 = getDateWithoutTimeFromString(endDate)

  const days = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
  const startMonth = date1.getMonth()
  const year = date1.getFullYear()
  if (days < 32) {
    return `${date1.toLocaleDateString('en', { month: 'long' })} ${year}`
  }
  if (days < 100) {
    return getQuarters(year)[Math.floor((startMonth + 1) / 3)]?.label
  }
  if (days < 200) {
    return `H${startMonth === 0 ? '1' : '2'} ${year}`
  }
  return year
}

export const useKill = () => {
  /* Use it to test the ErrorBoundary component
      const kill = useKill()
      <button onClick={kill}>Kill</button>
  */
  const [killed, kill] = useState(false)
  if (killed) {
    throw new Error('Killed')
  }
  return () => kill(true)
}

export default {
  getGoogleMapsLink,
  isIE,
  getYears,
}

export const getSolutionTitle = solution => {
  switch (solution.toLowerCase()) {
    case Solutions.Transparency:
      return SolutionTitles.Transparency

    case Solutions.Sourcing:
      return SolutionTitles.Sourcing

    case Solutions.SourcingStart:
      return SolutionTitles.SourcingStart

    case Solutions.Investment:
      return SolutionTitles.Investment

    case Solutions.Management:
      return SolutionTitles.Management

    case Solutions.Finance:
      return SolutionTitles.Finance

    default:
      return SolutionTitles.Default
  }
}

export const getDaysBetweenDates = (date1arg, date2arg, absolute) => {
  const date1 = date1arg === 'now' ? new Date() : new Date(date1arg)
  const date2 = date2arg === 'now' ? new Date() : new Date(date2arg)
  const timeDifference = date2.getTime() - date1.getTime()
  const diffInDays = Math.round(timeDifference / (1000 * 3600 * 24)) //convert to days

  if (absolute && diffInDays < 0) {
    return -1 * diffInDays
  }
  return diffInDays
}

export const getDateInDays = number => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() + number)
}

export function getDaysAgo(date) {
  const today = new Date()
  const timeDiff = today.getTime() - new Date(date).getTime()
  const daysAgo = Math.floor(timeDiff / (1000 * 3600 * 24))
  return daysAgo
}

function getDateWithoutTime(date) {
  const dateWithoutTime = new Date(date)
  dateWithoutTime.setHours(0, 0, 0, 0)
  return dateWithoutTime
}

const DEFAULT_DUE_SOON_DAYS = 14

export const isDateDueSoon = (due, DUE_SOON_DAYS = DEFAULT_DUE_SOON_DAYS) => {
  const dateToday = getDateWithoutTime(new Date())
  const dueDate = getDateWithoutTime(due)
  const dueSoonDate = getDateWithoutTime(getDateInDays(DUE_SOON_DAYS))

  return dueDate <= dueSoonDate && dueDate >= dateToday
}

export const isPassedDeadline = due => {
  const dateToday = getDateWithoutTime(new Date())
  const dueDate = getDateWithoutTime(due)

  return dueDate < dateToday
}

export const randomizeDecimal = (number, range = 1) => {
  const lowerBound = Math.floor(number)

  const randomDecimal = Math.random() * range - range / 2

  return lowerBound + randomDecimal
}

export const getCurrentTimestamp = () => {
  const now = new Date()
  return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now
    .getDate()
    .toString()
    .padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now
    .getMinutes()
    .toString()
    .padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`
}
