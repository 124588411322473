import {
  AssessmentRuleType,
  Question,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

type AnswerClassificationPreviewCardProps = {
  questionAnswerClassifications?: Question['questionAnswerClassificationRules']
  showTitle?: boolean
}

const sortClassificationBasedOnRuleType = (
  questionAnswerClassifications: Question['questionAnswerClassificationRules'] = [],
) => {
  const definedQuestionAnswerClassifications = questionAnswerClassifications.filter(item => item?.rank !== 'NotSet')

  const optionClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification => answerClassification?.ruleType === AssessmentRuleType.SelectedValue,
  )
  const numberClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification =>
      answerClassification?.ruleType === AssessmentRuleType.NumberEqualTo ||
      answerClassification?.ruleType === AssessmentRuleType.NumberGreaterThan ||
      answerClassification?.ruleType === AssessmentRuleType.NumberLowerThan,
  )
  const answerClassifications = definedQuestionAnswerClassifications.filter(
    answerClassification => answerClassification?.ruleType === AssessmentRuleType.AnswerStatus,
  )

  return { optionClassifications, numberClassifications, answerClassifications }
}

const getSxByRank = (rank?: QuestionAnswerRank) => {
  switch (rank) {
    case QuestionAnswerRank.Positive:
      return {
        color: 'common.white',
        bgcolor: 'visualization.positive.700',
      }
    case QuestionAnswerRank.Negative:
      return {
        color: 'primary',
        bgcolor: 'error.main',
      }
    case QuestionAnswerRank.NegativeAdverse:
      return {
        color: 'common.white',
        bgcolor: 'semantic.error',
      }
  }
}

const ClassificationRow: React.FC<{ item?: QuestionAnswerClassificationRule; translationKey: string }> = ({
  item,
  translationKey,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      key={item?.rank}
      borderTop="1px solid"
      borderColor="divider"
      py={1.5}
      px={2}
      gap={4}
    >
      <Typography>
        {formatMessage({ id: translationKey }, { ruleType: item?.ruleType.toString(), value: item?.value })}
      </Typography>
      <Chip
        label={formatMessage({
          id: `templateBuilder.answerClassifications.classificationsRanks.${item?.rank}`,
        })}
        size="small"
        sx={getSxByRank(item?.rank)}
      />
    </Stack>
  )
}

const AnswerClassificationPreviewCard: React.FC<AnswerClassificationPreviewCardProps> = ({
  questionAnswerClassifications,
  showTitle = true,
}) => {
  const { formatMessage } = useIntl()
  const { answerClassifications, numberClassifications, optionClassifications } = useMemo(
    () => sortClassificationBasedOnRuleType(questionAnswerClassifications),
    [questionAnswerClassifications],
  )

  return (
    <Stack>
      {showTitle && (
        <Typography variant="subtitle1" paragraph>
          {formatMessage({ id: 'templateBuilder.answerClassifications.title' })}
        </Typography>
      )}

      <Box mt={2}>
        {optionClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey="templateBuilder.answerClassifications.optionValue"
          />
        ))}

        {numberClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey="templateBuilder.answerClassifications.numberValue"
          />
        ))}

        {answerClassifications.map(item => (
          <ClassificationRow
            key={item?.rank}
            item={item}
            translationKey={`templateBuilder.answerClassifications.classificationsValues.${item?.value}`}
          />
        ))}
      </Box>
    </Stack>
  )
}

export default AnswerClassificationPreviewCard
