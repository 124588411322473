import { AirSharp, Construction, FmdBad, ForestSharp, QuestionMark, Spa, Waves } from '@mui/icons-material'
import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'
import { RiskIndexSource } from '@app/src/types/resourceExplorer'
import InformClimateChange from './InformClimateChange'
import ItucLogoIcon from './ItucLogoIcon'
import MinderooFoundationLogoIcon from './MinderooFoundationLogoIcon'
import SaveTheChildrenLogoIcon from './SaveTheChildrenLogoIcon'
import { TransparencyInternationalLogoIcon } from './TransparencyInternationalLogoIcon'
import UndpLogoIcon from './UndpLogoIcon'
import WgiLogoIcon from './WgiLogoIcon'
import WorldfavorDeforestation from './WorldfavorDeforestation'
import { WriLogoIcon } from './WriLogoIcon'
import WwfAirCondition from './WwfAirCondition'
import WwfLogoIcon from './WwfLogoIcon'
import WwfTreeCoverLoss from './WwfTreeCoverLoss'

interface RiskIndexIconProps {
  source: RiskIndexSource
}

const GetIndexIcon = (source: RiskIndexSource) => {
  switch (source) {
    case RiskIndexSource.WriWaterRisk:
      return <WriLogoIcon />
    case RiskIndexSource.WgiHumanRights:
      return <WgiLogoIcon />
    case RiskIndexSource.TransparencyInternationalCorruption:
      return <TransparencyInternationalLogoIcon />
    case RiskIndexSource.MinderooFoundationModernSlavery:
      return <MinderooFoundationLogoIcon />
    case RiskIndexSource.ItucLaborRights:
      return <ItucLogoIcon />
    case RiskIndexSource.WwfBiodiversity:
      return <WwfLogoIcon />
    case RiskIndexSource.WwfSoilCondition:
      return <WwfLogoIcon />
    case RiskIndexSource.SaveTheChildrenChildLabor:
      return <SaveTheChildrenLogoIcon />
    case RiskIndexSource.SaveTheChildrenEndOfChildHood:
      return <SaveTheChildrenLogoIcon />
    case RiskIndexSource.UndpHumanDevelopment:
      return <UndpLogoIcon />
    case RiskIndexSource.WorldfavorDeforestation:
      return <WorldfavorDeforestation />
    case RiskIndexSource.WwfTreeCoverLoss:
      return <WwfTreeCoverLoss />
    case RiskIndexSource.InformClimateChange:
      return <InformClimateChange />
    case RiskIndexSource.WwfAirCondition:
      return <WwfAirCondition />
    default:
      return <QuestionMark color="primary" />
  }
}

const topicStyling: SxProps<Theme> = ({ palette, spacing }) => ({
  background: palette.secondary.main,
  position: 'absolute',
  left: 0,
  top: 0,
  padding: 0.25,
  borderBottomRightRadius: spacing(0.5),
})

const GetIndexTopic = (source: RiskIndexSource) => {
  switch (source) {
    case RiskIndexSource.WwfBiodiversity:
      return <Spa fontSize="small" sx={topicStyling} />
    case RiskIndexSource.WwfSoilCondition:
      return <Waves fontSize="small" sx={topicStyling} />
    case RiskIndexSource.SaveTheChildrenChildLabor:
      return <Construction fontSize="small" sx={topicStyling} />
    case RiskIndexSource.SaveTheChildrenEndOfChildHood:
      return <FmdBad fontSize="small" sx={topicStyling} />
    case RiskIndexSource.WwfTreeCoverLoss:
      return <ForestSharp fontSize="small" sx={topicStyling} />
    case RiskIndexSource.WwfAirCondition:
      return <AirSharp fontSize="small" sx={topicStyling} />
  }
}

const RiskIndexIcon: React.FC<RiskIndexIconProps> = ({ source }: RiskIndexIconProps) => {
  const icon = GetIndexIcon(source)
  const topic = GetIndexTopic(source)

  return (
    <Box flexGrow={1} justifyContent="center" alignItems="center" display="flex">
      {topic}
      {icon}
    </Box>
  )
}

export default RiskIndexIcon
