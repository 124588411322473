import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchCount } from '@app/src/api/fetchHooks'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import LevelBar from '@app/src/components/LevelBar'
import ListItemSkeleton from '@app/src/components/Skeleton/ListItem'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import useProviderType from '@app/src/hooks/useProviderType'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import Assessment from '@app/src/types/assessment'
import { SortOrder } from '@app/src/types/filter'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { ChevronRightOutlined, ScheduleOutlined } from '@mui/icons-material'
import { ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography, alpha } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, generatePath } from 'react-router-dom'

type BaseAssessmentOverviewProps = {
  providerId: number
}

const BaseAssessmentOverview: React.FC<BaseAssessmentOverviewProps> = ({ providerId }) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const providerType = useProviderType()
  const { formatValueToDate } = useGetFormattedDate()
  const { assessmentQuestionnaireLink } = useAssessmentQuestionnaireLink(AssessmentType.BaselineAssessment)

  const {
    items: [latestAssessment],
    isLoading: isLoadingLatestAssessment,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload: {
      sort: {
        order: SortOrder.DESCENDING,
        target: 'periodName',
      },
      filter: [
        {
          name: 'providerId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: providerId,
            },
          ],
        },
      ],
      include: ['response'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 1,
      },
    },
  })

  const { count: baseAssessmentInquiryCount, isLoading: isLoadingBaseAssessmentInquiryCount } = useFetchCount({
    key: FetchKey.InquiryCount,
    endpoint: endpoints.inquiryCount,
    payload: [
      {
        name: 'providerId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: providerId,
          },
        ],
      },
      {
        name: 'QuestionnaireTemplateId',
        filters: [
          {
            operator: Operators.EqualTo,
            value: assessmentQuestionnaireLink?.questionnaireTemplateId,
          },
        ],
      },
    ],
    options: {
      enabled:
        !isLoadingLatestAssessment &&
        !latestAssessment &&
        Boolean(assessmentQuestionnaireLink?.questionnaireTemplateId),
    },
  })

  if (isLoadingLatestAssessment || isLoadingBaseAssessmentInquiryCount) {
    return (
      <ListItemSkeleton
        avatar
        listItemAvatarSx={{ minWidth: 64 }}
        avatarProps={{ size: AvatarSize.XL, sx: ({ palette }) => ({ bgcolor: alpha(palette.common.black, 0.04) }) }}
        primary
        secondary
      />
    )
  }

  if (!latestAssessment && !baseAssessmentInquiryCount) {
    return null
  }

  return (
    <ListItem disablePadding sx={{ color: 'text.primary' }}>
      <ListItemButton
        component={Link}
        onClick={closeDrawer}
        to={generatePath(paths.resourceExplorer, {
          resourceType: providerType,
          resourceId: providerId,
          activeTabParam: AllTabs.BaseAssessment,
        })}
      >
        <ListItemAvatar sx={{ minWidth: 64 }}>
          <Avatar
            size={AvatarSize.XL}
            sx={({ palette }) => ({
              bgcolor: palette.common.white,
              border: `1px solid ${alpha(palette.common.black, 0.12)}`,
            })}
          >
            {latestAssessment ? (
              <Stack alignItems="center">
                <Typography color="text.primary" variant="subtitle2">
                  {latestAssessment.totalLevel}
                </Typography>
                <LevelBar value={latestAssessment.totalLevel} compressed />
              </Stack>
            ) : (
              <ScheduleOutlined color="disabled" />
            )}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={formatMessage({ id: 'navbar.baseAssessment' })}
          secondary={
            latestAssessment
              ? formatMessage({ id: 'baseAssessment.providerPage.responseSubmittedOn' }) +
                ' ' +
                formatValueToDate({ value: latestAssessment.response?.submittedAt ?? '' })
              : formatMessage({ id: 'baseAssessment.infoDialog.pendingInfo' })
          }
        />

        <ChevronRightOutlined color="action" />
      </ListItemButton>
    </ListItem>
  )
}

export default BaseAssessmentOverview
