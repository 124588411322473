import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import Tabs, { TabsProps } from '@app/src/components/Tabs'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { ExternalVerificationStatus } from '@app/src/types/resourceExplorer'
import { reporting } from '@app/src/wf-constants/paths'
import { useReport } from '../ReportContext'
import ReportButton from './Actions/ReportButton'
import ResponseStatus from './ResponseStatus'

const ReportingHeader: React.FC = () => {
  const {
    request,
    response,
    isResponseLoading,
    isRequestLoading,
    requestsPerPeriod,
    isRequestsPerPeriodLoading,
    requestId,
    isPreview,
  } = useReport()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { formatValueToDate } = useGetFormattedDate()

  const deadlines = request?.inquiries?.filter(inq => inq.deadline).map(inq => new Date(inq?.deadline).getTime()) ?? []
  const closestDeadline = deadlines.length ? new Date(Math.min(...deadlines)).toString() : undefined

  const tabs: TabsProps['tabs'] =
    requestsPerPeriod?.map(request => ({
      label: request.periodName,
      type: request.id.toString(),
      url: `${reporting}?${qs.stringify({ requestId: request.id })}`,
    })) ?? []

  const handleTabChange = (value: string): void => {
    const selectedTab = tabs?.find(t => t.type === value)

    if (selectedTab) {
      history.push(selectedTab.url)
    }
  }

  if (isPreview) {
    return <Typography variant="h2">{request?.title}</Typography>
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          {isRequestLoading ? (
            <Skeleton variant="rounded" width="30%" height={40} />
          ) : (
            <Typography variant="h2">{request?.title}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} textAlign="right">
          <ReportButton />
        </Grid>
      </Grid>

      <Stack direction="row" mt={2}>
        <ResponseStatus
          responseStatus={response?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
          draftStatus={response?.draftStatus}
          deadline={closestDeadline}
          isLoadingStatus={isResponseLoading}
        />

        {closestDeadline && (
          <Typography variant="body1" color="textSecondary" sx={{ ml: 1 }}>
            {formatMessage(
              { id: 'questionnaire.actions.deadline' },
              { date: formatValueToDate({ value: closestDeadline }) },
            )}
          </Typography>
        )}
      </Stack>

      {isRequestsPerPeriodLoading ? (
        <Stack direction="row" alignItems="center" mt={2}>
          <Skeleton variant="text" width="10%" height={48} sx={{ mr: 1 }} />
          <Skeleton variant="text" width="10%" height={48} />
        </Stack>
      ) : (
        <Box mt={2}>
          <Tabs
            activeTab={requestId?.toString() ?? ''}
            tabs={tabs}
            handleChange={(_event, value) => handleTabChange(value)}
          />
        </Box>
      )}
    </>
  )
}

export default ReportingHeader
