import { useUpdateResource } from '@app/src/api/updateHooks'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import endpoints from '@app/src/api/endpoints'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { NotificationSeverity, OrganizationSettings } from '@app/src/wf-constants'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

export type OrganizationSettingsResult = Record<OrganizationSettings, boolean>

const useOrganizationSettings = () => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { mutate, isLoading: isSaving, isError: updateError } = useUpdateResource<OrganizationSettingsResult>()
  const {
    data: settings,
    isLoading,
    isFetching,
    error: fetchError,
  } = useFetchResource<OrganizationSettingsResult>({
    key: FetchKey.OrganizationSettings,
    endpoint: endpoints.organizationSettings,
  })

  const updateOrganizationSettings = (settings: OrganizationSettingsResult, customSuccessMessage?: string) => {
    mutate(
      {
        url: endpoints.organizationSettings,
        body: settings,
      },
      {
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.OrganizationSettings)
          showSnackbar({
            message: customSuccessMessage
              ? customSuccessMessage
              : formatMessage({ id: 'general.organizationSettingsSaved' }),
            severity: NotificationSeverity.success,
          })
        },
      },
    )
  }

  const set = (key: OrganizationSettings, value: boolean, customSuccessMessage?: string) => {
    updateOrganizationSettings({ [key]: value }, customSuccessMessage)
  }

  const get = (key: OrganizationSettings) => {
    return settings?.[key] ?? false
  }

  const getAll = () => {
    return settings ?? ({} as OrganizationSettingsResult)
  }

  return {
    set,
    get,
    setMultiple: updateOrganizationSettings,
    getAll,
    isSaving,
    updateError,
    fetchError,
    isLoading,
    isFetching,
  }
}

export default useOrganizationSettings
