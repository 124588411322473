import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { ActionButton } from '@app/src/components/ActionButtons'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import React from 'react'
import { useIntl } from 'react-intl'
import { Route, Switch, generatePath, useParams } from 'react-router-dom'
import AccessorRequestsScene from './AccessorRequestsScene'
import AccessorResponseScene from './AccessorResponseScene'
import OverviewManageRequestsScene from './Overview/OverviewManageRequestsScene'
import ProviderRequestsScene from './ProviderRequestScene'
import ProviderResponseScene from './ProviderResponseScene'

interface ManageRequestsSceneParams {
  view: string
}

export enum ViewTypeName {
  Requests = 'requests',
  Responses = 'responses',
  Overview = 'overview',
}

const ManageRequestsScene: React.FC = () => {
  const { view } = useParams<ManageRequestsSceneParams>()
  const [isCreateDialogOpen, setCreateDialogOpen, closeCreateDialog] = useDialogState()
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  const actionTabs = [
    ...insertIf(!hasPermission(Permissions.TRANSPARENCY_USER), {
      type: ViewTypeName.Overview,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Overview }),
      label: formatMessage({ id: 'navbar.manageRequestOverview' }),
    }),
    {
      type: ViewTypeName.Requests,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Requests }),
      label: formatMessage({ id: 'navbar.requests' }),
    },
    {
      type: ViewTypeName.Responses,
      url: generatePath(paths.manageRequest, { view: ViewTypeName.Responses }),
      label: formatMessage({ id: 'navbar.responses' }),
    },
  ]

  const actionButtons: ResourceCollectionSceneProps['actionButtons'] = [
    ...insertIf<ActionButton>(!hasPermission(Permissions.TRANSPARENCY_USER), {
      label: formatMessage({ id: 'resourceCollections.general.createRequest' }),
      onClick: setCreateDialogOpen,
      variant: 'contained',
    }),
  ]

  return (
    <>
      <Switch>
        {!hasPermission(Permissions.TRANSPARENCY_USER) && (
          <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Overview })}>
            <OverviewManageRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          </Route>
        )}

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}>
          {hasPermission(Permissions.TRANSPARENCY_USER) ? (
            <ProviderRequestsScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorRequestsScene
              tabs={{ actionTabs, activeTabParam: view }}
              actionButtons={actionButtons}
              setCreateDialogOpen={setCreateDialogOpen}
            />
          )}
        </Route>

        <Route path={generatePath(paths.manageRequest, { view: ViewTypeName.Responses })}>
          {hasPermission(Permissions.TRANSPARENCY_USER) ? (
            <ProviderResponseScene tabs={{ actionTabs, activeTabParam: view }} />
          ) : (
            <AccessorResponseScene tabs={{ actionTabs, activeTabParam: view }} actionButtons={actionButtons} />
          )}
        </Route>
      </Switch>

      <CreationModalProgressContextProvider>
        <CreateNewRequestModal open={isCreateDialogOpen} onClose={closeCreateDialog} />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default ManageRequestsScene
