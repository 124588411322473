import { Box, Grid, Typography } from '@mui/material'
import Tabs from '@app/src/components/Tabs'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import OrgInfoSection from '../OrgInfoSection'
import SharedWithNetworkCard from '../SharedWithNetworkCard'
import SubscriptionsCard from '../SubscriptionsCard'
import EsgLibrary from './EsgLibrary'
import YourData from './YourData'

enum SustainabilityProfilePages {
  ESG_LIBRARY = 'esg-library',
  YOUR_DATA = 'your-data',
}

const SustainabilityProfile = () => {
  const [currentPage, setCurrentPage] = useState<SustainabilityProfilePages>(SustainabilityProfilePages.ESG_LIBRARY)
  const { formatMessage } = useIntl()

  const tabs = [
    {
      type: SustainabilityProfilePages.ESG_LIBRARY,
      label: formatMessage({ id: 'transparencyOverview.esgLibrary' }),
      url: '',
    },
    {
      type: SustainabilityProfilePages.YOUR_DATA,
      label: formatMessage({ id: 'transparencyOverview.yourData' }),
      url: '',
    },
  ]

  return (
    <Box pt={2}>
      <Box px={4}>
        <Box mb={3}>
          <Typography variant="h5">{formatMessage({ id: 'transparencyOverview.sustainabilityProfile' })}</Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={6}>
              <OrgInfoSection />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <SubscriptionsCard />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <SharedWithNetworkCard />
            </Grid>
          </Grid>
        </Box>
        <Tabs
          activeTab={currentPage}
          tabs={tabs}
          handleChange={(_, value) => setCurrentPage(value as SustainabilityProfilePages)}
        />
      </Box>
      {currentPage === SustainabilityProfilePages.ESG_LIBRARY && <EsgLibrary />}
      {currentPage === SustainabilityProfilePages.YOUR_DATA && <YourData />}
    </Box>
  )
}

export default SustainabilityProfile
