import { QuestionType } from '@app/src/types/reporting'
import {
  AnswerClassificationValue,
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { InfoOutlined } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Select from '../../../components/Form/Select/ControlledSelect'
import AdvancedClassification from './AdvancedClassification'

type QuestionAnswerClassificationRuleSaveModel = QuestionAnswerClassificationRule & {
  disabled?: boolean
}

type EditQuestionAnswerClassificationProps = {
  questionTypeId: QuestionType
}

const EditQuestionAnswerClassification: React.FC<EditQuestionAnswerClassificationProps> = ({ questionTypeId }) => {
  const { formatMessage } = useIntl()
  const { control, watch, setValue } = useFormContext()

  const watchQuestionAnswerClassificationRules: Array<QuestionAnswerClassificationRuleSaveModel | undefined> = watch(
    'questionAnswerClassificationRules',
  )

  const presentValues = useMemo(
    () =>
      Object.values(watchQuestionAnswerClassificationRules ?? {})
        .flat()
        .filter(Boolean)
        .filter(item => item?.rank !== 'NotSet')
        .map(item => item?.value),
    [watchQuestionAnswerClassificationRules],
  )

  return (
    <Grid container py={3} justifyContent="center">
      <Grid container item bgcolor="common.white" spacing={3} xs={6} pt={2} pb={5} pl={2} pr={5} borderRadius={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'templateBuilder.answerClassifications.title' })}
          </Typography>
          <Box display="flex" alignItems="center">
            <InfoOutlined fontSize="small" color="action" />
            <Typography variant="caption" color="textSecondary" ml={1}>
              {formatMessage({ id: 'templateBuilder.answerClassifications.subtitle' })}
            </Typography>
          </Box>
        </Grid>

        {Object.values(QuestionAnswerRank)
          .filter(item => item !== QuestionAnswerRank.NotSet)
          .map(item => (
            <Grid item xs={12} key={item}>
              <Select<QuestionAnswerClassificationRuleSaveModel>
                name={`questionAnswerClassificationRules[${item}]`}
                control={control}
                fieldLabel={formatMessage({
                  id: `templateBuilder.answerClassifications.classificationsRanks.${item}`,
                })}
                size="small"
                multiple
                options={[
                  {
                    label: formatMessage({
                      id: `templateBuilder.answerClassifications.classificationsValues.${AnswerClassificationValue.Answered}`,
                    }),
                    value: {
                      rank: item,
                      ruleType: AssessmentRuleType.AnswerStatus,
                      value: AnswerClassificationValue.Answered,
                    },
                    disabled: presentValues.includes(AnswerClassificationValue.Answered),
                  },
                  {
                    label: formatMessage({
                      id: `templateBuilder.answerClassifications.classificationsValues.${AnswerClassificationValue.NotApplicable}`,
                    }),
                    value: {
                      rank: item,
                      ruleType: AssessmentRuleType.AnswerStatus,
                      value: AnswerClassificationValue.NotApplicable,
                    },
                    disabled: presentValues.includes(AnswerClassificationValue.NotApplicable),
                  },
                  {
                    label: formatMessage({
                      id: `templateBuilder.answerClassifications.classificationsValues.${AnswerClassificationValue.NotAvailable}`,
                    }),
                    value: {
                      rank: item,
                      ruleType: AssessmentRuleType.AnswerStatus,
                      value: AnswerClassificationValue.NotAvailable,
                    },
                    disabled: presentValues.includes(AnswerClassificationValue.NotAvailable),
                  },
                  {
                    label: formatMessage({
                      id: `templateBuilder.answerClassifications.classificationsValues.${AnswerClassificationValue.Other}`,
                    }),
                    value: {
                      rank: item,
                      ruleType: AssessmentRuleType.AnswerStatus,
                      value: AnswerClassificationValue.Other,
                    },
                    disabled: presentValues.includes(AnswerClassificationValue.Other),
                  },
                ]}
                findSelectedValue={(value, option) => value?.value === option?.value?.value}
                onInputChange={(_e, value) => {
                  if (!value) setValue(`questionAnswerClassificationRules[${item}]`, undefined)
                }}
              />
            </Grid>
          ))}

        <AdvancedClassification questionTypeId={questionTypeId} />
      </Grid>
    </Grid>
  )
}

export default EditQuestionAnswerClassification
