import { SvgIcon } from '@mui/material'
import React from 'react'

export const IntervalChartIconOne = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22.8" cy="23" r="18" fill="#E0E0E0" stroke="#fff" strokeWidth="4" />
        <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="6" y="7" width="33" height="32">
          <circle cx="22.8" cy="23" r="16" fill="#E0E0E0" />
        </mask>
        <g mask="url(#a)">
          <circle cx="22.8" cy="23" r="16" fill="#E0E0E0" />
          <mask id="b" maskUnits="userSpaceOnUse" x="20.8" y="5" width="20" height="21" fill="#000">
            <path fill="#fff" d="M20.8 5h20v21h-20z" />
            <path d="M22.8 7a16 16 0 0 1 15.217 11.056L22.8 23V7Z" />
          </mask>
          <path d="M22.8 7a16 16 0 0 1 15.217 11.056L22.8 23V7Z" fill="#9E0202" />
          <path d="M22.8 7a16 16 0 0 1 15.217 11.056L22.8 23V7Z" stroke="#fff" strokeWidth="3.333" mask="url(#b)" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconOne
