import { ListItemIcon, Menu, MenuItem, Tooltip, TypographyProps } from '@mui/material'
import React from 'react'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CircularProgress from '@mui/material/CircularProgress'
import { Delete } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import { Inquiry, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { useIntl } from 'react-intl'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { useQueryClient } from 'react-query'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import { useMenuState } from '@app/src/hooks/mui-hooks'

export type DeleteInquiryCellProps = {
  inquiry: Inquiry
} & TypographyProps

const getNotAllowedStringId = (inquiry: Inquiry, hasWfEmailAccess: boolean, role: string) => {
  if (!ADMIN_ROLES.includes(role.toLowerCase())) return 'schemas.request.delete.notAllowedMustBeAdmin'
  if (hasWfEmailAccess) return null
  if (inquiry.template.questionnaireTemplateType === QuestionnaireTypeEnum.Shared) return null
  if (!inquiry.requestId) return null
  return 'schemas.request.delete.notAllowedHasResponses'
}

const getConfirmTextStringId = (inquiry: Inquiry) => {
  if (!inquiry.request) return 'schemas.request.delete.descriptionNoResponse'
  if (inquiry.template.questionnaireTemplateType === QuestionnaireTypeEnum.Shared)
    return 'schemas.request.delete.descriptionSharedTemplate'

  return 'schemas.request.delete.descriptionRegularTemplate'
}

const DeleteInquiryCell: React.FC<DeleteInquiryCellProps> = ({ inquiry }) => {
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const { mutateAsync: deleteInquiryAsync, isLoading: isLoadingDelete } = useDeleteResource()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { hasPermission } = usePermissions()
  const { formatMessage } = useIntl()
  const confirm = useConfirm()
  const { scope } = useAuthentication()

  const hasWfEmailAccess = hasPermission(Permissions.WF_EMAIL_ACCESS)

  const performDelete = async () => {
    await deleteInquiryAsync(
      { url: endpoints.inquiryById(inquiry.id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Inquiry)
          queryClient.invalidateQueries(FetchKey.Response)
          queryClient.invalidateQueries(FetchKey.Request)
          queryClient.invalidateQueries(FetchKey.Answer)
          queryClient.invalidateQueries(FetchKey.ResponseItemCount)
          queryClient.invalidateQueries(FetchKey.Notification)
          closeMenu()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const confirmDelete = async () => {
    confirm({
      title: formatMessage({ id: 'schemas.request.delete.title' }),
      content: formatMessage({ id: getConfirmTextStringId(inquiry) }),
      confirmationText: formatMessage({ id: 'schemas.request.delete.buttonText' }),
      confirmationButtonProps: { color: 'error', startIcon: <Delete /> },
    })
      .then(performDelete)
      .catch(closeMenu)
  }

  const notAllowedStringId = getNotAllowedStringId(inquiry, hasWfEmailAccess, scope?.role ?? '')

  return (
    <ParentCellOrFragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <Tooltip title={notAllowedStringId ? formatMessage({ id: notAllowedStringId }) : undefined}>
          <span>
            <MenuItem onClick={confirmDelete} disabled={isLoadingDelete || Boolean(notAllowedStringId)}>
              <ListItemIcon>{isLoadingDelete ? <CircularProgress size={20} /> : <Delete />}</ListItemIcon>
              {formatMessage({ id: 'general.delete' })}
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </ParentCellOrFragment>
  )
}

export default DeleteInquiryCell
