import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import SectionSkeleton from '@app/src/components/Skeleton/Section'
import useLocalStorage from '@app/src/hooks/localStorage'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import DownloadResponse from '@app/src/pages/Questionnaire/DownloadResponse'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import ReportingDescription from '@app/src/pages/Questionnaire/ReportingDescription'
import { RequestStatus, ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import ReportTranslation from '../../ReportTranslation'
import ReuseAllPreviousAnswersDialog from './ReuseAllPreviousAnswersDialog'
import Section from './Section'
import UploadFiles from './UploadFiles'

const Report: React.FC = () => {
  const { request, isRequestLoading, isPreview, previouslyRespondedQuestions, response } = useReport()
  const [isReuseAllPreviousAnswersDialogOpen, openReuseAllPreviousAnswersDialog, closeReuseAllPreviousAnswersDialog] =
    useDialogState(false)
  const [answerSuggestionsDialogShown, setAnswerSuggestionsDialogShown] = useLocalStorage<number[]>(
    'answerSuggestionsDialogShown',
    [],
  )

  const haveBeenShownBefore = answerSuggestionsDialogShown.includes(response?.id ?? 0)
  const shouldShowAnswerSuggestionsDialog = Boolean(
    !haveBeenShownBefore &&
      previouslyRespondedQuestions?.length &&
      response?.draftStatus === ResponseDraftStatus.Draft &&
      request?.requestStatus === RequestStatus.Sent,
  )
  const sections = request?.sections

  useEffect(() => {
    if (shouldShowAnswerSuggestionsDialog) {
      openReuseAllPreviousAnswersDialog()
      setAnswerSuggestionsDialogShown(prev => [...prev, response?.id ?? 0])
    }
  }, [previouslyRespondedQuestions, response?.draftStatus, request?.requestStatus])

  return (
    <>
      <Box bgcolor="common.white" py={3} px={5} borderRadius={4}>
        {!isPreview && (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <ReportTranslation />
              <Stack direction="row">
                <UploadFiles />
                <DownloadResponse />
              </Stack>
            </Box>
            <Divider sx={{ mb: 3 }} />
          </>
        )}

        <Box mb={5}>
          {isPreview && (
            <Typography variant="h1" gutterBottom>
              {request?.title}
            </Typography>
          )}
          <ReportingDescription />
        </Box>

        {isRequestLoading ? (
          <SectionSkeleton howMany={2} />
        ) : (
          sections?.map((section, index) => (
            <Section key={section?.id ?? index} section={section} sectionNumber={index + 1} />
          ))
        )}
      </Box>
      <ReuseAllPreviousAnswersDialog
        open={isReuseAllPreviousAnswersDialogOpen}
        onClose={closeReuseAllPreviousAnswersDialog}
      />
    </>
  )
}

export default Report
