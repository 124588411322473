import { Stack, Typography } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import TextField from '@app/src/components/Ui/TextField'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { useDrawer } from '../../DrawerContext'
import DrawerView, { DrawerViewProps } from '../../DrawerView'
import { ReasonFormData } from './DrawerViewAddProviderContactReason'

type DrawerViewAddProviderContactProps = {
  companyName: string
  reason: ReasonFormData
  providerId: number
} & Omit<DrawerViewProps, 'title'>

type ContactDetailsFormData = {
  firstName: string
  lastName: string
  email: string
}

const DrawerViewAddProviderContactDetails: React.FC<DrawerViewAddProviderContactProps> = ({
  companyName,
  reason,
  providerId,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { closeDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { mutate } = useUpdateResource<ContactDetailsFormData & ReasonFormData>()
  const { trackEvent } = useAmplitude()
  const { handleSubmit, register, errors, setValue } = useForm<ContactDetailsFormData>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })
  const queryClient = useQueryClient()

  const onSubmit = (values: ContactDetailsFormData) => {
    const body = { ...values, ...reason, providerId }

    mutate(
      {
        url: endpoints.suggestedContact,
        body,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfullyRequestedProviderContact' }),
            severity: 'success',
          })
          queryClient.invalidateQueries(FetchKey.SuggestedContacts)
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
        onSettled: () => {
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.SuggestedContact.SubmittedRequest,
            eventProps: {
              reason: reason.reasonType,
              reasonOtherInfo: reason.reasonExtraInfo,
            },
          })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.addProviderContact.addContact' })}
      subTitle={companyName}
      buttons={[
        {
          label: formatMessage({ id: 'general.submit' }),
          variant: 'contained',
          type: 'submit',
        },
      ]}
      stackButtons
      onFormSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      <Stack p={2} spacing={3}>
        <Typography>{formatMessage({ id: 'schemas.addProviderContact.addContactInfo' }, { companyName })}</Typography>
        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.userFirstName' })}
          label={formatMessage({ id: 'schemas.user.givenName' })}
          name="firstName"
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          error={Boolean(errors?.firstName)}
          onClear={() => setValue('firstName', '')}
          helperText={errors?.firstName?.message}
        />
        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.userLastName' })}
          label={formatMessage({ id: 'schemas.user.familyName' })}
          name="lastName"
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          error={Boolean(errors?.lastName)}
          onClear={() => setValue('lastName', '')}
          helperText={errors?.lastName?.message}
        />
        <TextField
          hoveringLabel
          placeholder={formatMessage({ id: 'textFieldPlaceholders.userEmail' })}
          label={formatMessage({ id: 'schemas.user.email' })}
          name="email"
          type="email"
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          error={Boolean(errors?.email)}
          onClear={() => setValue('email', '')}
          helperText={errors?.email?.message}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddProviderContactDetails
