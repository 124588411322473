import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined'
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Avatar, Box, Button, Grid, IconButton, Link, Typography, alpha, lighten } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useIntl } from 'react-intl'

interface RecommendationCardProps {
  description: string
  linkUrl: string
  icon?: JSX.Element
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: lighten(palette.communication.main, 0.1),
    },
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: spacing(2),
    backgroundColor: alpha(palette.common.white, 0.3),
  },
}))

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  description,
  linkUrl,
  icon = <DescriptionOutlinedIcon color="primary" />,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.card}
      onClick={() => window.open(linkUrl, '_blank', 'noopener,noreferrer')}
      bgcolor="communication.main"
      p={3}
      borderRadius="4px"
      height="100%"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" mr={2}>
          <Avatar variant="square" className={classes.avatar}>
            {icon}
          </Avatar>
          <Typography variant="subtitle1">{description}</Typography>
        </Box>
        <IconButton component={Link} target="_blank" href={linkUrl} rel="noopener noreferrer" size="large">
          <OpenInNewIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const RecommendationsSection = () => {
  const { formatMessage } = useIntl()

  return (
    <Box px={4} py={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{formatMessage({ id: 'transparencyOverview.recommendations.title' })}</Typography>
        <Button
          component={Link}
          target="_blank"
          href="https://helpdesk.worldfavor.com/worldfavor-app/en/collections/3714205-sustainability-library"
          rel="noopener noreferrer"
          endIcon={<OpenInNewIcon />}
        >
          {formatMessage({ id: 'transparencyOverview.recommendations.exploreMore' })}
        </Button>
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              icon={<ForestOutlinedIcon color="primary" />}
              description={formatMessage({ id: 'transparencyOverview.recommendations.ghgBody' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6961962-what-are-greenhouse-gases"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              icon={<InsertChartOutlinedIcon color="primary" />}
              description={formatMessage({ id: 'transparencyOverview.recommendations.emissionsBody' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6962187-user-guide-wf-s-co2e-calculator"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              description={formatMessage({ id: 'transparencyOverview.recommendations.envPolicy' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6737231-guide-environmental-policy"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <RecommendationCard
              description={formatMessage({ id: 'transparencyOverview.recommendations.hrPolicy' })}
              linkUrl="https://helpdesk.worldfavor.com/en/articles/6737238-guide-human-rights-policy"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RecommendationsSection
