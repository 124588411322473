import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import DrawerViewDeleteProvider from '@app/src/components/Drawer/Views/DrawerViewDeleteProvider'
import ReferralContactListItem from '@app/src/components/ReferralContactListItem'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import { useReferralContact } from '@app/src/hooks/referralContact'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Provider } from '@app/src/types/organizations'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import {
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
  LabelOutlined,
  Language,
  MailOutline,
  MoreHorizOutlined,
  RefreshOutlined,
} from '@mui/icons-material'
import {
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import DrawerViewCreateReferralContact from './DrawerViewCreateReferralContact'
import DrawerViewLinkProviderCandidates from './DrawerViewLinkProviderCandidates'
import DrawerViewOrganizationForm from './DrawerViewOrganizationForm'
import DrawerViewReferralContact from './DrawerViewReferralContact'
import DrawerViewSendInviteToReferralContact from './DrawerViewSendInviteToReferralContact'

type DrawerViewLinkProviderProps = {
  provider: Provider
  solution?: string
} & Omit<DrawerViewProps, 'title' | 'buttons'>

const DrawerViewLinkProvider: React.FC<DrawerViewLinkProviderProps> = ({ provider, solution = '', ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)
  const { showSnackbar } = useSnackbar()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()

  const { data: foundOrganizations } = useFetchResource<Provider[]>({
    endpoint: endpoints.findOrganization(provider.name),
    key: [FetchKey.FindOrganization, provider.name],
    options: {
      enabled: Boolean(provider.name),
    },
  })

  const matchedOrganizationsFound = Boolean(foundOrganizations?.length)
  const hasSentInvite = Boolean(referralContact?.referralEmailHistory?.length)

  const copyRefLinkToClipBoard = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}?referral=${referralContact.referralCode}`)
    showSnackbar({ message: formatMessage({ id: 'referral.sendInvite.copySuccess' }), severity: 'success' })
  }

  return (
    <DrawerView
      title=""
      buttons={[
        {
          variant: 'outlined',
          label: formatMessage({ id: 'general.more' }),
          startIcon: <MoreHorizOutlined />,
          onClick: openMenu,
        },
        ...insertIf<ActionButton>(hasSentInvite, {
          label: formatMessage({ id: 'referral.sendInvite.copyInvite' }),
          startIcon: <ContentCopyOutlined color="action" />,
          variant: 'outlined',
          onClick: copyRefLinkToClipBoard,
        }),
        {
          label: hasSentInvite
            ? formatMessage({ id: 'referral.sendInvite.resendInviteToConnect' })
            : formatMessage({ id: 'referral.sendInvite.inviteToConnect' }),
          startIcon: hasSentInvite ? <RefreshOutlined /> : <MailOutline />,
          variant: 'contained',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Onboarding.SendInvite.OpenedConnectFlow,
              eventProps: {
                provider_id: String(provider.id),
              },
            })
            if (matchedOrganizationsFound && !referralContact) {
              openDrawer(
                <DrawerViewLinkProviderCandidates
                  resource={provider}
                  organizationCandidates={foundOrganizations}
                  referralContact={referralContact}
                />,
              )
            } else if (!referralContact) {
              openDrawer(<DrawerViewCreateReferralContact provider={provider} />)
            } else if (hasSentInvite) {
              openDrawer(<DrawerViewReferralContact provider={provider} />)
            } else {
              openDrawer(<DrawerViewSendInviteToReferralContact provider={provider} />)
            }
          },
        },
      ]}
      stackButtons
      {...props}
    >
      <Stack alignItems="center" spacing={2} pb={2}>
        {matchedOrganizationsFound && (
          <ListItem disablePadding sx={{ bgcolor: 'grey.50' }}>
            <ListItemButton
              onClick={() =>
                openDrawer(
                  <DrawerViewLinkProviderCandidates
                    resource={provider}
                    referralContact={referralContact}
                    organizationCandidates={foundOrganizations}
                  />,
                )
              }
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: 'grey.200',
                  }}
                >
                  <Language sx={{ color: 'secondary.main' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary={formatMessage({ id: 'referral.invitation.companyExists' })}
                secondaryTypographyProps={{ color: 'primary' }}
              />
              <Button variant="contained" size="small" sx={{ ml: 1 }}>
                {formatMessage({ id: 'general.view' })}
              </Button>
            </ListItemButton>
          </ListItem>
        )}
        <CompanyAvatar size={AvatarSize.XXL} organizationName={provider.name} imageUrl={provider.image?.url} unlinked />
        <Typography variant="h6">{provider.name}</Typography>

        {hasSentInvite ? (
          <Chip label={formatMessage({ id: 'referral.inviteSent.title' })} variant="filled" />
        ) : (
          <Chip
            label={formatMessage({ id: 'referral.invitation.notConnected' })}
            variant="outlined"
            icon={<InfoOutlined fontSize="small" />}
          />
        )}
      </Stack>

      <ReferralContactListItem
        provider={provider}
        referralContact={referralContact}
        isReferralContactsLoading={isReferralContactsLoading}
      />
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            openDrawer(<DrawerViewOrganizationForm provider={provider} />)
            closeMenu()
          }}
        >
          <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
            <LabelOutlined />
          </ListItemIcon>
          <ListItemText>{formatMessage({ id: 'general.categorize' })}</ListItemText>
        </MenuItem>
        {Boolean(solution) && (
          <MenuItem
            onClick={() => {
              openDrawer(<DrawerViewDeleteProvider provider={provider} solution={solution} />)
              closeMenu()
            }}
          >
            <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
              <DeleteOutlineOutlined />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'schemas.company.delete' })}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </DrawerView>
  )
}

export default DrawerViewLinkProvider
