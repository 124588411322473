import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import RequestQuestionStep from '@app/src/components/CreateNewRequestModal/RequestQuestionStep'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import GoalTrackerSelectFilterStep from '@app/src/pages/Dashboards/GoalTracker/FilterStep/GoalTrackerSelectFilterStep'
import { GOAL_TRACKER_FILTER_PREFIX } from '@app/src/pages/Dashboards/GoalTracker/GoalTrackerDialog'
import { useFiltersContext } from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GoalDirectionType, GoalTracker, Question } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Dialog, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import SelectQuestionStep from './SelectQuestionStep'
import WriteGoalSummaryStep from './WriteGoalSummaryStep'
import WriteGoalValueStep from './WriteGoalValueStep'

export enum GOAL_TRACKER_QUESTION_KEYS {
  TEMPLATE_ID = 'questionnaireTemplateId',
  REQUEST_ITEM_TEMPLATE_ID = 'questionId',
  TARGET_REFERENCE = 'targetReference',
  DESCRIPTION = 'description',
  TITLE = 'title',
  GOAL_VALUE = 'goalValue',
  GOAL_VALUE_SYMBOL = 'goalValueSymbol',
  FILTER_COLLECTIONS = 'filterCollections',
  UNIT_ID = 'unitId',
  GOAL_DIRECTION = 'goalDirection',
}

type CreateGoalTrackerModalProps = {
  onClose: () => void
  open: boolean
  goalTrackerItem?: GoalTracker
}

export interface GoalTrackerFormType {
  description: string
  questionId: number
  templateId: number
  targetReference: string
  title: string
  goalValueSymbol: string
  filterCollections: FilterGroup[]
  goalValue: number
  unitId: number
  goalDirection: GoalDirectionType
}

const useStyles = makeStyles(({ spacing }) => ({
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(10),
    '&:first-child': {
      paddingTop: spacing(9),
    },
  },
  progressBar: {
    marginTop: spacing(2),
  },
}))

const CreateGoalTrackerModal: React.FC<CreateGoalTrackerModalProps> = ({ onClose, open, goalTrackerItem }) => {
  const classes = useStyles()
  const { setActiveStep, activeStep, setTotalSteps } = useCreationModalProgress()
  const { formatMessage } = useIntl()
  const { mutateAsync: createRequest, isLoading } = useCreateResource()
  const { clearQueryFilter } = useFiltersContext()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [validRequestItems, setValidRequestItems] = useState<Question[]>([])

  const formMethods = useForm<GoalTrackerFormType>({
    shouldUnregister: false,
    defaultValues: goalTrackerItem,
  })

  const { getValues, reset } = formMethods

  const handleClose = () => {
    onClose()
  }

  useEffect(() => reset({}), [])

  const handleFormSubmit = async () => {
    const currentValues = getValues()

    const filterCollections = Array.isArray(currentValues.filterCollections)
      ? currentValues.filterCollections.map(f => ({ ...f, name: `${GOAL_TRACKER_FILTER_PREFIX}${f.name}` }))
      : undefined
    const body = {
      id: goalTrackerItem?.id,
      questionId: currentValues.questionId,
      targetReference: currentValues.targetReference,
      description: currentValues.description,
      title: currentValues.title,
      goalValue: currentValues.goalValue,
      goalDirection: currentValues.goalDirection,
      filterCollections: filterCollections,
      unitId: currentValues.unitId,
    }

    const successMessage = goalTrackerItem?.id ? 'editSuccessMessage' : 'successMessage'

    await createRequest(
      { url: endpoints.createGoalTracker, body },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: `schemas.goalTracker.${successMessage}` }),
            severity: NotificationSeverity.success,
          })
          setActiveStep(1)
          reset({})
          clearQueryFilter()
          queryClient.invalidateQueries([FetchKey.GoalTracker])
          queryClient.invalidateQueries(FetchKey.ProvidersMatchingFilters)
          queryClient.invalidateQueries(FetchKey.ProvidersMatchingCount)
          handleClose()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const GOAL_TRACKER_QUESTIONS = [
    {
      title: formatMessage({ id: 'schemas.goalTracker.question.title' }),
      description: formatMessage({ id: 'schemas.goalTracker.question.description' }),
      fieldnames: [
        GOAL_TRACKER_QUESTION_KEYS.TEMPLATE_ID,
        GOAL_TRACKER_QUESTION_KEYS.REQUEST_ITEM_TEMPLATE_ID,
        GOAL_TRACKER_QUESTION_KEYS.TARGET_REFERENCE,
      ],
      children: (
        <SelectQuestionStep
          setValidRequestItems={setValidRequestItems}
          currentGoalTrackerFilters={goalTrackerItem?.filterCollections}
        />
      ),
    },
    {
      title: formatMessage({ id: 'schemas.goalTracker.filter.title' }),
      description: formatMessage({ id: 'schemas.goalTracker.filter.description' }),
      fieldnames: [GOAL_TRACKER_QUESTION_KEYS.FILTER_COLLECTIONS],
      children: <GoalTrackerSelectFilterStep />,
    },
    {
      title: formatMessage({ id: 'schemas.goalTracker.inputGoalValue.title' }),
      description: formatMessage({ id: 'schemas.goalTracker.inputGoalValue.description' }),
      fieldnames: [GOAL_TRACKER_QUESTION_KEYS.GOAL_VALUE],
      children: <WriteGoalValueStep validRequestItems={validRequestItems} />,
    },
    {
      title: formatMessage({ id: 'schemas.goalTracker.goalSummary.title' }),
      description: formatMessage({ id: 'schemas.goalTracker.goalSummary.description' }),
      fieldnames: [GOAL_TRACKER_QUESTION_KEYS.TITLE, GOAL_TRACKER_QUESTION_KEYS.DESCRIPTION],
      children: <WriteGoalSummaryStep />,
    },
  ]

  const activeQuestion = useMemo(() => GOAL_TRACKER_QUESTIONS[activeStep - 1], [activeStep])

  useEffect(() => {
    setTotalSteps(GOAL_TRACKER_QUESTIONS.length)
  }, [])

  return (
    <Dialog open={open} fullScreen onClose={handleClose}>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <CreationModalContainer
          title={formatMessage({ id: 'schemas.goalTracker.title' })}
          onClose={() => {
            reset({})
            setActiveStep(1)
            handleClose()
          }}
        >
          <FormProvider {...formMethods}>
            {activeQuestion && (
              <RequestQuestionStep
                fieldnames={activeQuestion.fieldnames}
                title={activeQuestion.title}
                description={activeQuestion.description}
                key={activeQuestion.title}
                disableInitialBack
                onClose={handleClose}
                onBack={onClose}
                onSubmit={handleFormSubmit}
                createGoalTrackerFlow
                isLoading={isLoading}
                editMode={Boolean(goalTrackerItem)}
              >
                {activeQuestion.children}
              </RequestQuestionStep>
            )}
          </FormProvider>
        </CreationModalContainer>
      </DialogContent>
    </Dialog>
  )
}

export default CreateGoalTrackerModal
