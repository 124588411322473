import { Box, RatingProps } from '@mui/material'
import RatingDots from '@app/src/components/RatingDots'
import ParentCellOrFragment from '@app/src/components/Table/Cells/ParentCellOrFragment'
import React from 'react'
import HoverDialog from '@app/src/components/HoverDialog'
import { useIntl } from 'react-intl'

interface RatingCellProps extends RatingProps {
  value?: number
  disableCell?: boolean
  label: string
}

const RatingCell: React.FC<RatingCellProps> = ({ disableCell, value, label }) => {
  const { formatMessage } = useIntl()
  if (!value) return null
  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <HoverDialog content={formatMessage({ id: 'baseAssessment.assessedLevel' }, { value })} width={200}>
        <Box>
          <RatingDots level={value} />
        </Box>
      </HoverDialog>
    </ParentCellOrFragment>
  )
}

export default RatingCell
