import { Box, Stack, Tooltip } from '@mui/material'
import React from 'react'

import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewSubmitQuestionnaire from '@app/src/components/Drawer/Views/DrawerViewSubmitQuestionnaire'
import DrawerViewSubmitShareQuestionnaire from '@app/src/components/Drawer/Views/DrawerViewSubmitShareQuestionnaire'
import LoadingButton from '@app/src/components/LoadingButton'
import ButtonSkeletonWrapper from '@app/src/components/Skeleton/Buttons'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { Assertion } from '@app/src/types/reporting'
import { AnswerStatus, HistoryStatus, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { UnpackNestedValue, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useReport } from '../../ReportContext'
import useProviderQuestionnaire from '../../hooks/useProviderQuestionnaire'
import { FormData } from '../ProviderReport'

const scrollErrorIntoView = (key: string) => {
  const input =
    document.querySelector(`input[name="${key}.answer"]`) ??
    document.querySelector(`input[name="${key}.selectedOptions"]`) ??
    document.querySelector(`[name="${key}.uploadReferences.fileUpload"]`)

  input?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  })
}

const ReportButton: React.FC = () => {
  const {
    request,
    currentAccess,
    reportStatus,
    latestHistory,
    isRequestLoading,
    isResponseLoading,
    isRequestsPerPeriodLoading,
    setReuseAllQuestionsState,
    updateResponseIdParam,
  } = useReport()

  const {
    createDraft,
    updateDraft,
    submitResponse,
    reuseResponse,
    deleteDraft,
    isLoadingCreateDraft,
    isLoadingDeleteDraft,
    isLoadingUpdateSaveDraft,
    isLoadingSubmitDraft,
    isLoadingReuseResponse,
  } = useProviderQuestionnaire()

  const { formatMessage } = useIntl()
  const { getValues, formState, reset, setError, clearErrors } = useFormContext<FormData>()
  const { openDrawer } = useDrawer()

  const isRegularQuestionnaire = request?.template?.questionnaireTemplateType === QuestionnaireTypeEnum.Regular

  const createOrRedirectToDraft = () => {
    setReuseAllQuestionsState(ReuseAllQuestionsStates.Review)
    const existingDraft = latestHistory?.status === HistoryStatus.Draft

    if (existingDraft) {
      updateResponseIdParam(latestHistory.responseId)
    } else {
      createDraft()
    }
  }

  const createDraftFromResponse = async () => {
    await reuseResponse()
  }

  const handleUpdate = async () => {
    const values = getValues()
    await updateDraft(values)
    reset(values)
  }

  const handleSubmit = async (values: UnpackNestedValue<FormData>, assertions: Assertion[]) => {
    await submitResponse(values, assertions)
    reset(values)
  }

  const startSubmitFlow = () => {
    const values = getValues()
    if (!validateForm(values)) {
      return
    }
    openDrawer(
      isRegularQuestionnaire ? (
        <DrawerViewSubmitQuestionnaire
          isAllAccess={currentAccess?.isAllAccess}
          onSubmit={assertions => handleSubmit(values, assertions)}
        />
      ) : (
        <DrawerViewSubmitShareQuestionnaire
          request={request}
          currentAccess={currentAccess}
          onSubmit={assertions => handleSubmit(values, assertions)}
        />
      ),
    )
  }

  const handleDeleteDraft = async () => {
    await deleteDraft()
    reset({})
  }

  const validateForm = (values: UnpackNestedValue<FormData>) => {
    clearErrors()
    let errorsExist = false
    Object.entries(values).forEach(([key, value]) => {
      const commentRequired = value.answerStatus === AnswerStatus.Other
      const questionSkipped =
        value.answerStatus !== AnswerStatus.Answered && (!commentRequired || (value.comment?.length ?? 0) >= 10)

      const isAnswered = Boolean(
        value.answer || value.selectedOptions?.length || value.uploadReferences?.originalFileName || questionSkipped,
      )

      if (isAnswered) return

      setError(key, {
        type: 'required',
        message: formatMessage({
          id: commentRequired ? 'form.validation.requiredMinLenght' : 'form.validation.required',
        }),
      })

      setError(`${key}.skippedQuestionRequiredInfo`, {
        type: 'required',
      })

      if (errorsExist) return

      scrollErrorIntoView(key)
      errorsExist = true
    })

    return !errorsExist
  }

  if (isRequestLoading || isResponseLoading || isRequestsPerPeriodLoading) {
    return <ButtonSkeletonWrapper />
  }

  switch (reportStatus) {
    case HistoryStatus.Draft:
      return (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <LoadingButton
            variant="text"
            onClick={handleDeleteDraft}
            loading={isLoadingDeleteDraft}
            disabled={isLoadingDeleteDraft || isLoadingUpdateSaveDraft}
          >
            {formatMessage({ id: 'questionnaire.actions.discard' })}
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            onClick={handleUpdate}
            disabled={isLoadingDeleteDraft || isLoadingUpdateSaveDraft}
            loading={isLoadingUpdateSaveDraft}
          >
            {formatMessage({ id: 'questionnaire.actions.save' })}
          </LoadingButton>
          <Tooltip
            title={formatMessage({ id: 'questionnaire.actions.requiredFields' })}
            arrow
            disableHoverListener={formState.isValid && !isLoadingDeleteDraft && !isLoadingUpdateSaveDraft}
          >
            <Box display="inline-block">
              <LoadingButton variant="contained" onClick={startSubmitFlow} loading={isLoadingSubmitDraft}>
                {formatMessage({ id: 'questionnaire.actions.submit' })}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Stack>
      )

    case HistoryStatus.Submitted:
      return (
        <LoadingButton
          variant="contained"
          onClick={createDraftFromResponse}
          loading={isLoadingReuseResponse}
          disabled={isLoadingReuseResponse}
        >
          {formatMessage({ id: 'questionnaire.actions.update' })}
        </LoadingButton>
      )

    default:
      return (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            onClick={createOrRedirectToDraft}
            loading={isLoadingCreateDraft}
            disabled={isLoadingCreateDraft || isLoadingReuseResponse}
          >
            {formatMessage({ id: 'questionnaire.actions.respond' })}
          </LoadingButton>
        </Stack>
      )
  }
}

export default ReportButton
