import Drawer from '@app/src/components/Drawer_DEPRECATED'
import { OrganisationDrawerProps } from '@app/src/components/Form'
import CategoryDrawerContent, {
  CategoryDrawerViews,
  ProviderUpdateType,
} from '@app/src/components/ManageProviderDrawer/CategoryDrawer/CategoryDrawerContent'
import UpdateProviderMenu from '@app/src/components/ManageProviderDrawer/UpdateProviderMenu'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Category } from '@app/src/types/categories'

type UpdateProvidersDrawerProps = {
  selectedProviders: ProviderUpdateType[]
  setSelectedProviders: React.Dispatch<React.SetStateAction<Array<number>>>
}

export enum UpdateProvidersDrawerViews {
  Menu,
  CreateCategory,
  AssignCategoryOption,
  SetStandardCategory,
}

const UpdateProvidersDrawer = ({
  closeDrawer,
  isDrawerOpen,
  selectedProviders,
  setSelectedProviders,
}: UpdateProvidersDrawerProps & OrganisationDrawerProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const [drawerView, setDrawerView] = useState<UpdateProvidersDrawerViews>(UpdateProvidersDrawerViews.Menu)
  const [editingCategory, setEditingCategory] = useState<Category | Pick<Category, 'name'> | undefined>(undefined)

  const renderDrawerContent = () => {
    switch (drawerView) {
      case UpdateProvidersDrawerViews.Menu:
        return (
          <UpdateProviderMenu
            providersLength={selectedProviders.length}
            setDrawerView={setDrawerView}
            setEditCategory={setEditingCategory}
          />
        )
      case UpdateProvidersDrawerViews.CreateCategory:
        return (
          <CategoryDrawerContent
            setSelectedProviders={setSelectedProviders}
            providersUpdate={selectedProviders}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => setDrawerView(UpdateProvidersDrawerViews.Menu)}
            view={CategoryDrawerViews.CreateCategory}
          />
        )
      case UpdateProvidersDrawerViews.AssignCategoryOption:
        return (
          <CategoryDrawerContent
            providersUpdate={selectedProviders}
            setSelectedProviders={setSelectedProviders}
            category={editingCategory}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => setDrawerView(UpdateProvidersDrawerViews.Menu)}
            view={CategoryDrawerViews.AssignCategoryOptions}
          />
        )
      case UpdateProvidersDrawerViews.SetStandardCategory:
        return (
          <CategoryDrawerContent
            category={editingCategory}
            setSelectedProviders={setSelectedProviders}
            providersUpdate={selectedProviders}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => setDrawerView(UpdateProvidersDrawerViews.Menu)}
            view={CategoryDrawerViews.SetStandardCategory}
          />
        )
    }
  }

  const renderDrawerTitle = () => {
    switch (drawerView) {
      case UpdateProvidersDrawerViews.Menu:
        return formatMessage({ id: 'resourceCollections.general.categorize' }, { count: selectedProviders.length })
    }
  }

  return (
    <Drawer open={isDrawerOpen} closeDrawer={closeDrawer} title={renderDrawerTitle()}>
      {renderDrawerContent()}
    </Drawer>
  )
}

export default UpdateProvidersDrawer
