import PeriodField from '@app/src/components/CreateNewRequestModal/PeriodField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import { useQuestionnairePeriodOptions } from '@app/src/hooks/questionnairePeriodOptions'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationPeriod: React.FC = () => {
  const { formatMessage } = useIntl()
  const { watch, setValue, control } = useFormContext()
  const { wfStandardYearOptions } = useQuestionnairePeriodOptions()
  const reportType = watch(AUTOMATION_QUESTION_KEYS.REPORT_TYPE)
  if (reportType === ReportType.COMPANY) return <PeriodField />

  return (
    <Select
      options={wfStandardYearOptions}
      fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
      onInputChange={(e, value) => {
        if (!value) setValue(AUTOMATION_QUESTION_KEYS.DATE_YEAR, undefined)
      }}
      required
      enableAutoSelect
      name={AUTOMATION_QUESTION_KEYS.DATE_YEAR}
      control={control}
    />
  )
}
export default AutomationPeriod
