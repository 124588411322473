import { Grid, InputAdornment } from '@mui/material'
import ControlledNumberField from '@app/src/components/Form/ControlledNumberField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { getYears } from '@app/src/utils'
import { EDCI_REPORT_QUESTION_KEYS, FormData } from '.'

const EdciStep = () => {
  const { register, control, setValue } = useFormContext<FormData>()
  const { formatMessage } = useIntl()

  const getYearOptionLabel = (year: number) => {
    const isLastYear = year === new Date().getFullYear() - 1
    return `${String(year)}${isLastYear ? ` (${formatMessage({ id: `form.createRequest.lastReportingPeriod` })})` : ''}`
  }

  const DATE_YEAR_OPTIONS = useMemo(
    () =>
      getYears(10, -1).map(y => ({
        value: y,
        label: getYearOptionLabel(y),
      })),
    [],
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            name={EDCI_REPORT_QUESTION_KEYS.COMPANY_ID}
            label={formatMessage({ id: 'form.createRequest.edci.companyIdLabel' })}
            type="text"
            required
            onClear={(): void => setValue(EDCI_REPORT_QUESTION_KEYS.COMPANY_ID, '')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={register}
            fullWidth
            name={EDCI_REPORT_QUESTION_KEYS.FUND_ID}
            label={formatMessage({ id: 'form.createRequest.edci.fundIdLabel' })}
            type="text"
            required
            onClear={(): void => setValue(EDCI_REPORT_QUESTION_KEYS.FUND_ID, '')}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledNumberField
            control={control}
            name={EDCI_REPORT_QUESTION_KEYS.GP_OWNERSHIP}
            required
            label={formatMessage({ id: 'form.createRequest.edci.gpOwnershipLabel' })}
            onClear={(): void => setValue(EDCI_REPORT_QUESTION_KEYS.GP_OWNERSHIP, '')}
            isAllowed={({ floatValue }) => (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= 100}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledNumberField
            control={control}
            name={EDCI_REPORT_QUESTION_KEYS.FUND_OWNERSHIP}
            required
            label={formatMessage({ id: 'form.createRequest.edci.fundOwnershipLabel' })}
            onClear={(): void => setValue(EDCI_REPORT_QUESTION_KEYS.FUND_OWNERSHIP, '')}
            isAllowed={({ floatValue }) => (floatValue ?? 0) >= 0 && (floatValue ?? 0) <= 100}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            options={DATE_YEAR_OPTIONS}
            fieldLabel={formatMessage({ id: 'form.createRequest.edci.investmentYearLabel' })}
            onInputChange={(e, value) => {
              if (!value) setValue(EDCI_REPORT_QUESTION_KEYS.INVESTMENT_YEAR, undefined)
            }}
            required
            enableAutoSelect
            name={EDCI_REPORT_QUESTION_KEYS.INVESTMENT_YEAR}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            options={[
              {
                label: formatMessage({ id: 'form.createRequest.edci.private' }),
                value: formatMessage({ id: 'form.createRequest.edci.private' }),
              },
              {
                label: formatMessage({ id: 'form.createRequest.edci.public' }),
                value: formatMessage({ id: 'form.createRequest.edci.public' }),
              },
            ]}
            fieldLabel={formatMessage({ id: 'form.createRequest.edci.companyStructureLabel' })}
            onInputChange={(e, value) => {
              if (!value) setValue(EDCI_REPORT_QUESTION_KEYS.COMPANY_STRUCTURE, undefined)
            }}
            required
            enableAutoSelect
            name={EDCI_REPORT_QUESTION_KEYS.COMPANY_STRUCTURE}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            options={[
              {
                label: formatMessage({ id: 'form.createRequest.edci.venture' }),
                value: formatMessage({ id: 'form.createRequest.edci.venture' }),
              },
              {
                label: formatMessage({ id: 'form.createRequest.edci.growth' }),
                value: formatMessage({ id: 'form.createRequest.edci.growth' }),
              },
              {
                label: formatMessage({ id: 'form.createRequest.edci.buyout' }),
                value: formatMessage({ id: 'form.createRequest.edci.buyout' }),
              },
            ]}
            fieldLabel={formatMessage({ id: 'form.createRequest.edci.growthStageLabel' })}
            onInputChange={(e, value) => {
              if (!value) setValue(EDCI_REPORT_QUESTION_KEYS.GROWTH_STAGE, undefined)
            }}
            required
            enableAutoSelect
            name={EDCI_REPORT_QUESTION_KEYS.GROWTH_STAGE}
            control={control}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EdciStep
