import { InfoOutlined } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import { useConfirm } from 'material-ui-confirm'
import { FC, default as React } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { AnswerStatus, RequestItem } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import SkipQuestionOptions from './SkipQuestionOptions'

interface SkipQuestionSectionProps {
  requestItem: RequestItem
}

const SkipQuestionSection: FC<SkipQuestionSectionProps> = ({ requestItem }) => {
  const { errors, watch, clearErrors, setValue } = useFormContext()
  const { formatMessage } = useIntl()
  const { responseItemView, setResponseItemView } = useResponseItemContext()
  const confirm = useConfirm()

  if (requestItem.isRequired) return null

  const requestItemId = requestItem.id
  const cannotAnswer = `${requestItemId}.cannotAnswer`
  const answerStatus = `${requestItemId}.answerStatus`
  const answerStatusComment = `${requestItemId}.comment`

  const hasError = Boolean(errors?.[`${requestItem.id}`])
  const cannotAnswerWatch = watch(cannotAnswer)

  if (responseItemView !== ResponseItemView.SkipQuestion && !cannotAnswerWatch) {
    return (
      <Stack px={4} pb={2} direction="row" alignItems="center" justifyContent="flex-end">
        <Button
          onClick={() => {
            setResponseItemView(ResponseItemView.SkipQuestion)
            setValue(cannotAnswer, true)
            if (hasError) clearErrors(`${requestItem.id}`)
          }}
          data-testid="button-skip-this-question"
        >
          {formatMessage({ id: 'reporting.skipQuestion.skipThisQuestion' })}
        </Button>
      </Stack>
    )
  }

  const onCancel = (): void => {
    if (hasError) clearErrors(`${requestItem.id}`)
    setResponseItemView(ResponseItemView.SuggestedAnswerSkipped)
    setValue(cannotAnswer, false)
    setValue(answerStatus, AnswerStatus.Answered)
    setValue(answerStatusComment, '')
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2} px={4} py={2} bgcolor="grey.100">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'reporting.skipQuestion.skipThisQuestion' })}
            </Typography>
            <Typography color="textSecondary">
              {formatMessage({ id: 'reporting.skipQuestion.selectOption' })}
            </Typography>
          </Stack>
          <Button
            size="small"
            onClick={() =>
              confirm({
                title: formatMessage({ id: 'reporting.skipQuestion.aboutTitle' }),
                content: formatMessage({ id: 'reporting.skipQuestion.aboutDescription' }, { br }),
                confirmationText: formatMessage({ id: 'general.close' }),
                confirmationButtonProps: { variant: 'text' },
                hideCancelButton: true,
              })
            }
            startIcon={<InfoOutlined />}
          >
            {formatMessage({ id: 'general.learnMore' })}
          </Button>
        </Stack>
        <SkipQuestionOptions
          requestItemId={requestItem.id}
          cannotAnswer={cannotAnswer}
          answerStatus={answerStatus}
          answerStatusComment={answerStatusComment}
          hasError={hasError}
        />
        <Stack direction="row" spacing={1}>
          <Button variant="text" onClick={onCancel} data-testid="cancel-skip-question">
            {formatMessage({ id: 'general.cancel' })}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SkipQuestionSection
