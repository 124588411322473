import { AlignHorizontalLeftOutlined, BarChartOutlined, PieChartOutlined, ShowChartOutlined } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import React from 'react'
import { useIntl } from 'react-intl'

interface ChartTypeSelectorProps {
  selectedChartType?: ChartType
  setSelectedChartType: React.Dispatch<React.SetStateAction<ChartType>>
  allowedChartTypes?: ChartType[]
}

export enum ChartType {
  Pie = 'Pie',
  Line = 'Line',
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
}

const CHART_TYPE_TEXT_IDS: Record<ChartType, string> = {
  [ChartType.Pie]: 'statistics.dataInsights.pieType',
  [ChartType.Line]: 'statistics.dataInsights.lineType',
  [ChartType.Vertical]: 'statistics.dataInsights.verticalType',
  [ChartType.Horizontal]: 'statistics.dataInsights.horizontalType',
}

interface ChartTypeIconProps {
  chartType: ChartType
}

const ChartTypeIcon: React.FC<ChartTypeIconProps> = ({ chartType }) => {
  switch (chartType) {
    case ChartType.Pie:
      return <PieChartOutlined />
    case ChartType.Line:
      return <ShowChartOutlined />
    case ChartType.Vertical:
      return <BarChartOutlined />
    case ChartType.Horizontal:
      return <AlignHorizontalLeftOutlined />
  }
}

const ChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
  selectedChartType = ChartType.Horizontal,
  setSelectedChartType,
  allowedChartTypes = [ChartType.Pie, ChartType.Line, ChartType.Vertical, ChartType.Horizontal],
}) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const selectedOptionText = CHART_TYPE_TEXT_IDS[selectedChartType]

  const menuItemClick = (chartType: ChartType) => {
    setSelectedChartType(chartType)
    setOpen(false)
  }

  const toggleMenuOpen = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const toggleChartType = () => {
    setSelectedChartType(prevChartType => {
      const currentIndex = allowedChartTypes.indexOf(prevChartType)
      const nextIndex = currentIndex === allowedChartTypes.length - 1 ? 0 : currentIndex + 1
      return allowedChartTypes[nextIndex]
    })
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        <Button startIcon={<ChartTypeIcon chartType={selectedChartType} />} sx={{ pl: 3 }} onClick={toggleChartType}>
          {formatMessage({ id: selectedOptionText })}
        </Button>
        <Button size="small" onClick={toggleMenuOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {allowedChartTypes.map(chartType => (
                    <MenuItem
                      key={chartType}
                      selected={chartType === selectedChartType}
                      onClick={() => menuItemClick(chartType)}
                    >
                      <ListItemIcon>
                        <ChartTypeIcon chartType={chartType} />
                      </ListItemIcon>
                      {formatMessage({ id: CHART_TYPE_TEXT_IDS[chartType] })}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ChartTypeSelector
