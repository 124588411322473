import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import AutomationCard from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationCard'
import { AutomationFormData } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { getPeriodDisplayName } from '@app/src/utils'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationSummary: React.FC = () => {
  const { formatMessage } = useIntl()
  const { getValues } = useFormContext<AutomationFormData>()
  const data = getValues()
  const templateNames = data.templates.map(t => t.title)

  const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
    dateType: data.dateType,
    datePeriod: data.datePeriod,
    dateYear: data.dateYear,
  })
  const periodDisplayName = getPeriodDisplayName(periodStartsAt, periodEndsAt)

  return (
    <>
      <Box mb={4}>
        <AutomationCard requestAutomationType={data.automationType} categoryFilters={data.categoryFilters} />
      </Box>
      <Stack spacing={2}>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'form.createAutomation.summary.automationDetail' })}
        </Typography>
        <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
          <Typography variant="body1">{formatMessage({ id: 'resourceTypes.questionnaires' })}</Typography>
          {templateNames.map(template => (
            <Chip key={template} label={template} />
          ))}
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="body1">{formatMessage({ id: 'reporting.period' })}</Typography>
          <Chip label={periodDisplayName} />
        </Box>
        {data.deadlineOffsetDays && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body1">{formatMessage({ id: 'reporting.deadline' })}</Typography>
            <Chip
              label={formatMessage(
                {
                  id:
                    data.automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
                      ? 'form.createAutomation.deadlineOffset.optionTextForEveryNewProvider'
                      : 'form.createAutomation.deadlineOffset.optionTextForProvidersInCategory',
                },
                { days: data.deadlineOffsetDays },
              )}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}
export default AutomationSummary
