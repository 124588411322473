import AssessmentQuestionnaireLinkCell from '@app/src/components/Table/Cells/AssessmentQuestionnaireLinkCell'
import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import FlagIndicatorCell from '@app/src/components/Table/Cells/FlagIndicatorCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import { ActivationType } from '@app/src/types/flags'
import { Question, QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import ReportMenuCell from './ReportMenuCell'

export const countUniqueActiveItems = (flagRules?: Question['reportFlagRules']): number => {
  if (!flagRules?.length) {
    return 0
  }

  const uniqueItemIds = new Set<number>()

  flagRules?.forEach(flagRule => {
    if (flagRule && flagRule.activationType === ActivationType.Active) {
      uniqueItemIds.add(flagRule.questionId)
    }
  })

  return uniqueItemIds.size
}

interface StandardReportsRowProps {
  row: QuestionnaireTemplate
}

export const StandardReportsRow: React.FC<StandardReportsRowProps> = ({ row: requestTemplate }) => {
  const { formatMessage } = useIntl()
  const flagRules = requestTemplate.sections?.flatMap(s => s.questions).flatMap(q => q.reportFlagRules ?? []) ?? []
  const flagCount = countUniqueActiveItems(flagRules)
  const history = useHistory()
  const { assessments } = useFlags()

  return (
    <TableRow>
      <RequestCell
        hideAvatar
        title={requestTemplate.title || formatMessage({ id: 'general.noTitle' })}
        onClick={() =>
          history.push({
            pathname: generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questionnaires,
              configurationsSubPage: requestTemplate.id,
            }),
            search: qs.stringify({ isStandard: true }),
          })
        }
      />

      {assessments && (
        <AssessmentQuestionnaireLinkCell assessmentQuestionnaireLink={requestTemplate.assessmentQuestionnaireLink} />
      )}
      <ChipCell
        tooltipText={formatMessage({
          id: `questionnaireConfig.publicQuestionnaireExplanation`,
        })}
        value={formatMessage({ id: 'schemas.requestTemplate.Standard' })}
      />
      <FlagIndicatorCell count={flagCount} disabled={!flagCount} bgcolor="communication.main" />
      <ReportMenuCell questionnaireTemplate={requestTemplate} isStandard />
    </TableRow>
  )
}

export default StandardReportsRow
