import { TableRow } from '@mui/material'
import { TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import React from 'react'
import { useIntl } from 'react-intl'
import { Claim } from '@app/src/types/organizations'

interface ClaimsReviewHeaderProps {
  selectedClaimIds?: number[]
  setSelectedClaimIds: React.Dispatch<React.SetStateAction<number[]>>
  claimsInPage?: Claim[]
}

const ClaimsReviewHeader: React.FC<ClaimsReviewHeaderProps> = ({
  setSelectedClaimIds,
  claimsInPage,
  selectedClaimIds,
}) => {
  const { formatMessage } = useIntl()

  const isChecked = claimsInPage?.every(claim => selectedClaimIds?.find(selectedClaim => selectedClaim === claim.id))

  const handleCheckAllCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedClaimIds(prev => [
        ...prev,
        ...(claimsInPage ?? [])
          .filter(cInPage => !prev.some(prevClaimId => prevClaimId === cInPage.id))
          .map(claimInPage => claimInPage.id ?? 0),
      ])
    } else {
      setSelectedClaimIds([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckAllCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell label={formatMessage({ id: 'claim.claimId' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.name' })} minWidth={TableCellWidth.LARGE} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.country' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.websiteAddress' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.vatNumber' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.referralCompanyName' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.creatorName' })} />
      <TableHeaderCell label={formatMessage({ id: 'claim.creatorEmail' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.createdAt' })} />
    </TableRow>
  )
}

export default ClaimsReviewHeader
