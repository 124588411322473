import _IntervalChartIconFive from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals/IntervalChartIconFive'
import _IntervalChartIconFour from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals/IntervalChartIconFour'
import _IntervalChartIconOne from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals/IntervalChartIconOne'
import _IntervalChartIconThree from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals/IntervalChartIconThree'
import _IntervalChartIconTwo from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals/IntervalChartIconTwo'

export const FiveIntervalChartIconOne = _IntervalChartIconOne
export const FiveIntervalChartIconTwo = _IntervalChartIconTwo
export const FiveIntervalChartIconThree = _IntervalChartIconThree
export const FiveIntervalChartIconFour = _IntervalChartIconFour
export const FiveIntervalChartIconFive = _IntervalChartIconFive
