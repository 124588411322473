import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { FacetItem } from '@app/src/pages/ResourceCollection/index'
import { insertIf } from '@app/src/utils/helpersTs'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts-for-react'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export type AssessmentOverViewGraphDatapoint = {
  value: number
  level: string
  name: string
}

const seriesForLevel = (value = 0, color: string, name: string) => {
  return {
    name,
    value,
    emphasis: {
      focus: 'self',
    },
    itemStyle: {
      color: color,
      borderType: 'solid',
      borderWidth: 1,
      borderColor: 'white',
    },
  }
}

const getAxisForSelectedChartType = (
  selectedChartType: ChartType,
  categoryAxis: { type: string; data: string[] },
  valueAxis: { type: string },
): { xAxis?: XAXisOption; yAxis?: YAXisOption } => {
  if (selectedChartType === ChartType.Horizontal) {
    return {
      xAxis: valueAxis as XAXisOption,
      yAxis: categoryAxis as YAXisOption,
    }
  }
  if (selectedChartType === ChartType.Vertical)
    return {
      xAxis: categoryAxis as XAXisOption,
      yAxis: valueAxis as YAXisOption,
    }

  return {
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
  }
}

const getEchartsTypeFromSelectedChartType = (selectedChartType: ChartType) => {
  switch (selectedChartType) {
    case ChartType.Pie:
      return 'pie'
    default:
      return 'bar'
  }
}

const useAssessmentOverviewGraph = (
  levels: Record<number, FacetItem | undefined>,
  selectedChartType: ChartType,
  has5Levels: boolean,
): EChartsOption => {
  const { formatMessage } = useIntl()
  const { palette, typography } = useTheme()

  const dataSeries = [
    seriesForLevel(
      levels[1]?.count,
      has5Levels ? palette.semantic.error : palette.error.dark,
      formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 1 }),
    ),
    seriesForLevel(
      levels[2]?.count,
      has5Levels ? palette.error.dark : palette.warning.dark,
      formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 2 }),
    ),
    seriesForLevel(
      levels[3]?.count,
      has5Levels ? palette.warning.dark : palette.success.dark,
      formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 3 }),
    ),
    ...insertIf(has5Levels, [
      seriesForLevel(
        levels[4]?.count,
        palette.success.dark,
        formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 4 }),
      ),
      seriesForLevel(
        levels[5]?.count,
        palette.brandDecorative.emerald,
        formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 5 }),
      ),
    ]).flat(),
  ]

  const labelSeries = [
    formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 1 }),
    formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 2 }),
    formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 3 }),
    ...insertIf(has5Levels, [
      formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 4 }),
      formatMessage({ id: 'baseAssessment.totalLevel' }, { value: 5 }),
    ]).flat(),
  ]

  const labelAxis = {
    type: 'category',
    data: labelSeries,
  }

  const dataAxis = {
    type: 'value',
  }

  return useMemo(
    () => ({
      ...getAxisForSelectedChartType(selectedChartType, labelAxis, dataAxis),
      series: [
        {
          data: dataSeries,
          type: getEchartsTypeFromSelectedChartType(selectedChartType),
        },
      ],
      legend: {
        icon: 'circle',
        bottom: 10,
        left: 0,
        textStyle: {
          fontFamily: typography.fontFamily,
          fontSize: typography.body2.fontSize,
        },
      },
    }),
    [seriesForLevel, selectedChartType, labelAxis, dataAxis],
  )
}

export default useAssessmentOverviewGraph
