import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import { ListItem, ListItemAvatar, ListItemText, Skeleton, TableCell, TableRow } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity } from '@app/src/wf-constants'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'

interface OrganizationAssessmentProps {
  row: OrganizationAssessmentSettings
}

interface OrganizationAssessmentSettingSaveModel {
  assessmentTemplateId: number
  isActivated: boolean
}

export const AssessmentSettingsRow: React.FC<OrganizationAssessmentProps> = ({ row }) => {
  const { formatMessage } = useIntl()
  const [isActivated, setIsActivated] = useState(row.isActivated)
  const { mutateAsync, isLoading } = useUpdateResource<OrganizationAssessmentSettingSaveModel>()
  const { showSnackbar } = useSnackbar()
  const { trackEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const resourceType = useCurrentProviderType({ isPlural: true })
  const handleChange = async (row: OrganizationAssessmentSettings) => {
    setIsActivated(!row.isActivated)
    await mutateAsync(
      {
        url: endpoints.organizationAssessmentSettings,
        body: {
          assessmentTemplateId: row.assessmentTemplate.id,
          isActivated: !row.isActivated,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              {
                id: 'notifications.successfullyUpdatedAssessment',
              },
              { name: row.assessmentTemplate.name },
            ),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Analyze.Assessment.Toggled,
            eventProps: {
              assessment_name: row.assessmentTemplate.name,
              assessment_id: row.assessmentTemplate.id,
              enabled: !row.isActivated,
            },
          })

          queryClient.invalidateQueries(FetchKey.OrganizationAssessmentSettings)
          queryClient.invalidateQueries(FetchKey.Account)
        },
        onError: () => {
          setIsActivated(row.isActivated)
          showSnackbar({
            message: formatMessage({
              id: 'notifications.toggleAssessmentSettingsFailed',
            }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }

  return (
    <>
      <TableRow>
        <SwitchCell
          sx={{ ml: 2 }}
          disabled={isLoading}
          checked={isActivated}
          onChange={() => handleChange(row)}
          label={
            isLoading ? (
              <Skeleton width={10} height={20} variant="text" />
            ) : isActivated ? (
              formatMessage({ id: 'automations.on' })
            ) : (
              formatMessage({ id: 'automations.off' })
            )
          }
        />
        <TableCell>
          <ListItem component="div">
            <ListItemAvatar>
              <Avatar
                size={AvatarSize.XXL}
                sx={({ palette }) => ({
                  backgroundColor: isActivated ? palette.secondary.main : palette.grey[400],
                  color: isActivated ? palette.common.white : palette.grey[600],
                  mr: 2,
                })}
              >
                <FactCheckOutlinedIcon fontSize="large" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={row.assessmentTemplate.name}
              secondary={formatMessage(
                { id: 'assessmentConfig.assessmentDescription' },
                { providerType: resourceType },
              )}
            />
          </ListItem>
        </TableCell>
      </TableRow>
    </>
  )
}

export default AssessmentSettingsRow
