import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState/EmptyState'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import OverviewCardsSection from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Overview/OverviewCardsSection'
import OverviewCompanyProgressSection from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Overview/OverviewCompanyProgressSection'
import OverviewRequestProgressSection from '@app/src/pages/ResourceCollection/Collections/ManageRequests/Overview/OverviewRequestProgressSection'
import RequestsFilters from '@app/src/pages/ResourceCollection/Filters/RequestsFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { RawExportPayload } from '@app/src/types/filter'
import { QuestionnaireStatistics } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { ArrowForwardOutlined, ListAltOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface OverviewManageRequestsSceneProps {
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  relatedContentFilter?: FilterGroup[]
  setCreateDialogOpen?: () => void
}

const allowedFilters = [
  'template.id',
  'status',
  'periodType',
  'provider.websiteAddress',
  'provider.country.id',
  'provider.vatNumber',
  'provider.organization.id',
  'request.responseExternalStatus',
  'request.responseInternalStatus',
  'isActivated',
  'deadline',
  'createdAt',
  'activatedAt',
  'periodName',
  'provider.providerApprovalStatus',
  'provider.priority',
  'provider.finalRiskRating',
  'provider.activityStatus',
  'provider.ownerUserId',
  'provider.supplierUsage',
  'provider.tier',
  'provider.categoryOptions.id',
  'template.tags.tag',
]

const OverviewManageRequestsScene: React.FC<OverviewManageRequestsSceneProps> = ({
  actionButtons,
  relatedContentFilter,
  tabs,
  setCreateDialogOpen,
}) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)

  const { data: overviewStats, isLoading } = useFetchPost<QuestionnaireStatistics, RawExportPayload>({
    key: FetchKey.Request,
    endpoint: endpoints.overviewStatistics,
    payload: {
      filter: userFilters,
    },
  })

  const isEmptyState = !overviewStats?.totalRequests

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'navbar.manageRequests' })}
      tabs={tabs}
      actionButtons={actionButtons}
      filter={<RequestsFilters implicitFilters={relatedContentFilter} allowedFilters={allowedFilters} />}
      enableScroll
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
        </Box>
      ) : isEmptyState ? (
        <EmptyState
          iconComponent={ListAltOutlined}
          title={formatMessage({ id: 'table.manageRequests.requestsEmptyState.title' })}
          description={formatMessage({ id: 'table.manageRequests.requestsEmptyState.description' }, { br })}
          sx={{ my: 2 }}
        >
          {setCreateDialogOpen && (
            <Button
              variant="outlined"
              endIcon={<ArrowForwardOutlined fontSize="small" />}
              onClick={setCreateDialogOpen}
            >
              {formatMessage({ id: 'table.manageRequests.requestsEmptyState.action' })}
            </Button>
          )}
        </EmptyState>
      ) : (
        <Grid container spacing={2} sx={{ pr: 10, py: 2 }}>
          <Grid item xs={12}>
            <OverviewCardsSection relatedContentFilter={relatedContentFilter} overviewStats={overviewStats} />
          </Grid>

          <Grid item xs={6}>
            <StatisticsCard title="" justifyContent="left" withBorder>
              <OverviewCompanyProgressSection allowedFilters={allowedFilters} />
            </StatisticsCard>
          </Grid>

          <Grid item xs={6}>
            <StatisticsCard title="" justifyContent="left" withBorder>
              <OverviewRequestProgressSection allowedFilters={allowedFilters} />
            </StatisticsCard>
          </Grid>
        </Grid>
      )}
    </ResourceCollectionScene>
  )
}

export default OverviewManageRequestsScene
