import { InfoOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Link } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import { OrganizationSettings } from '@app/src/wf-constants'

const AutomationAlert: React.FC = () => {
  const { formatMessage } = useIntl()
  const settings = useOrganizationSettings()

  if (settings.isLoading || settings.isFetching || !settings.get(OrganizationSettings.AutoAcceptValueChainResponses))
    return null

  return (
    <Alert
      severity="info"
      action={
        <Box display="flex" alignItems="center" height="100%">
          <Button
            href="https://helpdesk.worldfavor.com/en/articles/8529469-automated-requests#h_c9114ea27f"
            target="_blank"
            component={Link}
          >
            {formatMessage({ id: 'automations.productMapping.alert.learnMore' })}
          </Button>
        </Box>
      }
      icon={<InfoOutlined />}
    >
      <AlertTitle sx={{ m: 0 }}>{formatMessage({ id: 'automations.productMapping.alert.title' })}</AlertTitle>
    </Alert>
  )
}

export default AutomationAlert
