import { AttachFile } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

interface Props {
  setFile: React.Dispatch<React.SetStateAction<string | Blob>>
}

const FileUploadField: React.FC<Props> = ({ setFile }) => {
  const [fileName, setFileName] = useState<string | undefined>()
  const { formatMessage } = useIntl()

  const _onDrop: DropzoneOptions['onDrop'] = acceptedFiles => {
    setFile(acceptedFiles[0])
    setFileName(acceptedFiles[0].name)
  }
  const onDrop: DropzoneOptions['onDrop'] = useCallback(_onDrop, [setFile])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const displayValue = isDragActive
    ? formatMessage({ id: 'reporting.dropFile' })
    : fileName || formatMessage({ id: 'reporting.uploadFile' })

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} data-testid="upload-file" />
      <Button variant="outlined" sx={{ maxWidth: 250 }} endIcon={<AttachFile color="secondary" />}>
        <Typography noWrap variant="button">
          {displayValue}
        </Typography>
      </Button>
    </Box>
  )
}

export default FileUploadField
