import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost } from '@app/src/api/fetchHooks'
import useExportEchartsToPDF from '@app/src/components/ExportToPDF/useExportEchartsToPdf'
import FilterDisplayChip from '@app/src/components/FilterDisplayChip'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { useAmplitudeTimeSpentTracking } from '@app/src/hooks/useAmplitudeTimeSpentTracking'
import DataHubInsights from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubInsights'
import { useDataInsightsModal } from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalContext'
import DatahubDataInsightsFilters from '@app/src/pages/ResourceCollection/Filters/DatahubDataInsightsFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { getCurrentTimestamp } from '@app/src/utils'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { BarChartOutlined, ListAltOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { StatisticsForQuestionPerPeriod } from '../../DataHubScene'
import DataHubInsightsDetails from './DataHubInsightsDetails'
import DataHubInsightsResponseRate from './DataHubInsightsResponseRate'

const questionFilters = [
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.questionnaireTemplateId',
].map(q => q.toLowerCase())

type DataHubInsightsSceneProps = {
  tabs: ResourceCollectionSceneProps['tabs']
}

export enum InsightsSubTabs {
  INSIGHTS,
  SUMMARY,
  DETAILS,
}

const DataHubInsightsScene: React.FC<DataHubInsightsSceneProps> = ({ tabs }) => {
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()
  const [activeTab, setActiveTab] = React.useState<InsightsSubTabs>(InsightsSubTabs.INSIGHTS)
  const appliedFiltersRef = useRef(null)

  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { generatePDF, isGeneratingPdf } = useExportEchartsToPDF()
  const { echartsRefs, clearEchartsRefOverIndex } = useDataInsightsModal()

  const anyQuestionFilterSet = useMemo(
    () => userFilters.some(filter => questionFilters.includes(filter.name.toLowerCase())),
    [userFilters],
  )

  const isDetailsTabActive = activeTab === InsightsSubTabs.DETAILS

  useAmplitudeTimeSpentTracking({
    eventName: AmplitudeTrackingEvents.Analyze.DataHub.Insights.LeftPage,
    timerStarted: anyQuestionFilterSet,
  })

  const { data: questions, isLoading } = useFetchPost<StatisticsForQuestionPerPeriod[], FilterGroup[]>({
    payload: userFilters,
    endpoint: endpoints.dataInsightsByQuestions,
    key: [FetchKey.ResponseItemDataInsights],
    options: { enabled: anyQuestionFilterSet },
  })

  useEffect(() => {
    clearEchartsRefOverIndex(questions?.length ?? 0)
  }, [clearEchartsRefOverIndex, questions?.length])

  const exportChartsToPdf = async () => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.DataHub.Insights.PdfExport,
    })
    const timestamp = getCurrentTimestamp()
    const pdfFileName = `DataInsights_${timestamp}.pdf`

    const dataToExport =
      questions?.flatMap((question, index) => {
        const echartsRef = echartsRefs?.[index]?.current
        if (!echartsRef) return []
        return { title: question.title, echarts: echartsRef }
      }) ?? []
    await generatePDF(pdfFileName, dataToExport, appliedFiltersRef)
  }

  const disableExport =
    !anyQuestionFilterSet || !questions?.length || echartsRefs.every(r => !r?.current) || isDetailsTabActive

  return (
    <ResourceCollectionScene
      title={formatMessage({
        id: 'schemas.responseItem.responseItems',
      })}
      tabs={tabs}
      filter={<DatahubDataInsightsFilters allowedFilters={dataHubAllowedFilters} />}
      actionButtons={[
        {
          label: formatMessage({ id: 'resourceCollections.general.exportToPdf' }),
          variant: 'outlined' as const,
          onClick: exportChartsToPdf,
          disabled: disableExport,
          loading: isGeneratingPdf,
        },
      ]}
      enableScroll
    >
      <Box display="flex" py={2} justifyContent="space-between">
        <Stack alignItems="center" direction="row">
          <DataHubInsightsResponseRate anyQuestionFilterSet={anyQuestionFilterSet} />
        </Stack>
        <Stack alignItems="center" direction="row">
          <ButtonGroup sx={{ mr: 4 }}>
            <Button
              startIcon={<BarChartOutlined />}
              onClick={() => setActiveTab(InsightsSubTabs.INSIGHTS)}
              variant={activeTab === InsightsSubTabs.INSIGHTS ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'resourceTypes.insight' })}
            </Button>
            <Button
              startIcon={<BarChartOutlined />}
              onClick={() => setActiveTab(InsightsSubTabs.SUMMARY)}
              variant={activeTab === InsightsSubTabs.SUMMARY ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'general.summary' })}
            </Button>
            <Button
              startIcon={<ListAltOutlined />}
              onClick={() => setActiveTab(InsightsSubTabs.DETAILS)}
              variant={isDetailsTabActive ? 'contained' : 'outlined'}
            >
              {formatMessage({ id: 'general.details' })}
            </Button>
          </ButtonGroup>
        </Stack>
      </Box>
      {isDetailsTabActive ? (
        <DataHubInsightsDetails />
      ) : (
        <DataHubInsights questions={questions} isLoading={isLoading} activeTab={activeTab} />
      )}
      {Boolean(userFilters?.length) && (
        <Box ref={appliedFiltersRef}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Stack direction="row" spacing={1} my={2}>
                <Typography whiteSpace="nowrap" mt={0.5}>
                  Filters applied:
                </Typography>
                <Stack direction="row" ml={-1} flexWrap="wrap">
                  {userFilters.map(filter => (
                    <FilterDisplayChip
                      key={`${filter.name}-${filter.filters[0].operator}`}
                      filterName={filter.name}
                      filterValue={filter.filters[0].value}
                      operator={filter.filters[0].operator}
                    />
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </ResourceCollectionScene>
  )
}

export default DataHubInsightsScene
