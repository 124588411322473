import React from 'react'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell from './ValueCell'
import { ChipCellProps } from '@app/src/components/Table/Cells/ChipCell'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { Chip } from '@mui/material'

export type AssessmentTemplateCellProps = {
  assessmentQuestionnaireLink?: QuestionnaireTemplate['assessmentQuestionnaireLink']
} & ChipCellProps

const AssessmentQuestionnaireLinkCell: React.FC<AssessmentTemplateCellProps> = ({
  assessmentQuestionnaireLink,
  disableCell,
  ...props
}) => {
  if (!assessmentQuestionnaireLink) return <ValueCell />
  return (
    <ParentCellOrFragment disableCell={disableCell}>
      {assessmentQuestionnaireLink.map(aql => (
        <Chip key={aql.id} label={aql.assessmentTemplate?.name ?? '-'} {...props} />
      ))}
    </ParentCellOrFragment>
  )
}

export default AssessmentQuestionnaireLinkCell
