import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewShareWithAllInfo from '@app/src/components/Drawer/Views/DrawerViewShareWithAllInfo'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import LoadingButton from '@app/src/components/LoadingButton'
import Avatar from '@app/src/components/Ui/Avatar'
import { useUpdateSharing } from '@app/src/hooks/updateSharing'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Inquiry, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { Share, VisibilityOff } from '@mui/icons-material'
import { Box, ButtonBase, CircularProgress, Divider, Link, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ShareWithWfNetworkListItem from './ShareWithNetworkListItem'

interface SubscribersPanelProps {
  inquiries: Inquiry[]
  isFetchingInquiries: boolean
}

const SubscribersPanel: React.FC<SubscribersPanelProps> = ({ inquiries, isFetchingInquiries }) => {
  const { formatMessage } = useIntl()
  const { currentAccess, isCurrentAccessLoading, request } = useReport()
  const { openDrawer } = useDrawer()
  const { formatValueToDate } = useGetFormattedDate()

  const isWorldfavorStandard = request?.creatorOrganizationId === WF_ORGANIZATION_ID

  const { updateSharing: updateSharingCall, isUpdatingSharing } = useUpdateSharing()

  const getSubtitle = (inquiry: Inquiry, currentlyHasAccess: boolean) => {
    if (request?.template?.questionnaireTemplateType === QuestionnaireTypeEnum.Regular) return ''

    if (!currentlyHasAccess) {
      return formatMessage(
        { id: 'reporting.sharing.accessRequested' },
        { date: formatValueToDate({ value: inquiry.createdAt }) },
      )
    }

    if (!inquiry.deadline) return formatMessage({ id: 'reporting.sharing.noDeadline' })

    return formatMessage(
      { id: 'reporting.sharing.responseDeadline' },
      { date: formatValueToDate({ value: inquiry.deadline }) },
    )
  }

  const updateSharing = async (organizationIdToBeAdded: number) => {
    const organizationIdsToShareWith = [...(currentAccess?.organizationIds ?? []), organizationIdToBeAdded]
    await updateSharingCall({ requestId: request?.id ?? 0, organizationIdsToShareWith })
  }

  const subscribers = inquiries.map(inq => {
    const currentlyHasAccess = currentAccess?.organizationIds.includes(inq.creatorOrganizationId) ?? false
    return {
      organizationId: inq.creatorOrganizationId,
      organizationName: inq.creatorOrganization.name,
      currentlyHasAccess: currentlyHasAccess,
      subtitle: getSubtitle(inq, currentlyHasAccess),
      createdAt: inq.createdAt,
      deadline: inq.deadline,
      imageUrl: inq.creatorOrganization?.image?.url,
    }
  })

  if (isCurrentAccessLoading || isFetchingInquiries) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    )
  }

  if (!subscribers.length && !isWorldfavorStandard) {
    return (
      <EmptyState
        iconComponent={Share}
        title={formatMessage({ id: 'reporting.sharing.noCompaniesToShareWithYet' })}
        variant={EmptyStateVariant.Small}
        sx={{ mt: 2 }}
      />
    )
  }

  return (
    <>
      {isWorldfavorStandard && (
        <>
          <ShareWithWfNetworkListItem
            isChecked={currentAccess?.isAllAccess}
            secondaryText={
              <Link
                component={ButtonBase}
                onClick={() => openDrawer(<DrawerViewShareWithAllInfo subTitle={request.title} />)}
                variant="caption"
                color="textSecondary"
              >
                {formatMessage({ id: 'general.learnMore' })}
              </Link>
            }
          />
          <Divider variant="fullWidth" />
        </>
      )}

      {subscribers.map(s => (
        <React.Fragment key={s.organizationId}>
          <ListItem
            secondaryAction={
              !s.currentlyHasAccess && (
                <LoadingButton
                  variant="contained"
                  onClick={() => updateSharing(s.organizationId)}
                  loading={isUpdatingSharing}
                >
                  {formatMessage({ id: 'general.accept' })}
                </LoadingButton>
              )
            }
            alignItems="flex-start"
            disablePadding
            sx={{ py: 2 }}
          >
            <ListItemAvatar>
              {s.currentlyHasAccess ? (
                <CompanyAvatar imageUrl={s.imageUrl} organizationName={s.organizationName} />
              ) : (
                <Avatar>
                  <VisibilityOff />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText primary={s.organizationName} secondary={s.subtitle} />
          </ListItem>

          <Divider variant="fullWidth" />
        </React.Fragment>
      ))}
    </>
  )
}

export default SubscribersPanel
