import { Box, Button, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import React, { useState } from 'react'
import { DownloadOutlined } from '@mui/icons-material'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { FetchKey, useFileDownload } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import endpoints from '@app/src/api/endpoints'
import { NotificationSeverity } from '@app/src/wf-constants'
import RequestError from '@app/src/errors/RequestError'
import FileUploadField from '@app/src/components/FileUploadField'
import { useQueryClient } from 'react-query'

type DrawerViewImportSpendProps = {
  solution?: string
  providerType: string
  allowedFilters: string[]
} & Omit<DrawerViewProps, 'title'>

const DrawerViewImportSpend: React.FC<DrawerViewImportSpendProps> = ({
  solution,
  providerType,
  allowedFilters,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const { handleFileDownloadWithFilters } = useFileDownload()
  const queryClient = useQueryClient()
  const [importFile, setImportFile] = useState<string | Blob>('')
  const { mutate: uploadImportFile, isLoading } = useCreateResource<FormData>()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()

  const handleFileDownload = async () => {
    await handleFileDownloadWithFilters(endpoints.providerSpendHistoryImportFileDownload, {
      filter: userFilters,
      include: [],
    })
  }

  const handleImport = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', importFile)

    uploadImportFile(
      { url: endpoints.providerSpendHistoryImport, body: data },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.notification.importSpend' }, { providerType }),
            severity: NotificationSeverity.info,
          })

          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: e => {
          showErrorNotification({ requestError: e as RequestError })
        },
      },
    )
  }

  return (
    <DrawerView
      buttons={[
        {
          label: formatMessage({ id: 'import.import' }),
          variant: 'contained',
          onClick: handleImport,
          disabled: !importFile,
          loading: isLoading,
        },
      ]}
      title={formatMessage({ id: 'import.importSpend' })}
      {...props}
    >
      <Box flexGrow={1} p={1} mt={1}>
        <Box m={1} p={1}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepOne' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.stepOneDescription' })}
          </Typography>
          <Box pt={2}>
            <Button variant="contained" startIcon={<DownloadOutlined />} onClick={handleFileDownload}>
              <Typography variant="body1" gutterBottom>
                {formatMessage({ id: 'import.templateDownload' })}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box m={1} p={1}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepTwo' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepTwoDescription' })}
          </Typography>
        </Box>
        <Box m={1} p={1}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepThree' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepThreeDescription' })}
          </Typography>
          <Box pt={2}>
            <FileUploadField setFile={setImportFile} />
          </Box>
        </Box>
        <Box m={1} p={1}>
          <Typography variant="subtitle1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepFour' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatMessage({ id: 'import.importSpendStepFourDescription' })}
          </Typography>
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewImportSpend
