import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewCountryRisk from '@app/src/components/Drawer/Views/DrawerViewCountryRisk'
import { getMapPieces } from '@app/src/pages/Dashboards/SourcingDashboard/CountryRisks/CountryRiskMap'
import { Provider, ProviderLinkStatus, RiskTableView } from '@app/src/types/organizations'
import { Country, Risk, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { MapChart } from 'echarts/charts'
import {
  GeoComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { GeoJSONSourceInput } from 'echarts/types/src/coord/geo/geoTypes'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { UseQueryResult, useQuery } from 'react-query'

const useWorldMap = (): UseQueryResult<GeoJSONSourceInput> =>
  useQuery('map', async () => await fetch('/world-map-light.json').then(response => response.text()))

export enum RiskLocationEnum {
  Headquarter = 'headquarter',
  OwnOperations = 'ownOperations',
  Subsupplier = 'subsupplier',
}

export type ProviderRisk = {
  name: string
  provider: Provider
  countryName: string
  countryCode: string
  value?: number
  riskType: RiskLocationEnum
  riskStatus?: RiskStatus
  risk?: Risk
}

export type ExposedCountry = Omit<ProviderRisk, 'provider' | 'countryCode' | 'risk' | 'riskType'>

type ExposedCountryRiskMapProps = {
  activeRisk?: RiskType
  selectedRiskId: number
  riskScreening: RiskTableView[]
  riskLevelFilter?: Array<string>
  height?: number
}

const createRiskObject = (
  parentObject: Provider,
  country: Country,
  riskType: RiskLocationEnum,
  risk?: Risk,
): ProviderRisk => ({
  name: country.countryCode.toUpperCase(),
  provider: parentObject,
  countryName: country.name,
  countryCode: country.countryCode,
  value: risk?.value,
  riskType,
  riskStatus: risk?.status,
  risk,
})

const ExposedCountryRiskMap: React.FC<ExposedCountryRiskMapProps> = ({
  activeRisk,
  selectedRiskId,
  riskScreening,
  riskLevelFilter,
  height = 500,
}) => {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    SVGRenderer,
    LegendComponent,
    GeoComponent,
    VisualMapComponent,
    MapChart,
  ])
  const { data: worldMap } = useWorldMap()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const { openDrawer } = useDrawer()

  const providerRisks: ProviderRisk[] = useMemo(
    () =>
      riskScreening
        .flatMap(({ parentObject }) => {
          const isConnected = parentObject.linkStatus === ProviderLinkStatus.Connected
          const risks = []

          if (!isConnected && parentObject?.country) {
            const risk = parentObject.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
            risks.push(createRiskObject(parentObject, parentObject.country, RiskLocationEnum.Headquarter, risk))
          }

          if (parentObject?.organization?.organizationsCountriesExposure?.length) {
            parentObject.organization?.organizationsCountriesExposure
              .filter(exposure => exposure.variant === 'Operation')
              .forEach(operation => {
                const risk = operation.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
                risks.push(createRiskObject(parentObject, operation.country, RiskLocationEnum.OwnOperations, risk))
              })
          }

          if (parentObject?.organization?.organizationsCountriesExposure?.length) {
            parentObject.organization?.organizationsCountriesExposure
              .filter(exposure => exposure.variant === 'Subsupplier')
              .forEach(subSupplier => {
                const risk = subSupplier.country.risks.find(risk => risk.riskTypeId === selectedRiskId)
                risks.push(createRiskObject(parentObject, subSupplier.country, RiskLocationEnum.Subsupplier, risk))
              })
          }

          if (parentObject?.organization?.country) {
            const risk = parentObject.organization?.country?.risks?.find(risk => risk.riskTypeId === selectedRiskId)
            risks.push(
              createRiskObject(parentObject, parentObject.organization?.country, RiskLocationEnum.Headquarter, risk),
            )
          }

          return risks
        })
        .flat()
        .filter(ec => riskLevelFilter?.includes(ec.riskStatus ?? '')),
    [riskScreening, selectedRiskId, riskLevelFilter],
  )

  const exposedCountries = useMemo<ExposedCountry[]>(
    () =>
      providerRisks
        .filter((item, index, self) => index === self.findIndex(t => t.name === item.name))
        .map(providerRisk => ({
          name: providerRisk.name,
          countryName: providerRisk.countryName,
          value: providerRisk.value,
          riskStatus: providerRisk.riskStatus,
        })),
    [providerRisks],
  )

  const options: EChartsOption = useMemo(() => {
    return {
      geo: {
        nameProperty: 'iso_a2',
        map: 'worldMap',
        label: {
          show: false,
          color: '#feffff',
          fontSize: 10,
          formatter: (x: { name: string }) => exposedCountries.find(pc => pc.countryName === x.name)?.countryName ?? '',
        },
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: palette.grey[200],
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 5,
            borderWidth: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        roam: true,
        zoom: 1,
        scaleLimit: {
          min: 1,
          max: 18,
        },
        itemStyle: {
          borderColor: palette.common.white,
          borderWidth: 1,
        },
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: formattingItem => {
          const item = Array.isArray(formattingItem) ? formattingItem[0] : formattingItem
          const data = exposedCountries[item.dataIndex]

          if (!data?.countryName) return ''
          return `${data.countryName} - ${formatMessage({ id: `schemas.risk.${data.riskStatus ?? 'notApplicable'}` })}`
        },
      },
      visualMap: {
        type: 'piecewise',
        min: 0,
        max: 100,
        pieces: getMapPieces(palette, activeRisk),
        inverse: true,
        text: [
          formatMessage({ id: 'dashboard.riskByCountryMapLow' }),
          formatMessage({ id: 'dashboard.riskByCountryMapHigh' }),
        ],
        textStyle: {
          color: palette.text.primary,
        },
        calculable: true,
        seriesIndex: 0,
      },
      series: [
        {
          name: 'risk1',
          type: 'map',
          map: 'worldMap',
          geoIndex: 0,
          emphasis: {
            label: {
              show: true,
            },
          },
          itemStyle: {
            color: palette.grey[300],
          },
          select: {
            disabled: true,
          },
          data: exposedCountries,
        },
      ],
    }
  }, [activeRisk, exposedCountries, palette, formatMessage])

  const events = useMemo(
    () => ({
      click: ({ data }: { data: ExposedCountry }) => {
        if (data) {
          openDrawer(
            <DrawerViewCountryRisk
              title={data.countryName}
              subTitle={`${formatMessage({
                id: `schemas.risk.riskIndexes.${activeRisk?.source}.label`,
              })} ${formatMessage(
                { id: 'transparencyOverview.valueWithDotSeparatorBefore' },
                { value: formatMessage({ id: `schemas.risk.${data.riskStatus}` }) },
              )}`}
              providerRisks={providerRisks.filter(ec => ec.name === data.name)}
            />,
          )
        }
      },
    }),
    [openDrawer, formatMessage, providerRisks, activeRisk],
  )

  if (!worldMap) return null

  echarts.registerMap('worldMap', worldMap)

  return (
    <ReactEChartsCore
      option={options}
      echarts={echarts}
      style={{ width: '100%', height: height || 500 }}
      onEvents={events}
    />
  )
}

export default ExposedCountryRiskMap
