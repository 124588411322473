import endpoints from '@app/src/api/endpoints'
import { useFileDownload } from '@app/src/api/fetchHooks'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import useFetchAiSuggestions, { AiSuggestion } from '@app/src/pages/Questionnaire/hooks/useFetchAiSuggestions'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { AutoAwesomeOutlined } from '@mui/icons-material'
import { alpha, Box, Chip, CircularProgress, Link, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AiSuggestedAnswerChipProps {
  requestItem: RequestItem
  questionType: string
  disabled?: boolean
  onApply?: (value: string) => void
  isPreview: boolean
}

const getSuggestionText = (suggestion: AiSuggestion | undefined, questionType: string) => {
  if (!suggestion) return undefined
  if (questionType?.toLowerCase() === QuestionTypes.Options.toLowerCase()) {
    return suggestion.selectedOptions?.map(option => option).join(', ')
  }

  return suggestion?.response?.trim() ? suggestion.response : undefined
}

const AiSuggestedAnswerChip: React.FC<AiSuggestedAnswerChipProps> = ({
  requestItem,
  questionType,
  disabled,
  onApply,
  isPreview,
}) => {
  const { request } = useReport()
  const { formatMessage } = useIntl()
  const { downloadFile, isFileDownloading } = useFileDownload()
  const { hideInputField } = useResponseItemContext()

  const { fetchedData: suggestion, isLoading } = useFetchAiSuggestions(
    requestItem,
    questionType,
    isPreview,
    request?.periodName,
  )

  const text = getSuggestionText(suggestion, questionType)

  if ((!isLoading && !text) || hideInputField) return null

  const shouldShowTooltip = Boolean(suggestion?.citations.length) || (text?.length ?? 0) > 35

  return (
    <Tooltip
      disableHoverListener={!shouldShowTooltip}
      title={
        <>
          {(text?.length ?? 0) > 35 && (
            <>
              {text} <br />
              <br />
            </>
          )}
          {Boolean(suggestion?.citations.length) && (
            <span>
              {formatMessage({ id: 'suggestedAnswers.citationDescription' })}
              {suggestion?.citations.map((citation, i) => (
                <Box display="flex" alignItems="center" key={i}>
                  {isFileDownloading && <CircularProgress size={15} sx={{ mr: 1 }} />}
                  <Link
                    display="block"
                    onClick={() => downloadFile(endpoints.suggestionFile(citation))}
                    sx={{ cursor: 'pointer' }}
                  >
                    {citation}
                  </Link>
                </Box>
              ))}
            </span>
          )}
        </>
      }
    >
      <Chip
        size="medium"
        icon={<AutoAwesomeOutlined color="primary" sx={{ color: 'communication.dark' }} fontSize="small" />}
        label={
          isLoading
            ? formatMessage({ id: 'general.loading' })
            : `${formatMessage({ id: 'suggestedAnswers.suggestion' })}: ${text}`
        }
        deleteIcon={
          onApply && !isLoading ? (
            <Typography variant="body2">{formatMessage({ id: 'suggestedAnswers.applySuggestion' })}</Typography>
          ) : undefined
        }
        onDelete={() => onApply?.(text ?? '')}
        disabled={isLoading || disabled}
        sx={({ palette, typography }) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          height: 40,
          backgroundColor: 'communication.light',
          color: 'communication.dark',
          px: 1,

          '& .MuiChip-label': {
            flexGrow: 1,
          },
          '& .MuiChip-deleteIcon': {
            color: 'communication.dark',
            fontSize: typography.button.fontSize,
            fontWeight: typography.button.fontWeight,
            letterSpacing: typography.button.letterSpacing,

            '&:hover': {
              color: alpha(palette.communication.dark, 0.6),
            },
          },
        })}
      />
    </Tooltip>
  )
}
export default AiSuggestedAnswerChip
