import { SvgIcon } from '@mui/material'
import React from 'react'

export const IntervalChartIconThree = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <circle cx="8.7998" cy="8" r="7" fill="#E0E0E0" stroke="white" strokeWidth="2" />
        <mask
          id="mask0_5431_2422"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="13"
          height="12"
        >
          <circle cx="8.7998" cy="8" r="6" fill="#E0E0E0" />
        </mask>
        <g mask="url(#mask0_5431_2422)">
          <circle cx="8.7998" cy="8" r="6" fill="#E0E0E0" />
          <mask
            id="path-4-outside-1_5431_2422"
            maskUnits="userSpaceOnUse"
            x="1.7998"
            y="0"
            width="14"
            height="15"
            fill="black"
          >
            <rect fill="white" x="1.7998" width="14" height="15" />
            <path d="M8.7998 2C10.3422 2 11.8253 2.59396 12.9413 3.65858C14.0573 4.7232 14.7205 6.17671 14.7931 7.71736C14.8658 9.25802 14.3424 10.7675 13.3315 11.9324C12.3206 13.0973 10.9 13.8282 9.36445 13.9734C7.82893 14.1185 6.29649 13.6668 5.08524 12.7119C3.87399 11.757 3.07698 10.3724 2.85966 8.8454C2.64234 7.31842 3.0214 5.7664 3.91816 4.51152C4.81491 3.25664 6.16047 2.39529 7.67552 2.10628L8.7998 8V2Z" />
          </mask>
          <path
            d="M8.7998 2C10.3422 2 11.8253 2.59396 12.9413 3.65858C14.0573 4.7232 14.7205 6.17671 14.7931 7.71736C14.8658 9.25802 14.3424 10.7675 13.3315 11.9324C12.3206 13.0973 10.9 13.8282 9.36445 13.9734C7.82893 14.1185 6.29649 13.6668 5.08524 12.7119C3.87399 11.757 3.07698 10.3724 2.85966 8.8454C2.64234 7.31842 3.0214 5.7664 3.91816 4.51152C4.81491 3.25664 6.16047 2.39529 7.67552 2.10628L8.7998 8V2Z"
            fill="#97DAAE"
          />
          <path
            d="M8.7998 2C10.3422 2 11.8253 2.59396 12.9413 3.65858C14.0573 4.7232 14.7205 6.17671 14.7931 7.71736C14.8658 9.25802 14.3424 10.7675 13.3315 11.9324C12.3206 13.0973 10.9 13.8282 9.36445 13.9734C7.82893 14.1185 6.29649 13.6668 5.08524 12.7119C3.87399 11.757 3.07698 10.3724 2.85966 8.8454C2.64234 7.31842 3.0214 5.7664 3.91816 4.51152C4.81491 3.25664 6.16047 2.39529 7.67552 2.10628L8.7998 8V2Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-4-outside-1_5431_2422)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconThree
