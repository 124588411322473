import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useCustomScrollbarStyles } from '@app/src/components/Ui/CustomScrollbar'
import React, { ReactNode } from 'react'
import ActionButtons, { ActionButtonsProps } from '../ActionButtons'

export type DrawerProps = {
  title?: ReactNode
  description?: string
  closeDrawer?: () => void
  buttons?: ActionButtonsProps['buttons']
  buttonAlignment?: ButtonAlignment
  customHeader?: ReactNode
} & Omit<MuiDrawerProps, 'onClose' | 'title'>

export enum ButtonAlignment {
  Default,
  Right,
}

const useStyles = makeStyles({
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
})

const Drawer_DEPRECATED: React.FC<DrawerProps> = ({
  children,
  title,
  description,
  closeDrawer,
  buttons,
  buttonAlignment,
  customHeader,
  anchor = 'right',
  ...props
}) => {
  const classes = useStyles()
  const customScrollbar = useCustomScrollbarStyles()
  const { spacing } = useTheme()

  const justifyButtons = buttonAlignment === ButtonAlignment.Right ? 'end' : 'space-between'

  return (
    <MuiDrawer onClose={closeDrawer} anchor={anchor} {...props}>
      {!customHeader && (
        <>
          {title && (
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h4">{title}</Typography>
              <IconButton onClick={closeDrawer} size="large">
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>
          )}
          {description && (
            <Typography variant="body1" paragraph>
              {description}
            </Typography>
          )}
        </>
      )}

      {customHeader ?? null}

      <Box flexGrow={1} display="flex" className={classes.content}>
        <Box
          className={classNames(classes.content, customScrollbar.scrollbar)}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          data-testid="drawer-content"
        >
          {children}
        </Box>
      </Box>
      {buttons?.length ? (
        <Box display="flex" justifyContent={justifyButtons} columnGap={spacing(1)} alignItems="center" mt={2}>
          <ActionButtons buttons={buttons} />
        </Box>
      ) : null}
    </MuiDrawer>
  )
}

export default Drawer_DEPRECATED
