import LinkButton from '@app/src/components/LinkButton'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { InfoOutlined } from '@mui/icons-material'
import { Alert, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { ViewTypeName } from '../ManageRequests/ManageRequestsScene'
interface ResponseRateSummaryProps {
  totalAssessmentResponded: number
  totalInquriesSent: number
  questionnaireTemplateId: number
  periodFilterValue?: string
  isLoading?: boolean
}

const ResponseRateSummary: React.FC<ResponseRateSummaryProps> = ({
  totalAssessmentResponded,
  totalInquriesSent,
  questionnaireTemplateId,
  periodFilterValue,
  isLoading,
}) => {
  const { formatMessage } = useIntl()
  const resourceType = useCurrentProviderType({ isPlural: true })
  const { stringifyQueryFilters } = useStringifyQueryFilters()

  const submittedPercentage =
    totalAssessmentResponded === 0 && totalInquriesSent === 0
      ? 0
      : Math.round((totalAssessmentResponded / totalInquriesSent) * 100)

  if (!periodFilterValue) return null

  return (
    <Alert
      severity="info"
      variant="outlined"
      iconMapping={{
        info: <InfoOutlined color="primary" />,
      }}
      sx={{ borderColor: 'divider', '& .MuiAlert-action': { pt: 0 } }}
      action={
        Boolean(totalAssessmentResponded) && (
          <LinkButton
            size="small"
            variant="text"
            to={stringifyQueryFilters({
              url: generatePath(paths.manageRequest, {
                view: ViewTypeName.Requests,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'template.id',
                    value: questionnaireTemplateId,
                    operator: Operators.EqualTo,
                  },
                  {
                    name: 'periodName',
                    value: periodFilterValue,
                    operator: Operators.EqualTo,
                  },
                ],
              },
              includeCurrentFilters: true,
            })}
          >
            {formatMessage({ id: 'baseAssessment.overView.viewRequests' })}
          </LinkButton>
        )
      }
    >
      {isLoading ? (
        <Skeleton width={400} />
      ) : (
        <Typography variant="body1">
          {formatMessage(
            { id: 'baseAssessment.overView.responseRate' },
            {
              percent: submittedPercentage,
              answeredSuppliers: totalAssessmentResponded,
              providerType: resourceType,
              allSuppliers: totalInquriesSent,
            },
          )}
        </Typography>
      )}
    </Alert>
  )
}

export default ResponseRateSummary
