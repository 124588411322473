import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddProviderContactReason from '@app/src/components/Drawer/Views/AddProviderContact/DrawerViewAddProviderContactReason'
import DrawerViewOrganizationForm from '@app/src/components/Drawer/Views/DrawerViewOrganizationForm'
import { getOrganizationDisplayName } from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import FinalRiskRatingCell from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import TagsCell from '@app/src/components/Table/Cells/TagsCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import Avatar from '@app/src/components/Ui/Avatar'
import PersonAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/PersonAvatar'
import { ContactTabs } from '@app/src/pages/ResourceCollection/Collections/ProviderContactsOverview'
import { AllTabs } from '@app/src/pages/ResourceExplorer/Content'
import { Category, SupplierUsage } from '@app/src/types/categories'
import { Company, Provider, Supplier } from '@app/src/types/organizations'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Add, ChevronRight, LabelOutlined, PersonOffOutlined } from '@mui/icons-material'
import {
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'

interface ProviderDetailsProps {
  provider: Provider
  openEditDrawer?: () => void
}

const isSupplierType = (provider: Provider): provider is Supplier =>
  provider?.type.toLowerCase() === ResourceTypes.Supplier

const isCompanyType = (provider: Provider): provider is Company =>
  provider?.type.toLowerCase() === ResourceTypes.Company

const ProviderDetails: React.FC<ProviderDetailsProps> = ({ provider }): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const history = useHistory()

  const { data: categories } = useFetchResource<Category[]>({
    key: FetchKey.Category,
    endpoint: endpoints.category,
  })

  const amountOfTagsShow = provider.categoryOptions?.length ?? 0
  const hasPublicContact = Boolean(provider.organization.contacts?.length)

  return (
    <Stack flexGrow={1}>
      <Box textAlign="center" mb={2}>
        <Button
          startIcon={<LabelOutlined color="primary" />}
          onClick={() => openDrawer(<DrawerViewOrganizationForm provider={provider} />)}
          variant="outlined"
        >
          {formatMessage({ id: 'general.categorize' })}
        </Button>
      </Box>

      <Box height={100} flexGrow={1} overflow="scroll" px={3} pb={3}>
        <Typography variant="subtitle1" color="textSecondary" mb={2}>
          {formatMessage({ id: 'general.orgInformation' })}
        </Typography>

        <Stack spacing={2}>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.address' })}</Typography>
            <ValueCell value={provider?.organization?.locations?.[0]?.address} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.country' })}</Typography>
            <CountryCell countryCode={provider?.organization?.country?.countryCode} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.websiteAddress' })}</Typography>
            <ValueCell value={provider?.organization?.websiteAddress} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.industry' })}</Typography>
            <ValueCell
              value={provider?.organization?.industryNaceCode?.description}
              disableCell
              color="textSecondary"
            />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.subIndustry' })}</Typography>
            <ValueCell
              value={provider?.organization?.subIndustryNaceCode?.description}
              disableCell
              color="textSecondary"
            />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.employees' })}</Typography>
            <ValueCell
              value={
                provider?.organization?.companySizeRange
                  ? formatMessage({ id: `claim.companySize.${provider.organization.companySizeRange}` })
                  : '-'
              }
              disableCell
              color="textSecondary"
            />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.revenue' })}</Typography>
            <ValueCell
              value={
                provider?.organization?.revenueRange
                  ? formatMessage({ id: `claim.companyRevenue.${provider.organization.revenueRange}` })
                  : '-'
              }
              disableCell
              color="textSecondary"
            />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.vatNumber' })}</Typography>
            <ValueCell value={provider.organization?.vatNumber} disableCell color="textSecondary" />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.registrationNumber' })}</Typography>
            <ValueCell value={provider.organization?.registrationNumber} disableCell color="textSecondary" />
          </Stack>
        </Stack>

        <Divider light sx={{ my: 3 }} />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" color="textSecondary">
            {formatMessage({ id: 'schemas.provider.contacts' })}
          </Typography>
          <Button
            size="small"
            startIcon={<Add />}
            onClick={() =>
              openDrawer(
                <DrawerViewAddProviderContactReason
                  companyName={getOrganizationDisplayName(provider)}
                  providerId={provider.id}
                />,
              )
            }
          >
            {formatMessage({ id: 'general.add' })}
          </Button>
        </Stack>

        <ListItem disablePadding sx={{ mt: 2 }}>
          <ListItemButton
            role={undefined}
            onClick={() =>
              history.push(
                generatePath(paths.resourceExplorer, {
                  resourceType: provider.type.toLowerCase(),
                  resourceId: provider.id,
                  activeTabParam: AllTabs.Contact,
                  view: ContactTabs.Public,
                }),
              )
            }
            dense
            disableGutters
          >
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 2 }}>
              <AvatarGroup max={2}>
                {hasPublicContact ? (
                  provider.organization.contacts?.map(contact => (
                    <PersonAvatar key={contact.id} name={contact.user.name ?? ''} />
                  ))
                ) : (
                  <Avatar>
                    <PersonOffOutlined />
                  </Avatar>
                )}
              </AvatarGroup>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage(
                { id: 'general.contacts' },
                { count: provider.organization.contacts?.length ?? 0 },
              )}
              secondary={formatMessage({ id: 'general.viewAll' })}
            />
            <IconButton>
              <ChevronRight />
            </IconButton>
          </ListItemButton>
        </ListItem>

        <Divider light sx={{ my: 3 }} />

        {Boolean(amountOfTagsShow) && (
          <>
            <Typography variant="subtitle1" color="textSecondary" mb={2}>
              {formatMessage({ id: 'schemas.supplier.customCategories' })}
            </Typography>
            <Stack spacing={2}>
              {categories?.map(category => {
                const currentCategoryOptions = provider.categoryOptions?.filter(
                  catOpt => catOpt.categoryId === category.id,
                )
                return (
                  <React.Fragment key={category.id}>
                    {Boolean(currentCategoryOptions?.length) && (
                      <Stack>
                        <Typography variant="body2">{category.name}</Typography>
                        <TagsCell
                          amountOfTagsShow={amountOfTagsShow}
                          tags={
                            provider.categoryOptions
                              ?.filter(catOpt => catOpt.categoryId === category.id)
                              ?.map(catOpt => catOpt.name) ?? []
                          }
                          disableCell
                        />
                      </Stack>
                    )}
                  </React.Fragment>
                )
              })}
            </Stack>
            <Divider light sx={{ my: 3 }} />
          </>
        )}
        <Typography variant="subtitle1" color="textSecondary" mb={2}>
          {formatMessage({ id: 'resourceTypes.categories' })}
        </Typography>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.activityStatus' })}</Typography>
            <Box>
              <Chip
                variant="outlined"
                size="small"
                disabled={!provider.activityStatus || provider.activityStatus === 'NotSelected'}
                label={formatMessage({ id: `schemas.provider.activityStatusValues.${provider.activityStatus}` })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.finalRiskRating' })}</Typography>
            <FinalRiskRatingCell color="textSecondary" value={provider.finalRiskRating} disableCell />
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.priority' })}</Typography>
            <Box>
              <Chip
                variant="outlined"
                size="small"
                disabled={!provider.priority || provider.priority === 'NotSelected'}
                label={formatMessage({ id: `schemas.provider.priorityValues.${provider.priority}` })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}</Typography>
            <Box>
              <Chip
                variant="outlined"
                size="small"
                disabled={!provider.providerApprovalStatus || provider.providerApprovalStatus === 'NotSelected'}
                label={formatMessage({
                  id: `schemas.provider.providerApprovalStatusValues.${provider.providerApprovalStatus}`,
                })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.ownerUser' })}</Typography>
            <Box>
              <Chip
                disabled={!provider.ownerUser}
                variant="outlined"
                size="small"
                label={provider.ownerUser?.name || formatMessage({ id: 'general.notSelected' })}
              />
            </Box>
          </Stack>
          {isSupplierType(provider) && (
            <>
              <Stack>
                <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.supplierUsage' })}</Typography>
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    disabled={!provider.supplierUsage || provider.supplierUsage === 'NotSelected'}
                    label={formatMessage({
                      id: `schemas.provider.supplierUsageValues.${provider.supplierUsage ?? SupplierUsage.NotSelected}`,
                    })}
                  />
                </Box>
              </Stack>
              <Stack>
                <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.tier' })}</Typography>
                <Box>
                  <Chip
                    variant="outlined"
                    size="small"
                    disabled={!provider.tier}
                    label={
                      provider.tier
                        ? formatMessage({ id: 'schemas.supplier.tierNumber' }, { number: provider.tier })
                        : formatMessage({ id: 'general.notSelected' })
                    }
                  />
                </Box>
              </Stack>
              <Stack>
                <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.yearlySpend' })}</Typography>
                <Box>
                  <Chip
                    disabled={!provider.yearlySpend}
                    variant="outlined"
                    size="small"
                    label={provider.yearlySpend || formatMessage({ id: 'general.notAdded' })}
                  />
                </Box>
              </Stack>
            </>
          )}
          {isCompanyType(provider) && (
            <>
              <Stack>
                <Typography variant="body2">{formatMessage({ id: 'schemas.company.segment' })}</Typography>
                <Box>
                  <Chip
                    disabled={!provider.segment}
                    variant="outlined"
                    size="small"
                    label={provider.segment || formatMessage({ id: 'general.notSelected' })}
                  />
                </Box>
              </Stack>

              <Stack>
                <Typography variant="body2">{formatMessage({ id: 'schemas.company.companyStatus' })}</Typography>
                <Box>
                  <Chip
                    disabled={!provider.companyStatus}
                    variant="outlined"
                    size="small"
                    label={provider.companyStatus || formatMessage({ id: 'general.notSelected' })}
                  />
                </Box>
              </Stack>
            </>
          )}
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.duns' })}</Typography>
            <Box>
              <Chip
                disabled={!provider.duns}
                variant="outlined"
                size="small"
                label={provider.duns || formatMessage({ id: 'general.notAdded' })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.organization.customId' })}</Typography>
            <Box>
              <Chip
                disabled={!provider.customId}
                variant="outlined"
                size="small"
                label={provider.customId || formatMessage({ id: 'general.notAdded' })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.customName' })}</Typography>
            <Box>
              <Chip
                disabled={!provider.name}
                variant="outlined"
                size="small"
                label={provider.name || formatMessage({ id: 'general.notAdded' })}
              />
            </Box>
          </Stack>
          <Stack>
            <Typography variant="body2">{formatMessage({ id: 'schemas.provider.customCountry' })}</Typography>
            <Box>
              <Chip
                disabled={!provider.country?.name}
                variant="outlined"
                size="small"
                label={provider.country?.name || formatMessage({ id: 'general.notAdded' })}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

export default ProviderDetails
