import { SvgIconComponent } from '@mui/icons-material'
import { alpha, Box, BoxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import React, { ReactNode } from 'react'

export enum EmptyStateVariant {
  Error = 'error',
  Small = 'small',
}

interface CommonProps {
  title: ReactNode
  children?: ReactNode
  iconComponent: SvgIconComponent
  sx?: BoxProps['sx']
}

type ConditionalProps =
  | {
      variant?: undefined | EmptyStateVariant.Error
      description: ReactNode
    }
  | {
      variant: EmptyStateVariant.Small
      description?: undefined
    }

type EmptyStateProps = CommonProps & ConditionalProps

const EmptyState: React.FC<EmptyStateProps> = ({ variant, title, description, iconComponent, sx, children }) => {
  const { palette } = useTheme()
  const avatarColor = variant === EmptyStateVariant.Error ? palette.error.light : alpha(palette.secondary.main, 0.08)
  const iconColor = variant === EmptyStateVariant.Error ? 'primary' : 'secondary'

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      sx={sx}
      data-testid="empty-state"
    >
      <Box mb={2}>
        <Avatar
          size={variant === EmptyStateVariant.Small ? undefined : AvatarSize.Large}
          sx={{ backgroundColor: avatarColor }}
        >
          {React.createElement(iconComponent, { color: iconColor })}
        </Avatar>
      </Box>

      <Typography variant={variant === EmptyStateVariant.Small ? 'body1' : 'h6'} gutterBottom align="center">
        {title}
      </Typography>

      {description && (
        <Typography variant="body1" align="center" maxWidth={600}>
          {description}
        </Typography>
      )}

      {children && <Box mt={2}>{children}</Box>}
    </Box>
  )
}

export default EmptyState
