import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import AutomationCard from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationCard'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { ArrowForward } from '@mui/icons-material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationType: React.FC = () => {
  const { formatMessage } = useIntl()
  const { setActiveStep } = useCreationModalProgress()
  const { setValue } = useFormContext()

  const onTypeSelected = (requestAutomationType: RequestAutomationType) => {
    setActiveStep(currentValue => currentValue + 1)
    setValue(AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE, requestAutomationType)
  }

  return (
    <>
      <AutomationCard
        onClick={() => onTypeSelected(RequestAutomationType.FOR_EVERY_NEW_PROVIDER)}
        endIcon={<ArrowForward />}
        categoryText={formatMessage({ id: 'form.createAutomation.automationType.newCompany' })}
        requestAutomationType={RequestAutomationType.FOR_EVERY_NEW_PROVIDER}
      />
      <AutomationCard
        onClick={() => onTypeSelected(RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY)}
        categoryText={formatMessage({ id: 'form.createAutomation.automationType.category' })}
        endIcon={<ArrowForward />}
        requestAutomationType={RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY}
      />
    </>
  )
}
export default AutomationType
