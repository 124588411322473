import { TableRow } from '@mui/material'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewProvider from '@app/src/components/Drawer/Views/DrawerViewProvider'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import React from 'react'
import { Provider } from '@app/src/types/organizations'

interface ProductMappingCompaniesRowProps {
  row: Provider
}

export const ProductMappingCompaniesRow: React.FC<ProductMappingCompaniesRowProps> = ({ row: provider }) => {
  const { openDrawer } = useDrawer()
  return (
    <TableRow>
      <CompanyCell
        onClick={() => openDrawer(<DrawerViewProvider provider={provider} />)}
        sx={{
          cursor: 'pointer',
          '.MuiTypography-root': {
            flexGrow: 1,
            '&:hover': {
              textDecoration: 'underline',
            },
            '& > span:first-of-type:hover': {
              textDecoration: 'none',
            },
          },
        }}
        company={provider}
        maxRows={1}
      />
      <CountryCell countryCode={provider.organization?.country?.countryCode ?? provider.country?.countryCode} />
      <LinkCell href={provider.organization?.websiteAddress ?? provider.websiteAddress} />
      <ValueCell value={provider.vatNumber} />
      <ValueCell value={provider.registrationNumber} />
      <DateCell value={provider.createdAt} />
    </TableRow>
  )
}

export default ProductMappingCompaniesRow
