import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import {
  RESPONSE_ITEM_ORGANIZATION_SMART_FILTER_SETTING,
  RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING,
  RESPONSE_ITEM_QUESTIONNAIRE_SMART_FILTER_SETTING,
  RESPONSE_ITEM_QUESTION_SMART_FILTER_SETTING,
} from '@app/src/pages/ResourceCollection/Filters/Common/SmartFilterSettings'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import SmartFilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/SmartFilterFacetSelect'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AmplitudeTrackingEvents, Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, Grid, Tooltip } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import AllFiltersButton from './AllFiltersButton'
import CustomCategoriesFilters from './CustomCategoriesFilters'
import FilterFacetSelect from './FilterFacetSelect'
import FilterSection from './FilterSection'
import OptionWithLabel from './OptionWithLabel'
import RangeFilter from './RangeFilter'
import StatusOption from './StatusOption'

interface DatahubDataInsightsFiltersProps {
  allowedFilters: string[]
  implicitFilters?: FilterGroup[]
}

const DatahubDataInsightsFilters: React.FC<DatahubDataInsightsFiltersProps> = ({ allowedFilters, implicitFilters }) => {
  const { formatMessage } = useIntl()
  const legislationCategoryName = 'Legislation'
  const { openDrawer } = useDrawer()
  const { hasPermission, renderWithPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)
  const { solution } = useAuthentication().scope
  const { productMapping } = useFlags()
  const { trackEvent } = useAmplitude()

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters}>
        {({ clearQueryFilter, inDrawerFiltersCount, isFiltered }) => {
          return (
            <>
              <Grid item xs={2}>
                <Filter name="requestItem.template.options.id" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <FilterFacetSelect
                      multiple
                      facetsParam={{
                        key: [FetchKey.ResponseItemsFacets, filterName],
                        endpoint: endpoints.responseItemsWithFacets,
                        facetsParam: [{ name: 'response.request.template.sections.questions.options.name' }],
                        filter: [
                          {
                            name: 'response.request.template.sections.questions.options.category.name',
                            filters: [{ value: legislationCategoryName, operator: Operators.EqualTo }],
                          },
                        ],
                      }}
                      size="small"
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'schemas.question.legislation' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item xs={3}>
                <Filter name="response.request.questionnaireTemplateId" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <SmartFilterFacetSelect
                      multiple
                      facetSettings={{
                        key: [FetchKey.ResponseItemsFacets, filterName],
                        ...RESPONSE_ITEM_QUESTIONNAIRE_SMART_FILTER_SETTING,
                      }}
                      size="small"
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'resourceTypes.questionnaires' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item xs={3}>
                <Filter name="requestItem.questionId" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <SmartFilterFacetSelect
                      size="small"
                      multiple
                      facetSettings={{
                        key: [FetchKey.ResponseItemsFacets, filterName],
                        ...RESPONSE_ITEM_QUESTION_SMART_FILTER_SETTING,
                      }}
                      filterName={filterName}
                      onChange={onChange}
                      fieldLabel={formatMessage({ id: 'dataHub.selectQuestions' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item xs={2}>
                <Filter name="response.request.periodName" operator={Operators.In}>
                  {({ value, onChange, filterName }) => (
                    <SmartFilterFacetSelect
                      size="small"
                      multiple
                      facetSettings={{
                        key: [FetchKey.ResponseItemsFacets, filterName],
                        ...RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING,
                      }}
                      filterName={filterName}
                      onChange={(value: string[]) => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                      value={[value].flat()}
                    />
                  )}
                </Filter>
              </Grid>
              <Grid item lg={2} sm={3} xs={12}>
                <Box display="inline-block">
                  <AllFiltersButton
                    inDrawerFiltersCount={inDrawerFiltersCount}
                    openDrawer={() =>
                      openDrawer(
                        <DrawerViewFilters allowedFilters={allowedFilters}>
                          <Box px={2}>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.dataInsights' })}>
                              <Filter name="requestItem.template.options.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.ResponseItemsFacets, filterName],
                                      endpoint: endpoints.responseItemsWithFacets,
                                      facetsParam: [
                                        { name: 'response.request.template.sections.questions.options.name' },
                                      ],
                                      filter: [
                                        {
                                          name: 'response.request.template.sections.questions.options.category.name',
                                          filters: [{ value: legislationCategoryName, operator: Operators.EqualTo }],
                                        },
                                      ],
                                    }}
                                    size="small"
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.question.legislation' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="response.request.questionnaireTemplateId" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <SmartFilterFacetSelect
                                    multiple
                                    facetSettings={{
                                      key: [FetchKey.ResponseItemsFacets, filterName],
                                      ...RESPONSE_ITEM_QUESTIONNAIRE_SMART_FILTER_SETTING,
                                    }}
                                    size="small"
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'resourceTypes.questionnaires' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="requestItem.questionId" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <SmartFilterFacetSelect
                                    size="small"
                                    multiple
                                    facetSettings={{
                                      key: [FetchKey.ResponseItemsFacets, filterName],
                                      ...RESPONSE_ITEM_QUESTION_SMART_FILTER_SETTING,
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'dataHub.selectQuestions' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                              <Filter name="response.request.periodName" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <SmartFilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetSettings={{
                                      key: [FetchKey.ResponseItemsFacets, filterName],
                                      ...RESPONSE_ITEM_PERIOD_SMART_FILTER_SETTING,
                                    }}
                                    filterName={filterName}
                                    onChange={(value: string[]) => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </FilterSection>
                            <FilterSection label={formatMessage({ id: 'schemas.filter.answersDetails' })}>
                              <Box mb={2}>
                                <Filter
                                  name="response.request.subscriptions.target.organizationId"
                                  operator={Operators.In}
                                >
                                  {({ value, onChange, filterName }) => (
                                    <SmartFilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      multiple
                                      facetSettings={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        ...RESPONSE_ITEM_ORGANIZATION_SMART_FILTER_SETTING,
                                      }}
                                      size="small"
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter name="response.request.subscriptions.target.country.id" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [{ name: 'response.request.subscriptions.target.country.name' }],
                                      }}
                                      size="small"
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.filter.country.countryCode' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {verificationEnabled && (
                                <>
                                  <Box mb={2}>
                                    <Filter
                                      name="response.verifications.externalVerificationStatus"
                                      operator={Operators.In}
                                    >
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseItemsFacets, filterName],
                                            endpoint: endpoints.responseItemsWithFacets,
                                            facetsParam: [{ name: filterName }],
                                          }}
                                          size="small"
                                          renderOption={(props, option, { selected }) => (
                                            <StatusOption
                                              key={option.label}
                                              props={props}
                                              option={option}
                                              selected={selected}
                                              verification
                                            />
                                          )}
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({
                                            id: 'filters.responseItem.externalVerificationStatus',
                                          })}
                                          value={[value].flat()}
                                          skipNotSet={false}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Tooltip
                                                    key={option?.value?.toString() || index}
                                                    title={formatMessage({
                                                      id: `reporting.reviewStatuses.${option.label}`,
                                                    })}
                                                    sx={{ mt: 2 }}
                                                    arrow
                                                  >
                                                    <Chip
                                                      label={formatMessage({
                                                        id: `reporting.reviewStatuses.${option.label}`,
                                                      })}
                                                      size="small"
                                                      {...getTagProps({ index })}
                                                    />
                                                  </Tooltip>
                                                ),
                                            )
                                          }
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter
                                      name="response.verifications.internalVerificationStatus"
                                      operator={Operators.In}
                                    >
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseItemsFacets, filterName],
                                            endpoint: endpoints.responseItemsWithFacets,
                                            facetsParam: [{ name: filterName }],
                                          }}
                                          size="small"
                                          renderOption={(props, option, { selected }) => (
                                            <StatusOption
                                              key={option.label}
                                              props={props}
                                              option={option}
                                              selected={selected}
                                              verification
                                            />
                                          )}
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({
                                            id: 'filters.responseItem.internalVerificationStatus',
                                          })}
                                          value={[value].flat()}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Tooltip
                                                    key={option?.value?.toString() || index}
                                                    title={formatMessage({
                                                      id: `reporting.internalVerificationStatuses.${option.label}`,
                                                    })}
                                                    sx={{ mt: 2 }}
                                                    arrow
                                                  >
                                                    <Chip
                                                      label={formatMessage({
                                                        id: `reporting.internalVerificationStatuses.${option.label}`,
                                                      })}
                                                      size="small"
                                                      {...getTagProps({ index })}
                                                    />
                                                  </Tooltip>
                                                ),
                                            )
                                          }
                                          skipNotSet={false}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              <RangeFilter
                                uniqueIdFrom="responseDateFrom"
                                uniqueIdTo="responseDateTo"
                                name="response.submittedAt"
                                fieldLabelFrom={formatMessage({ id: 'schemas.response.responseDateFrom' })}
                                fieldLabelTo={formatMessage({ id: 'schemas.response.responseDateTo' })}
                              />
                            </FilterSection>
                            <FilterSection
                              defaultCollapsed
                              label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                            >
                              <Box mb={2}>
                                <Filter
                                  operator={Operators.In}
                                  name="response.request.subscriptions.target.ownerUserId"
                                >
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [{ name: 'response.request.subscriptions.target.ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter
                                  operator={Operators.In}
                                  name="response.request.subscriptions.target.finalRiskRating"
                                >
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [
                                          { name: 'response.request.subscriptions.target.finalRiskRating' },
                                        ],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                          <OptionIcon selected={selected} multiple />
                                          <FinalRiskRatingIcon value={option?.label} />
                                          {formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.finalRiskRatingValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => {
                                        if (value.length) {
                                          trackEvent({
                                            name: AmplitudeTrackingEvents.Analyze.Filter.RiskAssessment,
                                            eventProps: {
                                              filter_value: value,
                                            },
                                          })
                                        }

                                        onChange(value)
                                      }}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="response.request.subscriptions.target.priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [{ name: 'response.request.subscriptions.target.priority' }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              {solution !== Solutions.Finance && (
                                <>
                                  <Box mb={2}>
                                    <Filter
                                      operator={Operators.In}
                                      name="response.request.subscriptions.target.activityStatus"
                                    >
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseItemsFacets, filterName],
                                            endpoint: endpoints.responseItemsWithFacets,
                                            facetsParam: [
                                              { name: 'response.request.subscriptions.target.activityStatus' },
                                            ],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.activityStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.activityStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter
                                      operator={Operators.In}
                                      name="response.request.subscriptions.target.providerApprovalStatus"
                                    >
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.ResponseItemsFacets, filterName],
                                            endpoint: endpoints.responseItemsWithFacets,
                                            facetsParam: [
                                              { name: 'response.request.subscriptions.target.providerApprovalStatus' },
                                            ],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              )}
                              {renderWithPermission({
                                [Permissions.SOURCING_USER]: (
                                  <>
                                    <Box mb={2}>
                                      <Filter
                                        operator={Operators.In}
                                        name="response.request.subscriptions.target.supplierUsage"
                                      >
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.ResponseItemsFacets, filterName],
                                              endpoint: endpoints.responseItemsWithFacets,
                                              facetsParam: [
                                                { name: 'response.request.subscriptions.target.supplierUsage' },
                                              ],
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage({
                                                  id: option?.label
                                                    ? `schemas.provider.supplierUsageValues.${option.label}`
                                                    : 'general.notAvailable',
                                                })}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage({
                                                        id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                      })}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                    <Box mb={2}>
                                      <Filter operator={Operators.In} name="response.request.subscriptions.target.tier">
                                        {({ value, onChange, filterName }) => (
                                          <FilterFacetSelect
                                            implicitFilters={implicitFilters}
                                            size="small"
                                            multiple
                                            facetsParam={{
                                              key: [FetchKey.ResponseItemsFacets, filterName],
                                              endpoint: endpoints.responseItemsWithFacets,
                                              facetsParam: [
                                                { name: 'response.request.subscriptions.target.tier', isEnum: true },
                                              ],
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                              <OptionWithLabel
                                                key={option.label}
                                                props={props}
                                                label={formatMessage(
                                                  {
                                                    id: `schemas.provider.tierValues`,
                                                  },
                                                  { tier: option?.label ?? '' },
                                                )}
                                                multiple
                                                selected={selected}
                                              />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                              value?.map(
                                                (option, index) =>
                                                  option && (
                                                    <Chip
                                                      {...getTagProps({ index })}
                                                      key={option?.value?.toString() || index}
                                                      label={formatMessage(
                                                        {
                                                          id: `schemas.provider.tierValues`,
                                                        },
                                                        { tier: option.label },
                                                      )}
                                                      size="small"
                                                    />
                                                  ),
                                              )
                                            }
                                            filterName={filterName}
                                            onChange={onChange}
                                            fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                            value={[value].flat()}
                                          />
                                        )}
                                      </Filter>
                                    </Box>
                                  </>
                                ),
                              })}
                            </FilterSection>
                            <CustomCategoriesFilters baseFilterName="response.request.subscriptions.target" />

                            {productMapping && (
                              <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
                                <Filter
                                  operator={Operators.In}
                                  name="response.request.subscriptions.target.mappingNodes.actorTypeModel.id"
                                >
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [
                                          {
                                            name: 'response.request.subscriptions.target.mappingNodes.actorTypeModel.name',
                                          },
                                        ],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={option?.value?.toString() || index}
                                                label={formatMessage({
                                                  id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                                <Filter
                                  operator={Operators.In}
                                  name="response.request.subscriptions.target.mappingNodes.tier"
                                >
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.ResponseItemsFacets, filterName],
                                        endpoint: endpoints.responseItemsWithFacets,
                                        facetsParam: [
                                          {
                                            name: 'response.request.subscriptions.target.mappingNodes.tier',
                                            isEnum: true,
                                          },
                                        ],
                                      }}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </FilterSection>
                            )}
                          </Box>
                        </DrawerViewFilters>,
                      )
                    }
                  />
                </Box>

                {isFiltered && (
                  <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                    {formatMessage({ id: 'filters.clearAll' })}
                  </Button>
                )}
              </Grid>
            </>
          )
        }}
      </Filters>
    </Grid>
  )
}

export default DatahubDataInsightsFilters
