import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { dataHubAllowedFilters } from '.'
import { FilterGroup, RESPONSE_LATEST_SUBMITTED_FILTER } from '../../../Filters/useFilters'
import { transformResponseFilterNames } from './DataHubInsightsDetails'

const transformFilterNames = (filter: FilterGroup[]) =>
  filter.map(filter => {
    const responseFilter = filter.name.startsWith('response')
    const requestItemTemplateFilter = filter.name.startsWith('requestItem.template')
    const requestItemQuestionIdFilter = filter.name.startsWith('requestItem.questionId')
    return responseFilter
      ? { ...filter, name: filter.name.replace('response.request.', '') }
      : requestItemTemplateFilter
        ? { ...filter, name: filter.name.replace('requestItem.template.', 'template.sections.questions.') }
        : requestItemQuestionIdFilter
          ? { ...filter, name: filter.name.replace('requestItem.questionId', 'template.sections.questions.id') }
          : filter
  })

interface DataHubInsightsResponseRateProps {
  anyQuestionFilterSet: boolean
}

const DataHubInsightsResponseRate: React.FC<DataHubInsightsResponseRateProps> = ({ anyQuestionFilterSet }) => {
  const userFilters = useGetApiQueryFilters(dataHubAllowedFilters)
  const { formatMessage } = useIntl()

  const cleanFilters = transformFilterNames(userFilters)
  const cleanResponseFilters = transformResponseFilterNames(userFilters)

  const {
    facets: [requestStatuses],
    count: statusCount,
    isLoading: isLoadingStatuses,
  } = useFetchFacets({
    key: FetchKey.InquiryStatus,
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'status' }],
    filter: [...cleanFilters],
  })

  const { count: responsesCount, isLoading: isLoadingResponses } = useFetchFacets({
    key: [FetchKey.ResponseFacets],
    endpoint: endpoints.responsesWithFacets,
    facetsParam: [{ name: 'id' }],
    filter: [...cleanResponseFilters, RESPONSE_LATEST_SUBMITTED_FILTER],
  })

  const respondedRequestsCount =
    requestStatuses
      ?.filter(facet => facet.value !== InquiryStatus.Requested)
      ?.reduce((prev, curr) => prev + curr.count, 0) ?? 0

  const responsePercentage = (respondedRequestsCount * 100) / statusCount
  const safeResponsePercentage = isNaN(responsePercentage) ? 0 : responsePercentage
  const roundedResponsePercentage = Math.round(safeResponsePercentage)

  const sharedResponsesCount = responsesCount - respondedRequestsCount > 0 ? responsesCount - respondedRequestsCount : 0

  if (!anyQuestionFilterSet) return null

  if (isLoadingStatuses || isLoadingResponses) return <Skeleton width={500} height={50} />

  return (
    <Stack>
      <Typography>
        {formatMessage(
          { id: 'statistics.dataInsights.responseRate' },
          { statusCount, respondedRequestsCount, responsePercentage: roundedResponsePercentage },
        )}
      </Typography>
      {Boolean(sharedResponsesCount) && (
        <Typography color="textSecondary">
          {formatMessage({ id: 'statistics.dataInsights.sharedResponses' }, { sharedResponsesCount })}
        </Typography>
      )}
    </Stack>
  )
}

export default DataHubInsightsResponseRate
