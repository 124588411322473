import { formatDate } from '@app/src/components/Form/DatePicker'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem, RequestStatus } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { CheckCircle, InfoOutlined } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import ResponseValue from './ResponseValue'

interface SuggestPreviousAnswerProps {
  requestItem: RequestItem
  onApply: () => void
  onSkip: () => void
  isApplying?: boolean
}

const SuggestPreviousAnswer: FC<SuggestPreviousAnswerProps> = ({
  requestItem,
  onApply: onApplyProp,
  onSkip: onSkipProp,
  isApplying,
}) => {
  const { request } = useReport()
  const { responseItemView, setResponseItemView, previousAnswer } = useResponseItemContext()
  const [isLearnMoreDialogOpen, setIsLearnMoreDialogOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { trackEvent } = useAmplitude()

  const trackReuseSingleAnswer = (action: string) => {
    trackEvent({
      name: AmplitudeTrackingEvents.Provider.Questionnaire.SuggestedAnswerSingle,
      eventProps: {
        action: action,
      },
    })
  }

  const onApply = () => {
    trackReuseSingleAnswer('Applied')
    onApplyProp()
  }

  const onSkip = () => {
    trackReuseSingleAnswer('Skipped')
    onSkipProp()
  }

  const onViewAnswer = () => {
    trackReuseSingleAnswer('ViewAnswerClicked')
    setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
  }

  const toggleLearnMoreDialog = () => setIsLearnMoreDialogOpen(prev => !prev)

  const responseTarget = previousAnswer?.response?.request?.subscriptions?.[0]?.creatorOrganization
  const questionType = requestItem.questionType?.name.toLowerCase()

  if (!previousAnswer || request?.requestStatus !== RequestStatus.Sent) return null
  if (responseItemView === ResponseItemView.SkipQuestion) return null

  if (responseItemView === ResponseItemView.SuggestedAnswerAccepted) {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={4} py={2} bgcolor="grey.100">
        <Stack direction="row" spacing={2} alignItems="center">
          <CheckCircle />
          <Typography variant="subtitle1">
            {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.applied' })}
          </Typography>
        </Stack>
        <Button onClick={onViewAnswer}>{formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.viewAnswer' })}</Button>
      </Stack>
    )
  }

  if (responseItemView === ResponseItemView.SuggestedAnswerSkipped) {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={4} py={2} bgcolor="grey.100">
        <Stack>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.skipped' })}
          </Typography>
          <Typography>{formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.suggestedAnswerInfo' })}</Typography>
        </Stack>
        <Button variant="contained" onClick={onViewAnswer}>
          {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.viewAnswer' })}
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <Stack spacing={2} px={4} py={2} bgcolor="grey.100">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.suggestedAnswer' })}
            </Typography>
            <Typography>{formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.suggestedAnswerInfo' })}</Typography>
          </Stack>
          <Button size="small" onClick={toggleLearnMoreDialog} startIcon={<InfoOutlined />}>
            {formatMessage({ id: 'general.learnMore' })}
          </Button>
        </Stack>
        <Stack bgcolor="common.white" p={2} spacing={2}>
          <ResponseValue item={previousAnswer} questionType={questionType} unitSymbol={requestItem?.unit?.symbol} />
          <Divider />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button disabled={isApplying} variant="outlined" onClick={onSkip}>
              {formatMessage({ id: 'general.skip' })}
            </Button>
            <LoadingButton loading={isApplying} variant="contained" onClick={onApply}>
              {formatMessage({ id: 'general.apply' })}
            </LoadingButton>
          </Stack>
        </Stack>
        {Boolean(responseTarget) && (
          <Typography variant="body2">
            {formatMessage(
              { id: 'reporting.reuseFlow.reuseQuestion.previousAnswerDetails' },
              {
                target: responseTarget?.name,
                date: formatDate({
                  date: new Date(previousAnswer.createdAt),
                  withoutTime: true,
                }),
                hasPeriod: Boolean(previousAnswer.response?.request?.periodName),
                period: previousAnswer.response?.request?.periodName,
              },
            )}
          </Typography>
        )}
      </Stack>
      <Dialog open={isLearnMoreDialogOpen} onClose={toggleLearnMoreDialog}>
        <DialogTitle>{formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.aboutTitle' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'reporting.reuseFlow.reuseQuestion.aboutContent' }, { br })}</DialogContent>
        <DialogActions>
          <Button onClick={toggleLearnMoreDialog}>{formatMessage({ id: 'general.close' })}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SuggestPreviousAnswer
