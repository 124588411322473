import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { AssessmentQuestionnaireLink, AssessmentType } from '@app/src/types/resourceExplorer'

const useAssessmentQuestionnaireLink = (assessmentType: AssessmentType) => {
  const { items: allAssessmentQuestionnaireLink, isLoading: isLoadingAssessmentQuestionnaireLink } =
    useFetchCollectionWithPost<AssessmentQuestionnaireLink>({
      key: FetchKey.AssessmentQuestionnaireLink,
      endpoint: endpoints.assessmentQuestionnaireLink,
      payload: {
        filter: [
          {
            name: 'assessmentTemplate.AssessmentType',
            filters: [
              {
                operator: Operators.EqualTo,
                value: assessmentType,
              },
            ],
          },
        ],
        include: ['assessmentTemplate'],
        sort: { target: 'id', order: SortOrder.ASCENDING },
      },
    })

  //this would have to be resolved in a different way for the new assessments
  const assessmentQuestionnaireLink = allAssessmentQuestionnaireLink?.[0] || null
  return { assessmentQuestionnaireLink, isLoadingAssessmentQuestionnaireLink }
}

export default useAssessmentQuestionnaireLink
