import { CloseOutlined, RemoveRedEyeOutlined, SendOutlined } from '@mui/icons-material'
import * as Sentry from '@sentry/react'
import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import EmailPreview from '@app/src/components/ReferralContact/EmailPreview'
import SendInviteContent from '@app/src/components/ReferralContact/SendInviteContent'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { Provider } from '@app/src/types/organizations'
import { ReferralEmailHistory } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { useDrawer } from '../DrawerContext'
import DrawerView from '../DrawerView'
import DrawerViewInviteSentToReferralContact from './DrawerViewInviteSentToReferralContact'

interface DrawerViewSendInviteToReferralContactProps {
  provider: Provider
}

const DrawerViewSendInviteToReferralContact: React.FC<DrawerViewSendInviteToReferralContactProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  const { openDrawer, setOptions, options } = useDrawer()
  const { mutateAsync: sendingInvite, isLoading: isSendingInvite } = useCreateResource<ReferralEmailHistory, unknown>()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { trackEvent, account } = useAmplitude()

  const handleSendInvite = async () => {
    await sendingInvite(
      { url: endpoints.sendInviteToReferralContact(provider.id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          trackEvent({
            name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationSent,
            eventProps: {
              invitation_type: 'single',
              count: 1,
            },
          })
          openDrawer(<DrawerViewInviteSentToReferralContact provider={provider} />)
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          Sentry.captureException(error, {
            tags: { event: 'Error sending invite' },
            user: { id: String(account?.user?.id) },
            extra: { errorDetails: error.message, providerId: String(provider.id) },
          })
        },
      },
    )
  }

  const buttons: ActionButton[] = [
    ...insertIf<ActionButton>(!options.fullScreen, {
      label: formatMessage({
        id: 'referral.sendInvite.previewEmail',
      }),
      startIcon: <RemoveRedEyeOutlined />,
      variant: 'outlined',
      color: 'primary',
      onClick: () => setOptions({ fullScreen: true }),
      disabled: isSendingInvite,
    }),
    ...insertIf<ActionButton>(options.fullScreen, {
      label: formatMessage({
        id: 'referral.sendInvite.closePreview',
      }),
      startIcon: <CloseOutlined />,
      variant: 'outlined',
      color: 'primary',
      onClick: () => setOptions({ fullScreen: false }),
      disabled: isSendingInvite,
    }),
    {
      label: formatMessage({ id: 'referral.sendInvite.send' }),
      startIcon: <SendOutlined />,
      variant: 'contained',
      color: 'primary',
      onClick: handleSendInvite,
      type: 'submit',
      loading: isSendingInvite,
    },
  ]

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Accessor.InviteSupplier.InvitationViewed,
      eventProps: {
        invitation_type: 'single',
      },
    })
  }, [])

  return (
    <DrawerView
      title={formatMessage({ id: 'referral.sendInvite.title' })}
      subTitle={provider.name}
      stackButtons
      buttons={buttons}
      fullscreenContent={<EmailPreview provider={provider} />}
    >
      <SendInviteContent provider={provider} />
    </DrawerView>
  )
}

export default DrawerViewSendInviteToReferralContact
