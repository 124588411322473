import { Skeleton } from '@mui/material'
import StatusChip from '@app/src/components/StatusChip'
import React from 'react'
import { useIntl } from 'react-intl'
import { ExternalVerificationStatus, ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { isDateDueSoon, isPassedDeadline } from '@app/src/utils'

type ResponseStatusProps = {
  responseStatus?: ExternalVerificationStatus
  draftStatus?: ResponseDraftStatus
  deadline?: string
  isLoadingStatus?: boolean
}

const StatusChipFromResponseStatus: React.FC<Omit<ResponseStatusProps, 'isLoadingStatus'>> = ({
  deadline,
  draftStatus,
  responseStatus,
}) => {
  const { formatMessage } = useIntl()

  //Check verificationStatus first
  switch (responseStatus) {
    case ExternalVerificationStatus.CorrectionNeeded:
      return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.CorrectionNeeded' })} />
    case ExternalVerificationStatus.Approved:
      return <StatusChip color="success" label={formatMessage({ id: 'reporting.statuses.Approved' })} />
  }

  //Check submitted
  if (draftStatus === ResponseDraftStatus.Submitted)
    return <StatusChip color="info" label={formatMessage({ id: 'reporting.statuses.Submitted' })} />

  //Check deadline
  if (isDateDueSoon(deadline))
    return <StatusChip color="warning" label={formatMessage({ id: 'reporting.statuses.due.DueSoon' })} />
  if (isPassedDeadline(deadline))
    return <StatusChip color="error" label={formatMessage({ id: 'reporting.statuses.due.Overdue' })} />

  return <StatusChip color="default" label={formatMessage({ id: 'reporting.statuses.Sent' })} />
}

const ResponseStatus: React.FC<ResponseStatusProps> = ({ responseStatus, draftStatus, deadline, isLoadingStatus }) => {
  if (isLoadingStatus) {
    return <Skeleton variant="rounded" width="10%" height={24} />
  }

  return <StatusChipFromResponseStatus responseStatus={responseStatus} draftStatus={draftStatus} deadline={deadline} />
}

export default ResponseStatus
