import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import EmptyState from '@app/src/components/EmptyState'
import GuidanceBanner from '@app/src/components/GuidanceBanner'
import Avatar from '@app/src/components/Ui/Avatar'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import AutomationCard from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationCard'
import AutomationMenu from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationMenu'
import CreateAutomationModal from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomation } from '@app/src/types/automations'
import { getPeriodDisplayName } from '@app/src/utils'
import { OrganizationSettings } from '@app/src/wf-constants'
import { Sync, SyncDisabled, TaskOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'

const AutomationsConfig: React.FC = () => {
  const { productMapping } = useFlags()
  const { formatMessage } = useIntl()
  const [isAutomationDialogOpen, openAutomationDialog, closeAutomationDialog] = useDialogState(false)
  const settings = useOrganizationSettings()
  const confirm = useConfirm()
  const { hasPermission } = usePermissions()
  const hasFullProductMappingAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)
  const isSourcingUser = hasPermission(Permissions.SOURCING_USER)
  const showAutoAcceptValueChainResponses = productMapping && hasFullProductMappingAccess && isSourcingUser

  const { items: automations } = useFetchCollectionWithPost<RequestAutomation>({
    payload: {
      filter: [],
      include: ['templateAutomations.template'],
    },
    key: FetchKey.Automation,
    endpoint: endpoints.automations,
  })

  const handleClick = (): void => {
    openAutomationDialog()
  }

  const handleAutoAcceptValueChainResponseSwitch = (checked: boolean) => {
    if (checked) {
      confirm({
        content: formatMessage({ id: 'automations.productMapping.acceptLatestValueChains.confirmationDialog.text' }),
        confirmationText: formatMessage({
          id: 'automations.productMapping.acceptLatestValueChains.confirmationDialog.button',
        }),
      }).then(() => settings.set(OrganizationSettings.AutoAcceptValueChainResponses, true))
      return
    }
    settings.set(OrganizationSettings.AutoAcceptValueChainResponses, false)
  }

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <ConfigurationCollection
          actionButtons={[
            {
              label: formatMessage({ id: 'automatedSendout.create' }),
              variant: 'contained',
              onClick: handleClick,
            },
          ]}
          enablePadding={{ right: true }}
        >
          {showAutoAcceptValueChainResponses && (
            <Box>
              <Typography variant="h6" mb={2}>
                {formatMessage({ id: 'navbar.productMapping' })}
              </Typography>
              <Paper sx={{ mb: 4 }}>
                <List>
                  <ListItem
                    secondaryAction={
                      <FormControlLabel
                        disabled={settings.isLoading || settings.isSaving || settings.isFetching}
                        control={
                          <Switch
                            checked={settings.get(OrganizationSettings.AutoAcceptValueChainResponses)}
                            onChange={(_, checked) => handleAutoAcceptValueChainResponseSwitch(checked)}
                          />
                        }
                        label={
                          settings.isLoading || settings.isSaving || settings.isFetching ? (
                            <Skeleton width={20} variant="rounded" />
                          ) : settings.get(OrganizationSettings.AutoAcceptValueChainResponses) ? (
                            formatMessage({ id: 'automations.on' })
                          ) : (
                            formatMessage({ id: 'automations.off' })
                          )
                        }
                      />
                    }
                  >
                    <ListItemAvatar>
                      {settings.get(OrganizationSettings.AutoAcceptValueChainResponses) ? (
                        <Avatar sx={{ backgroundColor: 'info.dark', color: 'text.primary' }}>
                          <Sync />
                        </Avatar>
                      ) : (
                        <Avatar sx={{ backgroundColor: 'info.light', color: 'text.disabled' }}>
                          <SyncDisabled />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText disableTypography>
                      <Typography variant="subtitle1">
                        {formatMessage({ id: 'automations.productMapping.acceptLatestValueChains.title' })}
                      </Typography>
                      <Typography variant="body1">
                        {formatMessage({ id: 'automations.productMapping.acceptLatestValueChains.description' })}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Box>
          )}
          <Grid container spacing={2}>
            {automations?.length ? (
              automations?.map(automation => (
                <Grid item xs={12} lg={6} xl={4} key={automation.id}>
                  <AutomationCard
                    templateNames={automation?.templateAutomations?.map(t => t.template.title) ?? []}
                    endIcon={<AutomationMenu automationId={automation.id} />}
                    requestAutomationType={automation.automationType}
                    categoryFilters={automation?.categoryFilters}
                    createdAt={automation.createdAt}
                    deadlineOffsetDays={automation.deadlineOffSetDays}
                    periodName={getPeriodDisplayName(automation.periodStartsAt, automation.periodEndsAt)}
                  />
                </Grid>
              ))
            ) : (
              <EmptyState
                iconComponent={TaskOutlined}
                title={formatMessage({ id: 'automatedSendout.emptyState.title' })}
                description={formatMessage({ id: 'automatedSendout.emptyState.description' })}
              >
                <Button variant="outlined" onClick={handleClick}>
                  {formatMessage({ id: 'automatedSendout.emptyState.actionButtonText' })}
                </Button>
              </EmptyState>
            )}
          </Grid>
        </ConfigurationCollection>
        <GuidanceBanner
          title={formatMessage({ id: 'automatedSendout.guidanceBanner.title' })}
          description={formatMessage({ id: 'automatedSendout.guidanceBanner.description' })}
          disableBorderRadius
        />
      </Box>
      <CreationModalProgressContextProvider>
        <CreateAutomationModal onClose={closeAutomationDialog} open={isAutomationDialogOpen} />
      </CreationModalProgressContextProvider>
    </>
  )
}
export default AutomationsConfig
