import React from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { isDateOnly } from '@app/src/utils/helpersTs'

export interface DateDisplayProps {
  value: string
  withTime?: boolean
}

export const useGetFormattedDate = () => {
  const { formatDate } = useIntl()

  const formatValueToDate = ({ value, withTime }: DateDisplayProps) => {
    if (withTime) {
      return formatDate(value, {
        hour12: false,
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
      })
    }

    return formatDate(value, {
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    })
  }

  return { formatValueToDate }
}

const DateDisplay: React.FC<DateDisplayProps> = ({ value, withTime }) => {
  if (!value) {
    return <>-</>
  }

  if (withTime)
    return (
      <FormattedDate
        hour12={false}
        value={value}
        year="numeric"
        day="numeric"
        month="long"
        hour="2-digit"
        minute="2-digit"
      />
    )

  return (
    <FormattedDate year="numeric" day="numeric" month="long" value={isDateOnly(value) ? `${value} 00:00:00` : value} />
  )
}

export default DateDisplay
