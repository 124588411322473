import { TableRow } from '@mui/material'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'

import * as React from 'react'
import { useIntl } from 'react-intl'

const SentMappingRequestHeader: React.FC<SortableHeaderProps> = ({ toggleSorting, activeSorting }) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.status' })}
        sorting={{ name: 'status', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.productName' })}
        sorting={{ name: 'product.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.EXTRA_LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.sentTo' })}
        sorting={{ name: 'responderOrganization.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.deadline' })}
        sorting={{ name: 'deadline', toggleSorting, activeSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.mappingRequest.period' })}
        sorting={{ name: 'periodName', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.SMALL}
      />
    </TableRow>
  )
}

export default SentMappingRequestHeader
