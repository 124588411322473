import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import LoadingButton from '@app/src/components/LoadingButton'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import { OrganizationAssessmentSettings } from '@app/src/types/resourceExplorer'
import { NotificationSeverity } from '@app/src/wf-constants'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface OrganizationAssessmentSettingSaveModel {
  assessmentTemplateId: number
}

const AssessmentAdminConfig: React.FC = () => {
  const { account } = useAccount()
  const { formatMessage } = useIntl()
  const { mutateAsync, isLoading } = useUpdateResource<OrganizationAssessmentSettingSaveModel>()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { data: assessmentSettings = [] } = useFetchResource<OrganizationAssessmentSettings[]>({
    key: FetchKey.OrganizationAssessmentSettings,
    endpoint: endpoints.organizationAssessmentSettings,
    options: {
      enabled: Boolean(account?.organization),
      keepPreviousData: true,
    },
  })

  const handleChange = async (row: OrganizationAssessmentSettings) => {
    await mutateAsync(
      {
        url: endpoints.reEvaluateAssessmentsByTemplate,
        body: {
          assessmentTemplateId: row.assessmentTemplate.id,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage(
              {
                id: 'notifications.successfullyTriggeredReEvaluatingAssessments',
              },
              { name: row.assessmentTemplate.name },
            ),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.Account)
        },
        onError: () => {
          showSnackbar({
            message: formatMessage({
              id: 'notifications.triggeredReEvaluatingAssessmentsFailed',
            }),
            severity: NotificationSeverity.error,
          })
        },
      },
    )
  }

  return (
    <>
      <ConfigurationCollection enablePadding={{ top: false, right: true }}>
        <List>
          {assessmentSettings.map((item, index) => (
            <ListItem
              key={item.assessmentTemplate.id || index}
              secondaryAction={
                <LoadingButton variant="contained" size="large" onClick={() => handleChange(item)} loading={isLoading}>
                  {formatMessage({ id: 'baseAssessment.trigger' })}
                </LoadingButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  size={AvatarSize.XXL}
                  sx={({ palette }) => ({
                    backgroundColor: palette.secondary.main,
                    border: `1px solid ${palette.secondary.main}`,
                    color: palette.common.white,
                    mr: 2,
                  })}
                >
                  <FactCheckOutlinedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.assessmentTemplate.name}
                secondary={formatMessage({ id: 'baseAssessment.triggerDisclaimer' })}
              />
            </ListItem>
          ))}
        </List>
      </ConfigurationCollection>
    </>
  )
}

export default AssessmentAdminConfig
