import { SvgIcon } from '@mui/material'
import React from 'react'

export const IntervalChartIconThree = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="23" cy="23" r="18" fill="#E0E0E0" stroke="#fff" strokeWidth="4" />
        <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="7" width="32" height="32">
          <circle cx="23" cy="23" r="16" fill="#E0E0E0" />
        </mask>
        <g mask="url(#a)">
          <circle cx="23" cy="23" r="16" fill="#E0E0E0" />
          <mask id="b" maskUnits="userSpaceOnUse" x="11" y="5" width="30" height="36" fill="#000">
            <path fill="#fff" d="M11 5h30v36H11z" />
            <path d="M23 7a16 16 0 1 1-9.405 28.944L23 23V7Z" />
          </mask>
          <path d="M23 7a16 16 0 1 1-9.405 28.944L23 23V7Z" fill="#FFC774" />
          <path d="M23 7a16 16 0 1 1-9.405 28.944L23 23V7Z" stroke="#fff" strokeWidth="3.333" mask="url(#b)" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconThree
