import { SvgIcon } from '@mui/material'
import React from 'react'

export const IntervalChartIconTwo = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <circle cx="8.7998" cy="8" r="7" fill="#E0E0E0" stroke="white" strokeWidth="2" />
        <mask
          id="mask0_5431_2495"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="13"
          height="12"
        >
          <circle cx="8.7998" cy="8" r="6" fill="#E0E0E0" />
        </mask>
        <g mask="url(#mask0_5431_2495)">
          <circle cx="8.7998" cy="8" r="6" fill="#E0E0E0" />
          <mask
            id="path-4-outside-1_5431_2495"
            maskUnits="userSpaceOnUse"
            x="1.7998"
            y="1"
            width="14"
            height="14"
            fill="black"
          >
            <rect fill="white" x="1.7998" y="1" width="14" height="14" />
            <path d="M8.7998 2C9.84237 2 10.8669 2.27166 11.7726 2.78821C12.6782 3.30476 13.4335 4.04837 13.9643 4.94575C14.495 5.84313 14.7827 6.86332 14.7991 7.90576C14.8154 8.9482 14.5599 9.97691 14.0576 10.8905C13.5554 11.8041 12.8237 12.5711 11.9348 13.1158C11.0459 13.6606 10.0303 13.9643 8.98827 13.997C7.94622 14.0298 6.91361 13.7904 5.99223 13.3026C5.07084 12.8147 4.29247 12.0952 3.73384 11.215L8.7998 8V2Z" />
          </mask>
          <path
            d="M8.7998 2C9.84237 2 10.8669 2.27166 11.7726 2.78821C12.6782 3.30476 13.4335 4.04837 13.9643 4.94575C14.495 5.84313 14.7827 6.86332 14.7991 7.90576C14.8154 8.9482 14.5599 9.97691 14.0576 10.8905C13.5554 11.8041 12.8237 12.5711 11.9348 13.1158C11.0459 13.6606 10.0303 13.9643 8.98827 13.997C7.94622 14.0298 6.91361 13.7904 5.99223 13.3026C5.07084 12.8147 4.29247 12.0952 3.73384 11.215L8.7998 8V2Z"
            fill="#FFC774"
          />
          <path
            d="M8.7998 2C9.84237 2 10.8669 2.27166 11.7726 2.78821C12.6782 3.30476 13.4335 4.04837 13.9643 4.94575C14.495 5.84313 14.7827 6.86332 14.7991 7.90576C14.8154 8.9482 14.5599 9.97691 14.0576 10.8905C13.5554 11.8041 12.8237 12.5711 11.9348 13.1158C11.0459 13.6606 10.0303 13.9643 8.98827 13.997C7.94622 14.0298 6.91361 13.7904 5.99223 13.3026C5.07084 12.8147 4.29247 12.0952 3.73384 11.215L8.7998 8V2Z"
            stroke="white"
            strokeWidth="2"
            mask="url(#path-4-outside-1_5431_2495)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconTwo
