import EmptyState from '@app/src/components/EmptyState'
import TableSkeleton from '@app/src/components/Skeleton/Table'
import { SetPage, SetPageSize, Sort, ToggleSorting } from '@app/src/types/filter'
import { ROWS_PER_PAGE_OPTIONS } from '@app/src/wf-constants'
import { ListOutlined } from '@mui/icons-material'
import {
  Box,
  Table as MUITable,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TablePaginationProps,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

export interface SortableHeaderProps {
  toggleSorting: ToggleSorting
  activeSorting: Sort
}

export enum TableCellWidth {
  EMPTY = 40,
  EXTRA_SMALL = 104,
  SMALL = 144,
  MEDIUM_SMALL = 195,
  MEDIUM = 240,
  LARGE = 328,
  EXTRA_LARGE = 504,
}

interface TableProps<T> {
  data: T[]
  count: number
  isLoading: boolean
  isError: boolean
  HeaderComponent: React.FC
  RowComponent: React.FC<{ row: T }>
  rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions']
  emptyState?: React.ReactNode
  noPagination?: boolean
  page: number
  pageSize: number
  setPage?: SetPage
  setPageSize?: SetPageSize
  stickyColumnIndex?: number
}

const Table = <T,>({
  data,
  page,
  pageSize,
  count,
  isLoading,
  isError,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  HeaderComponent,
  RowComponent,
  emptyState,
  setPage,
  setPageSize,
  noPagination,
  stickyColumnIndex,
}: TableProps<T>) => {
  const { formatMessage } = useIntl()

  const handlePageChange: TablePaginationProps['onPageChange'] = (e, pageNumber) => {
    const page = pageNumber + 1
    setPage?.(page)
  }

  const handleChangeRowsPerPage: TablePaginationProps['onRowsPerPageChange'] = e => {
    const pageSize = parseInt(e.target.value)
    setPageSize?.(pageSize)
  }

  if (isLoading) {
    return <TableSkeleton rows={8} />
  }

  if (isError) {
    return (
      <EmptyState
        iconComponent={ListOutlined}
        title={formatMessage({ id: 'table.errorTitle' })}
        description={formatMessage({ id: 'table.errorDescription' })}
        sx={{ my: 2 }}
      />
    )
  }

  if (!data.length) {
    return emptyState ? (
      <>{emptyState}</>
    ) : (
      <EmptyState
        iconComponent={ListOutlined}
        title={formatMessage({ id: 'table.emptyStateTitle' })}
        description={formatMessage({ id: 'table.emptyStateDescription' })}
        sx={{ my: 2 }}
      />
    )
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0} sx={{ overflowY: 'auto' }}>
        <MUITable stickyHeader aria-label="table" size="medium">
          <TableHead
            sx={[
              ...(stickyColumnIndex
                ? [
                    {
                      [`& .MuiTableCell-root:nth-child(${stickyColumnIndex})`]: {
                        position: 'sticky',
                        left: 0,
                        zIndex: 20,
                      },
                    },
                  ]
                : []),
            ]}
          >
            <HeaderComponent />
          </TableHead>

          <TableBody
            sx={[
              ...(stickyColumnIndex
                ? [
                    {
                      '& .MuiTableRow-root': {
                        [`& .MuiTableCell-root:nth-child(${stickyColumnIndex})`]: {
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: 'background.paper',
                        },
                      },
                      '& .MuiTableRow-root.Mui-selected:hover': {
                        [`& .MuiTableCell-root:nth-child(${stickyColumnIndex})`]: {
                          backgroundColor: '#E3E3E3',
                        },
                      },
                      '& .MuiTableRow-root.Mui-selected': {
                        [`& .MuiTableCell-root:nth-child(${stickyColumnIndex})`]: {
                          backgroundColor: '#EDEDED',
                        },
                      },
                    },
                  ]
                : []),
            ]}
          >
            {data?.map((row, index) => <RowComponent key={index} row={row} />)}
          </TableBody>
        </MUITable>
      </TableContainer>

      {!noPagination && (
        <Box display="flex" alignItems="center" justifyContent="flex-end" mr={8}>
          {count && (
            <Typography variant="body1" data-testid="table-count">
              {formatMessage({ id: 'resourceCollections.general.resultsCount' }, { count })}
            </Typography>
          )}
          <TablePagination
            component="div"
            count={count}
            page={page - 1} // backend pagination is 1-based and material-ui pagination is 0-based*/
            rowsPerPage={pageSize}
            rowsPerPageOptions={rowsPerPageOptions}
            labelRowsPerPage={formatMessage({ id: 'table.rowsPerPage' })}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid="table-pagination"
          />
        </Box>
      )}
    </>
  )
}

export default Table
