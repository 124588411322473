import {
  FiveIntervalChartIconFive,
  FiveIntervalChartIconFour,
  FiveIntervalChartIconOne,
  FiveIntervalChartIconThree,
  FiveIntervalChartIconTwo,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/FiveIntervals'
import {
  ThreeIntervalChartIconOne,
  ThreeIntervalChartIconThree,
  ThreeIntervalChartIconTwo,
} from '@app/src/pages/ResourceCollection/Collections/Assessments/icons/ThreeIntervals'
import Assessment from '@app/src/types/assessment'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import ParentCellOrFragment from './ParentCellOrFragment'
import ValueCell from './ValueCell'

type PieChartCellProps = {
  assessment: Assessment
  disableCell?: boolean
}

const IconBasedOnInterval: React.FC<{ intervals: number; value: number }> = ({ intervals, value }) => {
  if (intervals === 3) {
    switch (value) {
      case 1:
        return <ThreeIntervalChartIconOne />
      case 2:
        return <ThreeIntervalChartIconTwo />
      case 3:
        return <ThreeIntervalChartIconThree />
    }
  }

  switch (value) {
    case 1:
      return <FiveIntervalChartIconOne />
    case 2:
      return <FiveIntervalChartIconTwo />
    case 3:
      return <FiveIntervalChartIconThree />
    case 4:
      return <FiveIntervalChartIconFour />
    case 5:
      return <FiveIntervalChartIconFive />
  }
}

const PieChartCell: React.FC<PieChartCellProps> = ({ assessment, disableCell }) => {
  const { formatMessage } = useIntl()
  const intervals = assessment?.assessmentTemplate.levels ?? 5

  if (!assessment.percentage) {
    return <ValueCell disableCell={disableCell} />
  }

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Box display="flex" alignItems="center">
        <IconBasedOnInterval intervals={intervals} value={assessment.totalLevel ?? 0} />
        <Typography ml={1}>{formatMessage({ id: 'general.percentage' }, { value: assessment.percentage })}</Typography>
      </Box>
    </ParentCellOrFragment>
  )
}

export default PieChartCell
