import { SvgIcon } from '@mui/material'
import React from 'react'

export const IntervalChartIconFour = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22.999" cy="23" r="18" fill="#E0E0E0" stroke="#fff" strokeWidth="4" />
        <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="6" y="7" width="33" height="32">
          <circle cx="22.999" cy="23" r="16" fill="#E0E0E0" />
        </mask>
        <g mask="url(#a)">
          <circle cx="22.999" cy="23" r="16" fill="#E0E0E0" />
          <mask id="b" maskUnits="userSpaceOnUse" x="4.999" y="5" width="36" height="36" fill="#000">
            <path fill="#fff" d="M4.999 5h36v36h-36z" />
            <path d="M22.999 7A16 16 0 1 1 7.782 18.056L23 23V7Z" />
          </mask>
          <path d="M22.999 7A16 16 0 1 1 7.782 18.056L23 23V7Z" fill="#97DAAE" />
          <path d="M22.999 7A16 16 0 1 1 7.782 18.056L23 23V7Z" stroke="#fff" strokeWidth="3.333" mask="url(#b)" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default IntervalChartIconFour
