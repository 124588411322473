import AssessmentCell from '@app/src/components/Table/Cells/AssessmentCell'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { Provider } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import React from 'react'

interface AssessmentRowProps {
  row: Provider
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
  assessmentTemplatesIds: number[]
}

const AssessmentCellFromId: React.FC<{ provider: Provider; assessmentId: number }> = ({ provider, assessmentId }) => {
  const assessment = provider?.assessments?.find(assessment => assessment.assessmentTemplateId === assessmentId)

  if (!assessment) {
    return <ValueCell />
  }

  return <AssessmentCell key={assessmentId} assessment={assessment} />
}

export const AssessmentRow: React.FC<AssessmentRowProps> = ({
  row: provider,
  onCheckboxChange,
  selectedProviders,
  assessmentTemplatesIds,
}) => {
  const isChecked = Boolean(selectedProviders?.some(selectedProviderId => selectedProviderId === provider.id))

  return (
    <TableRow>
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, provider)} />
      <CompanyCell company={provider} drawer />
      {assessmentTemplatesIds.map(id => (
        <AssessmentCellFromId key={id} assessmentId={id} provider={provider} />
      ))}
    </TableRow>
  )
}

export default AssessmentRow
