import { default as endpoints } from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CategoryOptionLabel from '@app/src/components/CategoryOptionLabel'
import LinkButton from '@app/src/components/LinkButton'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RequestAutomation } from '@app/src/types/automations'
import { Category } from '@app/src/types/categories'
import { addSpaceBetweenWords } from '@app/src/utils/helpers'
import { Roles } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Alert, Box, Skeleton, Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

const CATEGORY_OPTIONS_NAME = 'categoryOptions.id'

const flattenAndMerge = (arr: FilterGroup[][]): { name: string; value: string[] }[] => {
  const result: Record<string, Set<string>> = {}

  arr.flat().forEach(item => {
    if (!result[item.name]) {
      result[item.name] = new Set()
    }
    item.filters.forEach(filter => {
      ;(Array.isArray(filter.value) ? filter.value : []).forEach(value => result[item.name].add(String(value)))
    })
  })

  return Object.entries(result).map(([name, values]) => ({
    name,
    value: Array.from(values),
  }))
}

interface RequestAutomationWarningAlertProps {
  automationType: RequestAutomationType
  providerType?: string
  currentCategory?: Category
  isCustomCategoryOption?: boolean
}

const AlertLoader = ({ canGoToAutomation }: { canGoToAutomation: boolean }) => {
  return (
    <Stack my={-2}>
      <Skeleton width={350} height={50} />
      {canGoToAutomation && <Skeleton width={100} height={50} />}
    </Stack>
  )
}

const RequestAutomationWarningAlert: React.FC<RequestAutomationWarningAlertProps> = ({
  automationType,
  providerType,
  currentCategory,
  isCustomCategoryOption,
}) => {
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { formatMessage } = useIntl()
  const { role } = useAuthentication().scope
  const canGoToAutomation = role === Roles.Admin || role === Roles.WfAdmin

  const automationRedirectUrl = stringifyQueryFilters({
    url: generatePath(paths.configurations, { configurationsPage: CONFIGURATION_PAGE_IDS.Automations }),
    queryParams: {},
  })

  const { items: automations, isLoading: isAutomationsLoading } = useFetchCollectionWithPost<RequestAutomation>({
    payload: {
      filter: [],
      include: [],
    },
    key: FetchKey.Automation,
    endpoint: endpoints.automations,
  })

  const hasAutomationForNewProvider = Boolean(
    automations.find(automation => automation.automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER),
  )

  const hasAutomationForCategory = Boolean(
    automations.find(automation => automation.automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY),
  )

  const automationsFilters = automations.map(automation => automation.categoryFilters ?? [])

  const flattenedAutomationFilters = flattenAndMerge(automationsFilters)

  const propertyNames = flattenedAutomationFilters
    .filter(filter => filter.name !== CATEGORY_OPTIONS_NAME)
    ?.filter(filter => filter.name !== 'country.id')
    ?.filter(filter => filter.name !== 'mappingNodes.actorTypeModel.id')
    ?.map(filters => formatMessage({ id: `dashboard.sourcing.companyStatistics.${filters.name}` }))

  const categoryOptionsFilter = flattenedAutomationFilters.find(filter => filter.name === CATEGORY_OPTIONS_NAME)

  const getFilterLabel = (property: string, value: string[]): React.ReactNode => {
    if (property.endsWith('priority')) {
      return `${value
        .map(v =>
          formatMessage({
            id: `schemas.provider.priorityValues.${v}`,
          }),
        )
        .join(', ')}`
    }
    if (property.endsWith('tier')) {
      return `${value.map(v => formatMessage({ id: 'schemas.provider.tierValues' }, { tier: v })).join(', ')}`
    }
    if (property.endsWith('supplierUsage')) {
      return `${value
        .map(v =>
          formatMessage({
            id: `schemas.provider.supplierUsageValues.${v}`,
          }),
        )
        .join(', ')}`
    }
    if (property.endsWith('finalRiskRating')) {
      return `${value
        .map(v =>
          formatMessage({
            id: `schemas.provider.finalRiskRatingValues.${v}`,
          }),
        )
        .join(', ')}`
    }
    if (property.endsWith('providerApprovalStatus')) {
      return `${value
        .map(v =>
          formatMessage({
            id: `schemas.provider.providerApprovalStatusValues.${v}`,
          }),
        )
        .join(', ')}`
    }
    if (property.endsWith('activityStatus') || property.endsWith('actorType')) {
      return `${value.map(v => addSpaceBetweenWords(v)).join(', ')}`
    }
    return ''
  }

  const { data: categories, isLoading: isCategoriesLoading } = useFetchCollectionWithPost<Category>({
    key: FetchKey.Category,
    endpoint: endpoints.categoryGetWithIds,
    payload: {
      filter: [
        {
          name: CATEGORY_OPTIONS_NAME,
          filters: [
            {
              value: categoryOptionsFilter?.value,
              operator: Operators.In,
            },
          ],
        },
      ],
      include: [],
    },
    options: {
      enabled: Boolean(categoryOptionsFilter),
    },
  })

  const currentCategoryValues = flattenedAutomationFilters.find(
    category => category.name === (isCustomCategoryOption ? CATEGORY_OPTIONS_NAME : currentCategory?.name),
  )?.value

  const categoryNames = categories?.items?.map(category => category.name)

  const isLoading = isAutomationsLoading || isCategoriesLoading

  const renderAutomationsButton = () =>
    canGoToAutomation && (
      <Box ml={-1} pt={1} mb={-1}>
        <LinkButton to={automationRedirectUrl ? automationRedirectUrl : ''}>
          <Typography variant="button">
            {formatMessage({ id: 'schemas.provider.requestAutomationGoToPage' })}
          </Typography>
        </LinkButton>
      </Box>
    )

  if (isCustomCategoryOption) {
    return (
      <>
        {currentCategoryValues ? (
          <Alert severity="info" sx={{ mb: 1 }}>
            <>
              {isLoading ? (
                <AlertLoader canGoToAutomation={canGoToAutomation} />
              ) : (
                <>
                  {`${formatMessage({ id: `schemas.provider.requestAutomationCustomCategoriesWarning` })}: `}
                  {currentCategoryValues.map((v, i) => (
                    <CategoryOptionLabel
                      key={v}
                      categoryOptionId={String(v)}
                      categoryCount={currentCategoryValues.length}
                      isLastItem={i === currentCategoryValues.length - 1}
                    />
                  ))}
                  <br />
                  {renderAutomationsButton()}
                </>
              )}
            </>
          </Alert>
        ) : null}
      </>
    )
  }

  if (currentCategory) {
    return (
      <>
        {currentCategoryValues ? (
          <Alert severity="info">
            {isLoading ? (
              <AlertLoader canGoToAutomation={canGoToAutomation} />
            ) : (
              <>
                {`${formatMessage({ id: 'schemas.provider.requestAutomationCustomCategoriesWarning' })}: `}
                {getFilterLabel(currentCategory.name, currentCategoryValues)}
                <br />
                {renderAutomationsButton()}
              </>
            )}
          </Alert>
        ) : null}
      </>
    )
  }

  if (hasAutomationForNewProvider && automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER) {
    return (
      <Alert severity="info">
        {isLoading ? (
          <AlertLoader canGoToAutomation={canGoToAutomation} />
        ) : (
          formatMessage(
            { id: 'resourceCollections.create.requestAutomationOnNewProviderWarning' },
            { provider: formatMessage({ id: `general.${capitalize(providerType)}` }, { count: 1 }) },
          )
        )}
      </Alert>
    )
  }

  if (hasAutomationForCategory && automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY) {
    return (
      <Alert severity="info">
        {isLoading ? (
          <AlertLoader canGoToAutomation={canGoToAutomation} />
        ) : (
          formatMessage(
            { id: 'schemas.provider.requestAutomationCategoriesWarning' },
            { categories: [...propertyNames, ...(categoryNames ?? [])].join(', ') },
          )
        )}
      </Alert>
    )
  }

  return null
}

export default RequestAutomationWarningAlert
