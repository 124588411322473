import { SearchOutlined } from '@mui/icons-material'
import TextField, { TextFieldProps } from '@app/src/components/Ui/TextField'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useFiltersContext } from './Filters'
import { Operators } from './useFilters'

type TextFieldFilterProps = {
  defaultValue: string | string[]
  label: string
  filterName: string
  operator: Operators
} & TextFieldProps

const TextFieldFilter = ({ defaultValue, filterName, operator, ...props }: TextFieldFilterProps): JSX.Element => {
  const { setQueryFilter, filters } = useFiltersContext()
  const [fieldValue, setFieldValue] = useState<TextFieldFilterProps['value']>(defaultValue)

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    //SET FIELD VALUE
    setFieldValue(e.target.value)

    //DEBOUNCE THE QUERY PARAM
    debouncedQueryChange(e.target.value)
  }

  const debouncedQueryChange = useDebouncedCallback(value => {
    setQueryFilter(filterName, value ?? '', operator)
  }, 500)

  useEffect(() => {
    if (!filters[filterName]) {
      setFieldValue('')
    }
    if (props.value && fieldValue !== props.value) {
      setFieldValue(props.value)
    }
  }, [filters[filterName], props.value])

  return (
    <TextField
      variant="outlined"
      InputProps={{
        endAdornment: <SearchOutlined color="action" />,
      }}
      {...props}
      value={fieldValue}
      onChange={handleChange}
    />
  )
}

export default TextFieldFilter
