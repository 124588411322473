import { QuestionStepRenderPropParams } from '@app/src/components/CreateNewRequestModal/Generic/QuestionStep'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useMultipleStepsFormContext } from '@app/src/context/MultipleStepsFormContext'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQuestionnairePeriodOptions } from '@app/src/hooks/questionnairePeriodOptions'

type Props = QuestionStepRenderPropParams
export interface SelectPeriodStepFormData {
  period?: string
}

const SelectPeriodStep: React.FC<Props> = ({ enableNext }) => {
  const { formatMessage } = useIntl()
  const { watch, control, setValue } = useFormContext<SelectPeriodStepFormData>()
  const periodStartsAtWatch = watch('period')
  const { values } = useMultipleStepsFormContext()
  const { wfStandardYearOptions } = useQuestionnairePeriodOptions()

  useEffect(() => {
    enableNext(Boolean(periodStartsAtWatch))
  }, [periodStartsAtWatch])

  useEffect(() => {
    if (values['period']) {
      setValue('period', values['period'])
    }
  }, [values])

  return (
    <Select
      options={wfStandardYearOptions}
      fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
      onInputChange={(e, value) => {
        if (!value) setValue('period', undefined)
      }}
      defaultValue={null}
      enableAutoSelect
      name="period"
      control={control}
    />
  )
}

export default SelectPeriodStep
