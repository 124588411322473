import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useRiskSettings } from '@app/src/context/RiskSettingsContext'
import AllFiltersButton from '@app/src/pages/ResourceCollection/Filters/AllFiltersButton'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterSection from '@app/src/pages/ResourceCollection/Filters/FilterSection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { RiskStatus } from '@app/src/types/resourceExplorer'
import { Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import CustomCategoriesFilters from './CustomCategoriesFilters'
import FilterFacetSelect from './FilterFacetSelect'
import OptionWithLabel from './OptionWithLabel'
import PreFetchedMultiFilterFacet from './PreFetchedMultiFilterFacet'
import RangeFilter from './RangeFilter'

type RiskScreeningAllRisksFiltersProps = FiltersProps & {
  riskStatusPreSelectedValues?: string[]
}

const RiskScreeningAllRisksFilters: React.FC<RiskScreeningAllRisksFiltersProps> = ({
  allowedFilters,
  resetPage,
  riskStatusPreSelectedValues = [RiskStatus.Extreme, RiskStatus.High],
}) => {
  const { activeRiskSettings } = useRiskSettings()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()
  const { solution } = useAuthentication().scope
  const { renderWithPermission } = usePermissions()
  const { productMapping } = useFlags()

  return (
    <Box display="flex" justifyContent="flex-end" flexDirection="row" p={0}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ filters, clearQueryFilter, inDrawerFiltersCount }) => {
          const isFiltered = Object.keys(filters).length > 1
          return (
            <Box display="flex">
              <Box width="300px" mr={1}>
                <Filter name="riskStatus" operator={Operators.In}>
                  {({ filterName, value, onChange }) => (
                    <PreFetchedMultiFilterFacet
                      endpoint={endpoints.riskScreeningAllRisksRiskStatusFacet}
                      filterName={filterName}
                      onChange={value => onChange(value, true)}
                      fieldLabel={formatMessage({ id: 'schemas.risk.riskStatusFilter' })}
                      value={[value].flat()}
                      preSelectedValues={riskStatusPreSelectedValues}
                      renderOption={(props, option, { selected }): JSX.Element => (
                        <li {...props}>
                          <OptionIcon selected={selected} multiple />
                          {formatMessage({
                            id: `schemas.risk.${option.label}`,
                          })}
                        </li>
                      )}
                      disabled={!activeRiskSettings.length}
                      renderTags={(value, getTagProps) =>
                        value?.map(
                          (option, index) =>
                            option && (
                              <Chip
                                {...getTagProps({ index })}
                                key={index}
                                label={formatMessage({
                                  id: `schemas.risk.${option.label}`,
                                })}
                                size="small"
                              />
                            ),
                        )
                      }
                    />
                  )}
                </Filter>
              </Box>

              <AllFiltersButton
                inDrawerFiltersCount={inDrawerFiltersCount}
                openDrawer={() =>
                  openDrawer(
                    <DrawerViewFilters allowedFilters={allowedFilters}>
                      <Box px={2}>
                        <FilterSection label={formatMessage({ id: 'schemas.filter.riskScreening' })}>
                          <Filter name="riskStatus" operator={Operators.In}>
                            {({ filterName, value, onChange }) => (
                              <PreFetchedMultiFilterFacet
                                endpoint={endpoints.riskScreeningAllRisksRiskStatusFacet}
                                filterName={filterName}
                                onChange={value => onChange(value, true)}
                                fieldLabel={formatMessage({ id: 'schemas.risk.riskStatusFilter' })}
                                value={[value].flat()}
                                preSelectedValues={riskStatusPreSelectedValues}
                                renderOption={(props, option, { selected }): JSX.Element => (
                                  <li {...props}>
                                    <OptionIcon selected={selected} multiple />
                                    {formatMessage({
                                      id: `schemas.risk.${option.label}`,
                                    })}
                                  </li>
                                )}
                                disabled={!activeRiskSettings.length}
                                renderTags={(value, getTagProps) =>
                                  value?.map(
                                    (option, index) =>
                                      option && (
                                        <Chip
                                          {...getTagProps({ index })}
                                          key={index}
                                          label={formatMessage({
                                            id: `schemas.risk.${option.label}`,
                                          })}
                                          size="small"
                                        />
                                      ),
                                  )
                                }
                              />
                            )}
                          </Filter>
                        </FilterSection>
                        <FilterSection label={formatMessage({ id: 'schemas.filter.companyDetails' })}>
                          <Filter operator={Operators.In} name="id">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: 'name' }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.organization.name' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>

                          <Filter operator={Operators.In} name="country.id">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: 'country.name' }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.filter.country.select' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                          <Filter operator={Operators.In} name="linkStatus">
                            {({ value, filterName, onChange }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: filterName, isEnum: true }],
                                  sort: {
                                    order: SortOrder.ASCENDING,
                                    target: 'value',
                                  },
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.filter.linkStatus' })}
                                value={[value].flat()}
                                renderOption={(props, option, { selected }) => (
                                  <OptionWithLabel
                                    key={option.label}
                                    props={props}
                                    label={formatMessage({
                                      id: option?.label
                                        ? `resourceExplorer.provider.status.${option.label}`
                                        : 'general.notAvailable',
                                    })}
                                    multiple
                                    selected={selected}
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value?.map(
                                    (option, index) =>
                                      option && (
                                        <Chip
                                          {...getTagProps({ index })}
                                          key={option.value.toString() || index}
                                          label={formatMessage({
                                            id: `resourceExplorer.provider.status.${option.label}`,
                                          })}
                                          size="small"
                                        />
                                      ),
                                  )
                                }
                              />
                            )}
                          </Filter>
                          <RangeFilter
                            uniqueIdFrom="createdFrom"
                            uniqueIdTo="createdTo"
                            name="createdAt"
                            fieldLabelFrom={formatMessage({ id: 'schemas.filter.createdFrom' })}
                            fieldLabelTo={formatMessage({ id: 'schemas.filter.createdTo' })}
                          />
                          <Filter operator={Operators.In} name="websiteAddress">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: 'websiteAddress', isEnum: true }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.organization.websiteAddress' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                          <Filter operator={Operators.In} name="vatNumber">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: 'vatNumber', isEnum: true }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.organization.vatNumber' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                          <Filter operator={Operators.In} name="registrationNumber">
                            {({ value, onChange, filterName }) => (
                              <FilterFacetSelect
                                size="small"
                                multiple
                                facetsParam={{
                                  key: [FetchKey.Provider, filterName],
                                  endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                  facetsParam: [{ name: 'registrationNumber', isEnum: true }],
                                }}
                                filterName={filterName}
                                onChange={value => onChange(value)}
                                fieldLabel={formatMessage({ id: 'schemas.organization.registrationNumber' })}
                                value={[value].flat()}
                              />
                            )}
                          </Filter>
                        </FilterSection>
                        <FilterSection
                          defaultCollapsed
                          label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                        >
                          {solution === Solutions.Finance ? (
                            <>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <li {...props}>
                                          <OptionIcon selected={selected} multiple />
                                          <FinalRiskRatingIcon value={option?.label} />
                                          {formatMessage({
                                            id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                          })}
                                        </li>
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={value => {
                                        onChange(value)
                                      }}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="ownerUserId">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'ownerUser.name' }],
                                      }}
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="activityStatus">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'activityStatus', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.activityStatusValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={formatMessage({
                                                  id: `schemas.provider.activityStatusValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="finalRiskRating">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'finalRiskRating', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.finalRiskRatingValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                icon={<FinalRiskRatingIcon value={option.label} />}
                                                label={formatMessage({
                                                  id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="priority">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'priority', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.priorityValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={formatMessage({
                                                  id: `schemas.provider.priorityValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                              <Box mb={2}>
                                <Filter operator={Operators.In} name="providerApprovalStatus">
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.Provider, filterName],
                                        endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                        facetsParam: [{ name: 'providerApprovalStatus', isEnum: true }],
                                      }}
                                      renderOption={(props, option, { selected }) => (
                                        <OptionWithLabel
                                          key={option.label}
                                          props={props}
                                          label={formatMessage({
                                            id: option?.label
                                              ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                              : 'general.notAvailable',
                                          })}
                                          multiple
                                          selected={selected}
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={formatMessage({
                                                  id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                })}
                                                size="small"
                                              />
                                            ),
                                        )
                                      }
                                      filterName={filterName}
                                      onChange={onChange}
                                      fieldLabel={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
                                      value={[value].flat()}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </>
                          )}
                          {renderWithPermission({
                            [Permissions.SOURCING_USER]: (
                              <>
                                <Box mb={2}>
                                  <Filter operator={Operators.In} name="supplierUsage">
                                    {({ value, onChange, filterName }) => (
                                      <FilterFacetSelect
                                        size="small"
                                        multiple
                                        facetsParam={{
                                          key: [FetchKey.Provider, filterName],
                                          endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                          facetsParam: [{ name: 'supplierUsage', isEnum: true }],
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                          <OptionWithLabel
                                            key={option.label}
                                            props={props}
                                            label={formatMessage({
                                              id: option?.label
                                                ? `schemas.provider.supplierUsageValues.${option.label}`
                                                : 'general.notAvailable',
                                            })}
                                            multiple
                                            selected={selected}
                                          />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                          value?.map(
                                            (option, index) =>
                                              option && (
                                                <Chip
                                                  {...getTagProps({ index })}
                                                  key={index}
                                                  label={formatMessage({
                                                    id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                  })}
                                                  size="small"
                                                />
                                              ),
                                          )
                                        }
                                        filterName={filterName}
                                        onChange={onChange}
                                        fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                        value={[value].flat()}
                                      />
                                    )}
                                  </Filter>
                                </Box>
                              </>
                            ),
                          })}
                        </FilterSection>
                        <CustomCategoriesFilters />

                        {productMapping && (
                          <FilterSection defaultCollapsed label={formatMessage({ id: 'navbar.productMapping' })}>
                            <Filter operator={Operators.In} name="mappingNodes.actorTypeModel.id">
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                    facetsParam: [{ name: 'mappingNodes.actorTypeModel.name', isEnum: true }],
                                  }}
                                  renderOption={(props, option, { selected }) => (
                                    <OptionWithLabel
                                      key={option.label}
                                      props={props}
                                      label={formatMessage({
                                        id: option?.label
                                          ? `schemas.mappingNode.actorTypeValues.${option.label}`
                                          : 'general.notAvailable',
                                      })}
                                      multiple
                                      selected={selected}
                                    />
                                  )}
                                  renderTags={(value, getTagProps) =>
                                    value?.map(
                                      (option, index) =>
                                        option && (
                                          <Chip
                                            {...getTagProps({ index })}
                                            key={index}
                                            label={formatMessage({
                                              id: `schemas.mappingNode.actorTypeValues.${option.label}`,
                                            })}
                                            size="small"
                                          />
                                        ),
                                    )
                                  }
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.mappingNode.actorType' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                            <Filter operator={Operators.In} name="mappingNodes.tier">
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.Provider, filterName],
                                    endpoint: endpoints.riskScreeningAllRisksProviderFacet,
                                    facetsParam: [{ name: 'mappingNodes.tier', isEnum: true }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.mappingNode.tier' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                          </FilterSection>
                        )}
                      </Box>
                    </DrawerViewFilters>,
                  )
                }
              />

              {isFiltered && (
                <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                  {formatMessage({ id: 'filters.clearAll' })}
                </Button>
              )}
            </Box>
          )
        }}
      </Filters>
    </Box>
  )
}

export default RiskScreeningAllRisksFilters
