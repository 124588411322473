import Select, { Option } from '@app/src/components/Form/Select/ControlledSelect'
import { AUTOMATION_QUESTION_KEYS } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

const AutomationDeadlineOffset: React.FC = () => {
  const { control, watch } = useFormContext()
  const { formatMessage } = useIntl()
  const dayOffsetValues = [15, 30, 60]
  const automationType = watch(AUTOMATION_QUESTION_KEYS.AUTOMATION_TYPE) as RequestAutomationType

  const offsetOptions: Option<number>[] = dayOffsetValues.map(days => ({
    value: days,
    label: formatMessage(
      {
        id:
          automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
            ? 'form.createAutomation.deadlineOffset.optionTextForEveryNewProvider'
            : 'form.createAutomation.deadlineOffset.optionTextForProvidersInCategory',
      },
      { days: days },
    ),
  }))

  return (
    <Select
      name={AUTOMATION_QUESTION_KEYS.DEADLINE_OFFSET}
      control={control}
      fieldLabel={formatMessage({ id: 'form.createAutomation.deadlineOffset.fieldLabel' })}
      options={offsetOptions}
    />
  )
}
export default AutomationDeadlineOffset
