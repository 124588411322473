import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import OnboardingForm from '@app/src/pages/SolutionSelector/OnboardingForm'
import { Provider } from '@app/src/types/organizations'
import { Add, ArrowBack } from '@mui/icons-material'
import { Button, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import TextTruncate from 'react-text-truncate'
import OrganizationRequestAccess from './OrganizationRequestAccess'

type SimilarOrganizationsProps = {
  similarOrganizations: Provider[]
  onPreviousStep: () => void
  onNextStep: () => void
  onClose: () => void
}

const SimilarOrganizations: React.FC<SimilarOrganizationsProps> = ({
  similarOrganizations,
  onPreviousStep,
  onNextStep,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const [showRequestAccess, setShowRequestAccess] = useState<Provider>()

  if (showRequestAccess) {
    return (
      <OrganizationRequestAccess
        organization={showRequestAccess}
        onBack={() => setShowRequestAccess(undefined)}
        onClose={onClose}
      />
    )
  }

  return (
    <OnboardingForm
      header={formatMessage({ id: 'claim.createClaim.similarOrganizationStep.header' })}
      subheader={formatMessage({ id: 'claim.createClaim.similarOrganizationStep.subHeader' })}
    >
      <Stack gap={2} height={100} flexGrow={1} overflow="scroll">
        {similarOrganizations.map(organization => (
          <ListItem
            key={organization.id}
            sx={({ palette }) => ({ border: `1px solid ${palette.divider}`, borderRadius: 1 })}
          >
            <ListItemAvatar>
              <CompanyAvatar organizationName={organization.name} />
            </ListItemAvatar>
            <ListItemText
              primary={<TextTruncate line={1} text={organization.name} />}
              secondary={organization.country?.name ?? formatMessage({ id: 'general.notAdded' })}
            />
            <Button onClick={() => setShowRequestAccess(organization)} sx={{ ml: 1 }}>
              {formatMessage({ id: 'general.view' })}
            </Button>
          </ListItem>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Button startIcon={<ArrowBack />} onClick={onPreviousStep}>
          {formatMessage({ id: 'general.back' })}
        </Button>
        <Button variant="outlined" startIcon={<Add />} onClick={onNextStep}>
          {formatMessage({ id: 'general.createNewOrg' })}
        </Button>
      </Stack>
    </OnboardingForm>
  )
}

export default SimilarOrganizations
